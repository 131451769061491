const PLAN_SWITCHER_OPTION_TYPE_ME = 'me';
const PLAN_SWITCHER_OPTION_TYPE_CHILDRENS = 'childrens';
const PLAN_SWITCHER_OPTION_TYPE_ME_AND_PARTNER = 'me-and-partner';
const PLAN_SWITCHER_OPTION_TYPE_FAMILY = 'family';

exports.PLAN_SWITCHER_OPTION_TYPE_ME = PLAN_SWITCHER_OPTION_TYPE_ME;
exports.PLAN_SWITCHER_OPTION_TYPE_CHILDRENS = PLAN_SWITCHER_OPTION_TYPE_CHILDRENS;
exports.PLAN_SWITCHER_OPTION_TYPE_ME_AND_PARTNER = PLAN_SWITCHER_OPTION_TYPE_ME_AND_PARTNER;
exports.PLAN_SWITCHER_OPTION_TYPE_FAMILY = PLAN_SWITCHER_OPTION_TYPE_FAMILY;

exports.PLAN_SWITCHER_OPTION_ME = {
  key: PLAN_SWITCHER_OPTION_TYPE_ME,
  label: 'Para mi',
  showUnitPicker: false,
  defaultFamilyGroupMembers: 1,
};
exports.PLAN_SWITCHER_OPTION_CHILDRENS = {
  key: PLAN_SWITCHER_OPTION_TYPE_CHILDRENS,
  label: 'Para mis hijos',
  showUnitPicker: true,
  defaultFamilyGroupMembers: 2,
};
exports.PLAN_SWITCHER_OPTION_ME_AND_PARTNER = {
  key: PLAN_SWITCHER_OPTION_TYPE_ME_AND_PARTNER,
  label: 'Para mi y mi pareja',
  showUnitPicker: false,
  defaultFamilyGroupMembers: 2,
};
exports.PLAN_SWITCHER_OPTION_FAMILY = {
  key: PLAN_SWITCHER_OPTION_TYPE_FAMILY,
  label: 'Para mi y mi familia',
  showUnitPicker: true,
  defaultFamilyGroupMembers: 4,
};
