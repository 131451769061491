import { getHttpBearerConfig } from 'services/httpServices';
import axios from 'axios';
import { authRef } from 'config/firebase';
import { getUserWithToken } from 'helpers/coreHelper';

const appConfig = require('config/appConfig');

export const listUsers = (currentUserToken) => {
  return new Promise(function (resolve, reject) {
    let config = getHttpBearerConfig(currentUserToken);

    return axios
      .get(`${appConfig.gcfUrl}/users`, config)
      .then((response) => {
        console.log('users response:', response);
        resolve(response.data.users);
      })
      .catch((e) => {
        console.log('error:', e);

        reject(e);
      });
  });
};

export const getUser = (userId) => {
  return new Promise(function (resolve, reject) {
    //var user = firebase.auth().currentUser;
    authRef.onAuthStateChanged((user) => {
      if (!user) {
        reject({ code: 'AUTH_REQUIRED', message: 'Auth required' });

        return;
      }

      user.getIdToken().then((currentUserToken) => {
        let config = getHttpBearerConfig(currentUserToken);

        return axios
          .get(`${appConfig.gcfUrl}/users/${userId}`, config)
          .then((response) => {
            console.log('user response:', response);
            resolve(response.data);
          })
          .catch((e) => {
            console.log('error:', e);

            reject(e);
          });
      });
    });
  });
};

export const createUser = (
  email,
  firstName,
  lastName,
  phoneNumber,
  photoURL,
  plan,
  paymentData
) => {
  return new Promise(function (resolve, reject) {
    getUserWithToken().then((uwt) => {
      let config = getHttpBearerConfig(uwt.token);

      const data = {
        email,
        firstName,
        lastName,
        phoneNumber,
        photoURL,

        plan: plan,
        paymentData: paymentData,
      };
      return axios
        .post(`${appConfig.gcfUrl}/users`, data, config)
        .then((response) => {
          console.log('users response:', response);
          resolve(response);
        })
        .catch((e) => {
          console.log('error:', e);

          reject(e);
        });
    });
  });
};

export const removeUser = (userId) => {
  return new Promise(function (resolve, reject) {
    getUserWithToken().then((uwt) => {
      let config = getHttpBearerConfig(uwt.token);

      return axios
        .delete(`${appConfig.gcfUrl}/users/${userId}`, config)
        .then((response) => {
          console.log('users response:', response);
          resolve(response);
        })
        .catch((e) => {
          console.log('error:', e);

          reject(e);
        });
    });
  });
};

export const lunchCRMUsersApiUpdate = () => {
  return new Promise(function (resolve, reject) {
    getUserWithToken().then((uwt) => {
      let config = getHttpBearerConfig(uwt.token);

      return axios
        .post(`${appConfig.gcfUrl}/users/sync-crm-users-from-api`, null, config)
        .then((response) => {
          console.log('sync crm plans response:', response);
          if (response && response.data) resolve(response.data);
          else resolve(null);
        })
        .catch((e) => {
          console.log('error:', e);

          reject(e);
        });
    });
  });
};
