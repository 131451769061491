import React from "react";

import classnames from "classnames";
// reactstrap components
import {
  Button,
  Modal,
  FormGroup,
  Input,
  
  InputGroupText,
  InputGroup,
} from "reactstrap";
// core components

class CoveragesEditionModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { coverage: this.props.coverage };
  }

  componentWillReceiveProps(nextProps) {
    //console.log("componentWillReceiveProps", nextProps);
    this.setState({ coverage: nextProps.coverage });
  }

  onMedicSelectionChanged(item, event) {
    this.state.coverage.medics = this.state.coverage.medics || [];

    if (event.target.checked) {
      if (!this.hasItem(this.state.coverage.medics, item)) {
        this.state.coverage.medics.push(item);
      }
    } //off
    else {
      if (this.hasItem(this.state.coverage.medics, item)) {
        let newList = [];
        this.state.coverage.medics.forEach((existent) => {
          if (existent.id !== item.id) newList.push(existent);
        });
        this.state.coverage.medics = newList;
      }
    }
    this.setState({ coverage: this.state.coverage });
  }

  onEmergencySelectionChanged(item, event) {
    this.state.coverage.emergencies = this.state.coverage.emergencies || [];

    if (event.target.checked) {
      if (!this.hasItem(this.state.coverage.emergencies, item)) {
        this.state.coverage.emergencies.push(item);
      }
    } //off
    else {
      if (this.hasItem(this.state.coverage.emergencies, item)) {
        let newList = [];
        this.state.coverage.emergencies.forEach((existent) => {
          if (existent.id !== item.id) newList.push(existent);
        });
        this.state.coverage.emergencies = newList;
      }
    }
    this.setState({ coverage: this.state.coverage });
  }

  onLifeInsuranceSelectionChanged(item, event) {
    this.state.coverage.lifeInsurances =
      this.state.coverage.lifeInsurances || [];

    if (event.target.checked) {
      if (!this.hasItem(this.state.coverage.lifeInsurances, item)) {
        this.state.coverage.lifeInsurances.push(item);
      }
    } //off
    else {
      if (this.hasItem(this.state.coverage.lifeInsurances, item)) {
        let newList = [];
        this.state.coverage.lifeInsurances.forEach((existent) => {
          if (existent.id !== item.id) newList.push(existent);
        });
        this.state.coverage.lifeInsurances = newList;
      }
    }
    this.setState({ coverage: this.state.coverage });
  }

  onHomeCareSelectionChanged(item, event) {
    this.state.coverage.homeCares = this.state.coverage.homeCares || [];

    if (event.target.checked) {
      if (!this.hasItem(this.state.coverage.homeCares, item)) {
        this.state.coverage.homeCares.push(item);
      }
    } //off
    else {
      if (this.hasItem(this.state.coverage.homeCares, item)) {
        let newList = [];
        this.state.coverage.homeCares.forEach((existent) => {
          if (existent.id !== item.id) newList.push(existent);
        });
        this.state.coverage.homeCares = newList;
      }
    }
    this.setState({ coverage: this.state.coverage });
  }

  onTelemedicineSelectionChanged(item, event) {
    this.state.coverage.telemedicines = this.state.coverage.telemedicines || [];

    if (event.target.checked) {
      if (!this.hasItem(this.state.coverage.telemedicines, item)) {
        this.state.coverage.telemedicines.push(item);
      }
    } //off
    else {
      if (this.hasItem(this.state.coverage.telemedicines, item)) {
        let newList = [];
        this.state.coverage.telemedicines.forEach((existent) => {
          if (existent.id !== item.id) newList.push(existent);
        });
        this.state.coverage.telemedicines = newList;
      }
    }
    this.setState({ coverage: this.state.coverage });
  }

  hasItem = (items, item) => {
    if (!items) return false;

    if (!item) return false;

    if (!items.length) return false;

    let exists = false;
    items.forEach((i) => {
      if (i.id === item.id) exists = true;
    });

    return exists;
  };

  render() {
    return (
      <>
        <Modal
          className="modal-dialog-centered"
          isOpen={this.props.coverageEditionModalIsOpen}
          toggle={() => this.props.toggleModal(this.props.modalName)}
        >
          <div className="modal-header">
            <h6 className="modal-title" id="modal-title-default">
              <>Coverage edition</>
            </h6>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.props.toggleModal(this.props.modalName)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            {this.state.coverage.id && (
              <FormGroup>
                <Input
                  disabled
                  placeholder="Id"
                  type="text"
                  value={this.state.coverage.id}
                />
              </FormGroup>
            )}
            <FormGroup
              className={classnames({
                focused: this.state.birthdayFocused,
              })}
            >
              <InputGroup className="mb-4">
                <Input
                  value={
                    this.state.coverage.name ? this.state.coverage.name : ""
                  }
                  placeholder="Name"
                  type="text"
                  onChange={(e) => {
                    e.preventDefault();
                    this.state.coverage.name = e.target.value;
                    this.setState({ coverage: this.state.coverage });
                  }}
                />

                
                  <InputGroupText>
                    <i className="ni ni-zoom-split-in" />
                  </InputGroupText>
                
              </InputGroup>

              <InputGroup className="mb-4">
                <Input
                  placeholder="Description"
                  value={
                    this.state.coverage.description
                      ? this.state.coverage.description
                      : ""
                  }
                  type="text"
                  onChange={(e) => {
                    e.preventDefault();
                    this.state.coverage.description = e.target.value;
                    this.setState({ coverage: this.state.coverage });
                  }}
                />

                
                  <InputGroupText>
                    <i className="ni ni-zoom-split-in" />
                  </InputGroupText>
                
              </InputGroup>

              <InputGroup className="mb-4">
                <Input
                  placeholder="Contract"
                  value={
                    this.state.coverage.contractId
                      ? this.state.coverage.contractId
                      : ""
                  }
                  type="text"
                  onChange={(e) => {
                    e.preventDefault();
                    this.state.coverage.contractId = e.target.value;
                    this.setState({ coverage: this.state.coverage });
                  }}
                />

                
                  <InputGroupText>
                    <i className="ni ni-zoom-split-in" />
                  </InputGroupText>
                
              </InputGroup>

              <p>Select medics</p>
              {this.props.medics.map((item, index) => {
                return (
                  <div key={index}>
                    <div className="custom-control custom-control-alternative custom-checkbox">
                      <input
                        className="custom-control-input"
                        type="checkbox"
                        id={"checkbox_MEDIC_" + index}
                        onChange={(e) => {
                          this.onMedicSelectionChanged(item, e);
                        }}
                        checked={this.hasItem(this.state.coverage.medics, item)}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor={"checkbox_MEDIC_" + index}
                      >
                        <span className="text-muted">{item.name}</span>
                      </label>
                    </div>
                  </div>
                );
              })}

              <p>Select life insurances</p>
              {this.props.lifeInsurances.map((item, index) => {
                return (
                  <div key={index}>
                    <div className="custom-control custom-control-alternative custom-checkbox">
                      <input
                        className="custom-control-input"
                        type="checkbox"
                        id={"checkbox_LIFEINSURANCE_" + index}
                        onChange={(e) => {
                          this.onLifeInsuranceSelectionChanged(item, e);
                        }}
                        checked={this.hasItem(
                          this.state.coverage.lifeInsurances,
                          item
                        )}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor={"checkbox_LIFEINSURANCE_" + index}
                      >
                        <span className="text-muted">{item.name}</span>
                      </label>
                    </div>
                  </div>
                );
              })}

              <p>Select emergencies</p>
              {this.props.emergencies.map((item, index) => {
                return (
                  <div key={index}>
                    <div className="custom-control custom-control-alternative custom-checkbox">
                      <input
                        className="custom-control-input"
                        type="checkbox"
                        id={"checkbox_URGENCY_" + index}
                        onChange={(e) => {
                          this.onEmergencySelectionChanged(item, e);
                        }}
                        checked={this.hasItem(
                          this.state.coverage.emergencies,
                          item
                        )}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor={"checkbox_URGENCY_" + index}
                      >
                        <span className="text-muted">{item.name}</span>
                      </label>
                    </div>
                  </div>
                );
              })}

              <p>Select Home Cares</p>
              {this.props.homeCares.map((item, index) => {
                return (
                  <div key={index}>
                    <div className="custom-control custom-control-alternative custom-checkbox">
                      <input
                        className="custom-control-input"
                        type="checkbox"
                        id={"checkbox_HOMECARE_" + index}
                        onChange={(e) => {
                          this.onHomeCareSelectionChanged(item, e);
                        }}
                        checked={this.hasItem(
                          this.state.coverage.homeCares,
                          item
                        )}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor={"checkbox_HOMECARE_" + index}
                      >
                        <span className="text-muted">{item.name}</span>
                      </label>
                    </div>
                  </div>
                );
              })}

              <p>Select Telemedicines</p>
              {this.props.telemedicines.map((item, index) => {
                return (
                  <div key={index}>
                    <div className="custom-control custom-control-alternative custom-checkbox">
                      <input
                        className="custom-control-input"
                        type="checkbox"
                        id={"checkbox_TELEMEDICINE_" + index}
                        onChange={(e) => {
                          this.onTelemedicineSelectionChanged(item, e);
                        }}
                        checked={this.hasItem(
                          this.state.coverage.telemedicines,
                          item
                        )}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor={"checkbox_TELEMEDICINE_" + index}
                      >
                        <span className="text-muted">{item.name}</span>
                      </label>
                    </div>
                  </div>
                );
              })}
            </FormGroup>
          </div>
          <div className="modal-footer">
            <Button
              color="primary"
              type="button"
              onClick={(e) => {
                e.preventDefault();
                this.props.saveCoverage(this.state.coverage);
              }}
            >
              Save
            </Button>
            <Button
              className="ml-auto"
              color="link"
              data-dismiss="modal"
              type="button"
              onClick={() => this.props.toggleModal(this.props.modalName)}
            >
              Close
            </Button>
          </div>
        </Modal>
      </>
    );
  }
}

export default CoveragesEditionModal;
