import React from "react";

import classnames from "classnames";
// reactstrap components
import { Button, Modal, FormGroup, Input, Row, Col } from "reactstrap";
// core components

import { simulateLADRequest } from "services/eventHandlerServices";
class AssistanceLadSimulatorEditionModal extends React.Component {
  constructor(props) {
    super(props);
    const assistance = this.props.assistance;
    this.state = {
      assistance: assistance,

      simulationData: {
        datetime: new Date(Date.now()).toISOString(),

        event_type: "CALL_ENDED",

        call_id: "6001c3ff1c84e30010ee3c77",
        patient_name: assistance.patient
          ? assistance.patient.firstName + " " + assistance.patient.lastName
          : "",
        patient_id: assistance.patient
          ? assistance.patient.identificationNumber
          : "",
        doctor_specialty: "Medicina General",
        doctor_id: "20316628134",
        call_resolution: "referred",
        call_start_datetime: new Date(Date.now()).toISOString(),
        patient_external_id: assistance.user_id,
      },
    };
  }

  componentWillReceiveProps(nextProps) {
    console.log("componentWillReceiveProps", nextProps);
    this.setState({ assistance: nextProps.assistance });
  }

  simulateLadRequest() {
    const ladRequestSimulation = {
      datetime: this.state.simulationData.datetime,
      event_type: this.state.simulationData.event_type,
      event_object: {
        call_id: this.state.simulationData.call_id,
        patient_name: this.state.simulationData.patient_name,
        patient_id: this.state.simulationData.patient_id,
        doctor_specialty: this.state.simulationData.doctor_specialty,
        doctor_id: this.state.simulationData.doctor_id,
        call_resolution: this.state.simulationData.call_resolution,
        call_start_datetime: this.state.simulationData.call_start_datetime,
        patient_external_id: this.state.simulationData.patient_external_id,
      },
    };
    simulateLADRequest(ladRequestSimulation)
      .then(() => {
        window.location.reload();
      })
      .catch((e) => {
        alert("Hubo un error al generar el request a LAD " + e.message);
      });
  }

  render() {
    return (
      <>
        <Modal
          className="modal-dialog-centered"
          isOpen={this.props.assistanceLadSimulatorEditionModalIsOpen}
          toggle={() => this.props.toggleModal(this.props.modalName)}
        >
          <div className="modal-header">
            <h6 className="modal-title" id="modal-title-default">
              <>LAD Response Simulator</>
            </h6>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.props.toggleModal(this.props.modalName)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            {this.state.assistance.id && (
              <FormGroup>
                <Input
                  disabled
                  placeholder="Id"
                  type="text"
                  value={this.state.assistance.id}
                />
              </FormGroup>
            )}
            <FormGroup
              className={classnames({
                focused: this.state.birthdayFocused,
              })}
            >
              <div className="mb-3">
                <small className="text-uppercase font-weight-bold">
                  Main data
                </small>
              </div>
              <Row>
                <Col lg="6" sm="6">
                  <FormGroup>
                    <label>
                      <span className="text-muted">datetime</span>
                    </label>

                    <Input
                      type="text"
                      value={this.state.simulationData.datetime}
                      onChange={(event) => {
                        this.state.simulationData.datetime = event.target.value;

                        this.setState({
                          simulationData: this.state.simulationData,
                        });
                      }}
                    />
                  </FormGroup>
                </Col>

                <Col lg="6" sm="6">
                  <FormGroup>
                    <label>
                      <span className="text-muted">event_type</span>
                    </label>

                    <Input
                      type="text"
                      value={this.state.simulationData.event_type}
                      onChange={(event) => {
                        this.state.simulationData.event_type =
                          event.target.value;

                        this.setState({
                          simulationData: this.state.simulationData,
                        });
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <div className="mb-3">
                <small className="text-uppercase font-weight-bold">
                  event_object
                </small>
              </div>
              <Row>
                <Col lg="6" sm="6">
                  <FormGroup>
                    <label>
                      <span className="text-muted">call_id</span>
                    </label>

                    <Input
                      type="text"
                      value={this.state.simulationData.call_id}
                      onChange={(event) => {
                        this.state.simulationData.call_id = event.target.value;

                        this.setState({
                          simulationData: this.state.simulationData,
                        });
                      }}
                    />
                  </FormGroup>
                </Col>

                <Col lg="6" sm="6">
                  <FormGroup>
                    <label>
                      <span className="text-muted">patient_name</span>
                    </label>

                    <Input
                      type="text"
                      value={this.state.simulationData.patient_name}
                      onChange={(event) => {
                        this.state.simulationData.patient_name =
                          event.target.value;

                        this.setState({
                          simulationData: this.state.simulationData,
                        });
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col lg="6" sm="6">
                  <FormGroup>
                    <label>
                      <span className="text-muted">patient_id</span>
                    </label>

                    <Input
                      type="text"
                      value={this.state.simulationData.patient_id}
                      onChange={(event) => {
                        this.state.simulationData.patient_id =
                          event.target.value;

                        this.setState({
                          simulationData: this.state.simulationData,
                        });
                      }}
                    />
                  </FormGroup>
                </Col>

                <Col lg="6" sm="6">
                  <FormGroup>
                    <label>
                      <span className="text-muted">doctor_specialty</span>
                    </label>

                    <Input
                      type="text"
                      value={this.state.simulationData.doctor_specialty}
                      onChange={(event) => {
                        this.state.simulationData.doctor_specialty =
                          event.target.value;

                        this.setState({
                          simulationData: this.state.simulationData,
                        });
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col lg="6" sm="6">
                  <FormGroup>
                    <label>
                      <span className="text-muted">doctor_id</span>
                    </label>

                    <Input
                      type="text"
                      value={this.state.simulationData.doctor_id}
                      onChange={(event) => {
                        this.state.simulationData.doctor_id =
                          event.target.value;

                        this.setState({
                          simulationData: this.state.simulationData,
                        });
                      }}
                    />
                  </FormGroup>
                </Col>

                <Col lg="6" sm="6">
                  <FormGroup>
                    <label>
                      <span className="text-muted">call_resolution</span>
                    </label>

                    <Input
                      type="text"
                      value={this.state.simulationData.call_resolution}
                      onChange={(event) => {
                        this.state.simulationData.call_resolution =
                          event.target.value;

                        this.setState({
                          simulationData: this.state.simulationData,
                        });
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col lg="6" sm="6">
                  <FormGroup>
                    <label>
                      <span className="text-muted">call_start_datetime</span>
                    </label>

                    <Input
                      type="text"
                      value={this.state.simulationData.call_start_datetime}
                      onChange={(event) => {
                        this.state.simulationData.call_start_datetime =
                          event.target.value;

                        this.setState({
                          simulationData: this.state.simulationData,
                        });
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col lg="6" sm="6">
                  <FormGroup>
                    <label>
                      <span className="text-muted">patient_external_id</span>
                    </label>

                    <Input
                      type="text"
                      value={this.state.simulationData.patient_external_id}
                      onChange={(event) => {
                        this.state.simulationData.patient_external_id =
                          event.target.value;

                        this.setState({
                          simulationData: this.state.simulationData,
                        });
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </FormGroup>
          </div>
          <div className="modal-footer">
            <Button
              color="primary"
              type="button"
              onClick={(e) => {
                e.preventDefault();
                this.simulateLadRequest();
              }}
            >
              Simulate LAD Request
            </Button>
            <Button
              className="ml-auto"
              color="link"
              data-dismiss="modal"
              type="button"
              onClick={() => this.props.toggleModal(this.props.modalName)}
            >
              Close
            </Button>
          </div>
        </Modal>
      </>
    );
  }
}

export default AssistanceLadSimulatorEditionModal;
