import { getHttpBearerConfig } from 'services/httpServices';
import axios from 'axios';

const appConfig = require('config/appConfig');

export const listTelemedicines = (currentUserToken) => {
  return new Promise(function (resolve, reject) {
    //exports.saveRole = function(currentUserToken, targetUser, role) {
    let config = getHttpBearerConfig(currentUserToken);

    return axios
      .get(`${appConfig.gcfUrl}/telemedicines`, config)
      .then((response) => {
        console.log('telemedicines response:', response);
        if (response && response.data) resolve(response.data);
        else resolve([]);
      })
      .catch((e) => {
        console.log('error:', e);

        reject(e);
      });
  });
};

export const createTelemedicine = (currentUserToken, telemedicine) => {
  return new Promise(function (resolve, reject) {
    //exports.saveRole = function(currentUserToken, targetUser, role) {
    let config = getHttpBearerConfig(currentUserToken);

    return axios
      .post(
        `${appConfig.gcfUrl}/telemedicines`,
        {
          name: telemedicine.name,
          description: telemedicine.description,
          tel: telemedicine.tel,
          address: telemedicine.address,
        },
        config
      )
      .then((response) => {
        console.log('create telemedicine response:', response);
        if (response && response.data) resolve(response.data);
        else resolve(null);
      })
      .catch((e) => {
        console.log('error:', e);

        reject(e);
      });
  });
};

export const updateTelemedicine = (currentUserToken, telemedicine) => {
  return new Promise(function (resolve, reject) {
    //exports.saveRole = function(currentUserToken, targetUser, role) {
    let config = getHttpBearerConfig(currentUserToken);

    return axios
      .patch(
        `${appConfig.gcfUrl}/telemedicines/${telemedicine.id}`,
        {
          name: telemedicine.name,
          description: telemedicine.description,
          tel: telemedicine.tel,
          address: telemedicine.address,
        },
        config
      )
      .then((response) => {
        console.log('update telemedicine response:', response);
        if (response && response.data) resolve(response.data);
        else resolve(null);
      })
      .catch((e) => {
        console.log('error:', e);

        reject(e);
      });
  });
};

export const deleteTelemedicine = (currentUserToken, telemedicine) => {
  return new Promise(function (resolve, reject) {
    let config = getHttpBearerConfig(currentUserToken);

    return axios
      .delete(`${appConfig.gcfUrl}/telemedicines/${telemedicine.id}`, config)
      .then((response) => {
        console.log('delete telemedicine response:', response);
        if (response && response.data) resolve(response.data);
        else resolve(null);
      })
      .catch((e) => {
        console.log('error:', e);

        reject(e);
      });
  });
};
