import React from "react";

import { Link } from "react-router-dom";

// Import Swiper styles
import "swiper/swiper.scss";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components
import WhatsappButton from "components/Buttons/WhatsappButton";
import ContactUsLandingNavbar from "./ContactUsLandingNavbar.jsx";
import Footer from "./Footer.jsx";
import {
  validateHasValue,
  PHONE_NUMBER_PREFIX_AR,
  goToFatalErrorPage,
} from "helpers/coreHelper";

import { createCallRequestLead } from "services/leadsServices";

class GetAppLanding extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      userFullName: "",
      phoneNumber: "",

      phoneRawValue: "",
      loading: false,
    };

    this.onPhoneChange = this.onPhoneChange.bind(this);
  }

  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;

    this.validateFormData();
  }

  onPhoneChange(event) {
    this.setState({ phoneNumber: "+549" + event.target.rawValue });
    this.setState({ phoneRawValue: event.target.rawValue });
  }

  onSubmitFormData() {
    let errors = this.validateFormData();

    if (errors) return;

    this.setState({ loading: true });

    //011 y lrpmqtrmp
    const phoneNumber = this.state.phoneNumber.replace(
      PHONE_NUMBER_PREFIX_AR + "011",
      PHONE_NUMBER_PREFIX_AR + "11"
    );

    let search = window.location.search;
    let params = new URLSearchParams(search);

    let utm_campaign = params.get("utm_campaign");
    let utm_source = params.get("utm_source");
    let utm_content = params.get("utm_content");
    let utm_medium = params.get("utm_medium");
    let utm_term = params.get("utm_term");

    let utmData = null;

    utmData = {
      utm_campaign: utm_campaign,
      utm_source: utm_source,
      utm_content: utm_content,
      utm_medium: utm_medium,
      utm_term: utm_term,
    };

    const fistName = this.state.userFullName;
    const lastName = "";

    let lead = {
      fistName: fistName,
      lastName: lastName,
      phoneNumber: phoneNumber,
      utmData: utmData,
    };

    createCallRequestLead(lead)
      .then(() => {
        this.props.history.push("/contact-us-success");
      })
      .catch(() => {
        goToFatalErrorPage(this.props.history);
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  validateFormData(userFullName, phoneNumber) {
    let errors = [];

    if (!userFullName) userFullName = this.state.userFullName;

    if (!phoneNumber) phoneNumber = this.state.phoneNumber;

    if (!validateHasValue(userFullName)) errors.push("userFullName");
    if (!validateHasValue(phoneNumber)) errors.push("phoneNumber");

    if (errors.length === 0) errors = null;

    this.setState({ errors: errors });

    //console.log("errors:", errors);
    return errors;
  }

  render() {
    return (
      <>
        <WhatsappButton />
        <ContactUsLandingNavbar />
        <main ref="main">
          {/*hero*/}
          <section className="section section-lg section-about-us pb-md-0 pt-md-0 pt-sm-5 mb-4">
            <Container className="d-flex">
              <div className="col">
                <form>
                  <Row>
                    <Col xs="12" lg="6">
                      <h1 className="main-title text-white pt-100">
                        Descargá ya la app
                      </h1>

                      <Link
                        to={{
                          pathname:
                            "https://apps.apple.com/ar/app/hola-ren%C3%A9/id1581520567",
                        }}
                        className="mr-2"
                        target="_blank"
                      >
                        <img
                          alt="..."
                          src={require("assets/img/brand/app-store-white.png").default}
                        />
                      </Link>
                      <Link
                        to={{
                          pathname:
                            "https://play.google.com/store/apps/details?id=com.holarene.reneapp",
                        }}
                        target="_blank"
                      >
                        <img
                          alt="..."
                          src={require("assets/img/brand/google-play-white.png").default}
                        />
                      </Link>
                    </Col>
                    <Col lg="6" className="z-index-1 pt-5">
                      <img
                        alt="..."
                        style={{ width: "350px" }}
                        className="mb-sm--150"
                        src={require("assets/img/brand/Click.svg").default}
                      />
                    </Col>
                  </Row>
                </form>{" "}
              </div>
              {/* SVG separator */}
              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 200"
                  x="0"
                  y="0"
                >
                  <polygon
                    className="fill-white"
                    points="2560 0 2560 200 0 200"
                  />
                </svg>
              </div>
            </Container>
          </section>
        </main>
        <Footer />
      </>
    );
  }
}

export default GetAppLanding;
