import { getHttpBearerConfig } from 'services/httpServices';
import axios from 'axios';
import { getUserWithToken } from 'helpers/coreHelper';

import qs from 'query-string';

const appConfig = require('config/appConfig');

export const listLogs = (filters) => {
  return new Promise(function (resolve, reject) {
    console.log('filters:', filters);
    //exports.saveRole = function(currentUserToken, targetUser, role) {

    getUserWithToken().then((uwt) => {
      let config = getHttpBearerConfig(uwt.token);

      let searchString = '';

      if (filters) {
        searchString = '?' + qs.stringify(filters);
        // const { uid, nextPageToken, dstart, dend } = filters;

        // if (uid) qs += "&uid=" + uid;
        // if (nextPageToken) qs += "&nextPageToken=" + nextPageToken;
        // if (dstart) qs += "&dstart=" + dstart;
        // if (dend) qs += "&dend=" + dend;
      }

      return axios
        .get(`${appConfig.gcfUrl}/logs${searchString}`, config)
        .then((response) => {
          console.log('logs response:', response);
          if (response && response.data) resolve(response.data);
          else resolve([]);
        })
        .catch((e) => {
          console.log('error:', e);

          reject(e);
        });
    });
  });
};
