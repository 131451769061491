import React, { useEffect, useState } from "react";
// import MetaTags from "react-meta-tags";

// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";

import {
  formatMoney,
  setPersistedValue,
  getPersistedValue,
  goToFatalErrorPage,
  validateHasValue,
  calculatePlanPrice,
  getPlanByMemberCount,
  getPlanBulletDescription,
  cleanAnalytics,
  analyticsTag,
} from "helpers/coreHelper";

import Loading from "components/Loading/Loading.jsx";
// core components
import BasicNavbar from "components/Navbars/BasicNavbar.jsx";
import WhatsappButton from "components/Buttons/WhatsappButton";

import { subscribePromotionalPlans } from "services/promotionalPlansServices";

import CustomInput from "components/Inputs/CustomInput.jsx";

const { NotFoundTechnicalError } = require("helpers/errorClasses");
const { PLAN_BULLETS } = require("types/planBulletTypes");
const {
  RELATIONSHIP_CHILDREN,
  RELATIONSHIP_PARTNER,
} = require("types/relationshipTypes");

const {
  PLAN_SWITCHER_OPTION_ME,
  PLAN_SWITCHER_OPTION_CHILDRENS,
  PLAN_SWITCHER_OPTION_ME_AND_PARTNER,
  PLAN_SWITCHER_OPTION_FAMILY,
} = require("types/planSwitcherTypes");

const DigitalOnboardingStep1PlanSelection = (props) => {
  // eslint-disable-next-line no-unused-vars
  const [lead, setLead] = useState(getPersistedValue("lead"));

  const [plans, setPlans] = useState(null);
  const [selectedPlanSwitcherOption, setSelectedPlanSwitcherOption] = useState(
    (lead && lead.selectedPlanSwitcherOption) || PLAN_SWITCHER_OPTION_ME
  );

  const [selectedFamilyGroupMembersCount, setSelectedFamilyGroupMembersCount] =
    useState(
      (lead && lead.selectedFamilyGroupMembersCount) ||
        PLAN_SWITCHER_OPTION_ME.defaultFamilyGroupMembers
    );

  const [selectedPromotionalPlan, setSelectedPromotionalPlan] = useState(null);
  const [loadingPlans, setLoadingPlans] = useState(true);

  // const [SMVM, setSMVM] = useState(16875);

  let search = window.location.search;
  let params = new URLSearchParams(search);

  let utm_campaign = params.get("utm_campaign");
  let utm_source = params.get("utm_source");
  let utm_content = params.get("utm_content");
  let utm_medium = params.get("utm_medium");
  let utm_term = params.get("utm_term");

  let utmData = null;

  utmData = {
    utm_campaign: utm_campaign,
    utm_source: utm_source,
    utm_content: utm_content,
    utm_medium: utm_medium,
    utm_term: utm_term,
  };

  useEffect(() => {
    loadPlans();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    cleanAnalytics();
  }, []);

  function onUpdatePromotionalPlans(renePromotionalPlans) {
    if (renePromotionalPlans.length === 0) {
      throw new NotFoundTechnicalError("NOT_FOUND", "Error, sin planes");
    }

    renePromotionalPlans = renePromotionalPlans.sort(function (a, b) {
      return a.order - b.order;
    });

    let selectedPromotionalPlanAux = null;

    if (lead && lead.selectedPlan) {
      selectedPromotionalPlanAux = renePromotionalPlans.find((rpp) => {
        if (!rpp.associatedPlans) return null;
        return rpp.associatedPlans.find((asocPlan) => {
          return asocPlan.id === lead.selectedPlan.id;
        });
      });
    }

    if (!selectedPromotionalPlanAux)
      selectedPromotionalPlanAux = renePromotionalPlans.find((ip) => {
        return ip.recomended && ip.recomended !== "0";
      });

    if (!selectedPromotionalPlanAux)
      selectedPromotionalPlanAux = renePromotionalPlans[0];

    setSelectedPromotionalPlan(selectedPromotionalPlanAux);

    setPlans(renePromotionalPlans);

    setLoadingPlans(false);
  }

  function loadPlans() {
    setLoadingPlans(true);

    subscribePromotionalPlans(onUpdatePromotionalPlans)
      .then((renePromotionalPlans) => {})
      .catch((e) => {
        console.log(e, e.message, e.code);
        goToFatalErrorPage(props.history);
      });
  }

  function onPlanSelectionChanged(item, event) {
    setSelectedPromotionalPlan(item);
  }

  function onPlanSwitcherSelectionChanged(item, event) {
    // if (event.target.checked) {
    setSelectedPlanSwitcherOption(item);
    setSelectedFamilyGroupMembersCount(item.defaultFamilyGroupMembers);
    // }
  }

  function onFamilyMembersSelectionChanged(count) {
    if (
      count > 7 &&
      selectedPlanSwitcherOption === PLAN_SWITCHER_OPTION_CHILDRENS
    )
      count = 7;

    if (count > 9 && selectedPlanSwitcherOption === PLAN_SWITCHER_OPTION_FAMILY)
      count = 9;

    setSelectedFamilyGroupMembersCount(count);
  }

  function innerSaveSelectedPlan() {
    let theLead = lead;

    if (!theLead) theLead = {};

    theLead.selectedPlan = getPlanByMemberCount(
      selectedPromotionalPlan.associatedPlans,
      selectedFamilyGroupMembersCount,
      mapPlanSwitcherSelectedOptionToCrmPlanSwitcherTypeCode(
        selectedPlanSwitcherOption
      )
    );

    console.log("theLead.selectedPlan:", theLead.selectedPlan);
    if (theLead.selectedPlan) {
      theLead.selectedPlan.friendlyName = selectedPromotionalPlan.name;

      //Cuando se quiera mostrar el nombre del plan del CRM, usar esto
      //theLead.selectedPlan.friendlyName = theLead.selectedPlan.name;
    }

    theLead.selectedFamilyGroupMembersCount = selectedFamilyGroupMembersCount;
    theLead.selectedPlanSwitcherOption = selectedPlanSwitcherOption;

    theLead.beneficiaries = [];
    if (selectedPlanSwitcherOption.key === PLAN_SWITCHER_OPTION_CHILDRENS.key) {
      for (let index = 0; index < selectedFamilyGroupMembersCount; index++) {
        theLead.beneficiaries.push({
          relationship: RELATIONSHIP_CHILDREN.key,
          name: "",
          birthday: "",
          identificationNumber: "",
          index: index + 1,
        });
      }
    } else if (
      selectedPlanSwitcherOption.key === PLAN_SWITCHER_OPTION_ME_AND_PARTNER.key
    ) {
      theLead.beneficiaries.push({
        relationship: RELATIONSHIP_PARTNER.key,
        name: "",
        birthday: "",
        identificationNumber: "",
        index: 0,
      });
    } else if (
      selectedPlanSwitcherOption.key === PLAN_SWITCHER_OPTION_FAMILY.key
    ) {
      theLead.beneficiaries.push({
        relationship: RELATIONSHIP_PARTNER.key,
        name: "",
        birthday: "",
        identificationNumber: "",
        index: 0,
      });

      for (
        let index = 0;
        index < selectedFamilyGroupMembersCount - 2; //-2 porque saco a la pareja y a el mismo
        index++
      ) {
        theLead.beneficiaries.push({
          relationship: RELATIONSHIP_CHILDREN.key,
          name: "",
          birthday: "",
          identificationNumber: "",
          index: index + 1,
        });
      }
    }

    if (!theLead.utmData) theLead.utmData = utmData;

    setPersistedValue("lead", theLead);

    if (theLead.selectedPlan) {
      const price = theLead.selectedPlan.prices[0].amount;
      analyticsTag({
        event: "add_to_cart",
        ecommerce: {
          value: price,
          items: [
            {
              item_name: theLead.selectedPlan.name, // Name or ID is required.
              item_id: theLead.selectedPlan.id,
              price: price,
              //item_brand: "Google",
              //item_category: "Apparel",
              //item_category2: "Mens",
              //item_category3: "Shirts",
              //item_category4: "Tshirts",
              //item_variant: "Black",
              item_list_name: theLead.selectedPlan.friendlyName,
              //item_list_id: "SR123",
              //index: 1,
              quantity: 1,
            },
          ],
        },
      });
    }

    props.history.push("/onboarding-step-2");
  }

  function isPlanSelected(plan) {
    return selectedPromotionalPlan && selectedPromotionalPlan.id === plan.id;
  }

  const planSwitcherOptions = [
    PLAN_SWITCHER_OPTION_ME,
    PLAN_SWITCHER_OPTION_CHILDRENS,
    PLAN_SWITCHER_OPTION_ME_AND_PARTNER,
    PLAN_SWITCHER_OPTION_FAMILY,
  ];

  function mapPlanSwitcherSelectedOptionToCrmPlanSwitcherTypeCode(
    planSwitcherOption
  ) {
    // if (planSwitcherOption.key === PLAN_SWITCHER_OPTION_TYPE_CHILDRENS)
    //   return PLAN_SWITCHER_OPTION_TYPE_FAMILY;

    return planSwitcherOption.key;
  }
  const maxPlans = 3;
  return (
    <>
      {/* <MetaTags>
        <title>El plan para tu seguro de salud | Hola René</title>
        <meta
          id="meta-description"
          name="description"
          content="Toda la información para que elijas el plan de salud ideal para vos y tu familia."
        />
      </MetaTags> */}

      <WhatsappButton />
      <BasicNavbar />

      <main>
        <section className="section section-shaped bg-dark-grey">
          <Container>
            <Row className="justify-content-center pt-5">
              <Col lg="12">
                <h1 className="form-title-2 text-center">
                  ¡Aprovechá hoy! <br /> Pedí <b>sin cargo</b> tu Plan
                </h1>
              </Col>
            </Row>
            <Row className="px-lg-8 px-md-8 pt-2">
              <Col xs="6" lg="6">
                <p className="bullet-form">
                  Hacelo en <b>5 minutos</b>
                </p>
              </Col>
              <Col xs="6" lg="6">
                <p className="bullet-form">
                  Solicitalo <b>Online</b> y te bonificamos <b>1 mes</b>.
                </p>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="section pt-2">
          <Container>
            <Row className="justify-content-center">
              <Col lg="12" className="pl-md-0">
                <h1 className="form-title text-center">
                  Elegí el plan ideal para vos y tu familia
                </h1>

                <p className="text-form text-center">
                  Recordá que podés sacar el plan para vos, para vos y tu
                  familia o solo para tus hijos. Para ver el precio, seleccioná
                  para
                  <br /> cuantas personas querés la cobertura.
                </p>

                <h3
                  className="form-subtitle text-center"
                  style={{ color: "#0a0a0b" }}
                >
                  <strong>¿Para quién es el plan?</strong>
                </h3>

                <Row>
                  {planSwitcherOptions.map((element, index) => {
                    return (
                      <Col key={index} lg="3" sm="6" md="3">
                        <div
                          className="custom-control custom-radio box mb-2 "
                          style={{ cursor: "pointer" }}
                          onClick={(event) => {
                            onPlanSwitcherSelectionChanged(element, event);
                          }}
                          checked={
                            selectedPlanSwitcherOption.key === element.key
                          }
                        >
                          <input
                            className="custom-control-input"
                            checked={
                              selectedPlanSwitcherOption.key === element.key
                            }
                            id={"customRadio_planswitcher_" + index}
                            name="custom-radio-planswitcher"
                            type="radio"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor={"customRadio_planswitcher_" + index}
                          >
                            <div className="d-flex px-1">
                              <div className="fw">
                                <p>{element.label}</p>
                              </div>
                            </div>
                          </label>
                        </div>
                      </Col>
                    );
                  })}
                </Row>
                <Row className="pt-5">
                  <Col lg="12" sm="12">
                    {selectedPlanSwitcherOption.showUnitPicker && (
                      <>
                        <p className="form-subtitle text-center">
                          ¿Para cuántas personas es el plan?
                        </p>
                        <div className="count-people pt-2">
                          <Button
                            type="button"
                            className="btn-circle mr-2"
                            onClick={(event) => {
                              var newQ = selectedFamilyGroupMembersCount - 1;

                              if (newQ < 1) newQ = 1;
                              onFamilyMembersSelectionChanged(newQ);
                            }}
                          >
                            -
                          </Button>
                          <CustomInput
                            labelText=""
                            errorLabelText=""
                            className="input-"
                            validationFn={validateHasValue}
                            behavior={{
                              className: "input form-control css-card",
                              options: {
                                numeral: true,
                                numeralDecimalMark: "",
                                delimiter: "",
                              },
                            }}
                            inputProps={{
                              value: selectedFamilyGroupMembersCount,
                              onChange: (event) => {
                                var newQ = event.target.value;
                                if (!newQ || newQ === "" || newQ === "0") {
                                  newQ = 1;
                                }
                                onFamilyMembersSelectionChanged(newQ);
                              },

                              placeholder: "",
                            }}
                          />
                          <Button
                            type="button"
                            className="btn-circle ml-2"
                            onClick={(event) => {
                              var newQ = 1;

                              try {
                                newQ =
                                  parseInt(selectedFamilyGroupMembersCount) + 1;
                              } catch (e) {}

                              onFamilyMembersSelectionChanged(newQ);
                            }}
                          >
                            +
                          </Button>
                        </div>
                      </>
                    )}
                  </Col>
                </Row>
                <Row className="pt-5">
                  <Loading loading={loadingPlans} />
                  {plans && plans.length >= maxPlans && (
                    <>
                      <Col lg="12" sm="12" className="plans">
                        <Row>
                          <Col lg="6" sm="6"></Col>
                          {plans.map((plan, index) => {
                            if (index >= maxPlans) return null;

                            return (
                              <Col
                                key={index}
                                lg="2"
                                sm="2"
                                xs="4"
                                className={
                                  isPlanSelected(plan)
                                    ? "selected nro-" + index
                                    : "nro-" + index
                                }
                              >
                                <div
                                  className={"plan nro-" + index}
                                  onClick={(event) => {
                                    onPlanSelectionChanged(plan, event);
                                  }}
                                >
                                  {plan.recomended &&
                                    plan.recomended !== "0" && (
                                      <div className="recomendado">
                                        Recomendado
                                      </div>
                                    )}
                                  <span>{plan.name}</span>
                                </div>
                              </Col>
                            );
                          })}
                        </Row>
                        <Row className="line">
                          <Col lg="6" sm="6">
                            <p className="title-plan">Precio mensual</p>
                          </Col>

                          {plans.map((plan, index) => {
                            if (index >= maxPlans) return null;

                            return (
                              <Col
                                lg="2"
                                sm="2"
                                xs="4"
                                key={index}
                                className="center-align"
                              >
                                $
                                {formatMoney(
                                  calculatePlanPrice(
                                    plan,
                                    selectedFamilyGroupMembersCount,
                                    mapPlanSwitcherSelectedOptionToCrmPlanSwitcherTypeCode(
                                      selectedPlanSwitcherOption
                                    )
                                  ),
                                  0
                                )}
                              </Col>
                            );
                          })}
                        </Row>

                        {PLAN_BULLETS &&
                          PLAN_BULLETS.map((planBullet, index) => {
                            return (
                              <Row key={index} className="line">
                                <Col lg="6" sm="6" xs="12">
                                  <p className="title-plan">
                                    {planBullet.title}
                                  </p>
                                  <p className="description-plan">
                                    {getPlanBulletDescription(
                                      planBullet,
                                      selectedPromotionalPlan,
                                      selectedFamilyGroupMembersCount,
                                      plans,
                                      mapPlanSwitcherSelectedOptionToCrmPlanSwitcherTypeCode(
                                        selectedPlanSwitcherOption
                                      )
                                    )}
                                  </p>
                                </Col>

                                {plans.map((plan, index) => {
                                  if (index >= maxPlans) return null;

                                  return (
                                    <Col
                                      lg="2"
                                      sm="2"
                                      xs="4"
                                      key={index}
                                      className="center-align"
                                    >
                                      {plan.includedBullets &&
                                      plan.includedBullets.find((bullet) => {
                                        return (
                                          bullet === planBullet.productType
                                        );
                                      }) ? (
                                        <div
                                          className={
                                            (isPlanSelected(plan) &&
                                              "current ") +
                                            "b-" +
                                            index +
                                            " check "
                                          }
                                        ></div>
                                      ) : (
                                        <div
                                          className={
                                            (isPlanSelected(plan) &&
                                              "current ") +
                                            "b-" +
                                            index +
                                            " uncheck"
                                          }
                                        ></div>
                                      )}
                                    </Col>
                                  );
                                })}
                              </Row>
                            );
                          })}
                      </Col>
                    </>
                  )}
                </Row>

                <div className="text-center mt-5">
                  <Button
                    className="mt-4 px-8"
                    color="custom"
                    type="button"
                    onClick={(event) => {
                      event.preventDefault();
                      innerSaveSelectedPlan();
                    }}
                    disabled={loadingPlans}
                  >
                    Continuar
                    {/* Solicitar online */}
                  </Button>
                </div>

                <div className="text-center">
                  <Button
                    className="mt-4 px-2"
                    color="link"
                    type="button"
                    onClick={(event) => {
                      event.preventDefault();
                      props.history.push("/asociarme-salud");
                    }}
                  >
                    Prefiero que me llamen
                    {/* Solicitar online */}
                  </Button>
                </div>

                {/* <div className="text-center mt-5">
                  <Button
                    className="mt-4 px-8"
                    // color="custom"
                    color="custom noarrow flat"
                    type="button"
                    onClick={(event) => {
                      event.preventDefault();
                      innerSaveSelectedPlan();
                    }}
                  >
                    Quiero que me llamen
                  </Button>
                </div> */}
              </Col>
            </Row>
          </Container>
        </section>
      </main>
    </>
  );
};

export default DigitalOnboardingStep1PlanSelection;
