import React from "react";

// reactstrap components
import { Modal } from "reactstrap";
// core components

import UploadFile from "./UploadFile";

class ImportExcelModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {}

  componentWillReceiveProps(nextProps) {
    //this.setState({ medic: nextProps.medic });
  }

  render() {
    return (
      <>
        <Modal
          className="modal-dialog-centered"
          isOpen={this.props.ImportExcelModalIsOpen}
          toggle={() => this.props.toggleModal(this.props.modalName)}
        >
          <UploadFile
            onUpload={this.props.onExcelUpload}
            closeModal={() => {
              this.props.toggleModal(this.props.modalName);
            }}
          />{" "}
        </Modal>
      </>
    );
  }
}

export default ImportExcelModal;
