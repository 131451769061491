import React, { useEffect, useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";

function ExcelUploader({ onChange, imageURL }) {
  const [files, setFiles] = useState(
    imageURL ? [{ name: "demo", preview: imageURL }] : []
  );
  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    onDrop: useCallback(
      (acceptedFiles) => {
        setFiles(
          acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          )
        );
        onChange(acceptedFiles);
      },
      [onChange]
    ),
  });

  const thumbs = files.map((file) => (
    <div key={file.name}>
      <div>
        <img src={file.preview} alt={file.name} />
      </div>
    </div>
  ));

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  return (
    <section className="container uploader">
      <div {...getRootProps()}>
        <input {...getInputProps()} />

        <div>
          <div>Drag/Upload</div> your excel file here.
        </div>
      </div>
      {thumbs && <div>{thumbs}</div>}
    </section>
  );
}

export default ExcelUploader;
