import React from 'react';

// reactstrap components
import { Button, Modal, FormGroup, Input, Row, Col } from 'reactstrap';

class LogDetailEditionModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = { log: this.props.log };
  }

  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
    });
  };

  componentWillReceiveProps(nextProps) {
    this.setState({
      log: nextProps.log,
    });
  }

  componentDidMount() {}

  componentWillUnmount() {}

  componentDidUpdate(e) {}

  render() {
    return (
      <>
        {
          <Modal
            className="modal-dialog-centered modal-lg"
            isOpen={this.props.LogDetailEditionModalIsOpen}
            toggle={() => this.props.toggleModal(this.props.modalName)}
          >
            <div className="modal-header">
              <h6 className="modal-title" id="modal-title-default">
                <>Detalle Log</>
              </h6>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => this.props.toggleModal(this.props.modalName)}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <div className="modal-body">
              {this.state.log.id && (
                <FormGroup>
                  <Input disabled placeholder="Id" type="text" value={this.state.log.id} />
                </FormGroup>
              )}

              <Row>
                <Col lg="6" sm="6">
                  <label>
                    <span className="text-muted">Fecha</span>
                  </label>
                  <p>{this.state.log.createdAt}</p>
                </Col>

                <Col lg="6" sm="6">
                  <label>
                    <span className="text-muted">Severidad</span>
                  </label>
                  <p>{this.state.log.severity}</p>
                </Col>
              </Row>

              <Row>
                <Col lg="6" sm="6">
                  <label>
                    <span className="text-muted">eventType</span>
                  </label>
                  <p> {this.state.log.eventType}</p>
                </Col>

                <Col lg="6" sm="6">
                  <label>
                    <span className="text-muted">env</span>
                  </label>
                  <p>{this.state.log.env}</p>
                </Col>
              </Row>

              <Row>
                <Col lg="6" sm="6">
                  <label>
                    <span className="text-muted">errorCode</span>
                  </label>
                  <p>{this.state.log.errorCode}</p>
                </Col>

                <Col lg="6" sm="6">
                  <label>
                    <span className="text-muted">message</span>
                  </label>
                  <p>{this.state.log.message}</p>
                </Col>
              </Row>

              <Row>
                <Col lg="12" sm="12">
                  <label>
                    <span className="text-muted">Data</span>
                  </label>
                  <p>{JSON.stringify(this.state.log.data)}</p>
                </Col>
              </Row>
            </div>

            <div className="modal-footer">
              <Button
                className="ml-auto"
                color="link"
                data-dismiss="modal"
                type="button"
                onClick={() => this.props.toggleModal(this.props.modalName)}
              >
                Salir
              </Button>
            </div>
          </Modal>
        }
      </>
    );
  }
}

export default LogDetailEditionModal;
