import React from "react";

// reactstrap components
import {
  Button,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Table,
  Container,
  Col,
  Row,
  Input,
  FormGroup,
} from "reactstrap";
// core components

import AdminNavbar from "components/Navbars/AdminNavbar.jsx";

import { authRef } from "config/firebase";

import LogDetailEditionModal from "./LogDetailEditionModal.jsx";
import CustomSelect from "components/Inputs/CustomSelect";
import CustomInput from "components/Inputs/CustomInput";

import { listLogs } from "services/logsServices";

const ENV_OPTIONS = [
  { key: "prod", value: "prod" },
  { key: "local-prod", value: "local-prod" },
  { key: "dev", value: "dev" },
  { key: "local", value: "local" },
  { key: "qa", value: "qa" },
];
const SEVERITY_OPTIONS = [
  { key: "INFO", value: "INFO" },
  { key: "ERROR", value: "ERROR" },
  { key: "WARN", value: "WARN" },
];
const PAGE_OPTIONS = [
  { key: 5, value: 5 },
  { key: 10, value: 10 },
  { key: 20, value: 20 },
  { key: 50, value: 50 },
  { key: 75, value: 75 },
  { key: 100, value: 100 },
];
class LogsDashboard extends React.Component {
  constructor(props) {
    super(props);

    const uid = this.props.match.params.id;

    this.state = {
      uid: uid,
      logs: [],
      loading: false,
      nextPageToken: null,
      loadingButton: false,
      envOption: null,
      sevOption: null,
      dateStart: null,
      dateEnd: null,
      logsQuantity: null,
    };
  }

  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
    });
  };

  componentDidMount() {
    this.setState({ loading: true });
    authRef.onAuthStateChanged((user) => {
      this.setState({ currentUser: user });
      this.fetchLogs();
    });
  }

  componentWillUnmount() {}

  componentDidUpdate(e) {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }

  fetchLogs() {
    const filters = {
      uid: this.state.uid,
      dstart: this.state.dateStart,
      dend: this.state.dateEnd,
      nextPageToken: this.state.nextPageToken,
      envOption: this.state.envOption,
      sevOption: this.state.sevOption,
      logsQuantity: this.state.logsQuantity,
    };

    listLogs(filters)
      .then((logs) => {
        if (this.state.logs && this.state.nextPageToken)
          logs.entries.concat(this.state.logs);

        this.setState({
          loading: true,
          logs: logs.entries,
          nextPageToken: logs.nextPageToken,
        });
      })
      .catch((e) => {
        alert("hubo un error al obtener");
      })
      .finally(() => {
        this.setState({
          loading: false,
          loadingButton: false,
        });
      });
  }

  spliceText(text) {
    if (text.length > 90) {
      let textPart = text.slice(0, 89);
      text = textPart + "...";
    }
    return text;
  }

  isError(severity) {
    if (severity !== "ERROR") {
      return (
        <td className="d-flex">
          <div
            style={{
              backgroundColor: "green",
              width: "10px",
              height: "10px",
              borderRadius: "50%",
            }}
          ></div>
          <div style={{ paddingLeft: "10px" }}>{severity}</div>
        </td>
      );
    } else {
      return (
        <td className="d-flex">
          <div
            style={{
              backgroundColor: "red",
              width: "10px",
              height: "10px",
              borderRadius: "50%",
            }}
          ></div>
          <div style={{ paddingLeft: "10px" }}>{severity}</div>
        </td>
      );
    }
  }
  render() {
    if (this.state.loading) return <>loading</>;
    return (
      <>
        <div className="main-content" ref="mainContent">
          <AdminNavbar {...this.props} currentUser={this.state.currentUser} />

          {this.state.logToEdit && (
            <LogDetailEditionModal
              modalName="LogDetailEditionModal"
              log={this.state.logToEdit}
              LogDetailEditionModalIsOpen={this.state.LogDetailEditionModal}
              toggleModal={this.toggleModal.bind(this)}
              // saveOrder={this.saveOrder.bind(this)}
            />
          )}

          <Container fluid style={{ margin: 20 }}>
            <div className="modal-body">
              <div className="mb-3">
                <small className="text-uppercase font-weight-bold">
                  Filtros
                </small>
              </div>
              <Row>
                <Col lg="4" sm="4">
                  <FormGroup>
                    <label>
                      <span className="text-muted">User ID</span>
                    </label>

                    <Input
                      type="text"
                      value={this.state.uid}
                      onChange={(event) => {
                        this.setState({ uid: event.target.value });
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col lg="4" sm="4">
                  <CustomSelect
                    labelText="Env"
                    select={{
                      options: ENV_OPTIONS,
                      defaultOption: "Seleccione",
                    }}
                    inputProps={{
                      value: this.state.envOption,

                      onChange: (event) => {
                        this.setState({
                          envOption: event.target.value,
                        });
                        // this.setState({
                        // lead: this.state.lead,
                        // });
                      },
                      className: "input form-control select",
                      placeholder: "",
                    }}
                  />
                </Col>
                <Col lg="4" sm="4">
                  <CustomSelect
                    labelText="Severity"
                    select={{
                      options: SEVERITY_OPTIONS,
                      defaultOption: "TODOS",
                    }}
                    inputProps={{
                      value: this.state.sevOption,

                      onChange: (event) => {
                        this.setState({
                          sevOption: event.target.value,
                        });

                        // this.setState({
                        // lead: this.state.lead,
                        // });
                      },
                      className: "input form-control select",
                      placeholder: "",
                    }}
                  />
                </Col>
              </Row>

              <Row>
                <Col lg="4" sm="4">
                  <FormGroup>
                    <CustomInput
                      labelText="Desde"
                      inputProps={{
                        value: this.state.dateStart,
                        type: "date",
                        name: "dateStart",
                        onChange: (event) => {
                          this.setState({
                            dateStart: event.target.value,
                          });
                        },

                        placeholder: "Desde",
                        className: "contact-input",
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col lg="4" sm="4">
                  <FormGroup>
                    <CustomInput
                      labelText="Hasta"
                      inputProps={{
                        value: this.state.dateEnd,
                        type: "date",
                        name: "dateEnd",
                        onChange: (event) => {
                          this.setState({
                            dateEnd: event.target.value,
                          });
                        },

                        placeholder: "Desde",
                        className: "contact-input",
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col lg="4" sm="4">
                  <Button
                    className="mt-4"
                    type="button"
                    onClick={() => {
                      this.setState({
                        nextPageToken: null,
                        loadingButton: true,
                      });
                      this.fetchLogs();
                    }}
                  >
                    {!this.state.loadingButton && "Consultar"}
                    {this.state.loadingButton && "Cargando..."}
                  </Button>
                </Col>
                {/* <Col lg="4" sm="4">
                  <Button
                    className="mt-4"
                    type="button"
                    onClick={() => {
                      this.fetchLogs();
                    }}
                  >
                    Ver más registros
                  </Button>
                </Col> */}
              </Row>

              <div className="mt-5 py-5 border-top text-center">
                <Row className="justify-content-center">
                  <Col lg="12">
                    <div>
                      <i className="ni education_hat mr-2" />
                      Logs
                    </div>
                    <CustomSelect
                      select={{
                        options: PAGE_OPTIONS,
                        defaultOption: "Cantidad",
                      }}
                      inputProps={{
                        value: this.state.logsQuantity,

                        onChange: (event) => {
                          this.setState(
                            {
                              logsQuantity: event.target.value,
                              nextPageToken: null,
                            },
                            () => {
                              this.fetchLogs();
                            }
                          );
                        },
                        className: "input form-control select col-2",
                        placeholder: "",
                      }}
                    />

                    <br />
                    {this.state.loading && <div>Loading</div>}
                    {!this.state.loading && (
                      <Table
                        className="align-items-center table-flush"
                        responsive
                      >
                        <thead className="thead-light">
                          <tr>
                            <th scope="col">Date</th>
                            <th scope="col">UID</th>
                            <th scope="col">SID</th>
                            <th scope="col">Severity</th>
                            <th scope="col">Event</th>
                            <th scope="col">Env</th>
                            <th scope="col">Fn</th>
                            <th scope="col">Message</th>

                            <th scope="col" />
                          </tr>
                        </thead>
                        <tbody style={{ fontSize: "12px" }}>
                          {this.state.logs.map((log, index) => {
                            const datetime = log.metadata
                              ? log.metadata.timestamp
                              : null;
                            const uid = log.data ? log.data.uid : null;
                            const spanId = log.data ? log.data.spanId : null;

                            const severity = log.metadata
                              ? log.metadata.severity
                              : null;
                            // if(log.metadata){
                            //     let severity = log.metadata.severity
                            //     if (severity !== "ERROR") {
                            //     severity = log.metadata.severity + "rojo"
                            //   }
                            // }else{
                            //   let severity = null
                            // }

                            const eventType = log.data
                              ? log.data.eventType
                              : null;
                            const env = log.data ? log.data.env : null;
                            const msg = log.data ? log.data.msg : null;
                            const functionName =
                              log.metadata &&
                              log.metadata.resource &&
                              log.metadata.resource.labels &&
                              log.metadata.resource.labels.function_name
                                ? log.metadata.resource.labels.function_name
                                : null;

                            return (
                              <tr key={index}>
                                <td>{datetime}</td>
                                <td>{uid}</td>
                                <td>{spanId}</td>
                                {this.isError(severity)}
                                <td>{eventType}</td>
                                <td>{env}</td>
                                <td>{functionName}</td>
                                <td className="text-left">
                                  {this.spliceText(msg)}
                                </td>
                                <td className="text-right">
                                  <UncontrolledDropdown>
                                    <DropdownToggle
                                      className="btn-icon-only text-light"
                                      href="#"
                                      role="button"
                                      size="sm"
                                      color=""
                                      onClick={(e) => e.preventDefault()}
                                    >
                                      <i className="fas fa-ellipsis-v" />
                                    </DropdownToggle>
                                    <DropdownMenu
                                      className="dropdown-menu-arrow"
                                      right
                                    >
                                      <DropdownItem
                                        onClick={(e) => {
                                          e.preventDefault();
                                          this.setState({ logToEdit: log });
                                          this.toggleModal(
                                            "LogDetailEditionModal"
                                          );
                                        }}
                                      >
                                        Detail
                                      </DropdownItem>
                                    </DropdownMenu>
                                  </UncontrolledDropdown>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    )}
                  </Col>
                </Row>
              </div>
            </div>
          </Container>
        </div>
      </>
    );
  }
}

export default LogsDashboard;
