export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;

export const FIREBASE_API_KEY = process.env.REACT_APP_FIREBASE_API_KEY;
export const FIREBASE_AUTH_DOMAIN = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN;
export const FIREBASE_PROJECT_ID = process.env.REACT_APP_FIREBASE_PROJECT_ID;
export const FIREBASE_STORAGE_BUCKET = process.env.REACT_APP_FIREBASE_STORAGE_BUCKET;
export const FIREBASE_MESSAGING_SENDER_ID = process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID;
export const FIREBASE_MEASURAMENT_ID = process.env.REACT_APP_FIREBASE_MEASURAMENT_ID;
export const FIREBASE_APP_ID = process.env.REACT_APP_FIREBASE_APP_ID;
export const gcfUrl = process.env.REACT_APP_gcfUrl;

export const DRAPP_COVERAGE_ID = 'DRAPP_COVERAGE';
export const REDSOM_COVERAGE_ID = 'REDSOM_COVERAGE';
