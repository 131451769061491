/* eslint-disable no-fallthrough */
import React, { useReducer, useEffect } from 'react';
import { AuthContext } from './auth.context';
import { authRef } from 'config/firebase';

const INITIAL_STATE = {
  currentForm: 'signIn',

  authStatusReported: false,
  isAuthenticated: false,
  displayName: null,
  user: null,
  role: null,
};

function reducer(state, action) {
  switch (action.type) {
    case 'SIGNIN':
      return {
        ...state,
        currentForm: 'signIn',
      };

    case 'INIT_AUTH':
      return {
        ...state,
        ...action.payload,
      };
    case 'SIGNIN_SUCCESS': {
      const user = action.payload;
      user.getIdTokenResult().then((idTokenResult) => {
        const role = idTokenResult.claims.role;

        return {
          ...state,
          ...{
            isAuthenticated: user && user.email ? true : false,
            displayName: user.displayName,
            user: user,
            role: role,
          },
        };
      });
    }
    case 'SIGNUP_SUCCESS': {
      const user = action.payload;
      user.getIdTokenResult().then((idTokenResult) => {
        const role = idTokenResult.claims.role;

        return {
          ...state,
          ...{
            isAuthenticated: user && user.email ? true : false,
            displayName: user.displayName,
            user: user,
            role: role,
          },
        };
      });
    }

    case 'SIGN_OUT':
      return {
        ...state,
        ...{
          isAuthenticated: false,
          displayName: '',
          user: null,
          role: null,
        },
      };
    case 'SIGNUP':
      return {
        ...state,
        currentForm: 'signUp',
      };
    case 'FORGOTPASS':
      return {
        ...state,
        currentForm: 'forgotPass',
      };
    default:
      return state;
  }
}

function onAuthStateChange(callback) {
  authRef.onAuthStateChanged((user) => {
    if (user) {
      user.getIdTokenResult().then((idTokenResult) => {
        callback({
          payload: {
            authStatusReported: true,
            isAuthenticated: user && user.email ? true : false,
            displayName: user.displayName,
            user: user,
            role: idTokenResult.claims.role,
          },
          type: 'INIT_AUTH',
        });
      });
    } else {
      callback({
        payload: {
          authStatusReported: true,
          isAuthenticated: false,
          user: null,
          displayName: null,
          role: null,
        },
        type: 'INIT_AUTH',
      });
    }
  });
}

export const AuthProvider = ({ children }) => {
  const [authState, authDispatch] = useReducer(reducer, INITIAL_STATE);

  useEffect(() => {
    const unsubscribe = onAuthStateChange(function (args) {
      authDispatch(args);
    });
    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, []);

  if (!authState) return <></>;
  return (
    <AuthContext.Provider value={{ authState, authDispatch }}>
      {/* {authState.authStatusReported && children} */}

      {children}
    </AuthContext.Provider>
  );
};
