import React from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";

// JavaScript plugin that hides or shows a component based on your scroll

// reactstrap components
import { NavItem, NavLink, Nav, Container, Row, Col } from "reactstrap";

class Footer extends React.Component {
  render() {
    return (
      <>
        <footer className=" footer">
          <Container>
            <Row className=" align-items-center justify-content-md-between mt-sm--4">
              <Col xs="12" md="4">
                <Nav className="nav-footer flex-column align-items-center flex-xs-row flex-sm-row justify-content-start justify-content-md-start justify-content-sm-center">
                  <NavItem>
                    <NavLink className="user-logo" href="/">
                      <span>
                        Hola <b>René</b>
                      </span>
                    </NavLink>
                  </NavItem>
                </Nav>
              </Col>
              <Col xs="12" md="8">
                <Nav className=" nav-footer flex-column align-items-center flex-xs-row flex-sm-row justify-content-end justify-content-md-end justify-content-sm-center">
                  <NavItem>
                    <NavLink href="/about-us">QUIÉNES SOMOS</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href="/onboarding">PLANES</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href="https://blog.holarene.com/">BLOG</NavLink>
                  </NavItem>
                </Nav>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col xs="12" md="4">
                <p className="address text-aling-sm-center">
                  Av. Libertador 5930, Piso 12 - C1428ARP
                  <br />
                  info@holarene.com
                </p>
                <p className="tel text-aling-sm-center">
                  Atención al cliente
                  <br />
                  <b>0810-220-0229</b>
                </p>
              </Col>
              <Col xs="12" sm="12" className="visible-sm text-center py-3">
                <Link
                  to={{
                    pathname: "https://www.instagram.com/holarene.ars",
                  }}
                  target="_blank"
                  className="mr-2"
                >
                  <img
                    alt="..."
                    src={require("assets/img/brand/instagram.png").default}
                  />
                </Link>
                <Link
                  to={{
                    pathname: "https://www.facebook.com/holarene.ars",
                  }}
                  target="_blank"
                  className="mr-2"
                >
                  <img
                    alt="..."
                    src={require("assets/img/brand/facebook.png").default}
                  />
                </Link>
              </Col>
              <Col xs="12" sm="12" className="visible-sm text-center py-3">
                <Link
                  to={{
                    pathname:
                      "https://apps.apple.com/ar/app/hola-ren%C3%A9/id1581520567",
                  }}
                  className="mr-2"
                  target="_blank"
                >
                  <img
                    alt="..."
                    src={require("assets/img/brand/app-store.png").default}
                  />
                </Link>
                <Link
                  to={{
                    pathname:
                      "https://play.google.com/store/apps/details?id=com.holarene.reneapp",
                  }}
                  target="_blank"
                >
                  <img
                    alt="..."
                    src={require("assets/img/brand/google-play.png").default}
                  />
                </Link>
              </Col>
            </Row>
            <Row className="mt-4 align-items-center hidden-sm">
              <Col xs="12" md="6">
                <Link
                  to={{
                    pathname: "https://www.instagram.com/holarene.ars",
                  }}
                  target="_blank"
                  className="mr-2"
                >
                  <img
                    alt="..."
                    src={require("assets/img/brand/instagram.png").default}
                  />
                </Link>
                <Link
                  to={{
                    pathname: "https://www.facebook.com/holarene.ars",
                  }}
                  target="_blank"
                  className="mr-2"
                >
                  <img
                    alt="..."
                    src={require("assets/img/brand/facebook.png").default}
                  />
                </Link>
                {/* <Link
                  to="https://play.google.com/store/apps/details?id=com.holarene.reneapp"
                  className="mr-2"
                >
                  <img
                    alt="..."
                    src={require("assets/img/brand/app-store.png")}
                  />
                </Link> */}
                {/* <Link to={{ pathname: "https://example.zendesk.com/hc/en-us/articles/123456789-Privacy-Policies" }}  /> */}
                <Link
                  to={{
                    pathname:
                      "https://apps.apple.com/ar/app/hola-ren%C3%A9/id1581520567",
                  }}
                  className="mr-2"
                  target="_blank"
                >
                  <img
                    alt="..."
                    src={require("assets/img/brand/app-store.png").default}
                  />
                </Link>
                <Link
                  to={{
                    pathname:
                      "https://play.google.com/store/apps/details?id=com.holarene.reneapp",
                  }}
                  target="_blank"
                >
                  <img
                    alt="..."
                    src={require("assets/img/brand/google-play.png").default}
                  />
                </Link>
              </Col>
              <Col xs="12" md="4" className="text-center p-0">
                <p className="copyright m-0">
                  Copyright © 2022 AR Solutions S.A.U.
                </p>
              </Col>
              {/* <Col xs="12" md="4" className="text-right">
                <img
                  alt="..."
                  className="mr-2"
                  style={{ width: "170px" }}
                  src={require("assets/img/brand/icon-ssn.png")}
                />
                <img
                  alt="..."
                  style={{ width: "70px" }}
                  className="mr-2"
                  src={require("assets/img/brand/logo-ar-vida.png")}
                />
                <img alt="..." src={require("assets/img/brand/qr.png")} />
              </Col> */}
            </Row>
            <Row className="visible-sm pt-2">
              <Col xs="12" md="4" className="text-center p-0 ">
                <p className="copyright m-0">
                  Copyright © 2022 AR Solutions S.A.U.
                </p>
              </Col>
            </Row>
          </Container>
        </footer>
      </>
    );
  }
}

export default withRouter(Footer);
