import React from "react";
import { authRef } from "config/firebase";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Container,
  Row,
  Col,
} from "reactstrap";

import CustomInput from "components/Inputs/CustomInput.jsx";

import Footer from "./../Landings/Footer.jsx";

// core components
import WhatsappButton from "components/Buttons/WhatsappButton";
import BasicNavbar from "components/Navbars/BasicNavbar.jsx";

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: null,
    };
  }

  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }

  forgotPassword() {
    authRef
      .sendPasswordResetEmail(this.state.email)
      .then((result) => {
        alert("Revisá tu correo para encontrar el link de recupero");
      })
      .catch((err) => {
        console.error("Error en el recupero de contraseña:", err);

        if (!err.code) {
          alert("Error reseteando su contraseña: " + err.message);
          return;
        }

        alert(err.code + " - " + err.message);
      });
  }

  render() {
    return (
      <>
        <WhatsappButton />
        <BasicNavbar />
        <main ref="main">
          <section className="section section-lg section-reset pb-lg-0 pt-lg-0 pt-sm-5">
            <Container className="d-flex">
              {/* SVG separator */}
              <div className="separator separator-top separator-skew hidden-md hidden-sm">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 200"
                  x="0"
                  y="0"
                >
                  <polygon
                    className="fill-white"
                    points="0 0 2560 0 2560 0 0 200"
                  />
                </svg>
              </div>
              <div className="col px-0 mb-5">
                <Row className="justify-content-center">
                  <Col lg="5">
                    <Card className="bg-secondary shadow border-0">
                      <CardHeader className="bg-white pb-2">
                        <h4 className="text-center text-black">
                          Ingresá tu correo para recuperar tu contraseña
                        </h4>
                      </CardHeader>
                      <CardBody className="px-lg-5 py-lg-5">
                        <Form role="form">
                          <FormGroup>
                            <CustomInput
                              inputProps={{
                                value: this.state.password,
                                onChange: (e) => {
                                  this.setState({
                                    email: e.target.value,
                                  });
                                },
                                placeholder: "Email",
                                type: "text",
                                autoComplete: "off",
                              }}
                            />
                            {/*

                          <InputGroup className="input-group-alternative">
                            
                              <InputGroupText>
                                <i className="ni ni-lock-circle-open" />
                              </InputGroupText>
                            
                            <Input
                              value={this.state.password}
                              onChange={(e) => {
                                this.setState({
                                  email: e.target.value,
                                });
                              }}
                              placeholder="Email"
                              type="text"
                              autoComplete="off"
                            />
                          </InputGroup> */}
                          </FormGroup>
                          <div className="text-center">
                            <Button
                              className="mt-4 full-w"
                              color="custom noarrow"
                              type="button"
                              onClick={(ev) => {
                                ev.preventDefault();
                                this.forgotPassword();
                              }}
                            >
                              Enviar mail de recupero
                            </Button>
                          </div>
                        </Form>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            </Container>
          </section>
        </main>
        <Footer />
      </>
    );
  }
}

export default ForgotPassword;
