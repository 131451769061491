import React from 'react';
// import MetaTags from "react-meta-tags";

// reactstrap components
import { Button, Card, CardHeader, CardBody, Container, Row, Col } from 'reactstrap';

// core components
import BasicNavbar from 'components/Navbars/BasicNavbar.jsx';
import WhatsappButton from 'components/Buttons/WhatsappButton';

import DigitalOnboardingStepper from './DigitalOnboardingStepper';
import {
  goToFatalErrorPage,
  getPersistedValue,
  removePersistedValue,
  formatMoney,
  analyticsTag,
} from 'helpers/coreHelper';

import Loading from 'components/Loading/Loading.jsx';
import { confirmReview } from 'services/leadsServices';

const {
  PLAN_SWITCHER_OPTION_ME,
  PLAN_SWITCHER_OPTION_CHILDRENS,
  PLAN_SWITCHER_OPTION_ME_AND_PARTNER,
  PLAN_SWITCHER_OPTION_FAMILY,
} = require('types/planSwitcherTypes');

class DigitalOnboardingStep5Review extends React.Component {
  constructor(props) {
    super(props);

    let lead = null;

    try {
      lead = getPersistedValue('lead');
    } catch (e) {}

    lead = {
      ...lead,
    };

    this.state = {
      lead: lead,
      loading: false,
    };
  }

  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;

    this.validateFormData();
  }

  validateFormData(lead) {
    let errors = [];

    if (!lead) lead = this.state.lead;

    if (errors.length === 0) errors = null;

    this.setState({ errors: errors });

    //console.log("errors:", errors);
    return errors;
  }

  confirmReview() {
    let errors = this.validateFormData();

    if (errors) return;

    this.setState({ loading: true });

    confirmReview(this.state.lead)
      .then(() => {
        const theLead = this.state.lead;
        const price = theLead.selectedPlan.prices[0].amount;

        analyticsTag({
          event: 'purchase',
          ecommerce: {
            transaction_id: theLead.id,
            value: price,
            items: [
              {
                item_name: theLead.selectedPlan.name, // Name or ID is required.
                item_id: theLead.selectedPlan.id,
                price: price,
                //item_brand: "Google",
                //item_category: "Apparel",
                //item_category2: "Mens",
                //item_category3: "Shirts",
                //item_category4: "Tshirts",
                //item_variant: "Black",
                item_list_name: theLead.selectedPlan.friendlyName,
                //item_list_id: "SR123",
                //index: 1,
                quantity: 1,
              },
            ],
          },
        });

        removePersistedValue('lead');

        this.props.history.push('/success', {
          lead: this.state.lead,
        });
      })
      .catch((e) => {
        console.log(e, e.message, e.code);

        //alert(e.message);

        goToFatalErrorPage(this.props.history, e);
      });
  }

  getBeneficiariesLabel() {
    if (this.state.lead.selectedPlanSwitcherOption.key === PLAN_SWITCHER_OPTION_ME.key)
      return 'Vos';

    if (this.state.lead.selectedPlanSwitcherOption.key === PLAN_SWITCHER_OPTION_CHILDRENS.key)
      return 'Tus hijos';

    if (this.state.lead.selectedPlanSwitcherOption.key === PLAN_SWITCHER_OPTION_ME_AND_PARTNER.key)
      return 'Vos y tu pareja';

    if (this.state.lead.selectedPlanSwitcherOption.key === PLAN_SWITCHER_OPTION_FAMILY.key)
      return 'Vos y toda tu familia';

    return '';
  }

  getPaymentMethodLabel() {
    let label = '';
    if (
      this.state.lead.paymentData.paymentMethod === 'TC' ||
      this.state.lead.paymentData.paymentMethod === 'TD'
    ) {
      switch (this.state.lead.paymentData.cardProvider) {
        case 'visa':
          label = 'Visa';
          break;
        case 'mastercard':
          label = 'Mastercard';
          break;
        case 'amex':
          label = 'American Express';
          break;
        default:
          label = this.state.lead.paymentData.cardProvider;
          break;
      }

      label += ' ';
      if (this.state.lead.paymentData.paymentMethod === 'TC') {
        label += 'crédito';
      } else {
        label += 'débito';
      }

      label += ' *** ';

      label += this.state.lead.paymentData.cardNumber.substring(12, 16);
      return label;
    } else if (this.state.lead.paymentData.paymentMethod === 'CBU') {
      label = 'CBU ' + this.state.lead.paymentData.CBU;
      return label;
    } else return 'Inválido';
  }

  render() {
    // let plan = this.state.lead.selectedPlan;
    return (
      <>
        {/* <MetaTags>
          <title>El plan para tu seguro de salud | Hola René</title>
          <meta
            id="meta-description"
            name="description"
            content="Toda la información para que elijas el plan de salud ideal para vos y tu familia."
          />
        </MetaTags> */}

        <WhatsappButton />
        <BasicNavbar />

        <main ref="main">
          <section className="section section-shaped bg-light-grey">
            <Container>
              <Row className="justify-content-center">
                <Col lg="3 px-sm-0">
                  <DigitalOnboardingStepper step={4} maxSteps={4} progress={100} />
                </Col>
                <Col lg="7" className="pl-md-0">
                  <Loading loading={this.state.loading} />
                  <Button
                    className="mt-4 hidden-sm"
                    color="custom flat back"
                    type="button"
                    onClick={() => {
                      this.props.history.goBack();
                    }}
                  ></Button>
                  <Card className="bg-transparent card-form border-0">
                    <CardHeader className="pb-0 bg-transparent">
                      <div className="form-title mb-3">Chequéa que todos los datos estén bien</div>
                    </CardHeader>
                    <CardBody>
                      <>
                        <Row>
                          <Col xs="12" lg="12" sm="12 resume-plan">
                            <div className="title-grey-light">Plan</div>
                            <div className="plan-resume">
                              {this.state.lead.selectedPlan.friendlyName}
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col xs="12" lg="12" sm="12 resume-plan">
                            <div className="title-grey-light">Quiénes están cubiertos</div>
                            <div>{this.getBeneficiariesLabel()}</div>
                          </Col>
                        </Row>

                        <Row>
                          <Col xs="12" lg="12" sm="12 resume-plan">
                            <div className="title-grey-light">Titular</div>

                            <div>{this.state.lead.firstName + ' ' + this.state.lead.lastName}</div>
                          </Col>
                        </Row>

                        <Row>
                          <Col xs="12" lg="12" sm="12 resume-plan">
                            <div className="title-grey-light">Medio de pago</div>

                            <div>{this.getPaymentMethodLabel()}</div>
                          </Col>
                        </Row>

                        <Row>
                          <Col xs="12" lg="12" sm="12 resume-plan">
                            <div className="title-grey-light">Monto mensual</div>

                            <div className="mount-resume">
                              $ {formatMoney(this.state.lead.selectedPlan.prices[0].amount)}
                            </div>
                          </Col>
                        </Row>
                      </>

                      <div className="pt-4">
                        <Button
                          className="mt-4"
                          color="custom"
                          type="button"
                          onClick={(event) => {
                            event.preventDefault();
                            this.confirmReview();
                          }}
                          disabled={
                            (this.state.errors && this.state.errors.length !== 0) ||
                            this.state.loading
                          }
                        >
                          Confirmar
                        </Button>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </section>
        </main>
      </>
    );
  }
}

export default DigitalOnboardingStep5Review;
