import { getHttpBearerConfig } from 'services/httpServices';
import axios from 'axios';
const appConfig = require('config/appConfig');

export const listPromotions = (currentUserToken) => {
  return new Promise(function (resolve, reject) {
    //exports.saveRole = function(currentUserToken, targetUser, role) {
    let config = getHttpBearerConfig(currentUserToken);

    return axios
      .get(`${appConfig.gcfUrl}/promotions`, config)
      .then((response) => {
        console.log('promotions response:', response);
        if (response && response.data) resolve(response.data);
        else resolve([]);
      })
      .catch((e) => {
        console.log('error:', e);

        reject(e);
      });
  });
};

export const createPromotion = (currentUserToken, promotion) => {
  return new Promise(function (resolve, reject) {
    let config = getHttpBearerConfig(currentUserToken);

    console.log('promotion en service:', promotion);

    let data = promotion;
    //le saco toda la info del coverage quedandome solo con el id y name de ese obj
    data.plan = { id: data.plan.id, name: data.plan.name };

    return axios
      .post(`${appConfig.gcfUrl}/promotions`, data, config)
      .then((response) => {
        console.log('create promotion response:', response);
        if (response && response.data) resolve(response.data);
        else resolve(null);
      })
      .catch((e) => {
        console.log('error:', e);

        reject(e);
      });
  });
};

export const updatePromotion = (currentUserToken, promotion) => {
  return new Promise(function (resolve, reject) {
    //exports.saveRole = function(currentUserToken, targetUser, role) {
    let config = getHttpBearerConfig(currentUserToken);

    let data = promotion;
    //le saco toda la info del coverage quedandome solo con el id y name de ese obj
    data.plan = { id: data.plan.id, name: data.plan.name };

    return axios
      .patch(`${appConfig.gcfUrl}/promotions/${promotion.id}`, data, config)
      .then((response) => {
        console.log('update promotion response:', response);
        if (response && response.data) resolve(response.data);
        else resolve(null);
      })
      .catch((e) => {
        console.log('error:', e);

        reject(e);
      });
  });
};

export const deletePromotion = (currentUserToken, promotion) => {
  return new Promise(function (resolve, reject) {
    let config = getHttpBearerConfig(currentUserToken);

    return axios
      .delete(`${appConfig.gcfUrl}/promotions/${promotion.id}`, config)
      .then((response) => {
        console.log('delete promotion response:', response);
        if (response && response.data) resolve(response.data);
        else resolve(null);
      })
      .catch((e) => {
        console.log('error:', e);

        reject(e);
      });
  });
};
