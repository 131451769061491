/*eslint-disable*/
import React from "react";
// reactstrap components
import { Progress, Button } from "reactstrap";

import { withRouter } from "react-router";

class DigitalOnboardingStepper extends React.Component {
  render() {
    return (
      <>
        <div className="progress-wrapper visible-sm">
          <Progress max="100" value={this.props.progress} />
          <p>
            <Button
              className="mr-2"
              color="custom flat back"
              type="button"
              onClick={() => {
                this.props.history.goBack();
              }}
            ></Button>
            PASO {this.props.step} DE {this.props.maxSteps}
          </p>
        </div>
        <ul className="form-stepper hidden-sm">
          <li
            className={
              this.props.step === 1
                ? "active"
                : this.props.step > 1
                ? "past"
                : ""
            }
          >
            <span>1</span> Datos del titular
          </li>
          <li
            className={
              this.props.step === 2
                ? "active"
                : this.props.step > 2
                ? "past"
                : ""
            }
          >
            <span>2</span> Datos de integrantes
          </li>
          <li
            className={
              this.props.step === 3
                ? "active"
                : this.props.step > 3
                ? "past"
                : ""
            }
          >
            <span>3</span> Medio de pago
          </li>
          <li
            className={
              this.props.step === 4
                ? "active"
                : this.props.step > 4
                ? "past"
                : ""
            }
          >
            <span>4</span> Confirmación
          </li>
        </ul>
      </>
    );
  }
}

export default withRouter(DigitalOnboardingStepper);
