import React from "react";

import classnames from "classnames";
// reactstrap components
import {
  Button,
  Modal,
  FormGroup,
  Input,
  
  InputGroupText,
  InputGroup,
  Row,
  Col
} from "reactstrap";
// core components

class PromotionsEditionModal extends React.Component {
  constructor(props) {
    super(props);

    if (!this.props.promotion.dueDate)
      this.props.promotion.dueDate = "2020-12-31T03:00:00.000Z";

    this.state = { promotion: this.props.promotion };
  }

  componentWillReceiveProps(nextProps) {
    console.log("componentWillReceiveProps", nextProps);
    this.setState({ promotion: nextProps.promotion });
  }

  onPlanSelectionChanged(item, event) {
    this.state.promotion.plan = this.state.promotion.plan || {};

    if (event.target.checked) {
      this.state.promotion.plan = item;
      //this.state.promotion.plan.id = item.id;
      //this.state.promotion.plan.name = item.name;
    }
    this.setState({ promotion: this.state.promotion });
  }

  render() {
    return (
      <>
        {this.props.plans && (
          <Modal
            className="modal-dialog-centered"
            isOpen={this.props.promotionsEditionModalIsOpen}
            toggle={() => this.props.toggleModal(this.props.modalName)}
          >
            <div className="modal-header">
              <h6 className="modal-title" id="modal-title-default">
                <>Promotion edition</>
              </h6>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => this.props.toggleModal(this.props.modalName)}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <div className="modal-body">
              {this.state.promotion.id && (
                <FormGroup>
                  <Input
                    disabled
                    placeholder="Id"
                    type="text"
                    value={this.state.promotion.id}
                  />
                </FormGroup>
              )}
              <FormGroup
                className={classnames({
                  focused: this.state.birthdayFocused
                })}
              >
                <InputGroup className="mb-4">
                  <Input
                    value={
                      this.state.promotion.code ? this.state.promotion.code : ""
                    }
                    placeholder="Code"
                    type="text"
                    onChange={e => {
                      e.preventDefault();
                      this.state.promotion.code = e.target.value;
                      this.setState({ promotion: this.state.promotion });
                    }}
                  />

                  
                    <InputGroupText>
                      <i className="ni ni-zoom-split-in" />
                    </InputGroupText>
                  
                </InputGroup>
                <InputGroup className="mb-4">
                  <Input
                    placeholder="Description"
                    value={
                      this.state.promotion.description
                        ? this.state.promotion.description
                        : ""
                    }
                    type="text"
                    onChange={e => {
                      e.preventDefault();
                      this.state.promotion.description = e.target.value;
                      this.setState({ promotion: this.state.promotion });
                    }}
                  />

                  
                    <InputGroupText>
                      <i className="ni ni-zoom-split-in" />
                    </InputGroupText>
                  
                </InputGroup>

                <InputGroup className="mb-4">
                  <Input
                    placeholder="Due date"
                    value={
                      this.state.promotion.dueDate
                        ? this.state.promotion.dueDate
                        : ""
                    }
                    type="text"
                    onChange={e => {
                      e.preventDefault();
                      this.state.promotion.dueDate = e.target.value;
                      this.setState({ promotion: this.state.promotion });
                    }}
                  />

                  
                    <InputGroupText>
                      <i className="ni ni-zoom-split-in" />
                    </InputGroupText>
                  
                </InputGroup>
              </FormGroup>

              <div className="mb-3">
                <small className="text-uppercase font-weight-bold">Plan</small>
              </div>
              <Row>
                <Col lg="12" sm="12">
                  {this.props.plans.map((item, index) => {
                    return (
                      <div key={index}>
                        <div
                          className="custom-control custom-control-alternative custom-checkbox"
                          key={index}
                        >
                          <input
                            className="custom-control-input"
                            type="checkbox"
                            id={"checkbox_PLAN_" + index}
                            onChange={e => {
                              this.onPlanSelectionChanged(item, e);
                            }}
                            checked={
                              this.state.promotion.plan &&
                              this.state.promotion.plan.id === item.id
                            }
                          />
                          <label
                            className="custom-control-label"
                            htmlFor={"checkbox_PLAN_" + index}
                          >
                            <span className="text-muted">{item.name}</span>
                          </label>
                        </div>
                      </div>
                    );
                  })}
                </Col>
              </Row>
              <Row>
                <Col lg="6" sm="6">
                  <FormGroup>
                    <label>
                      <span className="text-muted">Expiration</span>
                    </label>

                    <Input
                      placeholder="Expiration in days"
                      value={
                        this.state.promotion.expirationInDays
                          ? this.state.promotion.expirationInDays
                          : ""
                      }
                      type="text"
                      onChange={e => {
                        e.preventDefault();

                        this.state.promotion.expirationInDays = e.target.value;
                        this.setState({ promotion: this.state.promotion });
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col lg="6" sm="6">
                  <FormGroup>
                    <label>
                      <span className="text-muted">Bonification</span>
                    </label>

                    <Input
                      placeholder="Bonification in %"
                      value={
                        this.state.promotion.bonification
                          ? this.state.promotion.bonification
                          : ""
                      }
                      type="text"
                      onChange={e => {
                        e.preventDefault();

                        this.state.promotion.bonification = e.target.value;
                        this.setState({ promotion: this.state.promotion });
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </div>
            <div className="modal-footer">
              <Button
                color="primary"
                type="button"
                onClick={e => {
                  e.preventDefault();
                  this.props.savePromotion(this.state.promotion);
                }}
              >
                Save
              </Button>
              <Button
                className="ml-auto"
                color="link"
                data-dismiss="modal"
                type="button"
                onClick={() => this.props.toggleModal(this.props.modalName)}
              >
                Close
              </Button>
            </div>
          </Modal>
        )}
      </>
    );
  }
}

export default PromotionsEditionModal;
