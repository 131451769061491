/*eslint-disable*/
import React, { useState } from "react";
// reactstrap components
import { FormGroup, Input } from "reactstrap";

import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";

import Cleave from "cleave.js/react";

function CustomSelect({ ...props }) {
  const [isValid, setIsValid] = useState(true);

  const {
    classes,
    formControlProps,
    labelText,
    errorLabelText,
    errorLabelProps,
    id,
    labelProps,
    inputProps,
    error,
    white,
    inputRootCustomClasses,
    success,
    particularInputClasses,
    validationFn,
    select,
  } = props;

  const labelRoot = classNames({
    [" " + "labelRoot"]: true,
  });

  const labelClasses = classNames({
    [" " + "labelRootError"]: error || !isValid,
    [" " + "labelRootSuccess"]: success && !error && isValid,
  });
  const inputClasses = classNames({
    ["input"]: true,
    [" " + "inputError"]: error || !isValid,
    [" " + "inputSuccess"]: success && !error && isValid,
  });

  const formGroupClasses = classNames({ ["has-danger"]: error || !isValid });
  return (
    <>
      {labelText !== undefined && (
        <div className={labelRoot + " " + labelClasses} {...labelProps}>
          {labelText}
        </div>
      )}
      <FormGroup className={formGroupClasses}>
        <select
          className={inputClasses}
          {...inputProps}
          onChange={(e) => {
            let valid = true;

            if (validationFn && !validationFn(event.target.value))
              valid = false;

            setIsValid(valid);

            if (inputProps.onChange) inputProps.onChange(event);
            if (inputProps.onInputChange)
              inputProps.onInputChange(event, valid);
          }}
        >
          {select.defaultOption && (
            <option key={0} value="">
              {select.defaultOption}
            </option>
          )}
          {select.options.map((option, index) => {
            return (
              <option key={index + 1} value={option.key}>
                {option.value}
              </option>
            );
          })}
        </select>
      </FormGroup>

      {(error || !isValid) && errorLabelText !== undefined && (
        <div className="text-warning" {...errorLabelProps}>
          {errorLabelText}
        </div>
      )}
    </>
  );
}

CustomSelect.propTypes = {
  // classes: PropTypes.object.isRequired,
  labelText: PropTypes.node,
  errorLabelText: PropTypes.node,
  errorLabelProps: PropTypes.object,
  labelProps: PropTypes.object,
  id: PropTypes.string,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  inputRootCustomClasses: PropTypes.string,
  error: PropTypes.bool,
  success: PropTypes.bool,
  white: PropTypes.bool,
};

export default CustomSelect;
