import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Table,
  Container,
  Col,
  Row,
} from "reactstrap";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.jsx";
import CoverageEditionModal from "./CoverageEditionModal.jsx";
import MedicEditionModal from "./MedicEditionModal.jsx";
import EmergencyEditionModal from "./EmergencyEditionModal.jsx";
import HomeCareEditionModal from "./HomeCareEditionModal.jsx";
import TelemedicineEditionModal from "./TelemedicineEditionModal.jsx";
import LifeInsuranceEditionModal from "./LifeInsuranceEditionModal.jsx";

import { authRef } from "config/firebase";

import {
  listCoverages,
  createCoverage,
  updateCoverage,
  deleteCoverage,
} from "services/coveragesServices";

import { listMedics } from "services/medicsServices";

import {
  listEmergencies,
  createEmergency,
  updateEmergency,
  deleteEmergency,
} from "services/emergenciesServices";

import {
  listHomeCares,
  createHomeCare,
  updateHomeCare,
  deleteHomeCare,
} from "services/homeCaresServices";

import {
  listTelemedicines,
  createTelemedicine,
  updateTelemedicine,
  deleteTelemedicine,
} from "services/telemedicinesServices";

import {
  listLifeInsurances,
  createLifeInsurance,
  updateLifeInsurance,
  deleteLifeInsurance,
} from "services/lifeInsurancesServices";

class CoveragesOverview extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      coverages: null,
      medics: null,
      emergencies: null,
      homeCares: null,
      telemedicines: null,
      lifeInsurances: null,
      homeAssistances: null,
    };
  }

  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
    });
  };

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    authRef.onAuthStateChanged((user) => {
      this.setState({ currentUser: user });

      user.getIdToken().then((token) => {
        listMedics(999999, 0, null)
          .then((medics) => {
            console.log("medics: ", medics);
            this.setState({ medics: medics.items });
            return listCoverages(token);
          })
          .then((coverages) => {
            console.log("coverages: ", coverages);
            debugger;
            //por una cuestión de simplicidad y reutilizar lo que había, a la cobertura le agrego los medicos asociados
            this.state.medics.forEach((medic) => {
              coverages.forEach((coverage) => {
                if (
                  medic.coverages &&
                  medic.coverages.find((medicCoverage) => {
                    return medicCoverage === coverage.id;
                  })
                ) {
                  if (!coverage.medics) coverage.medics = [];
                  coverage.medics.push(medic);
                }
              });
            });

            this.setState({ coverages: coverages });
          })
          .catch((e) => {
            if (e.response && e.response.data && e.response.data.message)
              alert(e.response.data.message);
            else alert(e.message);
          });

        listEmergencies(token)
          .then((emergencies) => {
            console.log("emergencies: ", emergencies);
            this.setState({ emergencies: emergencies });
          })
          .catch((e) => {
            if (e.response && e.response.data && e.response.data.message)
              alert(e.response.data.message);
            else alert(e.message);
          });

        listHomeCares(token)
          .then((homeCares) => {
            console.log("homeCares: ", homeCares);
            this.setState({ homeCares: homeCares });
          })
          .catch((e) => {
            if (e.response && e.response.data && e.response.data.message)
              alert(e.response.data.message);
            else alert(e.message);
          });

        listTelemedicines(token)
          .then((telemedicines) => {
            console.log("telemedicines: ", telemedicines);
            this.setState({ telemedicines: telemedicines });
          })
          .catch((e) => {
            if (e.response && e.response.data && e.response.data.message)
              alert(e.response.data.message);
            else alert(e.message);
          });

        listLifeInsurances(token)
          .then((lifeInsurances) => {
            console.log("lifeInsurances: ", lifeInsurances);
            this.setState({ lifeInsurances: lifeInsurances });
          })
          .catch((e) => {
            if (e.response && e.response.data && e.response.data.message)
              alert(e.response.data.message);
            else alert(e.message);
          });
      });
    });
  }

  componentDidUpdate(e) {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.mainContent.scrollTop = 0;
  }

  hasItem = (items, item) => {
    if (!items) return false;

    if (!item) return false;

    if (!items.length) return false;

    let exists = false;
    items.forEach((i) => {
      if (i.id === item.id) exists = true;
    });

    return exists;
  };

  saveCoverage(coverage) {
    if (!coverage.name || !coverage.contractId || !coverage.description) {
      alert("Faltan datos requeridos");
      return;
    }

    if (this.state.medics) {
      this.state.medics.forEach((medic) => {
        //Está seleccionado este médico, pero no está en la lista del medico, entonces lo agrego
        if (
          coverage.medics &&
          coverage.medics.find((covMedic) => {
            return covMedic.id === medic.id;
          })
        ) {
          if (!this.hasItem(medic.coverages, coverage)) {
            if (!medic.coverages) medic.coverages = [];
            medic.coverages.push(coverage);
          }
        } else {
          //No está seleccionado, pero si está asociado al medico entonces lo borro
          if (this.hasItem(medic.coverages, coverage)) {
            if (!medic.coverages) medic.coverages = [];
            let newList = [];
            medic.coverages.forEach((existent) => {
              if (existent.id !== coverage.id) newList.push(existent);
            });
            medic.coverages = newList;
          }
        }
      });

      this.setState({ medics: this.state.medics });
    }

    authRef.onAuthStateChanged((user) => {
      user.getIdToken().then((token) => {
        if (coverage.id)
          updateCoverage(token, coverage)
            .then((coverage) => {
              this.loadData();
              console.log("Coverage: ", coverage);
              alert("Coverage: " + JSON.stringify(coverage));
              this.toggleModal("coverageEditionModal");
            })
            .catch((e) => {
              if (e.response && e.response.data && e.response.data.message)
                alert(e.response.data.message);
              else alert(e.message);
            });
        else
          createCoverage(token, coverage)
            .then((coverage) => {
              this.loadData();
              console.log("Coverage: ", coverage);
              alert("Coverage: " + JSON.stringify(coverage));
              this.toggleModal("coverageEditionModal");
            })
            .catch((e) => {
              if (e.response && e.response.data && e.response.data.message)
                alert(e.response.data.message);
              else alert(e.message);
            });
      });
    });
  }

  innerDeleteCoverage(coverage) {
    authRef.onAuthStateChanged((user) => {
      user.getIdToken().then((token) => {
        deleteCoverage(token, coverage)
          .then((coverage) => {
            this.loadData();
            console.log("Coverage: ", coverage);
            alert("Coverage: " + JSON.stringify(coverage) + "removed");
          })
          .catch((e) => {
            if (e.response && e.response.data && e.response.data.message)
              alert(e.response.data.message);
            else alert(e.message);
          });
      });
    });
  }

  saveEmergency(emergency) {
    if (
      !emergency.name ||
      !emergency.tel ||
      !emergency.address ||
      !emergency.description
    ) {
      alert("Faltan datos requeridos");
      return;
    }

    authRef.onAuthStateChanged((user) => {
      user.getIdToken().then((token) => {
        if (emergency.id)
          updateEmergency(token, emergency)
            .then((emergency) => {
              this.loadData();
              console.log("Emergency: ", emergency);
              alert("Emergency: " + JSON.stringify(emergency));
              this.toggleModal("emergencyEditionModal");
            })
            .catch((e) => {
              if (e.response && e.response.data && e.response.data.message)
                alert(e.response.data.message);
              else alert(e.message);
            });
        else
          createEmergency(token, emergency)
            .then((emergency) => {
              this.loadData();
              console.log("Emergency: ", emergency);
              alert("Emergency: " + JSON.stringify(emergency));
              this.toggleModal("emergencyEditionModal");
            })
            .catch((e) => {
              if (e.response && e.response.data && e.response.data.message)
                alert(e.response.data.message);
              else alert(e.message);
            });
      });
    });
  }

  innerDeleteEmergency(emergency) {
    authRef.onAuthStateChanged((user) => {
      user.getIdToken().then((token) => {
        deleteEmergency(token, emergency)
          .then((emergency) => {
            this.loadData();
            console.log("Emergency: ", emergency);
            alert("Emergency: " + JSON.stringify(emergency) + "removed");
          })
          .catch((e) => {
            if (e.response && e.response.data && e.response.data.message)
              alert(e.response.data.message);
            else alert(e.message);
          });
      });
    });
  }

  saveHomeCare(homeCare) {
    if (
      !homeCare.name ||
      !homeCare.tel ||
      !homeCare.address ||
      !homeCare.description
    ) {
      alert("Faltan datos requeridos");
      return;
    }

    authRef.onAuthStateChanged((user) => {
      user.getIdToken().then((token) => {
        if (homeCare.id)
          updateHomeCare(token, homeCare)
            .then((homeCare) => {
              this.loadData();
              console.log("HomeCare: ", homeCare);
              alert("HomeCare: " + JSON.stringify(homeCare));
              this.toggleModal("homeCareEditionModal");
            })
            .catch((e) => {
              if (e.response && e.response.data && e.response.data.message)
                alert(e.response.data.message);
              else alert(e.message);
            });
        else
          createHomeCare(token, homeCare)
            .then((homeCare) => {
              this.loadData();
              console.log("HomeCare: ", homeCare);
              alert("HomeCare: " + JSON.stringify(homeCare));
              this.toggleModal("homeCareEditionModal");
            })
            .catch((e) => {
              if (e.response && e.response.data && e.response.data.message)
                alert(e.response.data.message);
              else alert(e.message);
            });
      });
    });
  }

  innerDeleteHomeCare(homeCare) {
    authRef.onAuthStateChanged((user) => {
      user.getIdToken().then((token) => {
        deleteHomeCare(token, homeCare)
          .then((homeCare) => {
            this.loadData();
            console.log("HomeCare: ", homeCare);
            alert("HomeCare: " + JSON.stringify(homeCare) + "removed");
          })
          .catch((e) => {
            if (e.response && e.response.data && e.response.data.message)
              alert(e.response.data.message);
            else alert(e.message);
          });
      });
    });
  }

  saveTelemedicine(telemedicine) {
    if (
      !telemedicine.name ||
      !telemedicine.tel ||
      !telemedicine.address ||
      !telemedicine.description
    ) {
      alert("Faltan datos requeridos");
      return;
    }

    authRef.onAuthStateChanged((user) => {
      user.getIdToken().then((token) => {
        if (telemedicine.id)
          updateTelemedicine(token, telemedicine)
            .then((telemedicine) => {
              this.loadData();
              console.log("Telemedicine: ", telemedicine);
              alert("Telemedicine: " + JSON.stringify(telemedicine));
              this.toggleModal("telemedicineEditionModal");
            })
            .catch((e) => {
              if (e.response && e.response.data && e.response.data.message)
                alert(e.response.data.message);
              else alert(e.message);
            });
        else
          createTelemedicine(token, telemedicine)
            .then((telemedicine) => {
              this.loadData();
              console.log("Telemedicine: ", telemedicine);
              alert("Telemedicine: " + JSON.stringify(telemedicine));
              this.toggleModal("telemedicineEditionModal");
            })
            .catch((e) => {
              if (e.response && e.response.data && e.response.data.message)
                alert(e.response.data.message);
              else alert(e.message);
            });
      });
    });
  }

  innerDeleteTelemedicine(telemedicine) {
    authRef.onAuthStateChanged((user) => {
      user.getIdToken().then((token) => {
        deleteTelemedicine(token, telemedicine)
          .then((telemedicine) => {
            this.loadData();
            console.log("Telemedicine: ", telemedicine);
            alert("Telemedicine: " + JSON.stringify(telemedicine) + "removed");
          })
          .catch((e) => {
            if (e.response && e.response.data && e.response.data.message)
              alert(e.response.data.message);
            else alert(e.message);
          });
      });
    });
  }

  saveLifeInsurance(lifeInsurance) {
    if (!lifeInsurance.name || !lifeInsurance.description) {
      alert("Faltan datos requeridos");
      return;
    }

    authRef.onAuthStateChanged((user) => {
      user.getIdToken().then((token) => {
        if (lifeInsurance.id)
          updateLifeInsurance(token, lifeInsurance)
            .then((lifeInsurance) => {
              this.loadData();
              console.log("LifeInsurance: ", lifeInsurance);
              alert("LifeInsurance: " + JSON.stringify(lifeInsurance));
              this.toggleModal("lifeInsuranceEditionModal");
            })
            .catch((e) => {
              if (e.response && e.response.data && e.response.data.message)
                alert(e.response.data.message);
              else alert(e.message);
            });
        else
          createLifeInsurance(token, lifeInsurance)
            .then((lifeInsurance) => {
              alert(1);
              this.loadData();
              console.log("LifeInsurance: ", lifeInsurance);
              alert("LifeInsurance: " + JSON.stringify(lifeInsurance));
              this.toggleModal("lifeInsuranceEditionModal");
            })
            .catch((e) => {
              if (e.response && e.response.data && e.response.data.message)
                alert(e.response.data.message);
              else alert(e.message);
            });
      });
    });
  }

  innerDeleteLifeInsurance(lifeInsurance) {
    authRef.onAuthStateChanged((user) => {
      user.getIdToken().then((token) => {
        deleteLifeInsurance(token, lifeInsurance)
          .then((lifeInsurance) => {
            this.loadData();
            console.log("LifeInsurance: ", lifeInsurance);
            alert(
              "LifeInsurance: " + JSON.stringify(lifeInsurance) + "removed"
            );
          })
          .catch((e) => {
            if (e.response && e.response.data && e.response.data.message)
              alert(e.response.data.message);
            else alert(e.message);
          });
      });
    });
  }

  render() {
    return (
      <>
        <div className="main-content" ref="mainContent">
          <AdminNavbar {...this.props} currentUser={this.state.currentUser} />

          {this.state.medics &&
            this.state.emergencies &&
            this.state.homeCares &&
            this.state.telemedicines &&
            this.state.lifeInsurances &&
            this.state.coverageToEdit && (
              <CoverageEditionModal
                modalName="coverageEditionModal"
                medics={this.state.medics}
                emergencies={this.state.emergencies}
                homeCares={this.state.homeCares}
                telemedicines={this.state.telemedicines}
                lifeInsurances={this.state.lifeInsurances}
                coverageEditionModalIsOpen={this.state.coverageEditionModal}
                coverage={this.state.coverageToEdit}
                toggleModal={this.toggleModal.bind(this)}
                saveCoverage={this.saveCoverage.bind(this)}
              />
            )}

          {this.state.medics && this.state.medicToEdit && (
            <MedicEditionModal
              modalName="medicEditionModal"
              medicEditionModalIsOpen={this.state.medicEditionModal}
              medic={this.state.medicToEdit}
              toggleModal={this.toggleModal.bind(this)}
              saveMedic={this.saveMedic.bind(this)}
            />
          )}

          {this.state.lifeInsurances && this.state.lifeInsuranceToEdit && (
            <LifeInsuranceEditionModal
              modalName="lifeInsuranceEditionModal"
              lifeInsuranceEditionModalIsOpen={
                this.state.lifeInsuranceEditionModal
              }
              lifeInsurance={this.state.lifeInsuranceToEdit}
              toggleModal={this.toggleModal.bind(this)}
              saveLifeInsurance={this.saveLifeInsurance.bind(this)}
            />
          )}

          {this.state.emergencies && this.state.emergencyToEdit && (
            <EmergencyEditionModal
              modalName="emergencyEditionModal"
              emergencyEditionModalIsOpen={this.state.emergencyEditionModal}
              emergency={this.state.emergencyToEdit}
              toggleModal={this.toggleModal.bind(this)}
              saveEmergency={this.saveEmergency.bind(this)}
            />
          )}

          {this.state.homeCares && this.state.homeCareToEdit && (
            <HomeCareEditionModal
              modalName="homeCareEditionModal"
              homeCareEditionModalIsOpen={this.state.homeCareEditionModal}
              homeCare={this.state.homeCareToEdit}
              toggleModal={this.toggleModal.bind(this)}
              saveHomeCare={this.saveHomeCare.bind(this)}
            />
          )}

          {this.state.telemedicines && this.state.telemedicineToEdit && (
            <TelemedicineEditionModal
              modalName="telemedicineEditionModal"
              telemedicineEditionModalIsOpen={
                this.state.telemedicineEditionModal
              }
              telemedicine={this.state.telemedicineToEdit}
              toggleModal={this.toggleModal.bind(this)}
              saveTelemedicine={this.saveTelemedicine.bind(this)}
            />
          )}
          <Container fluid>
            {/* COVERAGES */}
            <Row>
              <div className="col">
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <Row>
                      <Col lg="10">
                        <h3 className="mb-0">Coverages</h3>
                      </Col>
                      <Col lg="2">
                        <div className="btn-wrapper">
                          <Button
                            color="success"
                            onClick={(e) => {
                              e.preventDefault();

                              this.setState({ coverageToEdit: {} });
                              this.toggleModal("coverageEditionModal");
                            }}
                          >
                            New
                          </Button>
                          {/* <Button
                            className="btn-white"
                            color="default"
                            to="/register-page"
                            tag={Link}
                          >
                            Register Page
                          </Button> */}
                        </div>
                      </Col>
                    </Row>
                  </CardHeader>
                  {!this.state.coverages && <div>Loading</div>}
                  {this.state.coverages && (
                    <Table
                      className="align-items-center table-flush"
                      responsive
                    >
                      <thead className="thead-light">
                        <tr>
                          <th scope="col">Id</th>
                          <th scope="col">Name</th>
                          <th scope="col">Contract</th>
                          <th scope="col">Asociated Services</th>

                          <th scope="col" />
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.coverages.map((coverage, index) => {
                          return (
                            <tr key={index}>
                              <th scope="row">
                                <Media className="align-items-center">
                                  <a
                                    className="avatar rounded-circle mr-3"
                                    onClick={(e) => e.preventDefault()}
                                  >
                                    <img alt="..." src={""} />
                                  </a>
                                </Media>
                              </th>
                              <td>
                                <Media>
                                  <span className="mb-0 text-sm">
                                    {coverage.name}
                                  </span>
                                </Media>
                              </td>
                              <td>{coverage.contractId}</td>

                              <td>
                                {coverage.medics && (
                                  <ul>
                                    {coverage.medics.map((item, index) => {
                                      return <li key={index}>{item.name}</li>;
                                    })}
                                  </ul>
                                )}

                                {coverage.lifeInsurances && (
                                  <ul>
                                    {coverage.lifeInsurances.map(
                                      (item, index) => {
                                        return <li key={index}>{item.name}</li>;
                                      }
                                    )}
                                  </ul>
                                )}

                                {coverage.emergencies && (
                                  <ul>
                                    {coverage.emergencies.map((item, index) => {
                                      return <li key={index}>{item.name}</li>;
                                    })}
                                  </ul>
                                )}

                                {coverage.homeCares && (
                                  <ul>
                                    {coverage.homeCares.map((item, index) => {
                                      return <li key={index}>{item.name}</li>;
                                    })}
                                  </ul>
                                )}

                                {coverage.telemedicines && (
                                  <ul>
                                    {coverage.telemedicines.map(
                                      (item, index) => {
                                        return <li key={index}>{item.name}</li>;
                                      }
                                    )}
                                  </ul>
                                )}
                              </td>

                              <td className="text-right">
                                <UncontrolledDropdown>
                                  <DropdownToggle
                                    className="btn-icon-only text-light"
                                    href="#"
                                    role="button"
                                    size="sm"
                                    color=""
                                    onClick={(e) => e.preventDefault()}
                                  >
                                    <i className="fas fa-ellipsis-v" />
                                  </DropdownToggle>
                                  <DropdownMenu
                                    className="dropdown-menu-arrow"
                                    right
                                  >
                                    <DropdownItem
                                      onClick={(e) => {
                                        e.preventDefault();

                                        this.setState({
                                          coverageToEdit: coverage,
                                        });
                                        this.toggleModal(
                                          "coverageEditionModal"
                                        );
                                      }}
                                    >
                                      Edit
                                    </DropdownItem>

                                    <DropdownItem
                                      onClick={(e) => {
                                        e.preventDefault();
                                        this.props.history.push(
                                          "/coverage-detail"
                                        );
                                      }}
                                    >
                                      Detail
                                    </DropdownItem>
                                    <DropdownItem
                                      onClick={(e) => {
                                        e.preventDefault();

                                        this.innerDeleteCoverage(coverage);
                                      }}
                                    >
                                      Delete
                                    </DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  )}{" "}
                </Card>
              </div>
            </Row>
            <br />
            <hr></hr>
            {/* MEDICS 
            
          */}
            <br />
            <hr></hr>
            {/* LIFE INSURANCES */}
            <Row>
              <div className="col">
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <Row>
                      <Col lg="10">
                        <h3 className="mb-0">Life insurances</h3>
                      </Col>
                      <Col lg="2">
                        <div className="btn-wrapper">
                          <Button
                            color="success"
                            onClick={(e) => {
                              e.preventDefault();

                              this.setState({ lifeInsuranceToEdit: {} });
                              this.toggleModal("lifeInsuranceEditionModal");
                            }}
                          >
                            New
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </CardHeader>
                  {!this.state.lifeInsurances && <div>Loading</div>}
                  {this.state.lifeInsurances && (
                    <Table
                      className="align-items-center table-flush"
                      responsive
                    >
                      <thead className="thead-light">
                        <tr>
                          <th scope="col">Id</th>
                          <th scope="col">Name</th>
                          <th scope="col">?</th>

                          <th scope="col" />
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.lifeInsurances.map(
                          (lifeInsurance, index) => {
                            return (
                              <tr key={index}>
                                <th scope="row">
                                  <Media className="align-items-center">
                                    <a
                                      className="avatar rounded-circle mr-3"
                                      onClick={(e) => e.preventDefault()}
                                    >
                                      <img alt="..." src={""} />
                                    </a>
                                  </Media>
                                </th>
                                <td>
                                  <Media>
                                    <span className="mb-0 text-sm">
                                      {lifeInsurance.name}
                                    </span>
                                  </Media>
                                </td>
                                <td>...</td>

                                <td className="text-right">
                                  <UncontrolledDropdown>
                                    <DropdownToggle
                                      className="btn-icon-only text-light"
                                      href="#"
                                      role="button"
                                      size="sm"
                                      color=""
                                      onClick={(e) => e.preventDefault()}
                                    >
                                      <i className="fas fa-ellipsis-v" />
                                    </DropdownToggle>
                                    <DropdownMenu
                                      className="dropdown-menu-arrow"
                                      right
                                    >
                                      <DropdownItem
                                        onClick={(e) => {
                                          e.preventDefault();

                                          this.setState({
                                            lifeInsuranceToEdit: lifeInsurance,
                                          });
                                          this.toggleModal(
                                            "lifeInsuranceEditionModal"
                                          );
                                        }}
                                      >
                                        Edit
                                      </DropdownItem>

                                      <DropdownItem
                                        onClick={(e) => {
                                          e.preventDefault();
                                          this.props.history.push(
                                            "/lifeInsurance-detail"
                                          );
                                        }}
                                      >
                                        Detail
                                      </DropdownItem>
                                      <DropdownItem
                                        onClick={(e) => {
                                          e.preventDefault();

                                          this.innerDeleteLifeInsurance(
                                            lifeInsurance
                                          );
                                        }}
                                      >
                                        Delete
                                      </DropdownItem>
                                    </DropdownMenu>
                                  </UncontrolledDropdown>
                                </td>
                              </tr>
                            );
                          }
                        )}
                      </tbody>
                    </Table>
                  )}{" "}
                </Card>
              </div>
            </Row>

            <br />
            <hr></hr>
            {/* URGENCIES */}
            <Row>
              <div className="col">
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <Row>
                      <Col lg="10">
                        <h3 className="mb-0">Emergencies</h3>
                      </Col>
                      <Col lg="2">
                        <div className="btn-wrapper">
                          <Button
                            color="success"
                            onClick={(e) => {
                              e.preventDefault();

                              this.setState({ emergencyToEdit: {} });
                              this.toggleModal("emergencyEditionModal");
                            }}
                          >
                            New
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </CardHeader>
                  {!this.state.emergencies && <div>Loading</div>}
                  {this.state.emergencies && (
                    <Table
                      className="align-items-center table-flush"
                      responsive
                    >
                      <thead className="thead-light">
                        <tr>
                          <th scope="col">Id</th>
                          <th scope="col">Name</th>
                          <th scope="col">Tel</th>
                          <th scope="col">Address</th>

                          <th scope="col" />
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.emergencies.map((emergency, index) => {
                          return (
                            <tr key={index}>
                              <th scope="row">
                                <Media className="align-items-center">
                                  <a
                                    className="avatar rounded-circle mr-3"
                                    onClick={(e) => e.preventDefault()}
                                  >
                                    <img alt="..." src={""} />
                                  </a>
                                </Media>
                              </th>
                              <td>
                                <Media>
                                  <span className="mb-0 text-sm">
                                    {emergency.name}
                                  </span>
                                </Media>
                              </td>
                              <td>{emergency.tel}</td>

                              <td>{emergency.address}</td>

                              <td className="text-right">
                                <UncontrolledDropdown>
                                  <DropdownToggle
                                    className="btn-icon-only text-light"
                                    href="#"
                                    role="button"
                                    size="sm"
                                    color=""
                                    onClick={(e) => e.preventDefault()}
                                  >
                                    <i className="fas fa-ellipsis-v" />
                                  </DropdownToggle>
                                  <DropdownMenu
                                    className="dropdown-menu-arrow"
                                    right
                                  >
                                    <DropdownItem
                                      onClick={(e) => {
                                        e.preventDefault();

                                        this.setState({
                                          emergencyToEdit: emergency,
                                        });
                                        this.toggleModal(
                                          "emergencyEditionModal"
                                        );
                                      }}
                                    >
                                      Edit
                                    </DropdownItem>

                                    <DropdownItem
                                      onClick={(e) => {
                                        e.preventDefault();
                                        this.props.history.push(
                                          "/emergency-detail"
                                        );
                                      }}
                                    >
                                      Detail
                                    </DropdownItem>
                                    <DropdownItem
                                      onClick={(e) => {
                                        e.preventDefault();

                                        this.innerDeleteEmergency(emergency);
                                      }}
                                    >
                                      Delete
                                    </DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  )}{" "}
                </Card>
              </div>
            </Row>
            <br />
            <hr></hr>
            {/* HOME CARES */}
            <Row>
              <div className="col">
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <Row>
                      <Col lg="10">
                        <h3 className="mb-0">Home Cares</h3>
                      </Col>
                      <Col lg="2">
                        <div className="btn-wrapper">
                          <Button
                            color="success"
                            onClick={(e) => {
                              e.preventDefault();

                              this.setState({ homeCareToEdit: {} });
                              this.toggleModal("homeCareEditionModal");
                            }}
                          >
                            New
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </CardHeader>
                  {!this.state.homeCares && <div>Loading</div>}
                  {this.state.homeCares && (
                    <Table
                      className="align-items-center table-flush"
                      responsive
                    >
                      <thead className="thead-light">
                        <tr>
                          <th scope="col">Id</th>
                          <th scope="col">Name</th>
                          <th scope="col">Tel</th>
                          <th scope="col">Address</th>

                          <th scope="col" />
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.homeCares.map((homeCare, index) => {
                          return (
                            <tr key={index}>
                              <th scope="row">
                                <Media className="align-items-center">
                                  <a
                                    className="avatar rounded-circle mr-3"
                                    onClick={(e) => e.preventDefault()}
                                  >
                                    <img alt="..." src={""} />
                                  </a>
                                </Media>
                              </th>
                              <td>
                                <Media>
                                  <span className="mb-0 text-sm">
                                    {homeCare.name}
                                  </span>
                                </Media>
                              </td>
                              <td>{homeCare.tel}</td>

                              <td>{homeCare.address}</td>

                              <td className="text-right">
                                <UncontrolledDropdown>
                                  <DropdownToggle
                                    className="btn-icon-only text-light"
                                    href="#"
                                    role="button"
                                    size="sm"
                                    color=""
                                    onClick={(e) => e.preventDefault()}
                                  >
                                    <i className="fas fa-ellipsis-v" />
                                  </DropdownToggle>
                                  <DropdownMenu
                                    className="dropdown-menu-arrow"
                                    right
                                  >
                                    <DropdownItem
                                      onClick={(e) => {
                                        e.preventDefault();

                                        this.setState({
                                          homeCareToEdit: homeCare,
                                        });
                                        this.toggleModal(
                                          "homeCareEditionModal"
                                        );
                                      }}
                                    >
                                      Edit
                                    </DropdownItem>

                                    <DropdownItem
                                      onClick={(e) => {
                                        e.preventDefault();
                                        this.props.history.push(
                                          "/homeCare-detail"
                                        );
                                      }}
                                    >
                                      Detail
                                    </DropdownItem>
                                    <DropdownItem
                                      onClick={(e) => {
                                        e.preventDefault();

                                        this.innerDeleteHomeCare(homeCare);
                                      }}
                                    >
                                      Delete
                                    </DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  )}{" "}
                </Card>
              </div>
            </Row>
            {/* TELEMEDICINES */}
            <Row>
              <div className="col">
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <Row>
                      <Col lg="10">
                        <h3 className="mb-0">Telemedicines</h3>
                      </Col>
                      <Col lg="2">
                        <div className="btn-wrapper">
                          <Button
                            color="success"
                            onClick={(e) => {
                              e.preventDefault();

                              this.setState({ telemedicineToEdit: {} });
                              this.toggleModal("telemedicineEditionModal");
                            }}
                          >
                            New
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </CardHeader>
                  {!this.state.telemedicines && <div>Loading</div>}
                  {this.state.telemedicines && (
                    <Table
                      className="align-items-center table-flush"
                      responsive
                    >
                      <thead className="thead-light">
                        <tr>
                          <th scope="col">Id</th>
                          <th scope="col">Name</th>
                          <th scope="col">Tel</th>
                          <th scope="col">Address</th>

                          <th scope="col" />
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.telemedicines.map((telemedicine, index) => {
                          return (
                            <tr key={index}>
                              <th scope="row">
                                <Media className="align-items-center">
                                  <a
                                    className="avatar rounded-circle mr-3"
                                    onClick={(e) => e.preventDefault()}
                                  >
                                    <img alt="..." src={""} />
                                  </a>
                                </Media>
                              </th>
                              <td>
                                <Media>
                                  <span className="mb-0 text-sm">
                                    {telemedicine.name}
                                  </span>
                                </Media>
                              </td>
                              <td>{telemedicine.tel}</td>

                              <td>{telemedicine.address}</td>

                              <td className="text-right">
                                <UncontrolledDropdown>
                                  <DropdownToggle
                                    className="btn-icon-only text-light"
                                    href="#"
                                    role="button"
                                    size="sm"
                                    color=""
                                    onClick={(e) => e.preventDefault()}
                                  >
                                    <i className="fas fa-ellipsis-v" />
                                  </DropdownToggle>
                                  <DropdownMenu
                                    className="dropdown-menu-arrow"
                                    right
                                  >
                                    <DropdownItem
                                      onClick={(e) => {
                                        e.preventDefault();

                                        this.setState({
                                          telemedicineToEdit: telemedicine,
                                        });
                                        this.toggleModal(
                                          "telemedicineEditionModal"
                                        );
                                      }}
                                    >
                                      Edit
                                    </DropdownItem>

                                    <DropdownItem
                                      onClick={(e) => {
                                        e.preventDefault();
                                        this.props.history.push(
                                          "/telemedicine-detail"
                                        );
                                      }}
                                    >
                                      Detail
                                    </DropdownItem>
                                    <DropdownItem
                                      onClick={(e) => {
                                        e.preventDefault();

                                        this.innerDeleteTelemedicine(
                                          telemedicine
                                        );
                                      }}
                                    >
                                      Delete
                                    </DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  )}{" "}
                </Card>
              </div>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

export default CoveragesOverview;
