import React from "react";

import classnames from "classnames";
// reactstrap components
import {
  Button,
  Modal,
  FormGroup,
  Input,
  
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";
// core components

class LeadEditionModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { lead: this.props.lead };
  }

  componentWillReceiveProps(nextProps) {
    console.log("componentWillReceiveProps", nextProps);
    this.setState({ lead: nextProps.lead });
  }

  hasItem = (items, item) => {
    if (!items) return false;

    if (!item) return false;

    if (!items.length) return false;

    let exists = false;
    items.forEach((i) => {
      if (i.id === item.id) exists = true;
    });

    return exists;
  };

  render() {
    return (
      <>
        {
          <Modal
            className="modal-dialog-centered"
            isOpen={this.props.leadEditionModalIsOpen}
            toggle={() => this.props.toggleModal(this.props.modalName)}
          >
            <div className="modal-header">
              <h6 className="modal-title" id="modal-title-default">
                <>Lead edition</>
              </h6>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => this.props.toggleModal(this.props.modalName)}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <div className="modal-body">
              {this.state.lead.id && (
                <FormGroup>
                  <Input
                    disabled
                    placeholder="Id"
                    type="text"
                    value={this.state.lead.id}
                  />
                </FormGroup>
              )}
              <FormGroup
                className={classnames({
                  focused: this.state.birthdayFocused,
                })}
              >
                <label>
                  <span className="text-muted">Full name</span>
                </label>
                <InputGroup className="mb-4">
                  <Input
                    value={
                      this.state.lead.userFullName
                        ? this.state.lead.userFullName
                        : ""
                    }
                    placeholder="Name"
                    type="text"
                    onChange={(e) => {
                      e.preventDefault();
                      this.state.lead.userFullName = e.target.value;
                      this.setState({ lead: this.state.lead });
                    }}
                  />

                  
                    <InputGroupText>
                      <i className="ni ni-zoom-split-in" />
                    </InputGroupText>
                  
                </InputGroup>
                <label>
                  <span className="text-muted">Phone number</span>
                </label>
                <InputGroup className="mb-4">
                  <Input
                    value={
                      this.state.lead.phoneNumber
                        ? this.state.lead.phoneNumber
                        : ""
                    }
                    placeholder="Phone number"
                    type="text"
                    onChange={(e) => {
                      e.preventDefault();
                      this.state.lead.phoneNumber = e.target.value;
                      this.setState({ lead: this.state.lead });
                    }}
                  />

                  
                    <InputGroupText>
                      <i className="ni ni-zoom-split-in" />
                    </InputGroupText>
                  
                </InputGroup>

                <label>
                  <span className="text-muted">Email</span>
                </label>
                <InputGroup className="mb-4">
                  <Input
                    placeholder="Email"
                    value={this.state.lead.email ? this.state.lead.email : ""}
                    type="text"
                    onChange={(e) => {
                      e.preventDefault();
                      this.state.lead.email = e.target.value;
                      this.setState({ lead: this.state.lead });
                    }}
                  />

                  
                    <InputGroupText>
                      <i className="ni ni-zoom-split-in" />
                    </InputGroupText>
                  
                </InputGroup>

                <label>
                  <span className="text-muted">Created at</span>
                </label>
                <InputGroup className="mb-4">
                  <Input
                    placeholder="Created at"
                    value={
                      this.state.lead.createdAt ? this.state.lead.createdAt : ""
                    }
                    type="text"
                    disabled
                  />

                  
                    <InputGroupText>
                      <i className="ni ni-zoom-split-in" />
                    </InputGroupText>
                  
                </InputGroup>

                <label>
                  <span className="text-muted">Updated at</span>
                </label>
                <InputGroup className="mb-4">
                  <Input
                    placeholder="Updated at"
                    value={
                      this.state.lead.updatedAt ? this.state.lead.updatedAt : ""
                    }
                    type="text"
                    disabled
                  />

                  
                    <InputGroupText>
                      <i className="ni ni-zoom-split-in" />
                    </InputGroupText>
                  
                </InputGroup>

                <div className="mb-3">
                  <small className="text-uppercase font-weight-bold">
                    Progress
                  </small>
                </div>
                <Row>
                  <Col lg="12" sm="12">
                    <FormGroup>
                      <label>
                        <span className="text-muted">Current step</span>
                      </label>

                      <Input
                        placeholder="Step"
                        type="text"
                        value={
                          this.state.lead.currentStep
                            ? this.state.lead.currentStep
                            : ""
                        }
                        onChange={(e) => {
                          e.preventDefault();

                          this.state.lead.currentStep = e.target.value;

                          this.setState({ lead: this.state.lead });
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col lg="6" sm="6">
                    <FormGroup>
                      <label>
                        <span className="text-muted">Completed</span>
                      </label>

                      <Input
                        placeholder="Step"
                        type="text"
                        value={this.state.lead.completed ? "Yes" : "No"}
                        disabled
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="6" sm="6">
                    <FormGroup>
                      <label>
                        <span className="text-muted">Completed at</span>
                      </label>

                      <Input
                        placeholder=""
                        type="text"
                        value={
                          this.state.lead.updatedAt
                            ? this.state.lead.updatedAt
                            : ""
                        }
                        disabled
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col lg="6" sm="6">
                    <FormGroup>
                      <label>
                        <span className="text-muted">Processed</span>
                      </label>

                      <Input
                        placeholder="Step"
                        type="text"
                        value={this.state.lead.processed ? "Yes" : "No"}
                        disabled
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="6" sm="6">
                    <FormGroup>
                      <label>
                        <span className="text-muted">Processed at</span>
                      </label>

                      <Input
                        placeholder=""
                        type="text"
                        value={
                          this.state.lead.processesUpdatedAt
                            ? this.state.lead.processesUpdatedAt
                            : ""
                        }
                        disabled
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col lg="12" sm="12">
                    <FormGroup>
                      <label>
                        <span className="text-muted">Errors</span>
                      </label>

                      <Input
                        placeholder="Step"
                        type="text"
                        value={
                          this.state.lead.error ? this.state.lead.error : "No"
                        }
                        disabled
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <div className="mb-3">
                  <small className="text-uppercase font-weight-bold">
                    Plan
                  </small>
                </div>
                <Row>
                  <Col lg="4" sm="6">
                    <FormGroup>
                      <label>
                        <span className="text-muted">Id</span>
                      </label>

                      <Input
                        placeholder="Id"
                        type="text"
                        value={
                          this.state.lead.selectedPlan &&
                          this.state.lead.selectedPlan.id
                            ? this.state.lead.selectedPlan.id
                            : ""
                        }
                        disabled
                      />
                    </FormGroup>
                  </Col>

                  <Col lg="4" sm="6">
                    <FormGroup>
                      <label>
                        <span className="text-muted">Name</span>
                      </label>

                      <Input
                        placeholder="Name"
                        type="text"
                        value={
                          this.state.lead.selectedPlan &&
                          this.state.lead.selectedPlan.name
                            ? this.state.lead.selectedPlan.name
                            : ""
                        }
                        disabled
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="4" sm="6">
                    <FormGroup>
                      <label>
                        <span className="text-muted">More...</span>
                      </label>

                      <Button
                        color="primary"
                        type="button"
                        onClick={(e) => {
                          e.preventDefault();
                          alert(JSON.stringify(this.state.lead.selectedPlan));
                        }}
                      >
                        More...
                      </Button>
                    </FormGroup>
                  </Col>
                </Row>

                <div className="mb-3">
                  <small className="text-uppercase font-weight-bold">
                    Beneficiaries
                  </small>
                </div>
                {this.state.lead.beneficiaries &&
                  this.state.lead.beneficiaries.map((beneficiary, index) => {
                    return (
                      <Row>
                        <Col lg="4" sm="4">
                          <FormGroup>
                            <label>
                              <span className="text-muted">Name</span>
                            </label>

                            <Input
                              placeholder="Id"
                              type="text"
                              value={beneficiary.name}
                              disabled
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="4" sm="4">
                          <FormGroup>
                            <label>
                              <span className="text-muted">Birthday</span>
                            </label>

                            <Input
                              placeholder="Name"
                              type="text"
                              value={beneficiary.birthday}
                              disabled
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="4" sm="4">
                          <FormGroup>
                            <label>
                              <span className="text-muted">Doc. Number</span>
                            </label>

                            <Input
                              placeholder="Name"
                              type="text"
                              value={beneficiary.identificationNumber}
                              disabled
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    );
                  })}
                <div className="mb-3">
                  <small className="text-uppercase font-weight-bold">
                    Address
                  </small>
                </div>
                <Row>
                  <Col lg="4" sm="6">
                    <FormGroup>
                      <label>
                        <span className="text-muted">Street</span>
                      </label>

                      <Input
                        placeholder="Street"
                        type="text"
                        value={
                          this.state.lead.address &&
                          this.state.lead.address.street
                            ? this.state.lead.address.street
                            : ""
                        }
                        onChange={(e) => {
                          e.preventDefault();

                          this.state.lead.address =
                            this.state.lead.address || {};

                          this.state.lead.address.street = e.target.value;

                          this.setState({ lead: this.state.lead });
                        }}
                      />
                    </FormGroup>
                  </Col>

                  <Col lg="4" sm="6">
                    <FormGroup>
                      <label>
                        <span className="text-muted">Number</span>
                      </label>

                      <Input
                        placeholder="Number"
                        type="text"
                        value={
                          this.state.lead.address &&
                          this.state.lead.address.number
                            ? this.state.lead.address.number
                            : ""
                        }
                        onChange={(e) => {
                          e.preventDefault();

                          this.state.lead.address =
                            this.state.lead.address || {};

                          this.state.lead.address.number = e.target.value;

                          this.setState({ lead: this.state.lead });
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="4" sm="6">
                    <FormGroup>
                      <label>
                        <span className="text-muted">Floor</span>
                      </label>

                      <Input
                        placeholder="Floor"
                        type="text"
                        value={
                          this.state.lead.address &&
                          this.state.lead.address.floor
                            ? this.state.lead.address.floor
                            : ""
                        }
                        onChange={(e) => {
                          e.preventDefault();

                          this.state.lead.address =
                            this.state.lead.address || {};

                          this.state.lead.address.floor = e.target.value;

                          this.setState({ lead: this.state.lead });
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col lg="4" sm="6">
                    <FormGroup>
                      <label>
                        <span className="text-muted">Department</span>
                      </label>

                      <Input
                        placeholder="Department"
                        type="text"
                        value={
                          this.state.lead.address &&
                          this.state.lead.address.department
                            ? this.state.lead.address.department
                            : ""
                        }
                        onChange={(e) => {
                          e.preventDefault();

                          this.state.lead.address =
                            this.state.lead.address || {};

                          this.state.lead.address.department = e.target.value;

                          this.setState({ lead: this.state.lead });
                        }}
                      />
                    </FormGroup>
                  </Col>

                  <Col lg="4" sm="6">
                    <FormGroup>
                      <label>
                        <span className="text-muted">Tower</span>
                      </label>

                      <Input
                        placeholder="Tower"
                        type="text"
                        value={
                          this.state.lead.address &&
                          this.state.lead.address.tower
                            ? this.state.lead.address.tower
                            : ""
                        }
                        onChange={(e) => {
                          e.preventDefault();

                          this.state.lead.address =
                            this.state.lead.address || {};

                          this.state.lead.address.tower = e.target.value;

                          this.setState({ lead: this.state.lead });
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="4" sm="6">
                    <FormGroup>
                      <label>
                        <span className="text-muted">Postal code</span>
                      </label>

                      <Input
                        placeholder="Postal code"
                        type="text"
                        value={
                          this.state.lead.address &&
                          this.state.lead.address.postalCode
                            ? this.state.lead.address.postalCode
                            : ""
                        }
                        onChange={(e) => {
                          e.preventDefault();

                          this.state.lead.address =
                            this.state.lead.address || {};

                          this.state.lead.address.postalCode = e.target.value;

                          this.setState({ lead: this.state.lead });
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col lg="6" sm="6">
                    <FormGroup>
                      <label>
                        <span className="text-muted">Province</span>
                      </label>

                      <Input
                        placeholder="Province"
                        type="text"
                        value={
                          this.state.lead.address &&
                          this.state.lead.address.province
                            ? this.state.lead.address.province
                            : ""
                        }
                        onChange={(e) => {
                          e.preventDefault();

                          this.state.lead.address =
                            this.state.lead.address || {};

                          this.state.lead.address.province = e.target.value;

                          this.setState({ lead: this.state.lead });
                        }}
                      />
                    </FormGroup>
                  </Col>

                  <Col lg="6" sm="6">
                    <FormGroup>
                      <label>
                        <span className="text-muted">Locality</span>
                      </label>

                      <Input
                        placeholder="Locality"
                        type="text"
                        value={
                          this.state.lead.address &&
                          this.state.lead.address.locality
                            ? this.state.lead.address.locality
                            : ""
                        }
                        onChange={(e) => {
                          e.preventDefault();

                          this.state.lead.address =
                            this.state.lead.address || {};

                          this.state.lead.address.locality = e.target.value;

                          this.setState({ lead: this.state.lead });
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <div className="mb-3">
                  <small className="text-uppercase font-weight-bold">
                    Additional Data
                  </small>
                </div>
                <Row>
                  <Col lg="4" sm="6">
                    <FormGroup>
                      <label>
                        <span className="text-muted">Birthday</span>
                      </label>

                      <Input
                        placeholder="Birthday"
                        type="text"
                        value={this.state.lead ? this.state.lead.birthday : ""}
                        onChange={(e) => {
                          e.preventDefault();

                          this.state.lead.birthday = e.target.value;

                          this.setState({ lead: this.state.lead });
                        }}
                      />
                    </FormGroup>
                  </Col>

                  <Col lg="4" sm="6">
                    <FormGroup>
                      <label>
                        <span className="text-muted">Document Type</span>
                      </label>

                      <Input
                        placeholder="Document Type"
                        type="text"
                        value={
                          this.state.lead && this.state.lead.documentType
                            ? this.state.lead.documentType
                            : ""
                        }
                        onChange={(e) => {
                          e.preventDefault();

                          this.state.lead.documentType = e.target.value;

                          this.setState({ lead: this.state.lead });
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="4" sm="6">
                    <FormGroup>
                      <label>
                        <span className="text-muted">Document Number</span>
                      </label>

                      <Input
                        placeholder="Document Number"
                        type="text"
                        value={
                          this.state.lead &&
                          this.state.lead.identificationNumber
                            ? this.state.lead.identificationNumber
                            : ""
                        }
                        onChange={(e) => {
                          e.preventDefault();

                          this.state.lead.identificationNumber = e.target.value;

                          this.setState({ lead: this.state.lead });
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col lg="6" sm="6">
                    <FormGroup>
                      <label>
                        <span className="text-muted">Gender</span>
                      </label>

                      <Input
                        placeholder="Gender"
                        type="text"
                        value={
                          this.state.lead.gender ? this.state.lead.gender : ""
                        }
                        onChange={(e) => {
                          e.preventDefault();

                          this.state.lead.gender = e.target.value;

                          this.setState({ lead: this.state.lead });
                        }}
                      />
                    </FormGroup>
                  </Col>

                  <Col lg="6" sm="6">
                    <FormGroup>
                      <label>
                        <span className="text-muted">Marital Status</span>
                      </label>

                      <Input
                        placeholder="Marital Status"
                        type="text"
                        value={
                          this.state.lead.maritalStatus
                            ? this.state.lead.maritalStatus
                            : ""
                        }
                        onChange={(e) => {
                          e.preventDefault();

                          this.state.lead.maritalStatus = e.target.value;

                          this.setState({ lead: this.state.lead });
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <div className="mb-3">
                  <small className="text-uppercase font-weight-bold">
                    Payment data
                  </small>
                </div>
                <Row>
                  <Col lg="4" sm="6">
                    <FormGroup>
                      <label>
                        <span className="text-muted">Payment type</span>
                      </label>

                      <Input
                        placeholder=""
                        type="text"
                        value={
                          this.state.lead.paymentData &&
                          this.state.lead.paymentData.paymentMethod
                            ? this.state.lead.paymentData.paymentMethod
                            : ""
                        }
                        onChange={(e) => {
                          e.preventDefault();

                          this.state.lead.paymentData =
                            this.state.lead.paymentData || {};

                          this.state.lead.paymentData.paymentMethod =
                            e.target.value;

                          this.setState({ lead: this.state.lead });
                        }}
                      />
                    </FormGroup>
                  </Col>

                  <Col lg="4" sm="6">
                    <FormGroup>
                      <label>
                        <span className="text-muted">Card provider</span>
                      </label>

                      <Input
                        placeholder=""
                        type="text"
                        value={
                          this.state.lead.paymentData &&
                          this.state.lead.paymentData.cardProvider
                            ? this.state.lead.paymentData.cardProvider
                            : ""
                        }
                        onChange={(e) => {
                          e.preventDefault();

                          this.state.lead.paymentData =
                            this.state.lead.paymentData || {};

                          this.state.lead.paymentData.cardProvider =
                            e.target.value;

                          this.setState({ lead: this.state.lead });
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="4" sm="6">
                    <FormGroup>
                      <label>
                        <span className="text-muted">Card Number</span>
                      </label>

                      <Input
                        placeholder=""
                        type="text"
                        value={
                          this.state.lead.paymentData &&
                          this.state.lead.paymentData.cardNumber
                            ? this.state.lead.paymentData.cardNumber
                            : ""
                        }
                        onChange={(e) => {
                          e.preventDefault();

                          this.state.lead.paymentData =
                            this.state.lead.paymentData || {};

                          this.state.lead.paymentData.cardNumber =
                            e.target.value;

                          this.setState({ lead: this.state.lead });
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col lg="6" sm="6">
                    <FormGroup>
                      <label>
                        <span className="text-muted">Card holder</span>
                      </label>

                      <Input
                        placeholder=""
                        type="text"
                        value={
                          this.state.lead.paymentData &&
                          this.state.lead.paymentData.cardHolder
                            ? this.state.lead.paymentData.cardHolder
                            : ""
                        }
                        onChange={(e) => {
                          e.preventDefault();

                          this.state.lead.paymentData =
                            this.state.lead.paymentData || {};

                          this.state.lead.paymentData.cardHolder =
                            e.target.value;

                          this.setState({ lead: this.state.lead });
                        }}
                      />
                    </FormGroup>
                  </Col>

                  <Col lg="6" sm="6">
                    <FormGroup>
                      <label>
                        <span className="text-muted">Card due date</span>
                      </label>

                      <Input
                        placeholder=""
                        type="text"
                        value={
                          this.state.lead.paymentData &&
                          this.state.lead.paymentData.cardDueDate
                            ? this.state.lead.paymentData.cardDueDate
                            : ""
                        }
                        onChange={(e) => {
                          e.preventDefault();

                          this.state.lead.paymentData =
                            this.state.lead.paymentData || {};

                          this.state.lead.paymentData.cardDueDate =
                            e.target.value;

                          this.setState({ lead: this.state.lead });
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col lg="6" sm="6">
                    <FormGroup>
                      <label>
                        <span className="text-muted">CVC</span>
                      </label>

                      <Input
                        placeholder=""
                        type="text"
                        value={
                          this.state.lead.paymentData &&
                          this.state.lead.paymentData.cardCVC
                            ? this.state.lead.paymentData.cardCVC
                            : ""
                        }
                        onChange={(e) => {
                          e.preventDefault();

                          this.state.lead.paymentData =
                            this.state.lead.paymentData || {};

                          this.state.lead.paymentData.cardCVC = e.target.value;

                          this.setState({ lead: this.state.lead });
                        }}
                      />
                    </FormGroup>
                  </Col>

                  <Col lg="6" sm="6">
                    <FormGroup>
                      <label>
                        <span className="text-muted">CBU</span>
                      </label>

                      <Input
                        placeholder=""
                        type="text"
                        value={
                          this.state.lead.paymentData &&
                          this.state.lead.paymentData.CBU
                            ? this.state.lead.paymentData.CBU
                            : ""
                        }
                        onChange={(e) => {
                          e.preventDefault();

                          this.state.lead.paymentData =
                            this.state.lead.paymentData || {};

                          this.state.lead.paymentData.CBU = e.target.value;

                          this.setState({ lead: this.state.lead });
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </FormGroup>
            </div>
            <div className="modal-footer">
              <Button
                color="primary"
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  this.props.saveLead(this.state.lead);
                }}
              >
                Save
              </Button>

              <Button
                color="primary"
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  this.props.leadToClient(this.state.lead);
                }}
              >
                Convert to client
              </Button>

              <Button
                className="ml-auto"
                color="link"
                data-dismiss="modal"
                type="button"
                onClick={() => this.props.toggleModal(this.props.modalName)}
              >
                Close
              </Button>
            </div>
          </Modal>
        }
      </>
    );
  }
}

export default LeadEditionModal;
