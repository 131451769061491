/*eslint-disable*/
import React, { useState } from "react";
// reactstrap components
import { FormGroup, Input } from "reactstrap";

import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";

import Cleave from "cleave.js/react";

function CustomInput({ ...props }) {
  const [isValid, setIsValid] = useState(true);

  const {
    classes,
    formControlProps,
    labelText,
    msgText,
    errorLabelText,
    errorLabelProps,
    id,
    labelProps,
    inputProps,
    error,
    white,
    inputRootCustomClasses,
    success,
    particularInputClasses,
    validationFn,
    behavior,
  } = props;

  const labelRoot = classNames({
    [" " + "labelRoot"]: true,
  });

  const labelClasses = classNames({
    [" " + "labelRootError"]: error || !isValid,
    [" " + "labelRootSuccess"]: success && !error && isValid,
  });
  const inputClasses = classNames({
    ["input"]: true,
    [" " + "inputError"]: error || !isValid,
    [" " + "inputSuccess"]: success && !error && isValid,
  });

  const formGroupClasses = classNames({ ["has-danger"]: error || !isValid });

  let cleaveDefaultValue = "";

  if (behavior && behavior.initialValue)
    cleaveDefaultValue = behavior.initialValue;
  else if (behavior && inputProps.value) cleaveDefaultValue = inputProps.value;

  return (
    <>
      {labelText !== undefined && (
        <div className={labelRoot + " " + labelClasses} {...labelProps}>
          {labelText}
        </div>
      )}

      {behavior ? (
        <FormGroup className={formGroupClasses}>
          {/* //   $('#select-country').change(function(){
  //     cleave.setPhoneRegionCode(this.value);
  //     cleave.setRawValue('');
  // }); */}
          <Cleave
            type={inputProps.type ? inputProps.type : "text"}
            autoComplete={
              inputProps.autoComplete ? inputProps.autoComplete : ""
            }
            name={inputProps.name ? inputProps.name : ""}
            placeholder={inputProps.placeholder ? inputProps.placeholder : ""}
            {...behavior}
            // placeholder={behavior.initialValue ? inputProps.placeholder : ""}
            value={cleaveDefaultValue}
            onFocus={(event) => {
              //this.handleInputFocus(event);
              if (inputProps.onFocus) inputProps.onFocus(event);
            }}
            onChange={(event) => {
              let valid = true;

              if (validationFn && !validationFn(event.target.value))
                valid = false;

              setIsValid(valid);

              if (inputProps.onChange) inputProps.onChange(event);
              if (inputProps.onInputChange)
                inputProps.onInputChange(event, valid);
            }}
            onInit={(cleave) => {
              if (behavior.initialValue)
                cleave.setRawValue(behavior.initialValue);
              else if (inputProps.value) cleave.setRawValue(inputProps.value);
            }}
          />
        </FormGroup>
      ) : (
        <FormGroup className={formGroupClasses}>
          <Input
            className={inputClasses}
            id={id}
            {...inputProps}
            onChange={(event) => {
              let valid = true;

              if (validationFn && !validationFn(event.target.value))
                valid = false;

              setIsValid(valid);

              if (inputProps.onChange) inputProps.onChange(event);
              if (inputProps.onInputChange)
                inputProps.onInputChange(event, valid);
            }}
          />
        </FormGroup>
      )}
      {(error || !isValid) && errorLabelText !== undefined && (
        <div className="text-warning" {...errorLabelProps}>
          {errorLabelText}
        </div>
      )}
    </>
  );
}

CustomInput.propTypes = {
  // classes: PropTypes.object.isRequired,
  labelText: PropTypes.node,
  errorLabelText: PropTypes.node,
  errorLabelProps: PropTypes.object,
  labelProps: PropTypes.object,
  id: PropTypes.string,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  inputRootCustomClasses: PropTypes.string,
  error: PropTypes.bool,
  success: PropTypes.bool,
  white: PropTypes.bool,
};

export default CustomInput;
