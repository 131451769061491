import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Table,
  Container,
  Col,
  Row,
} from "reactstrap";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.jsx";
import PromotionsEditionModal from "./PromotionsEditionModal.jsx";

import { authRef } from "config/firebase";

import {
  listPromotions,
  createPromotion,
  updatePromotion,
  deletePromotion,
} from "services/promotionsServices";

import { listPlans } from "services/plansServices";

class PromotionsOverview extends React.Component {
  constructor(props) {
    super(props);

    this.state = { promotions: null, addNewPromotion: {} };
  }

  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
    });
  };

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    authRef.onAuthStateChanged((user) => {
      this.setState({ currentUser: user });

      user.getIdToken().then((token) => {
        listPromotions(token)
          .then((promotions) => {
            console.log("promotions: ", promotions);
            this.setState({ promotions: promotions });
          })
          .catch((e) => {
            if (e.response && e.response.data && e.response.data.message)
              alert(e.response.data.message);
            else alert(e.message);
          });

        listPlans(token)
          .then((plans) => {
            console.log("plans: ", plans);
            this.setState({ plans: plans });
          })
          .catch((e) => {
            if (e.response && e.response.data && e.response.data.message)
              alert(e.response.data.message);
            else alert(e.message);
          });
      });
    });
  }

  componentDidUpdate(e) {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.mainContent.scrollTop = 0;
  }

  savePromotion(promotion) {
    console.log("promotion:", promotion);
    if (
      !promotion.code ||
      !promotion.description ||
      !promotion.dueDate ||
      !promotion.plan
    ) {
      console.log("Faltan datos requeridos: ", promotion);
      alert("Faltan datos requeridos");
      return;
    }

    authRef.onAuthStateChanged((user) => {
      user.getIdToken().then((token) => {
        if (promotion.id)
          updatePromotion(token, promotion)
            .then((promotion) => {
              this.loadData();
              console.log("Promotion: ", promotion);
              alert("Promotion: " + JSON.stringify(promotion));
              this.toggleModal("promotionsEditionModal");
            })
            .catch((e) => {
              if (e.response && e.response.data && e.response.data.message)
                alert(e.response.data.message);
              else alert(e.message);
            });
        else
          createPromotion(token, promotion)
            .then((promotion) => {
              this.loadData();
              console.log("Promotion: ", promotion);
              alert("Promotion: " + JSON.stringify(promotion));
              this.toggleModal("promotionsEditionModal");
            })
            .catch((e) => {
              if (e.response && e.response.data && e.response.data.message)
                alert(e.response.data.message);
              else alert(e.message);
            });
      });
    });

    //alert("promotion recibido: " + promotion.name);
  }

  innerDeletePromotion(promotion) {
    authRef.onAuthStateChanged((user) => {
      user.getIdToken().then((token) => {
        deletePromotion(token, promotion)
          .then((promotion) => {
            this.loadData();
            console.log("Promotion: ", promotion);
            alert("Promotion: " + JSON.stringify(promotion) + "removed");
          })
          .catch((e) => {
            if (e.response && e.response.data && e.response.data.message)
              alert(e.response.data.message);
            else alert(e.message);
          });
      });
    });
  }

  render() {
    return (
      <>
        <div className="main-content" ref="mainContent">
          <AdminNavbar {...this.props} currentUser={this.state.currentUser} />

          {this.state.promotions && this.state.promotionToEdit && (
            <PromotionsEditionModal
              modalName="promotionsEditionModal"
              plans={this.state.plans}
              promotionsEditionModalIsOpen={this.state.promotionsEditionModal}
              promotion={this.state.promotionToEdit}
              toggleModal={this.toggleModal.bind(this)}
              savePromotion={this.savePromotion.bind(this)}
            />
          )}
          <Container fluid>
            <Row>
              <div className="col">
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <Row>
                      <Col lg="10">
                        <h3 className="mb-0">Promotions</h3>
                      </Col>
                      <Col lg="2">
                        <div className="btn-wrapper">
                          <Button
                            color="success"
                            onClick={(e) => {
                              e.preventDefault();

                              this.setState({ promotionToEdit: {} });
                              this.toggleModal("promotionsEditionModal");
                            }}
                          >
                            New
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </CardHeader>
                  {!this.state.promotions && <div>Loading</div>}
                  {this.state.promotions && (
                    <Table
                      className="align-items-center table-flush"
                      responsive
                    >
                      <thead className="thead-light">
                        <tr>
                          <th scope="col">Code</th>
                          <th scope="col">Description</th>
                          <th scope="col">Due date</th>
                          <th scope="col">Plan</th>
                          <th scope="col">Expiration</th>
                          <th scope="col">Bonification</th>

                          <th scope="col" />
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.promotions.map((promotion, index) => {
                          return (
                            <tr key={index}>
                              <th scope="row">
                                <Media>
                                  <span className="mb-0 text-sm">
                                    {promotion.code}
                                  </span>
                                </Media>
                              </th>
                              <td>
                                <Media>
                                  <span className="mb-0 text-sm">
                                    {promotion.description}
                                  </span>
                                </Media>
                              </td>
                              <td>{promotion.dueDate}</td>
                              <td>{promotion.plan.name}</td>
                              <td>{promotion.expirationInDays}</td>
                              <td>{promotion.bonification}</td>

                              <td className="text-right">
                                <UncontrolledDropdown>
                                  <DropdownToggle
                                    className="btn-icon-only text-light"
                                    href="#"
                                    role="button"
                                    size="sm"
                                    color=""
                                    onClick={(e) => e.preventDefault()}
                                  >
                                    <i className="fas fa-ellipsis-v" />
                                  </DropdownToggle>
                                  <DropdownMenu
                                    className="dropdown-menu-arrow"
                                    right
                                  >
                                    <DropdownItem
                                      onClick={(e) => {
                                        e.preventDefault();

                                        this.setState({
                                          promotionToEdit: promotion,
                                        });
                                        this.toggleModal(
                                          "promotionsEditionModal"
                                        );
                                      }}
                                    >
                                      Edit
                                    </DropdownItem>

                                    <DropdownItem
                                      onClick={(e) => {
                                        e.preventDefault();

                                        this.innerDeletePromotion(promotion);
                                      }}
                                    >
                                      Delete
                                    </DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  )}{" "}
                </Card>
              </div>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

export default PromotionsOverview;
