import { getHttpBearerConfig } from 'services/httpServices';
import axios from 'axios';
const appConfig = require('config/appConfig');

export const listParameters = (currentUserToken) => {
  return new Promise(function (resolve, reject) {
    //exports.saveRole = function(currentUserToken, targetUser, role) {
    let config = getHttpBearerConfig(currentUserToken);

    return axios
      .get(`${appConfig.gcfUrl}/parameters`, config)
      .then((response) => {
        console.log('parameters response:', response);
        if (response && response.data) resolve(response.data);
        else resolve([]);
      })
      .catch((e) => {
        console.log('error:', e);

        reject(e);
      });
  });
};

export const createParameter = (currentUserToken, parameter) => {
  return new Promise(function (resolve, reject) {
    let config = getHttpBearerConfig(currentUserToken);

    console.log('parameter en service:', parameter);

    let data = parameter;

    return axios
      .post(`${appConfig.gcfUrl}/parameters`, data, config)
      .then((response) => {
        console.log('create parameter response:', response);
        if (response && response.data) resolve(response.data);
        else resolve(null);
      })
      .catch((e) => {
        console.log('error:', e);

        reject(e);
      });
  });
};

export const updateParameter = (currentUserToken, parameter) => {
  return new Promise(function (resolve, reject) {
    //exports.saveRole = function(currentUserToken, targetUser, role) {
    let config = getHttpBearerConfig(currentUserToken);

    let data = parameter;

    return axios
      .patch(`${appConfig.gcfUrl}/parameters/${parameter.id}`, data, config)
      .then((response) => {
        console.log('update parameter response:', response);
        if (response && response.data) resolve(response.data);
        else resolve(null);
      })
      .catch((e) => {
        console.log('error:', e);

        reject(e);
      });
  });
};

export const deleteParameter = (currentUserToken, parameter) => {
  return new Promise(function (resolve, reject) {
    let config = getHttpBearerConfig(currentUserToken);

    return axios
      .delete(`${appConfig.gcfUrl}/parameters/${parameter.id}`, config)
      .then((response) => {
        console.log('delete parameter response:', response);
        if (response && response.data) resolve(response.data);
        else resolve(null);
      })
      .catch((e) => {
        console.log('error:', e);

        reject(e);
      });
  });
};
