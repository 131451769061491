/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Navbar,
  Nav,
  Container,
  Media,
} from "reactstrap";

class AdminNavbar extends React.Component {
  render() {
    //console.log("currentUser:", this.props.currentUser);
    return (
      <>
        <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
          <Container fluid>
            <Link
              className="h4 mb-0 text-uppercase d-none d-lg-inline-block"
              to="/"
            >
              {"Rene"}
            </Link>
            <Link className="mr-3 d-none d-md-flex ml-lg-auto" to="/users">
              {"Users"}
            </Link>
            <Link className="mr-3 d-none d-md-flex ml-lg-auto" to="/plans">
              {"Plans"}
            </Link>

            <Link
              className="mr-3 d-none d-md-flex ml-lg-auto"
              to="/promotional-plans"
            >
              {"Planes onboarding"}
            </Link>

            <Link className="mr-3 d-none d-md-flex ml-lg-auto" to="/coverages">
              {"Coverages"}
            </Link>
            <Link
              className="mr-3 d-none d-md-flex ml-lg-auto"
              to="/assistances"
            >
              {"Asistencias"}
            </Link>

            <Link className="mr-3 d-none d-md-flex ml-lg-auto" to="/leads">
              {"Leads"}
            </Link>
            {/* <Form className="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto">
              <FormGroup className="mb-0">
                <InputGroup className="input-group-alternative">

                    <InputGroupText>
                      <i className="fas fa-search" />
                    </InputGroupText>

                  <Input placeholder="Search" type="text" />
                </InputGroup>
              </FormGroup>
            </Form> */}

            <UncontrolledDropdown nav>
              <DropdownToggle className="pr-0" nav>
                <span
                  className="mb-0 text-sm font-weight-bold"
                  style={{ color: "black" }}
                >
                  Manage...
                </span>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem className="noti-title" header tag="div">
                  <h6 className="text-overflow m-0">Manage</h6>
                </DropdownItem>
                <DropdownItem to="/medics" tag={Link}>
                  <i className="ni ni-single-02" />
                  <span>Medics</span>
                </DropdownItem>
                <DropdownItem to="/promotions" tag={Link}>
                  <i className="ni ni-single-02" />
                  <span>Promotions</span>
                </DropdownItem>

                <DropdownItem to="/notification-events" tag={Link}>
                  <i className="ni ni-single-02" />
                  <span>Notification events</span>
                </DropdownItem>
                <DropdownItem to="/parameters" tag={Link}>
                  <i className="ni ni-single-02" />
                  <span>Parameters</span>
                </DropdownItem>

                <DropdownItem to="/logs" tag={Link}>
                  <i className="ni ni-single-02" />
                  <span>Logs</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>

            {this.props.currentUser && (
              <Nav className="align-items-center d-none d-md-flex" navbar>
                <UncontrolledDropdown nav>
                  <DropdownToggle className="pr-0" nav>
                    <Media className="align-items-center">
                      <span className="avatar avatar-sm rounded-circle">
                        <img
                          alt="..."
                          //src={require("assets/img/theme/team-4-800x800.jpg")}
                          src={this.props.currentUser.photoURL}
                        />
                      </span>
                      <Media className="ml-2 d-none d-lg-block">
                        <span
                          className="mb-0 text-sm font-weight-bold"
                          style={{ color: "black" }}
                        >
                          {this.props.currentUser.displayName}
                        </span>
                      </Media>
                    </Media>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-arrow" right>
                    <DropdownItem className="noti-title" header tag="div">
                      <h6 className="text-overflow m-0">Welcome!</h6>
                    </DropdownItem>
                    <DropdownItem to="/admin/user-profile" tag={Link}>
                      <i className="ni ni-single-02" />
                      <span>My profile</span>
                    </DropdownItem>
                    <DropdownItem to="/admin/user-profile" tag={Link}>
                      <i className="ni ni-settings-gear-65" />
                      <span>Settings</span>
                    </DropdownItem>
                    <DropdownItem to="/admin/user-profile" tag={Link}>
                      <i className="ni ni-calendar-grid-58" />
                      <span>Activity</span>
                    </DropdownItem>
                    <DropdownItem to="/admin/user-profile" tag={Link}>
                      <i className="ni ni-support-16" />
                      <span>Support</span>
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem to="/logout" tag={Link}>
                      <i className="ni ni-user-run" />
                      <span>Logout</span>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </Nav>
            )}
          </Container>
        </Navbar>
      </>
    );
  }
}

export default AdminNavbar;
