/*eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
// reactstrap components
import { FormGroup, Input } from "reactstrap";

import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";

import { loadScript } from "../../helpers/coreHelper";

function SearchLocationInput({ ...props }) {
  const [isValid, setIsValid] = useState(true);

  const {
    labelText,

    errorLabelText,
    errorLabelProps,

    labelProps,
    inputProps,

    error,
    success,

    onPlaceSelected,
  } = props;

  const labelRoot = classNames({
    [" " + "labelRoot"]: true,
  });

  const labelClasses = classNames({
    [" " + "labelRootError"]: error || !isValid,
    [" " + "labelRootSuccess"]: success && !error && isValid,
  });
  const inputClasses = classNames({
    ["input"]: true,
    [" " + "inputError"]: error || !isValid,
    [" " + "inputSuccess"]: success && !error && isValid,
  });

  const [query, setQuery] = useState(
    inputProps && inputProps.value ? inputProps.value : ""
  );
  const autoCompleteRef = useRef(null);

  const formGroupClasses = classNames({ ["has-danger"]: error || !isValid });

  useEffect(() => {
    //const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;
    const apiKey = "AIzaSyA9TTOIdiEekAMQIAijN_0jqTwZ4FcfNPk";

    if (!window.google || !window.google.maps) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`,
        () => handleScriptLoad(setQuery, autoCompleteRef)
      );
    } else {
      handleScriptLoad(setQuery, autoCompleteRef);
    }
  }, []);

  let autoComplete;

  function handleScriptLoad(updateQuery, autoCompleteRef) {
    autoComplete = new window.google.maps.places.Autocomplete(
      autoCompleteRef.current,
      //{ types: ["(cities)"], componentRestrictions: { country: "us" } }
      { types: ["address"], componentRestrictions: { country: "ar" } }

      //{ componentRestrictions: { country: "us" } }
    );
    autoComplete.setFields([
      "address_components",
      "formatted_address",
      "geometry",
    ]);
    autoComplete.addListener("place_changed", () =>
      handlePlaceSelect(updateQuery)
    );
  }

  async function handlePlaceSelect(updateQuery) {
    const addressObject = autoComplete.getPlace();
    const query = addressObject.formatted_address;
    updateQuery(query);
    console.log(addressObject);

    if (!addressObject.geometry || !addressObject.geometry.location) {
      // User entered the name of a Place that was not suggested and
      // pressed the Enter key, or the Place Details request failed.
      //window.alert("No details available for input: '" + place.name + "'");
      return;
    }

    debugger;
    if (!onPlaceSelected) return;
    let city = addressObject.address_components
      ? addressObject.address_components.find((ad) => {
          if (ad && ad.types) {
            return ad.types.find((t) => {
              return t === "locality";
            });
          }
          return false;
        })
      : null;

    if (!city)
      city = addressObject.address_components
        ? addressObject.address_components.find((ad) => {
            if (ad && ad.types) {
              return ad.types.find((t) => {
                return t === "sublocality";
              });
            }
            return false;
          })
        : null;

    const county = addressObject.address_components
      ? addressObject.address_components.find((ad) => {
          if (ad && ad.types) {
            return ad.types.find((t) => {
              return t === "administrative_area_level_2";
            });
          }
          return false;
        })
      : null;

    const postal_code = addressObject.address_components
      ? addressObject.address_components.find((ad) => {
          if (ad && ad.types) {
            return ad.types.find((t) => {
              return t === "postal_code";
            });
          }
          return false;
        })
      : null;

    const state = addressObject.address_components
      ? addressObject.address_components.find((ad) => {
          if (ad && ad.types) {
            return ad.types.find((t) => {
              return t === "administrative_area_level_1";
            });
          }
          return false;
        })
      : null;

    let street = addressObject.address_components
      ? addressObject.address_components.find((ad) => {
          if (ad && ad.types) {
            return ad.types.find((t) => {
              return t === "route";
            });
          }
          return false;
        })
      : "";

    if (street) street = street.long_name;

    let streetNumber = addressObject.address_components
      ? addressObject.address_components.find((ad) => {
          if (ad && ad.types) {
            return ad.types.find((t) => {
              return t === "street_number";
            });
          }
          return false;
        })
      : "";

    if (streetNumber) streetNumber = streetNumber.long_name;

    let country = addressObject.address_components
      ? addressObject.address_components.find((ad) => {
          if (ad && ad.types) {
            return ad.types.find((t) => {
              return t === "country";
            });
          }
          return false;
        })
      : "";

    onPlaceSelected({
      lat: addressObject.geometry.location.lat(),
      lng: addressObject.geometry.location.lng(),

      city: city ? city.long_name : null,
      county: county ? county.long_name : null,
      postal_code: postal_code ? postal_code.long_name : null,
      state: state ? state.long_name : null,
      addressObject: addressObject,
      addressString: addressObject.formatted_address,
      streetAndNumber: street + " " + streetNumber,
      country: country ? country.long_name : null,
    });
  }

  return (
    <>
      {labelText !== undefined && (
        <div className={labelRoot + " " + labelClasses} {...labelProps}>
          {labelText}
        </div>
      )}

      <FormGroup className={formGroupClasses}>
        <div className="search-location-input">
          <input
            ref={autoCompleteRef}
            onChange={(event) => {
              setQuery(event.target.value);
            }}
            onBlur={inputProps.onBlur}
            placeholder=""
            value={query}
            className="form-control"
            autoComplete=""
            name="searchLocationInput"
          />
        </div>
      </FormGroup>

      {(error || !isValid) && errorLabelText !== undefined && (
        <div className="text-warning" {...errorLabelProps}>
          {errorLabelText}
        </div>
      )}
    </>
  );
}

SearchLocationInput.propTypes = {
  // classes: PropTypes.object.isRequired,
  labelText: PropTypes.node,
  errorLabelText: PropTypes.node,
  errorLabelProps: PropTypes.object,
  labelProps: PropTypes.object,
  id: PropTypes.string,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  inputRootCustomClasses: PropTypes.string,
  error: PropTypes.bool,
  success: PropTypes.bool,
  white: PropTypes.bool,
};

export default SearchLocationInput;
