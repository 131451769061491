import React from "react";
// nodejs library that concatenates classes

import { Button, Modal } from "reactstrap";

class ModalError extends React.Component {
  constructor(props) {
    const { title, description } = props;

    super(props);

    this.state = {
      title: title,
      description: description,
    };
  }
  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
    });
  };

  render() {
    return (
      <>
        <Modal
          className="modal-dialog-centered modal-danger"
          contentClassName="bg-gradient-danger"
          isOpen={true}
          toggle={() => this.toggleModal("notificationModal")}
        >
          <div className="modal-header">
            <h6 className="modal-title" id="modal-title-notification">
              {}
            </h6>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => {
                this.toggleModal("notificationModal");
                if (this.props.onClose) {
                  this.props.onClose();
                }
              }}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="py-3 text-center">
              <i className="ni ni-bell-55 ni-3x" />
              <h4 className="heading mt-4">{this.state.title}</h4>
              <p>{this.state.description}</p>
            </div>
          </div>
          <div className="modal-footer">
            {/* <Button className="btn-white" color="default" type="button">
                  Ok, Got it
                </Button> */}
            <Button
              className="text-white ml-auto"
              color="link"
              data-dismiss="modal"
              type="button"
              onClick={() => {
                this.toggleModal("notificationModal");
                if (this.props.onClose) {
                  this.props.onClose();
                }
              }}
            >
              Close
            </Button>
          </div>
        </Modal>
      </>
    );
  }
}

export default ModalError;
