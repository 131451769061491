import React from 'react';
// import MetaTags from "react-meta-tags";

// reactstrap components
import { Button, Card, CardHeader, CardBody, Form, Container, Row, Col } from 'reactstrap';

import {
  setPersistedValue,
  getPersistedValue,
  validatePhone,
  validateDate,
  validateHasValue,
  validateEmail,
  PHONE_NUMBER_PREFIX_AR,
  goToFatalErrorPage,
  onlyText,
} from 'helpers/coreHelper';

import CustomInput from 'components/Inputs/CustomInput.jsx';
import Loading from 'components/Loading/Loading.jsx';

//do not remove
// eslint-disable-next-line no-unused-vars
import CleavePhone from 'cleave.js/dist/addons/cleave-phone.ar';

// core components
import BasicNavbar from 'components/Navbars/BasicNavbar.jsx';
import WhatsappButton from 'components/Buttons/WhatsappButton';

import { createLead } from 'services/leadsServices';

import DigitalOnboardingStepper from './DigitalOnboardingStepper';

class DigitalOnboardingStep2BasicData extends React.Component {
  constructor(props) {
    super(props);

    let lead = null;

    let titleTextPlan = '';
    let subtitleTextPlan = '';

    try {
      lead = getPersistedValue('lead');

      if (lead.selectedPlanSwitcherOption.key === 'childrens') {
        titleTextPlan = 'Completá tus datos';
        subtitleTextPlan =
          'Aunque la cobertura sea solo para tus hijos, necesitamos tu información, ya que vos vas a ser el titular del plan.';
      } else {
        titleTextPlan = 'Completá los datos del titular';
        subtitleTextPlan = `El titular va a poder administrar desde la app el medio de pago y los integrantes del plan.`;
      }
      console.log('hola mundo', lead);
    } catch (e) {
      goToFatalErrorPage(props.history);
      return;
    }

    this.state = {
      lead: lead,
      titleTextPlan: titleTextPlan,
      subtitleTextPlan: subtitleTextPlan,
      phoneNumber: lead && lead.phoneNumber ? lead.phoneNumber : '',
      firstName: lead && lead.firstName ? lead.firstName : '',
      lastName: lead && lead.lastName ? lead.lastName : '',
      identificationNumber: lead && lead.identificationNumber ? lead.identificationNumber : '',
      email: lead && lead.email ? lead.email : '',
      birthday: lead && lead.birthday ? lead.birthday : '',

      musValidatePhoneNumber: false,

      phoneRawValue: '',
      errors: null,
      errorPhoneNumber: false,
      loading: false,
    };
  }

  validateFormData(firstName, lastName, identificationNumber, email, phoneNumber, birthday) {
    let errors = [];

    if (!firstName) firstName = this.state.firstName;
    if (!lastName) lastName = this.state.lastName;
    if (!identificationNumber) identificationNumber = this.state.identificationNumber;

    if (!email) email = this.state.email;
    if (!phoneNumber) phoneNumber = this.state.phoneNumber;
    if (!birthday) birthday = this.state.birthday;

    if (!validateHasValue(firstName)) errors.push('firstName');
    if (!validateHasValue(lastName)) errors.push('lastName');
    if (!validateHasValue(identificationNumber)) errors.push('identificationNumber');

    if (!validateEmail(email)) errors.push('email');
    if (!validateDate(birthday)) errors.push('birthday');
    if (!validatePhone(phoneNumber)) errors.push('phoneNumber');

    if (errors.length === 0) errors = null;

    this.setState({ errors: errors });

    //console.log("errors:", errors);
    return errors;
  }

  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;

    this.validateFormData();
  }

  onSubmitFormData() {
    let errors = this.validateFormData();

    if (errors) return;

    //011 y lrpmqtrmp
    this.state.lead.phoneNumber = this.state.phoneNumber.replace(
      PHONE_NUMBER_PREFIX_AR + '011',
      PHONE_NUMBER_PREFIX_AR + '11'
    );

    this.state.lead.email = this.state.email;
    this.state.lead.firstName = this.state.firstName;
    this.state.lead.lastName = this.state.lastName;
    this.state.lead.birthday = this.state.birthday;
    this.state.lead.identificationType = 'DNI';
    this.state.lead.identificationNumber = this.state.identificationNumber;
    this.state.lead.path = window.location.href;

    this.innerSaveLead(this.state.lead);
  }

  innerSaveLead(lead) {
    this.setState({ loading: true });

    lead.phoneNumberValidated = false;
    createLead(lead)
      .then((leadId) => {
        lead.id = leadId;

        setPersistedValue('lead', lead);

        //if (lead.selectedPlanSwitcherOption.key === PLAN_SWITCHER_OPTION_ME.key)
        this.props.history.push('/onboarding-step-4');
        //else this.props.history.push("/onboarding-step-3");
      })
      .catch((e) => {
        console.log(e, e.message, e.code);

        goToFatalErrorPage(this.props.history);
      });
  }

  textOnly(input) {
    return onlyText(input);
  }

  render() {
    return (
      <>
        {/* <MetaTags>
          <title>El plan para tu seguro de salud | Hola René</title>
          <meta
            id="meta-description"
            name="description"
            content="Toda la información para que elijas el plan de salud ideal para vos y tu familia."
          />
        </MetaTags> */}

        <WhatsappButton />
        <BasicNavbar />

        <main ref="main">
          <section className="section section-shaped bg-light-grey">
            <Container>
              <Row className="justify-content-center">
                <Col lg="3 px-sm-0" md="3 mr-3">
                  <DigitalOnboardingStepper step={1} maxSteps={4} progress={25} />
                </Col>
                <Col lg="7" md="7" className="pl-md-0">
                  <Loading loading={this.state.loading} />
                  <Button
                    className="mt-4 hidden-sm"
                    color="custom flat back"
                    type="button"
                    onClick={() => {
                      this.props.history.goBack();
                    }}
                  ></Button>
                  <Card className="bg-transparent card-form border-0">
                    <CardHeader className="bg-transparent">
                      <div className="form-title mb-3">{this.state.titleTextPlan}</div>
                      <div className="form-subtitle">{this.state.subtitleTextPlan}</div>
                    </CardHeader>
                    <CardBody className="bg-transparent py-lg-3">
                      <Form role="form">
                        <CustomInput
                          labelText="Nombre"
                          errorLabelText="Campo inválido"
                          validationFn={validateHasValue}
                          inputProps={{
                            name: 'given-name',
                            autoComplete: 'given-name',
                            value: this.state.firstName,
                            onChange: (event) => {
                              if (this.textOnly(event.target.value)) {
                                this.state.firstName = event.target.value;

                                this.validateFormData(
                                  this.state.firstName,
                                  this.state.lastName,
                                  this.state.identificationNumber,
                                  this.state.email,
                                  this.state.phoneNumber,
                                  this.state.birthday
                                );

                                this.setState({
                                  firstName: this.state.firstName,
                                });
                              }
                            },

                            placeholder: '',
                          }}
                        />

                        <CustomInput
                          labelText="Apellido"
                          errorLabelText="Campo inválido"
                          validationFn={validateHasValue}
                          inputProps={{
                            name: 'family-name',
                            autoComplete: 'family-name',
                            value: this.state.lastName,
                            onChange: (event) => {
                              if (this.textOnly(event.target.value)) {
                                this.state.lastName = event.target.value;
                                this.validateFormData(
                                  this.state.firstName,
                                  this.state.lastName,
                                  this.state.identificationNumber,
                                  this.state.email,
                                  this.state.phoneNumber,
                                  this.state.birthday
                                );

                                this.setState({
                                  lastName: this.state.lastName,
                                });
                              }
                            },

                            placeholder: '',
                          }}
                        />

                        <CustomInput
                          behavior={{
                            className: 'css-phone input form-control',
                            options: {
                              phone: true,
                              phoneRegionCode: 'AR',
                            },
                            // initialValue: this.state.phoneNumber
                            //   .replace(" ", "")
                            //   .replace(" ", "")
                            //   .replace(PHONE_NUMBER_PREFIX_AR, ""),
                          }}
                          labelText="Tél. celular (código de área + núm)"
                          errorLabelText="Número de celular inválido"
                          validationFn={validatePhone}
                          error={this.state.errorPhoneNumber}
                          inputProps={{
                            type: 'tel',
                            name: 'phone',
                            value: this.state.phoneNumber
                              .replace(' ', '')
                              .replace(' ', '')
                              .replace(PHONE_NUMBER_PREFIX_AR, ''),
                            onChange: (event) => {
                              this.state.phoneNumber =
                                PHONE_NUMBER_PREFIX_AR + event.target.rawValue;

                              this.validateFormData(
                                this.state.firstName,
                                this.state.lastName,
                                this.state.identificationNumber,
                                this.state.email,
                                this.state.phoneNumber,
                                this.state.birthday
                              );

                              this.setState({
                                phoneNumber: this.state.phoneNumber,
                                phoneNumberValidated: false, //si lo cambia tiene que validarlo nuevamente
                                phoneRawValue: event.target.rawValue,
                              });
                            },

                            placeholder: '',
                          }}
                        />
                        <p className="msg-input">
                          Incluí el código de área + el número de teléfono. Sólo números. Ej. 11
                          12345678
                        </p>
                        {this.state.musValidatePhoneNumber && (
                          <div className="msg-form text-center my-5">
                            Vamos a mandarte un SMS en el siguiente paso para verificar este número
                          </div>
                        )}

                        <CustomInput
                          labelText="Email"
                          errorLabelText="Campo inválido"
                          validationFn={validateEmail}
                          helpText="1234"
                          inputProps={{
                            autoComplete: 'email',
                            name: 'email',
                            type: 'email',
                            value: this.state.email,
                            onChange: (event) => {
                              this.state.email = event.target.value;

                              this.validateFormData(
                                this.state.firstName,
                                this.state.lastName,
                                this.state.identificationNumber,
                                this.state.email,
                                this.state.phoneNumber,
                                this.state.birthday
                              );

                              this.setState({
                                email: this.state.email,
                              });
                            },

                            placeholder: '',
                          }}
                        />

                        <CustomInput
                          behavior={{
                            className: 'input form-control css-date',
                            options: {
                              date: true,
                              datePattern: ['d', 'm', 'Y'],
                            },
                          }}
                          labelText="Fecha de nacimiento"
                          errorLabelText="Campo inválido"
                          validationFn={validateDate}
                          inputProps={{
                            value: this.state.birthday,

                            onChange: (event) => {
                              this.state.birthday = event.target.value;

                              this.validateFormData(
                                this.state.firstName,
                                this.state.lastName,
                                this.state.identificationNumber,
                                this.state.email,
                                this.state.phoneNumber,
                                this.state.birthday
                              );

                              this.setState({
                                birthday: this.state.birthday,
                              });
                            },

                            placeholder: 'DD/MM/AAAA',
                          }}
                        />
                        <p className="msg-input">Día / mes / año</p>

                        <CustomInput
                          labelText="DNI"
                          errorLabelText="Campo inválido"
                          validationFn={validateHasValue}
                          behavior={{
                            className: 'input form-control css-card',
                            options: {
                              numeral: true,
                              numeralDecimalMark: '',
                              delimiter: '',
                            },
                          }}
                          inputProps={{
                            name: 'dni',
                            value: this.state.identificationNumber,
                            onChange: (event) => {
                              this.state.identificationNumber = event.target.rawValue;

                              this.validateFormData(
                                this.state.firstName,
                                this.state.lastName,
                                this.state.identificationNumber,
                                this.state.email,
                                this.state.phoneNumber,
                                this.state.birthday
                              );

                              this.setState({
                                identificationNumber: this.state.identificationNumber,
                              });
                            },

                            placeholder: '',
                          }}
                        />

                        <div>
                          <Button
                            className="mt-4"
                            color="custom"
                            type="button"
                            onClick={(event) => {
                              this.onSubmitFormData();
                            }}
                            disabled={
                              (this.state.errors && this.state.errors.length !== 0) ||
                              this.state.loading
                            }
                          >
                            Continuar
                          </Button>
                        </div>

                        <div id="recaptchaVerifierContainer"></div>
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </section>
        </main>
      </>
    );
  }
}

export default DigitalOnboardingStep2BasicData;
