import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Table,
  Container,
  Col,
  Row,
} from "reactstrap";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.jsx";
import LeadEditionModal from "./LeadEditionModal.jsx";

import { authRef } from "config/firebase";

import {
  listLeads,
  createLead,
  updateLead,
  deleteLead,
} from "services/leadsServices";
import { createUser } from "services/usersServices";

class LeadsOverview extends React.Component {
  constructor(props) {
    super(props);

    this.state = { leads: null, coverages: null, addNewLead: {} };
  }

  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
    });
  };

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    authRef.onAuthStateChanged((user) => {
      this.setState({ currentUser: user });

      user.getIdToken().then((token) => {
        listLeads(token)
          .then((leads) => {
            leads = leads.sort(function (a, b) {
              let aDate = a.updatedAt ? a.updatedAt : a.createdAt;
              let bDate = b.updatedAt ? b.updatedAt : b.createdAt;

              aDate = new Date(aDate);
              bDate = new Date(bDate);

              return bDate.getTime() - aDate.getTime();
            });

            this.setState({ leads: leads });
          })
          .catch((e) => {
            if (e.response && e.response.data && e.response.data.message)
              alert(e.response.data.message);
            else alert(e.message);
          });
      });
    });
  }

  componentDidUpdate(e) {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.mainContent.scrollTop = 0;
  }

  saveLead(lead) {
    console.log("lead:", lead);
    if (!lead.userFullName) {
      alert("Faltan datos requeridos");
      return;
    }

    authRef.onAuthStateChanged((user) => {
      user.getIdToken().then((token) => {
        if (lead.id)
          updateLead(token, lead)
            .then((lead) => {
              this.loadData();
              console.log("Lead: ", lead);
              alert("Lead: " + JSON.stringify(lead));
              this.toggleModal("leadEditionModal");
            })
            .catch((e) => {
              if (e.response && e.response.data && e.response.data.message)
                alert(e.response.data.message);
              else alert(e.message);
            });
        else
          createLead(token, lead)
            .then((lead) => {
              this.loadData();
              console.log("Lead: ", lead);
              alert("Lead: " + JSON.stringify(lead));
              this.toggleModal("leadEditionModal");
            })
            .catch((e) => {
              if (e.response && e.response.data && e.response.data.message)
                alert(e.response.data.message);
              else alert(e.message);
            });
      });
    });

    //alert("lead recibido: " + lead.name);
  }

  innerDeleteLead(lead) {
    authRef.onAuthStateChanged((user) => {
      user.getIdToken().then((token) => {
        deleteLead(token, lead)
          .then((lead) => {
            this.loadData();
            console.log("Lead: ", lead);
            alert("Lead: " + JSON.stringify(lead) + "removed");
          })
          .catch((e) => {
            if (e.response && e.response.data && e.response.data.message)
              alert(e.response.data.message);
            else alert(e.message);
          });
      });
    });
  }

  leadToClient(lead) {
    console.log("lead:", lead);
    if (!lead.userFullName) {
      alert("Faltan datos requeridos");
      return;
    }

    const firstName = lead.userFullName.split(" ")[0];
    let lastName =
      lead.userFullName.split(" ").length !== 0
        ? lead.userFullName.split(" ")[1]
        : "";

    if (!lastName || !lastName.length) lastName = "";

    createUser(
      lead.email,
      firstName,
      lastName,
      lead.phoneNumber,
      null,
      lead.selectedPlan,
      null //paymentData >> TODO
    )
      .then((newUserId) => {
        this.loadData();
        console.log("New user ID: ", newUserId);
        alert("newUserId: " + JSON.stringify(newUserId));
        this.toggleModal("leadEditionModal");
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.message)
          alert(e.response.data.message);
        else alert(e.message);
      });
  }

  render() {
    return (
      <>
        <div className="main-content" ref="mainContent">
          <AdminNavbar {...this.props} currentUser={this.state.currentUser} />

          {this.state.leadToEdit && (
            <LeadEditionModal
              modalName="leadEditionModal"
              leads={this.state.leads}
              coverages={this.state.coverages}
              leadEditionModalIsOpen={this.state.leadEditionModal}
              lead={this.state.leadToEdit}
              toggleModal={this.toggleModal.bind(this)}
              saveLead={this.saveLead.bind(this)}
              leadToClient={this.leadToClient.bind(this)}
            />
          )}
          <Container fluid>
            <Row>
              <div className="col">
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <Row>
                      <Col lg="10">
                        <h3 className="mb-0">Leads</h3>
                      </Col>
                      <Col lg="2">
                        <div className="btn-wrapper">
                          <Button
                            color="success"
                            onClick={(e) => {
                              e.preventDefault();

                              this.setState({ leadToEdit: {} });
                              this.toggleModal("leadEditionModal");
                            }}
                          >
                            New
                          </Button>
                          {/* <Button
                            className="btn-white"
                            color="default"
                            to="/register-page"
                            tag={Link}
                          >
                            Register Page
                          </Button> */}
                        </div>
                      </Col>
                    </Row>
                  </CardHeader>
                  {!this.state.leads && <div>Loading</div>}
                  {this.state.leads && (
                    <Table
                      className="align-items-center table-flush"
                      responsive
                    >
                      <thead className="thead-light">
                        <tr>
                          <th scope="col">Id</th>
                          <th scope="col">FirstName</th>
                          <th scope="col">LastName</th>
                          <th scope="col">PhoneNumber</th>
                          <th scope="col">Email</th>
                          <th scope="col">Current Step</th>
                          <th scope="col">Last update</th>

                          <th scope="col" />
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.leads.map((lead, index) => {
                          return (
                            <tr key={index}>
                              <th scope="row">
                                <Media>
                                  <span className="mb-0 text-sm">
                                    {lead.id}
                                  </span>
                                </Media>
                              </th>
                              <td>
                                <Media>
                                  <span className="mb-0 text-sm">
                                    {lead.firstName}
                                  </span>
                                </Media>
                              </td>
                              <td>
                                <Media>
                                  <span className="mb-0 text-sm">
                                    {lead.lastName}
                                  </span>
                                </Media>
                              </td>
                              <td>
                                <Media>
                                  <span className="mb-0 text-sm">
                                    {lead.phoneNumber}
                                  </span>
                                </Media>
                              </td>
                              <td>
                                <Media>
                                  <span className="mb-0 text-sm">
                                    {lead.email}
                                  </span>
                                </Media>
                              </td>
                              <td>
                                <Media>
                                  <span className="mb-0 text-sm">
                                    {lead.currentStep ? lead.currentStep : ""}
                                  </span>
                                </Media>
                              </td>

                              <td>
                                <Media>
                                  <span className="mb-0 text-sm">
                                    {lead.updatedAt
                                      ? lead.updatedAt
                                      : lead.createdAt}
                                  </span>
                                </Media>
                              </td>

                              <td className="text-right">
                                <UncontrolledDropdown>
                                  <DropdownToggle
                                    className="btn-icon-only text-light"
                                    href="#"
                                    role="button"
                                    size="sm"
                                    color=""
                                    onClick={(e) => e.preventDefault()}
                                  >
                                    <i className="fas fa-ellipsis-v" />
                                  </DropdownToggle>
                                  <DropdownMenu
                                    className="dropdown-menu-arrow"
                                    right
                                  >
                                    <DropdownItem
                                      onClick={(e) => {
                                        e.preventDefault();

                                        this.setState({ leadToEdit: lead });
                                        this.toggleModal("leadEditionModal");
                                      }}
                                    >
                                      Edit
                                    </DropdownItem>
                                    {/* 
                                    <DropdownItem
                                      onClick={e => {
                                        e.preventDefault();
                                        this.props.history.push("/lead-detail");
                                      }}
                                    >
                                      Detail
                                    </DropdownItem> */}

                                    <DropdownItem
                                      onClick={(e) => {
                                        e.preventDefault();

                                        this.innerDeleteLead(lead);
                                      }}
                                    >
                                      Delete
                                    </DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  )}{" "}
                </Card>
              </div>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

export default LeadsOverview;
