const {
  TYPE_ASSISTANCE,
  TYPE_TELEMEDICINE,

  TYPE_MEDIC_BOOKING,
  TYPE_HOME_ASSISTANCE,

  TYPE_SEG_IQX,
  TYPE_FAR_DESC,
  TYPE_SEG_RDI,
  TYPE_ODONTOLOGY_MAYOR,
} = require('../types/productsTypes');

const PLAN_BULLET_VMD = {
  title: 'Médico a domicilio',
  description: 'Las 24hs, los 7 días de la semana',
  productType: TYPE_HOME_ASSISTANCE,
};
const PLAN_BULLET_TELEMEDICINE = {
  title: 'Médico Online',
  description: 'a través de nuestra app',
  productType: TYPE_TELEMEDICINE,
};
const PLAN_BULLET_ASSISTANT = {
  title: 'Asesor personal por chat',
  description: '',
  productType: TYPE_ASSISTANCE,
};

const PLAN_BULLET_MEDICS_BOOKING = {
  title: 'Cartilla médica',
  description: 'Con más de 5.000 profesionales',
  productType: TYPE_MEDIC_BOOKING,
};
// const PLAN_BULLET_MEDICS_ODONTOLOGY = {
//   title: "Odontología",
//   description: "Atención de casos básicos",
//   productType: TYPE_ODONTOLOGY,
// };

const PLAN_BULLET_MEDICS_ODONTOLOGY_PREMIUM = {
  title: 'Odontología Premium',
  description: 'Urgencias, caries, ortodoncia y más.',
  productType: TYPE_ODONTOLOGY_MAYOR,
};

const PLAN_BULLET_PHARMACY_DISCOUNT = {
  title: 'Descuento en Farmacia',
  description: '40% de descuento',
  productType: TYPE_FAR_DESC,
};

const PLAN_BULLET_MEDICS_RENTA_DIARIA = {
  title: 'Renta diaria en caso de accidentes',
  description: 'Hasta %REFUND_MAX_AMOUNT% por año',
  productType: TYPE_SEG_RDI,
};
const PLAN_BULLET_MEDICS_INTERNACIONES = {
  title: 'Intervenciones quirúrgicas',
  description: 'Hasta %REFUND_MAX_AMOUNT% por año',
  productType: TYPE_SEG_IQX,
};
exports.PLAN_BULLETS = [
  PLAN_BULLET_ASSISTANT,
  PLAN_BULLET_VMD,
  PLAN_BULLET_TELEMEDICINE,
  PLAN_BULLET_MEDICS_BOOKING,
  // PLAN_BULLET_GUARDIA,
  PLAN_BULLET_PHARMACY_DISCOUNT,
  // PLAN_BULLET_MEDICS_ODONTOLOGY,
  PLAN_BULLET_MEDICS_ODONTOLOGY_PREMIUM,

  PLAN_BULLET_MEDICS_INTERNACIONES,
  PLAN_BULLET_MEDICS_RENTA_DIARIA,
];
