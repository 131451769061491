import { getHttpBearerConfig } from 'services/httpServices';
import { getUserWithToken } from 'helpers/coreHelper';
import axios from 'axios';

const appConfig = require('config/appConfig');

export const simulateLADRequest = (ladRequestBody) => {
  return new Promise(function (resolve, reject) {
    getUserWithToken().then((uwt) => {
      let config = getHttpBearerConfig(uwt.token);
      return axios
        .post(`${appConfig.gcfUrl}/eventHandler/lad/assistance-event`, ladRequestBody, config)
        .then((response) => {
          console.log('eventHandler/lad/assistance-event response:', response);
          if (response && response.data) resolve(response.data);
          else resolve([]);
        })
        .catch((e) => {
          console.log('error:', e);

          reject(e);
        });
    });
  });
};
