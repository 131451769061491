import React from "react";

import classnames from "classnames";
// reactstrap components
import {
  Button,
  Modal,
  FormGroup,
  Input,
  
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";
// core components

class LifeInsuranceEditionModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { lifeInsurance: this.props.lifeInsurance };
  }

  componentWillReceiveProps(nextProps) {
    //console.log("componentWillReceiveProps", nextProps);
    this.setState({ lifeInsurance: nextProps.lifeInsurance });
  }

  render() {
    return (
      <>
        <Modal
          className="modal-dialog-centered"
          isOpen={this.props.lifeInsuranceEditionModalIsOpen}
          toggle={() => this.props.toggleModal(this.props.modalName)}
        >
          <div className="modal-header">
            <h6 className="modal-title" id="modal-title-default">
              <>LifeInsurance edition</>
            </h6>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.props.toggleModal(this.props.modalName)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            {this.state.lifeInsurance.id && (
              <FormGroup>
                <Input
                  disabled
                  placeholder="Id"
                  type="text"
                  value={this.state.lifeInsurance.id}
                />
              </FormGroup>
            )}
            <FormGroup
              className={classnames({
                focused: this.state.birthdayFocused,
              })}
            >
              <label>
                <span className="text-muted">Name</span>
              </label>
              <InputGroup className="mb-4">
                <Input
                  value={
                    this.state.lifeInsurance.name
                      ? this.state.lifeInsurance.name
                      : ""
                  }
                  placeholder="Name"
                  type="text"
                  onChange={(e) => {
                    e.preventDefault();
                    this.state.lifeInsurance.name = e.target.value;
                    this.setState({ lifeInsurance: this.state.lifeInsurance });
                  }}
                />

                
                  <InputGroupText>
                    <i className="ni ni-zoom-split-in" />
                  </InputGroupText>
                
              </InputGroup>

              <label>
                <span className="text-muted">Description</span>
              </label>
              <InputGroup className="mb-4">
                <Input
                  placeholder="Description"
                  value={
                    this.state.lifeInsurance.description
                      ? this.state.lifeInsurance.description
                      : ""
                  }
                  type="text"
                  onChange={(e) => {
                    e.preventDefault();
                    this.state.lifeInsurance.description = e.target.value;
                    this.setState({ lifeInsurance: this.state.lifeInsurance });
                  }}
                />

                
                  <InputGroupText>
                    <i className="ni ni-zoom-split-in" />
                  </InputGroupText>
                
              </InputGroup>

              <div className="mb-3">
                <small className="text-uppercase font-weight-bold">
                  Fallecimiento
                </small>
              </div>

              <Row>
                <Col lg="4" sm="6">
                  <FormGroup>
                    <label>
                      <span className="text-muted">Normal</span>
                    </label>

                    <Input
                      placeholder=""
                      type="text"
                      value={
                        this.state.lifeInsurance.death
                          ? this.state.lifeInsurance.death
                          : ""
                      }
                      onChange={(e) => {
                        e.preventDefault();

                        this.state.lifeInsurance.death = e.target.value;
                        this.setState({
                          lifeInsurance: this.state.lifeInsurance,
                        });
                      }}
                    />
                  </FormGroup>
                </Col>

                <Col lg="4" sm="6">
                  <FormGroup>
                    <label>
                      <span className="text-muted">Accidental</span>
                    </label>

                    <Input
                      placeholder=""
                      type="text"
                      value={
                        this.state.lifeInsurance.accidentalDeath
                          ? this.state.lifeInsurance.accidentalDeath
                          : ""
                      }
                      onChange={(e) => {
                        e.preventDefault();

                        this.state.lifeInsurance.accidentalDeath =
                          e.target.value;
                        this.setState({
                          lifeInsurance: this.state.lifeInsurance,
                        });
                      }}
                    />
                  </FormGroup>
                </Col>

                <Col lg="4" sm="6">
                  <FormGroup>
                    <label>
                      <span className="text-muted">Fallecimiento +65</span>
                    </label>

                    <Input
                      placeholder=""
                      type="text"
                      value={
                        this.state.lifeInsurance.advancedAgeDeath
                          ? this.state.lifeInsurance.advancedAgeDeath
                          : ""
                      }
                      onChange={(e) => {
                        e.preventDefault();

                        this.state.lifeInsurance.advancedAgeDeath =
                          e.target.value;
                        this.setState({
                          lifeInsurance: this.state.lifeInsurance,
                        });
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <div className="mb-3">
                <small className="text-uppercase font-weight-bold">
                  Enfermedad terminal
                </small>
              </div>
              <Row>
                <Col lg="6" sm="6">
                  <FormGroup>
                    <label>
                      <span className="text-muted">Pago anticipado</span>
                    </label>

                    <Input
                      placeholder=""
                      type="text"
                      value={
                        this.state.lifeInsurance.advancePaymentTerminalDisease
                          ? this.state.lifeInsurance
                              .advancePaymentTerminalDisease
                          : ""
                      }
                      onChange={(e) => {
                        e.preventDefault();

                        this.state.lifeInsurance.advancePaymentTerminalDisease =
                          e.target.value;
                        this.setState({
                          lifeInsurance: this.state.lifeInsurance,
                        });
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <div className="mb-3">
                <small className="text-uppercase font-weight-bold">
                  Grupo familiar
                </small>
              </div>
              <Row>
                <Col lg="6" sm="6">
                  <FormGroup>
                    <label>
                      <span className="text-muted">Muerte cónyuge</span>
                    </label>

                    <Input
                      placeholder=""
                      type="text"
                      value={
                        this.state.lifeInsurance.mateDeath
                          ? this.state.lifeInsurance.mateDeath
                          : ""
                      }
                      onChange={(e) => {
                        e.preventDefault();

                        this.state.lifeInsurance.mateDeath = e.target.value;
                        this.setState({
                          lifeInsurance: this.state.lifeInsurance,
                        });
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </FormGroup>
          </div>
          <div className="modal-footer">
            <Button
              color="primary"
              type="button"
              onClick={(e) => {
                e.preventDefault();
                this.props.saveLifeInsurance(this.state.lifeInsurance);
              }}
            >
              Save
            </Button>
            <Button
              className="ml-auto"
              color="link"
              data-dismiss="modal"
              type="button"
              onClick={() => this.props.toggleModal(this.props.modalName)}
            >
              Close
            </Button>
          </div>
        </Modal>
      </>
    );
  }
}

export default LifeInsuranceEditionModal;
