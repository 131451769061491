export const leadItemsTotalPrice = (items, coupon = null) => {
  let total = items.reduce((price, product) => {
    if (product.salePrice) {
      return price + product.salePrice * product.quantity;
    }
    return price + product.price * product.quantity;
  }, 0);
  const discount = coupon ? (total * Number(coupon.discountInPercent)) / 100 : 0;

  return total - discount;
};
// leadItems, leadItemToAdd
const addItemToLead = (state, action) => {
  const existingLeadItemIndex = state.items.findIndex((item) => item.id === action.payload.id);

  if (existingLeadItemIndex > -1) {
    const newState = [...state.items];
    newState[existingLeadItemIndex].quantity += action.payload.quantity;
    return newState;
  }
  return [...state.items, action.payload];
};

// leadItems, leadItemToRemove
const removeItemFromLead = (state, action) => {
  return state.items.reduce((acc, item) => {
    if (item.id === action.payload.id) {
      const newQuantity = item.quantity - action.payload.quantity;

      return newQuantity > 0 ? [...acc, { ...item, quantity: newQuantity }] : [...acc];
    }
    return [...acc, item];
  }, []);
};

const clearItemFromLead = (state, action) => {
  return state.items.filter((item) => item.id !== action.payload.id);
};

export const reducer = (state, action) => {
  switch (action.type) {
    case 'REHYDRATE':
      return { ...state, ...action.payload };
    case 'TOGGLE_CART':
      return { ...state, isOpen: !state.isOpen };
    case 'ADD_ITEM':
      return { ...state, items: addItemToLead(state, action) };
    case 'REMOVE_ITEM':
      return { ...state, items: removeItemFromLead(state, action) };
    case 'CLEAR_ITEM_FROM_CART':
      return { ...state, items: clearItemFromLead(state, action) };
    case 'CLEAR_CART':
      return {
        ...state,
        items: [],
        coupon: null,
        paymentData: action.initialState.paymentData,
      };
    case 'APPLY_COUPON':
      return { ...state, coupon: action.payload };
    case 'REMOVE_COUPON':
      return { ...state, coupon: null };
    case 'SET_DELIVERY_DATE':
      return { ...state, selectedDeliveryDate: action.payload };
    case 'SET_PAYMENT_DATA':
      return { ...state, paymentData: action.payload };

    default:
      throw new Error(`Unknown action: ${action.type}`);
  }
};
