import React from "react";
// import MetaTags from "react-meta-tags";

// Import Swiper styles
import "swiper/swiper.scss";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  Container,
  Row,
  Col,
  CardTitle,
  CardSubtitle,
} from "reactstrap";

// core components
import WhatsappButton from "components/Buttons/WhatsappButton";
import ContactUsLandingNavbar from "./ContactUsLandingNavbar.jsx";
import Footer from "./Footer.jsx";
class AboutUs extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }

  render() {
    return (
      <>
        {/* <MetaTags>
          <title>¿Quiénes somos? | Hola René</title>
          <meta
            id="meta-description"
            name="description"
            content="HolaRené es un producto de AR Vida, compañía de seguros con más de 120 años de experiencia en el país y más de 200.000 asegurados."
          />
        </MetaTags> */}
        <WhatsappButton />

        <ContactUsLandingNavbar />
        <main ref="main">
          {/*hero*/}
          <section className="section section-lg section-about-us pb-md-0 pt-md-0 pt-sm-5">
            <Container className="d-flex">
              <div className="col px-0">
                <form>
                  <Row>
                    <Col xs="12" lg="6">
                      <h1 className="main-title text-white pt-100">
                        ¿Quiénes somos?
                      </h1>
                      <p className="text-white">
                        HolaRené es un producto de <b>AR Vida</b>, compañía de
                        seguros con más de <b>120 años de experiencia</b> en el
                        país y <b>más de 200.000 asegurados,</b> convirtiéndola
                        en una de las empresas con más trayectoria en su rubro.
                      </p>
                    </Col>
                    <Col lg="6" className="z-index-1 pt-5">
                      <img
                        alt="..."
                        style={{ width: "350px" }}
                        className="mb-sm--150"
                        src={require("assets/img/brand/hola.png")}
                      />
                    </Col>
                  </Row>
                </form>{" "}
              </div>
              {/* SVG separator */}
              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 200"
                  x="0"
                  y="0"
                >
                  <polygon
                    className="fill-white"
                    points="2560 0 2560 200 0 200"
                  />
                </svg>
              </div>
            </Container>
          </section>

          <section className="section section-lg">
            <Container>
              <Row className="row-grid aling-flex-center">
                <Card className="custom-about">
                  <CardBody>
                    <div className="image">
                      <img
                        alt="..."
                        style={{ width: "120px" }}
                        src={require("assets/img/brand/reward.png")}
                      />
                    </div>
                    <CardTitle>+200.000</CardTitle>
                    <CardSubtitle>de asegurados</CardSubtitle>
                  </CardBody>
                </Card>
                <Card className="custom-about">
                  <CardBody>
                    <div className="image">
                      <img
                        alt="..."
                        style={{ width: "120px" }}
                        src={require("assets/img/brand/time.png")}
                      />
                    </div>
                    <CardTitle>120</CardTitle>
                    <CardSubtitle>años de experiencia</CardSubtitle>
                  </CardBody>
                </Card>
                <Card className="custom-about">
                  <CardBody>
                    <div className="image">
                      <img
                        alt="..."
                        style={{ width: "120px" }}
                        src={require("assets/img/brand/app.png")}
                      />
                    </div>
                    <CardTitle>+5.000</CardTitle>
                    <CardSubtitle>profesionales en una app</CardSubtitle>
                  </CardBody>
                </Card>
              </Row>
            </Container>
          </section>

          {/*¿Qué buscamos?*/}
          <section className="section section-lg py-0">
            <Container>
              <Row className="row-grid align-items-center">
                <Col md="6" className="px-sm-0">
                  <img
                    alt="..."
                    className="img-fluid hidden-sm"
                    src={require("assets/img/brand/buscamos.png")}
                  />
                  <img
                    alt="..."
                    className="img-full visible-sm pb-4 "
                    src={require("assets/img/brand/buscamos-sm.png")}
                  />
                </Col>
                <Col md="6">
                  <div className="pr-md-5">
                    <h3 className="home-title">¿Qué buscamos?</h3>
                    <p>
                      Como nuestro foco es <b>asegurar tu bienestar</b>,
                      lanzamos HolaRené, un nuevo servicio que{" "}
                      <b>te protege a vos y a tu familia</b> en el día a día,
                      dándote acceso a <b>la mejor medicina en Argentina.</b>
                    </p>

                    <Button
                      color="custom arrow flat"
                      type="button"
                      onClick={(event) => {
                        this.navigateToOnboarding();
                      }}
                    >
                      Ver planes
                    </Button>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>

          {/*¿Qué nos diferencia?*/}
          <section className="section section-lg">
            <Container>
              <Row className="row-grid align-items-center">
                <Col className="order-md-2 px-sm-0" md="6">
                  <img
                    alt="..."
                    className="img-fluid hidden-sm"
                    src={require("assets/img/brand/diferencias.png")}
                  />
                  <img
                    alt="..."
                    className="img-full visible-sm pb-4"
                    src={require("assets/img/brand/diferencias-sm.png")}
                  />
                </Col>
                <Col className="order-md-1" md="6">
                  <div className="pr-md-5">
                    <h3 className="home-title">¿Qué nos diferencia?</h3>
                    <p>
                      Somos una compañía que combina el{" "}
                      <b>talento jóven y la innovación,</b> con la experiencia y
                      el respaldo de una compañía de más de 100 años en el
                      rubro.
                    </p>
                  </div>
                </Col>
              </Row>
              {/* SVG separator */}
              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 200"
                  x="0"
                  y="0"
                >
                  <polygon fill="#FAFAFA" points="2560 0 2560 200 0 200" />
                </svg>
              </div>
            </Container>
          </section>
        </main>
        <Footer />
      </>
    );
  }
}

export default AboutUs;
