import React from "react";

import { FormGroup, Form, Button } from "reactstrap";

import { createCallRequestLead } from "services/leadsServices";
import CustomInput from "components/Inputs/CustomInput.jsx";

import {
  validateHasValue,
  PHONE_NUMBER_PREFIX_AR,
  validateEmail,
} from "helpers/coreHelper";

class ContactForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      phoneNumber: "",
      firstName: "",
      lastName: "",
      email: "",
      phoneRawValue: "",
      loading: false,
      disabled: true,
    };

    this.onPhoneChange = this.onPhoneChange.bind(this);
  }

  onPhoneChange(event) {
    this.setState({ phoneNumber: "+549" + event.target.rawValue });
    this.setState({ phoneRawValue: event.target.rawValue });
  }

  validateForm() {
    if (
      validateHasValue(this.state.firstName) &&
      validateHasValue(this.state.lastName) &&
      validateHasValue(this.state.email) &&
      validateHasValue(this.state.phoneRawValue, 10) &&
      validateEmail(this.state.email)
    )
      return true;

    return false;
  }

  formIsFull() {
    if (this.validateForm()) {
      this.setState({ disabled: false });
    } else {
      this.setState({ disabled: true });
    }
  }

  onSubmitFormData(event) {
    if (this.state.loading) return;

    if (!this.validateForm()) {
      this.setState({
        errorModalInfo: {
          title: "Error",
          description: "Alguno de los campos es inválido",
        },
      });
      return;
    }

    let search = window.location.search;
    let params = new URLSearchParams(search);

    let utm_campaign = params.get("utm_campaign");
    let utm_source = params.get("utm_source");
    let utm_content = params.get("utm_content");
    let utm_medium = params.get("utm_medium");
    let utm_term = params.get("utm_term");

    let utmData = {
      utm_campaign: utm_campaign,
      utm_source: utm_source,
      utm_content: utm_content,
      utm_medium: utm_medium,
      utm_term: utm_term,
    };

    let lead = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      phoneNumber: this.state.phoneRawValue,
      email: this.state.email,
      utmData: utmData,
      path: window.location.href,
      createdAt: Date.now(),
    };

    this.setState({ loading: true });

    createCallRequestLead(lead)
      .then(() => {
        this.props.onSuccess();
      })
      .catch((e) => {
        this.props.onError(e);
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  render() {
    return (
      <>
        <Form className="pt-140">
          <FormGroup>
            <CustomInput
              inputProps={{
                value: this.state.firstName,
                name: "firstName",
                onChange: (event) => {
                  this.setState(
                    {
                      firstName: event.target.value,
                    },
                    () => {
                      this.formIsFull();
                    }
                  );
                },

                placeholder: "Nombre",
                className: "contact-input",
              }}
            />
          </FormGroup>

          <FormGroup>
            <CustomInput
              inputProps={{
                value: this.state.lastName,
                name: "lastName",
                onChange: (event) => {
                  this.setState(
                    {
                      lastName: event.target.value,
                    },
                    () => {
                      this.formIsFull();
                    }
                  );
                },

                placeholder: "Apellido",
                className: "contact-input",
              }}
            />
          </FormGroup>
          <FormGroup>
            <CustomInput
              behavior={{
                className: "css-phone input form-control contact-input",
                options: {
                  phone: true,
                  phoneRegionCode: "AR",
                },
                // initialValue: this.state.phoneNumber
                //   .replace(" ", "")
                //   .replace(" ", "")
                //   .replace(PHONE_NUMBER_PREFIX_AR, ""),
              }}
              validationFn={(info) => {
                return validateHasValue(info, 10);
              }}
              // error={this.state.errorPhoneNumber}
              inputProps={{
                type: "tel",
                name: "phone",
                autoComplete: "tel",
                value: this.state.phoneNumber
                  .replace(" ", "")
                  .replace(" ", "")
                  .replace(PHONE_NUMBER_PREFIX_AR, ""),
                onChange: (event) => {
                  this.state.phoneNumber =
                    PHONE_NUMBER_PREFIX_AR + event.target.rawValue;

                  // this.validateFormData(
                  //   this.state.userFullName,
                  //   this.state.phoneNumber
                  // );

                  this.setState(
                    {
                      phoneNumber: this.state.phoneNumber,
                      // phoneNumberValidated: false, //si lo cambia tiene que validarlo nuevamente
                      phoneRawValue: event.target.rawValue,
                    },
                    () => {
                      this.formIsFull();
                    }
                  );
                },
                placeholder: "Tél. celular (código de área + núm)",
              }}
            />
          </FormGroup>
          <FormGroup>
            <CustomInput
              inputProps={{
                value: this.state.email,
                name: "email",
                onChange: (event) => {
                  this.setState(
                    {
                      email: event.target.value,
                    },
                    () => {
                      this.formIsFull();
                    }
                  );
                },

                placeholder: "Email",
                className: "contact-input",
              }}
            />
          </FormGroup>
          <div className="btn-wrapper">
            <Button
              variant="primary"
              className="mt-4 full-w"
              color="custom noarrow"
              type="button"
              size="lg"
              block
              onClick={(event) => {
                this.onSubmitFormData();
              }}
              disabled={this.state.loading || this.state.disabled}
            >
              {!this.state.loading && "Enviar"}
              {this.state.loading && "Cargando..."}
            </Button>
          </div>
        </Form>
      </>
    );
  }
}
export default ContactForm;
