// import { authRef } from "config/firebase";

const { CustomError } = require('helpers/errorClasses');

const {
  PAYMENT_METHOD_TC,
  PAYMENT_METHOD_TD,
  PAYMENT_METHOD_CBU,
} = require('../types/paymentMethodTypes');

const { authRef } = require('config/firebase');

exports.getRelationshipList = function (list) {
  if (!list) return null;

  let newList = [];
  list.forEach((item) => {
    newList.push({
      id: item.id,
      name: item.name || null,
      photo: item.photo || null,
    });
  });

  return newList;
};

const formatMoney = function (amount, decimalCount = 2, decimal = ',', thousands = '.') {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? '-' : '';

    let i = parseInt((amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))).toString();
    let j = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : '') +
      i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : '')
    );
  } catch (e) {
    console.log(e);
  }
};

exports.formatMoney = formatMoney;

exports.setPersistedValue = function (key, defaultValue) {
  // const [state, setState] = React.useState(
  //   () => JSON.parse(localStorage.getItem(key)) || defaultValue
  // );

  localStorage.setItem(key, JSON.stringify(defaultValue));

  // useEffect(() => {
  //   localStorage.setItem(key, JSON.stringify(state));
  // }, [key, state]);
  // return [state, setState];
};

exports.getPersistedValue = function (key) {
  // const [state, setState] = React.useState(
  //   () => JSON.parse(localStorage.getItem(key)) || defaultValue
  // );

  return JSON.parse(localStorage.getItem(key));

  // useEffect(() => {
  //   localStorage.setItem(key, JSON.stringify(state));
  // }, [key, state]);
  // return [state, setState];
};

exports.removePersistedValue = function (key) {
  return localStorage.removeItem(key);
};

exports.validateEmail = function (email) {
  var re =
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

exports.onlyText = function (input) {
  var re = /^[a-zA-Z\u00C0-\u00FF ]*$/;

  return re.test(input);
};

exports.validateHasValue = function (val, len) {
  if (!len) len = 1;
  return val && val.length >= len;
};

exports.validateDate = function (date) {
  return date && date.length === 10;
};

exports.validatePhone = function (phoneNumber) {
  const PHONE_NUMBER_PREFIX_AR = '+549';

  if (!phoneNumber) return false;

  let phoneNumberAux = phoneNumber
    // .split(" ")
    // .join("")
    .replace(' ', '')
    .replace(' ', '')
    .replace(PHONE_NUMBER_PREFIX_AR, '');

  //011 y lrpmqtrmp
  if (phoneNumberAux.length === 11 && phoneNumberAux.startsWith('0')) {
    return true;
  }

  if (phoneNumberAux.length !== 10) {
    return false;
  }

  return true;
};

exports.validateCard = function (card) {
  if (!card || card.length < 10) return false;
  return true;
};

exports.getUserToken = function () {
  return new Promise((resolve, reject) => {
    authRef.onAuthStateChanged((user) => {
      if (!user) resolve(null);
      else
        user.getIdToken().then((token) => {
          resolve(token);
        });
    });
  });
};

exports.PHONE_NUMBER_PREFIX_AR = '+549';

exports.MARITAL_STATUS_OPTIONS = [
  { key: 'S', value: 'Soltero/a' },
  { key: 'C', value: 'Casado/a' },
  { key: 'D', value: 'Divorciado/a' },
  { key: 'V', value: 'Viudo/a' },
];

exports.GENDER_OPTIONS = [
  { key: 'M', value: 'Masculino' },
  { key: 'F', value: 'Femenino' },
];

exports.PAYMENT_METHODS = [
  { key: PAYMENT_METHOD_TC, value: 'Tarjeta de crédito' },
  { key: PAYMENT_METHOD_TD, value: 'Tarjeta de débito' },
  { key: PAYMENT_METHOD_CBU, value: 'Débito en cuenta (CBU o CVU)' },
];

exports.goToFatalErrorPage = function (history, error) {
  history.push({
    pathname: '/error',
    //search: '?query=abc',
    state: { error: error },
  });
  //history.push("/error");
};

exports.getUserWithToken = function () {
  return new Promise((resolve, reject) => {
    authRef.onAuthStateChanged((user) => {
      if (!user) resolve(null);
      else
        user.getIdToken().then((token) => {
          resolve({ token, user });
        });
    });
  });
};

exports.getCustomError = function (err) {
  if (
    err &&
    err.isAxiosError &&
    err.response &&
    err.response.data &&
    err.response.data.isCustomError
  ) {
    return new CustomError(err.response.data.code, err.response.status, err.response.data.message);
  }
  return null;
};

exports.calculatePlanPrice = function (
  promotionalPlan,
  familyGroupMembersCount,
  selectedPlanSwitcherOptionKey
) {
  if (!promotionalPlan || !promotionalPlan.associatedPlans) return 0;

  const asocPlan = getPlanByMemberCount(
    promotionalPlan.associatedPlans,
    familyGroupMembersCount,
    selectedPlanSwitcherOptionKey
  );

  if (!asocPlan) return 0;

  return asocPlan.prices[0].amount;
};

const getPlanByMemberCount = function (
  plans,
  familyGroupMembersCount,
  selectedPlanSwitcherOptionKey
) {
  if (!plans) return null;

  const plansAux = plans.sort((a, b) => {
    return a.maxFamilyMembers - b.maxFamilyMembers;
  });

  return plansAux.find((asocPlan) => {
    if (
      familyGroupMembersCount <= asocPlan.maxFamilyMembers &&
      selectedPlanSwitcherOptionKey === asocPlan.planSwitcherType
    )
      return true;

    return false;
  });
};

exports.getPlanByMemberCount = getPlanByMemberCount;

exports.getPlanBulletDescription = function (
  planBullet,
  selectedPromotionalPlan,
  familyGroupMembersCount,
  allPromotionalPlans,
  selectedPlanSwitcherOptionKey
) {
  if (!planBullet || !selectedPromotionalPlan || !selectedPromotionalPlan.associatedPlans)
    return 'Error plan bullet description (1)';

  const asocPlan = getPlanByMemberCount(
    selectedPromotionalPlan.associatedPlans,
    familyGroupMembersCount,
    selectedPlanSwitcherOptionKey
  );

  if (!asocPlan || !asocPlan.products) return 'Error plan bullet description (2)';

  let asocPlanProduct = asocPlan.products.find((product) => {
    return product.type === planBullet.productType;
  });

  if (!asocPlanProduct) {
    const aux = selectedPromotionalPlan.associatedPlans.find((associatedPlan) => {
      return associatedPlan.products.find((product) => {
        return product.type === planBullet.productType;
      });
    });
    if (aux)
      asocPlanProduct = aux.products.find((product) => {
        return product.type === planBullet.productType;
      });
    else {
      allPromotionalPlans.forEach((promotionalPlan) => {
        if (promotionalPlan.associatedPlans && promotionalPlan.associatedPlans.length !== 0) {
          promotionalPlan.associatedPlans.forEach((associatedPlan) => {
            associatedPlan.products.forEach((product) => {
              if (!asocPlanProduct && product.type === planBullet.productType) {
                asocPlanProduct = product;
                return;
              }
            });
          });
        }
      });
    }
  }

  //console.log("asocPlanProduct:", asocPlanProduct);
  if (asocPlanProduct) {
    if (!asocPlanProduct.quotas) {
      debugger;
    }
    return planBullet.description
      .replace('%QUOTA_ANUAL%', asocPlanProduct.quotas.anual)
      .replace('%QUOTA_MONTHLY%', asocPlanProduct.quotas.monthly)

      .replace('%COPAY_AMOUNT%', '$' + formatMoney(asocPlanProduct.copayAmount))
      .replace(
        '%ADDITIONAL_EVENT_AMOUNT%',
        '$' + formatMoney(asocPlanProduct.additionalEventAmount)
      )
      .replace('%REFUND_EVENT_AMOUNT%', '$' + formatMoney(asocPlanProduct.refundEventAmount))
      .replace('%REFUND_MAX_AMOUNT%', '$' + formatMoney(asocPlanProduct.refundMaxAmount));
  }
  return null;
};

exports.loadScript = (url, callback) => {
  let script = document.createElement('script');
  script.type = 'text/javascript';

  if (script.readyState) {
    script.onreadystatechange = function () {
      if (script.readyState === 'loaded' || script.readyState === 'complete') {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    script.onload = () => callback();
  }

  script.src = url;
  document.getElementsByTagName('head')[0].appendChild(script);
};

exports.loadStylesheet = (scriptToAppend) => {
  const script = document.createElement('link');
  document.body.appendChild(script);
  script.async = true;
  script.href = scriptToAppend;
  script.rel = 'stylesheet';
};

exports.cleanAnalytics = function () {
  analyticsTag({ ecommerce: null }); // Clear the previous ecommerce object.
};

const analyticsTag = function (data) {
  window.dataLayer = window.dataLayer || [];

  window.dataLayer.push(data);
};

exports.analyticsTag = analyticsTag;
