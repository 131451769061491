import React from 'react';

// reactstrap components
import {
  Button,
  Card,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Table,
  Container,
  Col,
  Row,
  Input,
  FormGroup,
} from 'reactstrap';
import AdminNavbar from 'components/Navbars/AdminNavbar.jsx';

// core components

import SimpleFooter from 'components/Footers/SimpleFooter.jsx';
import { getUser, removeUser } from 'services/usersServices';

import { listUserActivity } from 'services/userActivityServices';

import LogDetailEditionModal from './LogDetailEditionModal.jsx';

class UserProfile extends React.Component {
  constructor(props) {
    super(props);

    this.state = { currentUser: null };
  }

  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
    });
  };

  createPaymentErrorData(code, description) {
    return { code: code, description: description };
  }

  getPaymentErrorData(e) {
    if (e.code && e.description) {
      return e;
    }

    if (e.response && e.response.data && e.response.data.status) {
      let errorDescription = '';
      if (e.response.data) errorDescription = JSON.parse(e.response.data.data).status_detail;

      return {
        code: e.response.data.status,
        description: errorDescription,
      };
    }

    return {
      description: e.message,
    };
  }

  cc_format(value) {
    var v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '');
    var matches = v.match(/\d{4,16}/g);
    var match = (matches && matches[0]) || '';
    var parts = [];

    for (let i = 0, len = match.length; i < len; i += 4) {
      parts.push(match.substring(i, i + 4));
    }

    if (parts.length) {
      return parts.join(' ');
    } else {
      return value;
    }
  }

  onCreditCardNumberChange(e, value) {
    if (value.length > 19) return;

    value = this.cc_format(value);

    //16
    this.setState({ oldCcreditCardNumber: value, creditCardNumber: value });
  }

  onCreditCardOwnerChange(value) {
    this.setState({ creditCardOwner: value });
  }

  onCreditCardDueDateKeyUp(e) {
    if (e.target.value[e.target.value.length] === ' ' && e.keyCode === 8) {
      e.target.value = e.target.value.substring(0, e.target.value.length - 2);

      if (e.target.value[e.target.value.length] === ' ')
        e.target.value = e.target.value.substring(0, e.target.value.length - 1);

      this.setState({ creditCardDueDate: e.target.value });
    } else if (e.keyCode === 8) {
      e.target.value = e.target.value.substring(0, e.target.value.length - 1);
      this.setState({ creditCardDueDate: e.target.value });
    }
  }

  onCreditCardDueDateKeyPress(e) {
    let value = e.target.value;

    if (value.length > 4) return;

    value += e.key;

    if (value.length === 2) value += '/';

    this.setState({ creditCardDueDate: value });
  }

  onCreditCardCVVChange(value) {
    if (value.length > 4)
      //amex es de 4...
      return;

    this.setState({ creditCardCVV: value });
  }

  onCreditCardDNIChange(value) {
    if (value.length > 12) return;

    this.setState({ creditCardDNI: value });
  }

  componentDidMount() {
    // document.documentElement.scrollTop = 0;
    // document.scrollingElement.scrollTop = 0;
    // this.refs.main.scrollTop = 0;

    let userId = this.props.match.params.id;

    console.log('El id de usuario es:', userId);

    listUserActivity(userId)
      .then((logs) => {
        this.setState({ logs: logs });
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.message)
          alert(e.response.data.message);
        else alert(e.message);
      });

    getUser(userId)
      .then((user) => {
        console.log('user:', user, 'Hola mundo', userId);
        this.setState({ currentUser: user });
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.message)
          alert(e.response.data.message);
        else alert(e.message);
      });
  }

  render() {
    if (!this.state.currentUser) return <div>loading</div>;

    const currentPlan = this.state.currentUser.userData.currentPlan.plan;
    const currentPlanDueDate = this.state.currentUser.userData.currentPlan.dueDate;

    const cacheExpiration = this.state.currentUser.userData.cacheExpiration;
    const pushToken = this.state.currentUser.userData.pushToken;
    const cbu = this.state.currentUser.userData.paymentData.CBU;
    const cardHolder = this.state.currentUser.userData.paymentData.cardHolder;
    const cardNumber = this.state.currentUser.userData.paymentData.cardNumber;
    const cardDueDate = this.state.currentUser.userData.paymentData.cardDueDate;
    const cardCVC = this.state.currentUser.userData.paymentData.cardCVC;
    const paymentMethod = this.state.currentUser.userData.paymentData.paymentMethod;
    return (
      <>
        <AdminNavbar {...this.props} currentUser={this.state.currentUser.user} />

        {this.state.logToEdit && (
          <LogDetailEditionModal
            modalName="LogDetailEditionModal"
            log={this.state.logToEdit}
            LogDetailEditionModalIsOpen={this.state.LogDetailEditionModal}
            toggleModal={this.toggleModal.bind(this)}
            // saveOrder={this.saveOrder.bind(this)}
          />
        )}

        <main className="profile-page" ref="main">
          <section className="section-profile-cover section-shaped my-0">
            {/* Circles background */}
            <div className="shape shape-style-1 shape-default alpha-4">
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
            </div>
            {/* SVG separator */}
            <div className="separator separator-bottom separator-skew">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon className="fill-white" points="2560 0 2560 100 0 100" />
              </svg>
            </div>
          </section>
          <section className="section">
            <Container>
              <Card className="card-profile shadow mt--300">
                <div className="px-4">
                  <Row className="justify-content-center">
                    <Col className="order-lg-2" lg="3">
                      <div className="card-profile-image">
                        <a href="#" onClick={(e) => e.preventDefault()}>
                          <img
                            alt="..."
                            className="rounded-circle"
                            src={this.state.currentUser.user.photoURL}
                          />
                        </a>
                      </div>
                    </Col>
                    <Col className="order-lg-3 text-lg-right align-self-lg-center" lg="4">
                      <div className="card-profile-actions py-4 mt-lg-0">
                        <Button
                          className="mr-4"
                          color="info"
                          href="#"
                          onClick={(e) => {
                            this.props.history.push('/logs/' + this.props.match.params.id);
                          }}
                          size="sm"
                        >
                          Logs
                        </Button>
                        {/* <Button
                          className="float-right"
                          color="default"
                          href="#"
                          onClick={(e) => e.preventDefault()}
                          size="sm"
                        >
                          Message
                        </Button> */}
                      </div>
                    </Col>
                    <Col className="order-lg-1" lg="4">
                      <div className="card-profile-stats d-flex justify-content-center">
                        <div>
                          <span className="heading">-</span>
                          <span className="description">-</span>
                        </div>
                        <div>
                          <span className="heading">-</span>
                          <span className="description">-</span>
                        </div>
                        <div>
                          <span className="heading">-</span>
                          <span className="description">-</span>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <div className="text-center mt-5">
                    <h3>{this.state.currentUser.user.displayName}</h3>
                    <div className="h6 font-weight-300">
                      <i className="ni location_pin mr-2" />
                      {this.state.currentUser.user.email}
                    </div>

                    <div className="h6 font-weight-300">
                      <i className="ni location_pin mr-2" />
                      {this.state.currentUser.user.uid}
                    </div>

                    {/* <div>
                      <i className="ni education_hat mr-2" />
                      University of Computer Science
                    </div> */}
                  </div>

                  <div className="mt-5 py-5 border-top text-center">
                    <Row className="justify-content-center">
                      <Col lg="9">
                        <div>
                          <i className="ni education_hat mr-2" />
                          Payment
                        </div>
                        <br />

                        {!currentPlan && <>Sin plan</>}

                        {currentPlan && (
                          <>
                            <Row>
                              <Col lg="12" sm="12">
                                <FormGroup>
                                  <label>
                                    <span className="text-muted">Nombre titular</span>
                                  </label>

                                  <Input
                                    placeholder="Step"
                                    type="text"
                                    value={cardHolder}
                                    disabled
                                  />
                                </FormGroup>
                              </Col>

                              {paymentMethod === 'CBU' && (
                                <Col lg="12" sm="12">
                                  <FormGroup>
                                    <label>
                                      <span className="text-muted">CBU</span>
                                    </label>

                                    <Input placeholder="Step" type="text" value={cbu} disabled />
                                  </FormGroup>
                                </Col>
                              )}

                              {paymentMethod === 'TC' && (
                                <>
                                  <Col lg="12" sm="12">
                                    <FormGroup>
                                      <label>
                                        <span className="text-muted">Número de tarjeta</span>
                                      </label>

                                      <Input
                                        placeholder="Step"
                                        type="text"
                                        value={cardNumber}
                                        disabled
                                      />
                                    </FormGroup>
                                  </Col>

                                  <Col lg="6" sm="6">
                                    <FormGroup>
                                      <label>
                                        <span className="text-muted">Vencimiento</span>
                                      </label>

                                      <Input
                                        placeholder=""
                                        type="text"
                                        value={cardDueDate}
                                        disabled
                                      />
                                    </FormGroup>
                                  </Col>

                                  <Col lg="6" sm="6">
                                    <FormGroup>
                                      <label>
                                        <span className="text-muted">CVV</span>
                                      </label>

                                      <Input
                                        placeholder="Step"
                                        type="text"
                                        value={cardCVC}
                                        disabled
                                      />
                                    </FormGroup>
                                  </Col>
                                </>
                              )}
                            </Row>
                          </>
                        )}
                      </Col>
                    </Row>
                  </div>
                  <div className="mt-5 py-5 border-top text-center">
                    <Row className="justify-content-center">
                      <Col lg="9">
                        <div>
                          <i className="ni education_hat mr-2" />
                          Datos aplicativos
                        </div>
                        <br />

                        {!currentPlan && <>Sin plan</>}

                        {currentPlan && (
                          <>
                            <Row>
                              <Col lg="6" sm="6">
                                <FormGroup>
                                  <label>
                                    <span className="text-muted">Cache Expiration</span>
                                  </label>

                                  <Input
                                    placeholder="Step"
                                    type=""
                                    value={cacheExpiration}
                                    disabled
                                  />
                                </FormGroup>
                              </Col>
                              <Col lg="6" sm="6">
                                <FormGroup>
                                  <label>
                                    <span className="text-muted">PushToken</span>
                                  </label>

                                  <Input placeholder="" type="text" value={pushToken} disabled />
                                </FormGroup>
                              </Col>
                            </Row>
                          </>
                        )}
                      </Col>
                    </Row>
                  </div>
                  <div className="mt-5 py-5 border-top text-center">
                    <Row className="justify-content-center">
                      <Col lg="9">
                        <div>
                          <i className="ni education_hat mr-2" />
                          Datos aplicativos
                        </div>
                        <br />

                        {!currentPlan && <>Sin plan</>}

                        {currentPlan && (
                          <>
                            <Row>
                              <Col lg="6" sm="6">
                                <FormGroup>
                                  <label>
                                    <span className="text-muted">Cache Expiration</span>
                                  </label>

                                  <Input
                                    placeholder="Step"
                                    type=""
                                    value={cacheExpiration}
                                    disabled
                                  />
                                </FormGroup>
                              </Col>
                              <Col lg="6" sm="6">
                                <FormGroup>
                                  <label>
                                    <span className="text-muted">PushToken</span>
                                  </label>

                                  <Input placeholder="" type="text" value={pushToken} disabled />
                                </FormGroup>
                              </Col>
                            </Row>
                          </>
                        )}
                      </Col>
                    </Row>
                  </div>

                  <div className="mt-5 py-5 border-top text-center">
                    <Row className="justify-content-center">
                      <Col lg="9">
                        <div>
                          <i className="ni education_hat mr-2" />
                          Plan actual
                        </div>
                        <br />

                        {!currentPlan && <>Sin plan</>}

                        {currentPlan && (
                          <>
                            <Row>
                              <Col lg="4" sm="4">
                                <FormGroup>
                                  <label>
                                    <span className="text-muted">ID</span>
                                  </label>

                                  <Input
                                    placeholder="Step"
                                    type="text"
                                    value={currentPlan.id}
                                    disabled
                                  />
                                </FormGroup>
                              </Col>
                              <Col lg="4" sm="4">
                                <FormGroup>
                                  <label>
                                    <span className="text-muted">Nombre</span>
                                  </label>

                                  <Input
                                    placeholder=""
                                    type="text"
                                    value={currentPlan.name}
                                    disabled
                                  />
                                </FormGroup>
                              </Col>

                              <Col lg="4" sm="4">
                                <FormGroup>
                                  <label>
                                    <span className="text-muted">Due Date</span>
                                  </label>

                                  <Input
                                    placeholder=""
                                    type="text"
                                    value={currentPlanDueDate}
                                    disabled
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                          </>
                        )}
                      </Col>
                    </Row>
                  </div>

                  <div className="mt-5 py-5 border-top text-center">
                    <Row className="justify-content-center">
                      <Col lg="12">
                        <div>
                          <i className="ni education_hat mr-2" />
                          Actividad
                        </div>
                        <br />
                        {!this.state.logs && <div>Loading</div>}
                        {this.state.logs && (
                          <Table className="align-items-center table-flush" responsive>
                            <thead className="thead-light">
                              <tr>
                                <th scope="col">Date</th>
                                <th scope="col">Severity</th>
                                <th scope="col">Event</th>
                                <th scope="col">Env</th>
                                <th scope="col">Error Code</th>
                                <th scope="col">Message</th>

                                <th scope="col" />
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.logs.map((log, index) => {
                                return (
                                  <tr key={index}>
                                    <td>{log.createdAt}</td>
                                    <td>{log.severity}</td>
                                    <td>{log.eventType}</td>
                                    <td>{log.env}</td>
                                    <td>{log.errorCode}</td>
                                    <td>{log.message}</td>

                                    <td className="text-right">
                                      <UncontrolledDropdown>
                                        <DropdownToggle
                                          className="btn-icon-only text-light"
                                          href="#"
                                          role="button"
                                          size="sm"
                                          color=""
                                          onClick={(e) => e.preventDefault()}
                                        >
                                          <i className="fas fa-ellipsis-v" />
                                        </DropdownToggle>
                                        <DropdownMenu className="dropdown-menu-arrow" right>
                                          <DropdownItem
                                            onClick={(e) => {
                                              e.preventDefault();
                                              this.setState({ logToEdit: log });
                                              this.toggleModal('LogDetailEditionModal');
                                            }}
                                          >
                                            Detail
                                          </DropdownItem>
                                        </DropdownMenu>
                                      </UncontrolledDropdown>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </Table>
                        )}
                      </Col>
                    </Row>
                  </div>

                  <div className="mt-5 py-5 border-top text-center">
                    <Row className="justify-content-center">
                      <Col lg="9">
                        <div>
                          <i className="ni education_hat mr-2" />
                          Danger zone
                        </div>
                        <br />
                        <Button
                          className="mt-4"
                          color="custom secondary noarrow"
                          type="button"
                          onClick={() => {
                            removeUser(this.props.match.params.id)
                              .then(() => {
                                alert('Ok');
                              })
                              .catch((e) => {
                                console.log('RemoveError:', e);
                                alert('Error: ' + e.message);
                              });
                          }}
                        >
                          Eliminar
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Card>
            </Container>
          </section>
        </main>
        <SimpleFooter />
      </>
    );
  }
}

export default UserProfile;
