import { getHttpBearerConfig } from 'services/httpServices';
import axios from 'axios';

const appConfig = require('config/appConfig');

export const listEmergencies = (currentUserToken) => {
  return new Promise(function (resolve, reject) {
    //exports.saveRole = function(currentUserToken, targetUser, role) {
    let config = getHttpBearerConfig(currentUserToken);

    return axios
      .get(`${appConfig.gcfUrl}/emergencies`, config)
      .then((response) => {
        console.log('emergencies response:', response);
        if (response && response.data) resolve(response.data);
        else resolve([]);
      })
      .catch((e) => {
        console.log('error:', e);

        reject(e);
      });
  });
};

export const createEmergency = (currentUserToken, emergency) => {
  return new Promise(function (resolve, reject) {
    //exports.saveRole = function(currentUserToken, targetUser, role) {
    let config = getHttpBearerConfig(currentUserToken);

    return axios
      .post(
        `${appConfig.gcfUrl}/emergencies`,
        {
          name: emergency.name,
          description: emergency.description,
          tel: emergency.tel,
          address: emergency.address,
        },
        config
      )
      .then((response) => {
        console.log('create emergency response:', response);
        if (response && response.data) resolve(response.data);
        else resolve(null);
      })
      .catch((e) => {
        console.log('error:', e);

        reject(e);
      });
  });
};

export const updateEmergency = (currentUserToken, emergency) => {
  return new Promise(function (resolve, reject) {
    //exports.saveRole = function(currentUserToken, targetUser, role) {
    let config = getHttpBearerConfig(currentUserToken);

    return axios
      .patch(
        `${appConfig.gcfUrl}/emergencies/${emergency.id}`,
        {
          name: emergency.name,
          description: emergency.description,
          tel: emergency.tel,
          address: emergency.address,
        },
        config
      )
      .then((response) => {
        console.log('update emergency response:', response);
        if (response && response.data) resolve(response.data);
        else resolve(null);
      })
      .catch((e) => {
        console.log('error:', e);

        reject(e);
      });
  });
};

export const deleteEmergency = (currentUserToken, emergency) => {
  return new Promise(function (resolve, reject) {
    let config = getHttpBearerConfig(currentUserToken);

    return axios
      .delete(`${appConfig.gcfUrl}/emergencies/${emergency.id}`, config)
      .then((response) => {
        console.log('delete emergency response:', response);
        if (response && response.data) resolve(response.data);
        else resolve(null);
      })
      .catch((e) => {
        console.log('error:', e);

        reject(e);
      });
  });
};
