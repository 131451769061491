import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Table,
  Container,
  Col,
  Row,
} from "reactstrap";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.jsx";
import NotificationEventsEditionModal from "./NotificationEventsEditionModal.jsx";

import { authRef } from "config/firebase";

import {
  listNotificationEvents,
  createNotificationEvent,
  updateNotificationEvent,
  deleteNotificationEvent,
} from "services/notificationsServices";

class NotificationEventsOverview extends React.Component {
  constructor(props) {
    super(props);

    this.state = { notificationEvents: null, addNewNotificationEvent: {} };
  }

  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
    });
  };

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    authRef.onAuthStateChanged((user) => {
      this.setState({ currentUser: user });

      user.getIdToken().then((token) => {
        listNotificationEvents(token)
          .then((notificationEvents) => {
            console.log("notificationEvents: ", notificationEvents);
            this.setState({ notificationEvents: notificationEvents });
          })
          .catch((e) => {
            if (e.response && e.response.data && e.response.data.message)
              alert(e.response.data.message);
            else alert(e.message);
          });
      });
    });
  }

  componentDidUpdate(e) {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.mainContent.scrollTop = 0;
  }

  saveNotificationEvent(notificationEvent) {
    console.log("notificationEvent:", notificationEvent);
    if (
      !notificationEvent.title ||
      !notificationEvent.body ||
      !notificationEvent.navigation ||
      !notificationEvent.startDate ||
      !notificationEvent.endDate
    ) {
      console.log("Faltan datos requeridos: ", notificationEvent);
      alert("Faltan datos requeridos");
      return;
    }

    authRef.onAuthStateChanged((user) => {
      user.getIdToken().then((token) => {
        if (notificationEvent.id)
          updateNotificationEvent(token, notificationEvent)
            .then((notificationEvent) => {
              this.loadData();
              console.log("NotificationEvent: ", notificationEvent);
              alert("NotificationEvent: " + JSON.stringify(notificationEvent));
              this.toggleModal("notificationEventsEditionModal");
            })
            .catch((e) => {
              if (e.response && e.response.data && e.response.data.message)
                alert(e.response.data.message);
              else alert(e.message);
            });
        else
          createNotificationEvent(token, notificationEvent)
            .then((notificationEvent) => {
              this.loadData();
              console.log("NotificationEvent: ", notificationEvent);
              alert("NotificationEvent: " + JSON.stringify(notificationEvent));
              this.toggleModal("notificationEventsEditionModal");
            })
            .catch((e) => {
              if (e.response && e.response.data && e.response.data.message)
                alert(e.response.data.message);
              else alert(e.message);
            });
      });
    });

    //alert("notificationEvent recibido: " + notificationEvent.name);
  }

  innerDeleteNotificationEvent(notificationEvent) {
    authRef.onAuthStateChanged((user) => {
      user.getIdToken().then((token) => {
        deleteNotificationEvent(token, notificationEvent)
          .then((notificationEvent) => {
            this.loadData();
            console.log("NotificationEvent: ", notificationEvent);
            alert(
              "NotificationEvent: " +
                JSON.stringify(notificationEvent) +
                "removed"
            );
          })
          .catch((e) => {
            if (e.response && e.response.data && e.response.data.message)
              alert(e.response.data.message);
            else alert(e.message);
          });
      });
    });
  }

  render() {
    return (
      <>
        <div className="main-content" ref="mainContent">
          <AdminNavbar {...this.props} currentUser={this.state.currentUser} />

          {this.state.notificationEvents &&
            this.state.notificationEventToEdit && (
              <NotificationEventsEditionModal
                modalName="notificationEventsEditionModal"
                notificationEventsEditionModalIsOpen={
                  this.state.notificationEventsEditionModal
                }
                notificationEvent={this.state.notificationEventToEdit}
                toggleModal={this.toggleModal.bind(this)}
                saveNotificationEvent={this.saveNotificationEvent.bind(this)}
              />
            )}
          <Container fluid>
            <Row>
              <div className="col">
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <Row>
                      <Col lg="10">
                        <h3 className="mb-0">Notification Events</h3>
                      </Col>
                      <Col lg="2">
                        <div className="btn-wrapper">
                          <Button
                            color="success"
                            onClick={(e) => {
                              e.preventDefault();

                              this.setState({ notificationEventToEdit: {} });
                              this.toggleModal(
                                "notificationEventsEditionModal"
                              );
                            }}
                          >
                            New
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </CardHeader>
                  {!this.state.notificationEvents && <div>Loading</div>}
                  {this.state.notificationEvents && (
                    <Table
                      className="align-items-center table-flush"
                      responsive
                    >
                      <thead className="thead-light">
                        <tr>
                          <th scope="col">Image</th>
                          <th scope="col">Title</th>
                          <th scope="col">Body</th>

                          <th scope="col">Start date</th>
                          <th scope="col">End date</th>

                          <th scope="col">Navigation</th>

                          <th scope="col" />
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.notificationEvents.map(
                          (notificationEvent, index) => {
                            return (
                              <tr key={index}>
                                <th scope="row">
                                  <Media className="align-items-center">
                                    <a
                                      className="avatar rounded-circle mr-3"
                                      onClick={(e) => e.preventDefault()}
                                    >
                                      <img
                                        alt="..."
                                        src={notificationEvent.image}
                                      />
                                    </a>
                                  </Media>
                                </th>
                                <td>
                                  <Media>
                                    <span className="mb-0 text-sm">
                                      {notificationEvent.title}
                                    </span>
                                  </Media>
                                </td>
                                <td>
                                  <Media>
                                    <span className="mb-0 text-sm">
                                      {notificationEvent.body}
                                    </span>
                                  </Media>
                                </td>
                                <td>{notificationEvent.startDate}</td>
                                <td>{notificationEvent.endDate}</td>
                                <td>{notificationEvent.navigation}</td>

                                <td className="text-right">
                                  <UncontrolledDropdown>
                                    <DropdownToggle
                                      className="btn-icon-only text-light"
                                      href="#"
                                      role="button"
                                      size="sm"
                                      color=""
                                      onClick={(e) => e.preventDefault()}
                                    >
                                      <i className="fas fa-ellipsis-v" />
                                    </DropdownToggle>
                                    <DropdownMenu
                                      className="dropdown-menu-arrow"
                                      right
                                    >
                                      <DropdownItem
                                        onClick={(e) => {
                                          e.preventDefault();

                                          this.setState({
                                            notificationEventToEdit:
                                              notificationEvent,
                                          });
                                          this.toggleModal(
                                            "notificationEventsEditionModal"
                                          );
                                        }}
                                      >
                                        Edit
                                      </DropdownItem>

                                      <DropdownItem
                                        onClick={(e) => {
                                          e.preventDefault();

                                          this.innerDeleteNotificationEvent(
                                            notificationEvent
                                          );
                                        }}
                                      >
                                        Delete
                                      </DropdownItem>
                                    </DropdownMenu>
                                  </UncontrolledDropdown>
                                </td>
                              </tr>
                            );
                          }
                        )}
                      </tbody>
                    </Table>
                  )}{" "}
                </Card>
              </div>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

export default NotificationEventsOverview;
