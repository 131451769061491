import { getHttpBearerConfig } from 'services/httpServices';
import axios from 'axios';

const appConfig = require('config/appConfig');

export const listLifeInsurances = (currentUserToken) => {
  return new Promise(function (resolve, reject) {
    //exports.saveRole = function(currentUserToken, targetUser, role) {
    let config = getHttpBearerConfig(currentUserToken);

    return axios
      .get(`${appConfig.gcfUrl}/lifeInsurances`, config)
      .then((response) => {
        console.log('lifeInsurances response:', response);
        if (response && response.data) resolve(response.data);
        else resolve([]);
      })
      .catch((e) => {
        console.log('error:', e);

        reject(e);
      });
  });
};

export const createLifeInsurance = (currentUserToken, lifeInsurance) => {
  return new Promise(function (resolve, reject) {
    //exports.saveRole = function(currentUserToken, targetUser, role) {
    let config = getHttpBearerConfig(currentUserToken);

    let data = lifeInsurance;
    return axios
      .post(`${appConfig.gcfUrl}/lifeInsurances`, data, config)
      .then((response) => {
        console.log('create lifeInsurance response:', response);
        if (response && response.data) resolve(response.data);
        else resolve(null);
      })
      .catch((e) => {
        console.log('error:', e);

        reject(e);
      });
  });
};

export const updateLifeInsurance = (currentUserToken, lifeInsurance) => {
  return new Promise(function (resolve, reject) {
    //exports.saveRole = function(currentUserToken, targetUser, role) {
    let config = getHttpBearerConfig(currentUserToken);

    let data = lifeInsurance;

    return axios
      .patch(`${appConfig.gcfUrl}/lifeInsurances/${lifeInsurance.id}`, data, config)
      .then((response) => {
        console.log('update lifeInsurance response:', response);
        if (response && response.data) resolve(response.data);
        else resolve(null);
      })
      .catch((e) => {
        console.log('error:', e);

        reject(e);
      });
  });
};

export const deleteLifeInsurance = (currentUserToken, lifeInsurance) => {
  return new Promise(function (resolve, reject) {
    let config = getHttpBearerConfig(currentUserToken);

    return axios
      .delete(`${appConfig.gcfUrl}/lifeInsurances/${lifeInsurance.id}`, config)
      .then((response) => {
        console.log('delete lifeInsurance response:', response);
        if (response && response.data) resolve(response.data);
        else resolve(null);
      })
      .catch((e) => {
        console.log('error:', e);

        reject(e);
      });
  });
};
