import React from "react";

import ExcelUploader from "components/Uploader/ExcelUploader";

export default function UploadFile({ onUpload, closeModal }) {
  const handleUploader = (files) => {
    const excelFile = files[0];

    onUpload(excelFile);
    closeModal();
  };

  return (
    <div>
      <ExcelUploader onChange={handleUploader} imageURL={null} />
    </div>
  );
}
