// import * as firebase from "firebase";

// import { FirebaseConfig } from "../config/keys";
// firebase.initializeApp(FirebaseConfig);

// export const db = firebase.firestore();
// export const storage = firebase.storage();
// const settings = {};
// db.settings(settings);

// export const authRef = firebase.auth();
// export const auth = firebase.auth;

// export const facebookProvider = new firebase.auth.FacebookAuthProvider();
// export const twitterProvider = new firebase.auth.TwitterAuthProvider();
// export const googleProvider = new firebase.auth.GoogleAuthProvider();

// import * as firebase from "firebase";
import { initializeApp } from 'firebase/app';
// import { getStorage } from 'firebase/storage';

import { getAuth } from 'firebase/auth';

import { getFirestore } from 'firebase/firestore';

import { FirebaseConfig } from './firebaseConfig';

const app = initializeApp(FirebaseConfig);

const dbAux = getFirestore(app);

const authAux = getAuth();

export const db = dbAux;

export const authRef = authAux;
export const auth = getAuth;
