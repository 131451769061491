import React from "react";
// import MetaTags from "react-meta-tags";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import WhatsappButton from "components/Buttons/WhatsappButton";
import BasicNavbar from "components/Navbars/BasicNavbar.jsx";

class DigitalOnboardingStep8Success extends React.Component {
  
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }

  render() {
    return (
      <>
        {/* <MetaTags>
          <title>El plan para tu seguro de salud | Hola René</title>
          <meta
            id="meta-description"
            name="description"
            content="Toda la información para que elijas el plan de salud ideal para vos y tu familia."
          />
        </MetaTags> */}

        <WhatsappButton />
        <BasicNavbar />

        <main ref="main">
          <section className="section section-shaped bg-light-grey">
            <Container>
              <Row className="justify-content-center">
                <Col lg="8">
                  <Card className="bg-transparent card-form border-0">
                    <CardHeader className="pb-0 bg-transparent">
                      <img
                        alt="..."
                        className="img-center"
                        style={{}}
                        src={require("assets/img/brand/success.png")}
                      />
                      <div className="big-title text-center">
                        Felicitaciones, tu familia está protegida!
                      </div>
                    </CardHeader>
                    <CardBody>
                      <p className="text-end">
                        Te mandamos un mail con toda la información que
                        necesitas. Si no lo ves, revisá en “correo no deseado”
                      </p>
                      <p className="text-end">
                        Bajate la app y empezá a disfrutar de tu cobertura.
                      </p>
                      <div className="text-center">
                        <p className="text-end phone-end">
                          Si tenés dudas escribinos por{" "}
                          <a
                            href="https://wa.me/+5491122929353?text=Hola%20René,%20me%20podrías%20dar%20más%20información%20por%20favor?"
                            style={{ color: "#24A46D" }}
                          >
                            WhatsApp
                          </a>
                        </p>
                      </div>
                    </CardBody>

                    <div className="text-center">
                      <Button
                        color="custom noarrow"
                        type="button"
                        onClick={(event) => {
                          event.preventDefault();

                          this.props.history.push("/get-app");
                        }}
                      >
                        Descargar app
                      </Button>
                    </div>
                  </Card>
                </Col>
              </Row>
            </Container>
          </section>
        </main>
      </>
    );
  }
}

export default DigitalOnboardingStep8Success;
