import React, { useReducer, useContext, createContext } from 'react';
import { reducer, leadItemsTotalPrice } from './lead.reducer';

const LeadContext = createContext({});
const isDevelopment = false;
const INITIAL_STATE = {
  isOpen: false,
  items: [],
  selectedDeliveryDate: {},
  paymentData: {
    paymentMethod: 'CASH_ON_DELIVERY',
    identificationType: 'DNI',
    identificationNumber: isDevelopment ? '32659430' : '',
    creditCard: {
      cvc: isDevelopment ? '1234' : '',
      expiry: isDevelopment ? '11/25' : '',

      name: isDevelopment ? 'APRO MICHE' : '',
      number: isDevelopment ? '3711 8030 3257 522' : '',
      issuer: isDevelopment ? 'amex' : '',
    },
  },
  coupon: null,
};

const useLeadActions = (initialLead = INITIAL_STATE) => {
  const [state, dispatch] = useReducer(reducer, initialLead);

  const setPaymentDataHandler = (paymentData) => {
    dispatch({
      type: 'SET_PAYMENT_DATA',
      payload: paymentData,
    });
  };

  const addItemHandler = (item, quantity = 1) => {
    dispatch({ type: 'ADD_ITEM', payload: { ...item, quantity } });
  };

  const removeItemHandler = (item, quantity = 1) => {
    dispatch({ type: 'REMOVE_ITEM', payload: { ...item, quantity } });
  };

  const clearItemFromLeadHandler = (item) => {
    dispatch({ type: 'CLEAR_ITEM_FROM_CART', payload: item });
  };

  const clearLeadHandler = () => {
    dispatch({ type: 'CLEAR_CART', initialState: INITIAL_STATE });
  };
  const toggleLeadHandler = () => {
    dispatch({ type: 'TOGGLE_CART' });
  };
  const couponHandler = (coupon) => {
    dispatch({ type: 'APPLY_COUPON', payload: coupon });
  };
  const removeCouponHandler = () => {
    dispatch({ type: 'REMOVE_COUPON' });
  };
  const rehydrateLocalState = (payload) => {
    dispatch({ type: 'REHYDRATE', payload });
  };

  const getLeadItemsPrice = () => leadItemsTotalPrice(state.items).toFixed(2);
  const getLeadItemsTotalPrice = () => leadItemsTotalPrice(state.items, state.coupon).toFixed(2);

  return {
    state,

    rehydrateLocalState,

    setPaymentDataHandler,
    addItemHandler,
    removeItemHandler,
    clearItemFromLeadHandler,
    clearLeadHandler,
    toggleLeadHandler,
    getLeadItemsTotalPrice,
    getLeadItemsPrice,
    couponHandler,
    removeCouponHandler,
  };
};

export const LeadProvider = ({ children }) => {
  const {
    state,

    setPaymentDataHandler,
    addItemHandler,
    removeItemHandler,
    clearItemFromLeadHandler,
    clearLeadHandler,
    toggleLeadHandler,
    getLeadItemsTotalPrice,
    couponHandler,
    removeCouponHandler,
    getLeadItemsPrice,
  } = useLeadActions();

  return (
    <LeadContext.Provider
      value={{
        isOpen: state.isOpen,
        items: state.items,
        selectedDeliveryDate: state.selectedDeliveryDate,
        paymentData: state.paymentData,
        coupon: state.coupon,

        setPaymentData: setPaymentDataHandler,
        addItem: addItemHandler,
        removeItem: removeItemHandler,
        removeItemFromLead: clearItemFromLeadHandler,
        clearLead: clearLeadHandler,
        toggleLead: toggleLeadHandler,
        calculatePrice: getLeadItemsTotalPrice,
        calculateSubTotalPrice: getLeadItemsPrice,
        applyCoupon: couponHandler,
        removeCoupon: removeCouponHandler,
      }}
    >
      {children}
    </LeadContext.Provider>
  );
};

export const useLead = () => useContext(LeadContext);
