import { getHttpBearerConfig } from 'services/httpServices';
import { getRelationshipList } from 'helpers/coreHelper';
import axios from 'axios';

const appConfig = require('config/appConfig');

export const listCoverages = (currentUserToken) => {
  return new Promise(function (resolve, reject) {
    //exports.saveRole = function(currentUserToken, targetUser, role) {
    let config = getHttpBearerConfig(currentUserToken);

    return axios
      .get(`${appConfig.gcfUrl}/coverages`, config)
      .then((response) => {
        console.log('coverages response:', response);
        if (response && response.data) resolve(response.data);
        else resolve([]);
      })
      .catch((e) => {
        console.log('error:', e);

        reject(e);
      });
  });
};

export const createCoverage = (currentUserToken, coverage) => {
  return new Promise(function (resolve, reject) {
    //exports.saveRole = function(currentUserToken, targetUser, role) {
    let config = getHttpBearerConfig(currentUserToken);

    const data = {
      name: coverage.name,
      description: coverage.description,
      contractId: coverage.contractId,
      medics: getMedicRelationshipList(coverage.medics) || null,
      emergencies: getRelationshipList(coverage.emergencies) || null,
      homeCares: getRelationshipList(coverage.homeCares) || null,
      telemedicines: getRelationshipList(coverage.telemedicines) || null,
      lifeInsurances: getRelationshipList(coverage.lifeInsurances) || null,
    };

    return axios
      .post(`${appConfig.gcfUrl}/coverages`, data, config)
      .then((response) => {
        console.log('create coverage response:', response);
        if (response && response.data) resolve(response.data);
        else resolve(null);
      })
      .catch((e) => {
        console.log('error:', e);

        reject(e);
      });
  });
};

export const updateCoverage = (currentUserToken, coverage) => {
  return new Promise(function (resolve, reject) {
    let config = getHttpBearerConfig(currentUserToken);

    const data = {
      name: coverage.name,
      description: coverage.description,
      contractId: coverage.contractId,
      medics: getMedicRelationshipList(coverage.medics) || null,
      emergencies: getRelationshipList(coverage.emergencies) || null,
      homeCares: getRelationshipList(coverage.homeCares) || null,
      telemedicines: getRelationshipList(coverage.telemedicines) || null,
      lifeInsurances: getRelationshipList(coverage.lifeInsurances) || null,
    };

    return axios
      .patch(`${appConfig.gcfUrl}/coverages/${coverage.id}`, data, config)
      .then((response) => {
        console.log('update coverage response:', response);
        if (response && response.data) resolve(response.data);
        else resolve(null);
      })
      .catch((e) => {
        console.log('error:', e);

        reject(e);
      });
  });
};

export const deleteCoverage = (currentUserToken, coverage) => {
  return new Promise(function (resolve, reject) {
    let config = getHttpBearerConfig(currentUserToken);

    return axios
      .delete(`${appConfig.gcfUrl}/coverages/${coverage.id}`, config)
      .then((response) => {
        console.log('delete coverage response:', response);
        if (response && response.data) resolve(response.data);
        else resolve(null);
      })
      .catch((e) => {
        console.log('error:', e);

        reject(e);
      });
  });
};

function getMedicRelationshipList(list) {
  if (!list) return null;

  let newList = [];
  list.forEach((item) => {
    newList.push({
      id: item.id,
      name: item.name || null,
      photo: item.photo || null,
      specialities: item.specialities || null,
    });
  });

  return newList;
}
