class CustomError extends Error {
  constructor(code, httpCode, message, innerException) {
    super(message);
    this.name = 'CustomError';
    this.processed = false;
    this.httpCode = httpCode ? httpCode : 500;
    this.code = code;
    this.innerException = innerException;
  }

  setProcessed(b) {
    this.processed = b;
  }

  setHttpCode(httpCode) {
    this.httpCode = httpCode;
  }
}

class TechnicalError extends CustomError {
  constructor(code, httpCode, message, innerException) {
    super(code, httpCode || 500, message, innerException);
    this.name = 'TechnicalError';
  }
}

class BusinessError extends CustomError {
  constructor(code, httpCode, message, innerException) {
    super(code, httpCode || 400, message, innerException);
    this.name = 'BusinessError';
  }
}

class NotFoundBusinessError extends BusinessError {
  constructor(code, message, innerException) {
    super(code, 404, message, innerException);
  }
}

class NotFoundTechnicalError extends TechnicalError {
  constructor(code, message, innerException) {
    super(code, 404, message, innerException);
  }
}

class DuplicatedResourceBusinessError extends BusinessError {
  constructor(code, message, innerException) {
    super(code, 409, message, innerException);
  }
}

export {
  CustomError,
  TechnicalError,
  BusinessError,
  NotFoundBusinessError,
  NotFoundTechnicalError,
  DuplicatedResourceBusinessError,
};

// exports.CustomError = CustomError;
// exports.TechnicalError = TechnicalError;
// exports.BusinessError = BusinessError;
// exports.NotFoundBusinessError = NotFoundBusinessError;
// exports.DuplicatedResourceBusinessError = DuplicatedResourceBusinessError;
