import React from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Table,
  Container,
  Col,
  Row,
} from "reactstrap";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.jsx";

import { authRef } from "config/firebase";

import {
  listAssistances,
  createAssistance,
  updateAssistance,
  bulkUpsertDrappMedicBookingAssistances,
  lunchDrappGoogleSheetsMedicBookingAssistancesUpdate,
  lunchGAMWebServiceHomeAssistancesUpdate,
} from "services/assistancesServices";

import { listMedicsSpecialities } from "services/medicsServices";

import ImportExcelModal from "./ImportExcelModal.jsx";

import ExcelJS from "exceljs";

import { TYPE_MEDIC_BOOKING } from "types/productsTypes";
import { PROVIDER_TYPE_DRAPP } from "types/providersTypes";

const { STATE_ACTIVE } = require("types/stateTypes");

const {
  ASSISTANCE_RESULT_STATUS_RESOLVED,

  ASSISTANCE_RESULT_STATUS_UNKNOWN,
} = require("types/assistancesResultStatusTypes");

const {
  ASSISTANCE_STATE_IN_PROGRESS,
  ASSISTANCE_STATE_FINISHED,
  ASSISTANCE_STATE_CANCELED_BY_USER,
} = require("types/assistancesStateTypes");

class AssistancesOverview extends React.Component {
  constructor(props) {
    super(props);

    this.state = { assistances: null, coverages: null, addNewAssistance: {} };
  }

  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
    });
  };

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    authRef.onAuthStateChanged((user) => {
      this.setState({ currentUser: user });

      listAssistances(9999, 0, null)
        .then((assistances) => {
          console.log("assistances: ", assistances);
          assistances = assistances.items;

          assistances = assistances.sort(function (a, b) {
            let aDate = a.updatedAt ? a.updatedAt : a.createdAt;
            let bDate = b.updatedAt ? b.updatedAt : b.createdAt;

            aDate = new Date(aDate);
            bDate = new Date(bDate);

            return bDate.getTime() - aDate.getTime();
          });

          this.setState({ assistances: assistances });
        })
        .catch((e) => {
          if (e.response && e.response.data && e.response.data.message)
            alert(e.response.data.message);
          else alert(e.message);
        });

      listMedicsSpecialities()
        .then((specialities) => {
          this.setState({ specialities: specialities });
        })
        .catch((e) => {
          if (e.response && e.response.data && e.response.data.message)
            alert(e.response.data.message);
          else alert(e.message);
        });
    });
  }

  componentDidUpdate(e) {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    // this.refs.mainContent.scrollTop = 0;
  }

  saveAssistance(assistance) {
    console.log("assistance:", assistance);
    if (!assistance.name || !assistance.prices || !assistance.description) {
      alert("Faltan datos requeridos");
      return;
    }

    authRef.onAuthStateChanged((user) => {
      user.getIdToken().then((token) => {
        if (assistance.id)
          updateAssistance(token, assistance)
            .then((assistance) => {
              this.loadData();
              console.log("Assistance: ", assistance);
              alert("Assistance: " + JSON.stringify(assistance));
              this.toggleModal("assistanceEditionModal");
            })
            .catch((e) => {
              if (e.response && e.response.data && e.response.data.message)
                alert(e.response.data.message);
              else alert(e.message);
            });
        else
          createAssistance(token, assistance)
            .then((assistance) => {
              this.loadData();
              console.log("Assistance: ", assistance);
              alert("Assistance: " + JSON.stringify(assistance));
              this.toggleModal("assistanceEditionModal");
            })
            .catch((e) => {
              if (e.response && e.response.data && e.response.data.message)
                alert(e.response.data.message);
              else alert(e.message);
            });
      });
    });

    //alert("assistance recibido: " + assistance.name);
  }

  worksheetHasHeader(worksheet, headerName) {
    const headerValues = worksheet.getRow(1).values;

    let exist = false;
    for (let index = 1; index <= headerValues.length; index++) {
      if (headerValues[index] === headerName) exist = true;
    }
    return exist;
  }

  parseDRAPPAssistanceResultStatus(drappStatus) {
    if (drappStatus === "cancelled") return ASSISTANCE_RESULT_STATUS_UNKNOWN;

    return ASSISTANCE_RESULT_STATUS_RESOLVED;
  }

  onExcelUploadDrApp(excelFile) {
    if (!this.state.specialities) return;

    const workbook = new ExcelJS.Workbook();

    const assistances = [];
    let parseErrors = [];

    const arryBuffer = new Response(excelFile).arrayBuffer();

    const $this = this;
    arryBuffer.then((data) => {
      workbook.xlsx.load(data).then(async function () {
        // play with workbook and worksheet now

        //const worksheet = workbook.getWorksheet(1);
        const worksheet = workbook.worksheets[1];

        let database_index = 1;
        let practitionerID_index = 2;
        let fechaTurno_index = 3;
        let horaTurno_index = 4;
        let creado_index = 5;
        let nombrePaciente_index = 6;
        let apellidoPaciente_index = 7;
        let dniPaciente_index = 8;
        let telefonoPaciente_index = 9;
        let mailPaciente_index = 10;
        let profesional_index = 11;
        let especialidad_index = 12;
        let practica_index = 13;
        let direccion_index = 14;
        let arancel_index = 15;
        let aviso_index = 16;
        let estado_index = 17;
        let linkCancelacion_index = 18;

        const rowHeader_database = "database";
        const rowHeader_practitionerID = "practitionerID";

        const rowHeader_FechaTurno = "Fecha Turno";
        const rowHeader_HoraTurno = "Horario Turno";
        const rowHeader_Creado = "Creado";
        const rowHeader_NombrePaciente = "Nombre Paciente";
        const rowHeader_ApellidoPaciente = "Apellido Paciente";
        const rowHeader_DNIPaciente = "DNI Paciente";
        const rowHeader_TelefonoPaciente = "Telefono Paciente";
        const rowHeader_MailPaciente = "Mail Paciente";
        const rowHeader_Profesional = "Profesional";
        const rowHeader_Especialidad = "Especialidad";
        const rowHeader_Practica = "Practica";
        const rowHeader_Direccion = "Direccion";
        const rowHeader_Arancel = "Arancel";
        const rowHeader_Aviso = "Aviso";
        const rowHeader_Estado = "Estado";
        const rowHeader_LinkCancelacion = "Link para Cancelar";

        try {
          if (!$this.worksheetHasHeader(worksheet, rowHeader_database))
            throw new Error("No existe la columna " + rowHeader_database);

          if (!$this.worksheetHasHeader(worksheet, rowHeader_practitionerID))
            throw new Error("No existe la columna " + rowHeader_practitionerID);

          if (!$this.worksheetHasHeader(worksheet, rowHeader_FechaTurno))
            throw new Error("No existe la columna " + rowHeader_FechaTurno);

          if (!$this.worksheetHasHeader(worksheet, rowHeader_HoraTurno))
            throw new Error("No existe la columna " + rowHeader_HoraTurno);

          if (!$this.worksheetHasHeader(worksheet, rowHeader_Creado))
            throw new Error("No existe la columna " + rowHeader_Creado);

          if (!$this.worksheetHasHeader(worksheet, rowHeader_NombrePaciente))
            throw new Error("No existe la columna " + rowHeader_NombrePaciente);

          if (!$this.worksheetHasHeader(worksheet, rowHeader_ApellidoPaciente))
            throw new Error(
              "No existe la columna " + rowHeader_ApellidoPaciente
            );

          if (!$this.worksheetHasHeader(worksheet, rowHeader_DNIPaciente))
            throw new Error("No existe la columna " + rowHeader_DNIPaciente);

          if (!$this.worksheetHasHeader(worksheet, rowHeader_TelefonoPaciente))
            throw new Error(
              "No existe la columna " + rowHeader_TelefonoPaciente
            );

          if (!$this.worksheetHasHeader(worksheet, rowHeader_MailPaciente))
            throw new Error("No existe la columna " + rowHeader_MailPaciente);

          if (!$this.worksheetHasHeader(worksheet, rowHeader_Profesional))
            throw new Error("No existe la columna " + rowHeader_Profesional);

          if (!$this.worksheetHasHeader(worksheet, rowHeader_Especialidad))
            throw new Error("No existe la columna " + rowHeader_Especialidad);

          if (!$this.worksheetHasHeader(worksheet, rowHeader_Practica))
            throw new Error("No existe la columna " + rowHeader_Practica);

          if (!$this.worksheetHasHeader(worksheet, rowHeader_Direccion))
            throw new Error("No existe la columna " + rowHeader_Direccion);

          if (!$this.worksheetHasHeader(worksheet, rowHeader_Arancel))
            throw new Error("No existe la columna " + rowHeader_Arancel);

          if (!$this.worksheetHasHeader(worksheet, rowHeader_Aviso))
            throw new Error("No existe la columna " + rowHeader_Aviso);

          if (!$this.worksheetHasHeader(worksheet, rowHeader_Estado))
            throw new Error("No existe la columna " + rowHeader_Estado);

          if (!$this.worksheetHasHeader(worksheet, rowHeader_LinkCancelacion))
            throw new Error(
              "No existe la columna " + rowHeader_LinkCancelacion
            );
        } catch (e) {
          $this.setState({ error: e });
          console.log("Values de columnas:", worksheet.getRow(1).values);
          $this.setState({ loading: false });
          return;
        }
        worksheet.eachRow((row, rowNumber) => {
          //primera fila = header
          if (rowNumber === 1) {
            const rowValues = row.values;
            for (let index = 1; index <= rowValues.length; index++) {
              const element = rowValues[index];
              if (element === rowHeader_database) database_index = index;
              if (element === rowHeader_practitionerID)
                practitionerID_index = index;

              if (element === rowHeader_FechaTurno) fechaTurno_index = index;

              if (element === rowHeader_HoraTurno) horaTurno_index = index;

              if (element === rowHeader_Creado) creado_index = index;

              if (element === rowHeader_NombrePaciente)
                nombrePaciente_index = index;

              if (element === rowHeader_ApellidoPaciente)
                apellidoPaciente_index = index;

              if (element === rowHeader_DNIPaciente) dniPaciente_index = index;

              if (element === rowHeader_TelefonoPaciente)
                telefonoPaciente_index = index;

              if (element === rowHeader_MailPaciente)
                mailPaciente_index = index;

              if (element === rowHeader_Profesional) profesional_index = index;

              if (element === rowHeader_Especialidad)
                especialidad_index = index;

              if (element === rowHeader_Practica) practica_index = index;

              if (element === rowHeader_Direccion) direccion_index = index;

              if (element === rowHeader_Arancel) arancel_index = index;

              if (element === rowHeader_Aviso) aviso_index = index;

              if (element === rowHeader_Estado) estado_index = index;

              if (element === rowHeader_LinkCancelacion)
                linkCancelacion_index = index;
            }

            return;
          }

          try {
            const practitionerID = row.getCell(practitionerID_index).text;

            //salteo los vacíos
            if (practitionerID === "") return;

            const database = row.getCell(database_index).text;
            const eventDateAux = row.getCell(fechaTurno_index).text;
            const eventHourAux = row.getCell(horaTurno_index).text;
            const eventCreationDateAux = row.getCell(creado_index).text;
            const patientFirstName = row.getCell(nombrePaciente_index).text;
            const patientLastName = row.getCell(apellidoPaciente_index).text;
            const patientIdentificationNumber =
              row.getCell(dniPaciente_index).text;
            const patientPhoneNumber = row.getCell(telefonoPaciente_index).text;
            const patientEmail = row.getCell(mailPaciente_index).text;
            const professionalName = row.getCell(profesional_index).text;

            const drappSpeciality = row.getCell(especialidad_index).text;
            const mappedProfessionalSpeciality =
              $this.getSpecialityByDrappEquivalence(drappSpeciality);

            const drappMedicalPractice = row.getCell(practica_index).text;
            const professionalAddress = row.getCell(direccion_index).text;
            const professionalPrice = row.getCell(arancel_index).text;
            const professionalMessage = row.getCell(aviso_index).text;

            const assistanceStateAux = row.getCell(estado_index).text;
            const assistanceResultStatus =
              $this.parseDRAPPAssistanceResultStatus(assistanceStateAux);

            let assistanceState = ASSISTANCE_STATE_IN_PROGRESS;

            if (assistanceStateAux === "cancelled")
              assistanceState = ASSISTANCE_STATE_CANCELED_BY_USER;
            else if (assistanceStateAux === "pending")
              assistanceState = ASSISTANCE_STATE_IN_PROGRESS;

            const assistanceCancelationUrl = row.getCell(
              linkCancelacion_index
            ).text;

            const eventDate = $this.parseDrappDate(eventDateAux, eventHourAux);
            const eventCreationDate = $this.parseDrappDate(
              eventCreationDateAux,
              null
            );

            const assistance = {
              user: null, //se completa en el servicio
              patient: {
                firstName: patientFirstName,
                lastName: patientLastName,
                identificationNumber: patientIdentificationNumber,
                phoneNumber: patientPhoneNumber,
                email: patientEmail,
              },
              professional: {
                externalId: practitionerID,

                practitionerID: practitionerID,
                database: database,

                name: professionalName,

                speciality: mappedProfessionalSpeciality,
                specialities: mappedProfessionalSpeciality
                  ? [mappedProfessionalSpeciality]
                  : [],
                drappSpeciality: drappSpeciality,
                address: professionalAddress,
                price: professionalPrice,

                coordinate: null,
                url: null,
                phoneNumber: null,
                photo: null,
                description: null,
                drappSpecialities: null,
                rawSpeciality: drappSpeciality,
              },
              assistanceResult: {
                message: professionalMessage,
                medicalPractice: drappMedicalPractice,
                status: assistanceResultStatus,
              },
              assistancePrice: professionalPrice,
              assistanceState: assistanceState,
              eventDate: eventDate,
              eventCreationDate: eventCreationDate,

              cancelationUrl: assistanceCancelationUrl,
              type: TYPE_MEDIC_BOOKING,
              label: PROVIDER_TYPE_DRAPP,
              provider: PROVIDER_TYPE_DRAPP,
            };

            assistances.push(assistance);
          } catch (err) {
            debugger;
            parseErrors.push({ rowNumber: rowNumber, message: err.message });
          }
        });

        if (parseErrors && parseErrors.length !== 0) {
          let errorString = "";
          parseErrors.forEach((element) => {
            errorString +=
              "Fila: " +
              element.rowNumber +
              " - Error: " +
              element.message +
              "\n";
          });
          $this.setState({ error: { message: errorString } });

          return;
        }

        if (!assistances || assistances.length === 0) {
          alert("No hay medics a importar");
          return;
        }

        console.log("assistances to update:", assistances);
        $this.setState({ loading: true });

        try {
          let requestData = {
            assistances: assistances,
          };

          await bulkUpsertDrappMedicBookingAssistances(requestData);
        } catch (e) {
          $this.setState({ error: e });
        }
        $this.setState({ loading: false });
        window.location.reload();
      });
    });
  }

  parseDrappDate(date, hours) {
    let result = null;

    const hsAux = this.parseDrappHours(hours);

    let splittedDate = date.split("/");

    if (splittedDate.length === 3) {
      const day = parseInt(splittedDate[0]);
      const month = parseInt(splittedDate[1]);
      const year = parseInt(splittedDate[2]);

      result = new Date(year, month, day - 1, hsAux.h, hsAux.m, 0, 0);
    } else {
      result = new Date(date);
      result.setUTCHours(hsAux.h, hsAux.m);
    }

    return result;
  }

  parseDrappHours(hours) {
    if (!hours) return { h: 0, m: 0 };

    let splittedHours = null;

    if (hours.split(":").length === 2) {
      splittedHours = hours.split(":");
      let h = 0;
      let m = 0;
      if (splittedHours) {
        h = parseInt(splittedHours[0]);
        m = parseInt(splittedHours[1]);
      }

      return { h, m };
    } else {
      const aux = new Date(hours);
      return { h: aux.getUTCHours(), m: aux.getUTCMinutes() };
    }
  }

  renderAssistancesTable(title, assistances) {
    return (
      <Row>
        <div className="col">
          <Card className="shadow">
            <CardHeader className="border-0">
              <Row>
                <Col lg="12">
                  <h3 className="mb-0">{title}</h3>
                </Col>
              </Row>
            </CardHeader>
            {!assistances && <div>Loading</div>}
            {assistances && (
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Id</th>
                    <th scope="col">Usuario</th>
                    <th scope="col">Paciente</th>

                    <th scope="col">Estado</th>
                    <th scope="col">Tipo</th>
                    <th scope="col">Resultado</th>
                    <th scope="col">Last update</th>

                    <th scope="col" />
                  </tr>
                </thead>
                <tbody>
                  {assistances.map((assistance, index) => {
                    return (
                      <tr key={index}>
                        <th scope="row">{assistance.id}</th>

                        <td>
                          <span className="mb-0 text-sm">
                            {assistance.user
                              ? assistance.user.firstName +
                                " " +
                                assistance.user.lastName
                              : assistance.user_id}
                          </span>
                        </td>
                        <td>
                          <span className="mb-0 text-sm">
                            {assistance.patient
                              ? assistance.patient.firstName +
                                " " +
                                assistance.patient.lastName
                              : null}
                          </span>
                        </td>
                        <td>{assistance.assistanceState}</td>
                        <td>{assistance.type}</td>
                        <td>
                          {assistance.assistanceResult
                            ? assistance.assistanceResult.status
                            : "-"}
                        </td>

                        <td>
                          {assistance.updatedAt
                            ? assistance.updatedAt
                            : assistance.createdAt}
                        </td>

                        <td className="text-right">
                          <UncontrolledDropdown>
                            <DropdownToggle
                              className="btn-icon-only text-light"
                              href="#"
                              role="button"
                              size="sm"
                              color=""
                              onClick={(e) => e.preventDefault()}
                            >
                              <i className="fas fa-ellipsis-v" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow" right>
                              <DropdownItem
                                onClick={(e) => {
                                  e.preventDefault();

                                  this.props.history.push(
                                    "/assistance-detail/" + assistance.id
                                  );
                                }}
                              >
                                Detail
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            )}{" "}
          </Card>
        </div>
      </Row>
    );
  }

  getSpecialityByDrappEquivalence(drappSpeciality) {
    const spec = this.state.specialities.find((spec) => {
      return (
        spec &&
        spec.equivalences &&
        spec.equivalences.drappExcel === drappSpeciality
      );
    });
    if (spec) return spec;
    return null;
  }

  lunchDrappGoogleSheetsMedicBookingAssistancesUpdate() {
    this.setState({ loading: true });
    lunchDrappGoogleSheetsMedicBookingAssistancesUpdate()
      .then((data) => {
        alert("OK");
        window.location.reload();
      })
      .catch((e) => {
        console.log("Error", e);
        alert("Hubo un error al procesar " + e.message);
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  lunchGAMWebServiceHomeAssistancesUpdate() {
    this.setState({ loading: true });
    lunchGAMWebServiceHomeAssistancesUpdate()
      .then((data) => {
        alert("OK");
        window.location.reload();
      })
      .catch((e) => {
        console.log("Error", e);
        alert("Hubo un error al procesar " + e.message);
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  render() {
    if (this.state.loading) return <>loading</>;
    if (this.state.error) return <>{this.state.error.message}</>;

    if (!this.state.assistances) return <>loading</>;
    let pendingAssistances = this.state.assistances.filter((p) => {
      return (
        p.state === STATE_ACTIVE &&
        p.assistanceState !== ASSISTANCE_STATE_FINISHED &&
        p.assistanceState !== ASSISTANCE_STATE_CANCELED_BY_USER
      );
    });

    let finishedAssistances = this.state.assistances.filter((p) => {
      return (
        p.state === STATE_ACTIVE &&
        (p.assistanceState === ASSISTANCE_STATE_FINISHED ||
          p.assistanceState === ASSISTANCE_STATE_CANCELED_BY_USER)
      );
    });

    return (
      <>
        <div className="main-content" ref="mainContent">
          <AdminNavbar {...this.props} currentUser={this.state.currentUser} />

          <ImportExcelModal
            modalName="ImportExcelModal"
            ImportExcelModalIsOpen={
              this.state.ImportDrappMedicBookingAssistancesExcel
            }
            toggleModal={this.toggleModal.bind(this)}
            onExcelUpload={this.onExcelUploadDrApp.bind(this)}
          />

          <Container fluid>
            <Row>
              <Col lg="9"></Col>
              <Col lg="3">
                <UncontrolledDropdown>
                  <DropdownToggle
                    href="#"
                    role="button"
                    size="lg"
                    color=""
                    onClick={(e) => e.preventDefault()}
                  >
                    Más
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-arrow" right>
                    <DropdownItem
                      onClick={(e) => {
                        e.preventDefault();
                        this.toggleModal(
                          "ImportDrappMedicBookingAssistancesExcel"
                        );
                      }}
                    >
                      Importar Drapp Turnos desde Excel
                    </DropdownItem>
                    <DropdownItem
                      onClick={(e) => {
                        e.preventDefault();
                        this.lunchDrappGoogleSheetsMedicBookingAssistancesUpdate();
                      }}
                    >
                      Actualizar Drapp Turnos desde GoogleSheets
                    </DropdownItem>

                    <DropdownItem
                      onClick={(e) => {
                        e.preventDefault();
                        this.lunchGAMWebServiceHomeAssistancesUpdate();
                      }}
                    >
                      Actualizar GAM Turnos desde WebService
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </Col>
            </Row>
            {this.renderAssistancesTable(
              "Pending assistances",
              pendingAssistances
            )}
            {this.renderAssistancesTable(
              "Finished assistances",
              finishedAssistances
            )}
          </Container>
        </div>
      </>
    );
  }
}

export default AssistancesOverview;
