/*eslint-disable*/
import React, { useState } from "react";
// reactstrap components
import { FormGroup, Input } from "reactstrap";

import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";

import PropagateLoader from "react-spinners/PropagateLoader";

function Loading({ ...props }) {
  const [isLoading, setIsLoading] = useState(false);

  const { loading } = props;

  return (
    <>
      <PropagateLoader
        css={"position: relative; display: block; margin: 0 auto; width: 1px; float: left; height: 20px;"}
        size={15}
        color={"#2f2c99"}
        loading={loading}
      />
    </>
  );
}

Loading.propTypes = {
  // classes: PropTypes.object.isRequired,
  labelText: PropTypes.node,
  errorLabelText: PropTypes.node,
  errorLabelProps: PropTypes.object,
  labelProps: PropTypes.object,
  id: PropTypes.string,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  inputRootCustomClasses: PropTypes.string,
  error: PropTypes.bool,
  success: PropTypes.bool,
  white: PropTypes.bool,
};

export default Loading;
