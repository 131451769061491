import React, { useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
// JavaScript plugin that hides or shows a component based on your scroll
import Headroom from 'headroom.js';
// reactstrap components
import {
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
} from 'reactstrap';

import { AuthContext } from 'contexts/auth/auth.context';

import { ROLE_ADMIN, ROLE_OPERATOR } from 'types/rolesTypes';

//class BasicNavbar extends React.Component {
const BasicNavbar = () => {
  const {
    authState: { authStatusReported, isAuthenticated, user, role },
  } = useContext(AuthContext);

  useEffect(() => {
    let headroom = new Headroom(document.getElementById('navbar-main'));
    // initialise
    headroom.init();
  }, []);

  let operatorName = '';

  if (!authStatusReported) {
    operatorName = '.'; //dejo este punto para interpretar ante un screen de un issue productivo
  } else if (
    isAuthenticated &&
    user &&
    role &&
    (role.includes(ROLE_ADMIN) || role.includes(ROLE_OPERATOR))
  ) {
    operatorName = ' - ' + user.displayName;
  }

  return (
    <>
      <header className="header-global">
        <Navbar
          className="bg-white navbar-main navbar-transparent navbar-light headroom navbar navbar-expand-lg headroom--top headroom--not-bottom"
          expand="lg"
          id="navbar-main"
        >
          <Container>
            <NavbarBrand className="mr-lg-5" to="/" tag={Link}>
              <NavLink className="user-logo" href="/">
                Hola <b>René</b> {operatorName}
              </NavLink>
            </NavbarBrand>
            <button className="navbar-toggler" id="navbar_global">
              <span className="navbar-toggler-icon" />
            </button>
            <UncontrolledCollapse navbar toggler="#navbar_global">
              <div className="navbar-collapse-header">
                <Row>
                  <Col className="collapse-brand" xs="6">
                    <Link to="/">
                      <img
                        alt="..."
                        style={{ width: 110, height: 80 }}
                        src={require('assets/img/brand/logo.png').default}
                      />
                    </Link>
                  </Col>
                  <Col className="collapse-close" xs="6">
                    <button className="navbar-toggler" id="navbar_global">
                      <span />
                      <span />
                    </button>
                  </Col>
                </Row>
              </div>
              <Nav className="navbar-nav-hover align-items-lg-center" navbar />
            </UncontrolledCollapse>
          </Container>
        </Navbar>
      </header>
    </>
  );
};

export default BasicNavbar;
