import React from 'react';
// import MetaTags from "react-meta-tags";

// reactstrap components
import { Button, Card, CardHeader, CardBody, Container, Row, Col } from 'reactstrap';

import {
  getUserToken,
  validateHasValue,
  setPersistedValue,
  getPersistedValue,
  goToFatalErrorPage,
} from 'helpers/coreHelper';

// core components
import WhatsappButton from 'components/Buttons/WhatsappButton';
import BasicNavbar from 'components/Navbars/BasicNavbar.jsx';

import { updatePhoneNumberValidated } from 'services/leadsServices';
import CustomInput from 'components/Inputs/CustomInput.jsx';
import Loading from 'components/Loading/Loading.jsx';
import DigitalOnboardingStepper from './DigitalOnboardingStepper';
class DigitalOnboardingValidateSMS extends React.Component {
  totalCountdown = 40;
  constructor(props) {
    super(props);

    let lead = null;

    try {
      lead = getPersistedValue('lead');
    } catch (e) {}

    if (!lead && this.props.location && this.props.location.state && this.props.location.state.lead)
      lead = this.props.location.state.lead;

    this.state = {
      lead: lead,
      //confirmationResult: this.props.location.state.confirmationResult,
      verificationCode: '',
      smsCountDown: this.totalCountdown,
      errors: ['verificationCode'],
      codeRejected: false,

      loading: false,
    };
  }

  downloadTimer = null;
  componentDidMount() {
    let $this = this;
    var timeleft = this.totalCountdown;
    //window.confirmationResult es null cuando hace un F5
    if (!window.confirmationResult || this.state.lead.phoneNumberValidated) {
      this.props.history.goBack();
    } else {
      $this.downloadTimer = setInterval(function () {
        if (timeleft < 0) {
          clearInterval($this.downloadTimer);
        } else {
          $this.setState({ smsCountDown: timeleft });
          //document.getElementById("progressBar").value = 10 - timeleft;
          timeleft -= 1;
        }
      }, 1000);
    }
  }

  validateFormData(verificationCode) {
    let errors = [];

    if (!verificationCode) verificationCode = this.state.verificationCode;

    if (!validateHasValue(verificationCode)) errors.push('verificationCode');

    if (this.state.codeRejected) errors.push('verificationCode');
    if (errors.length === 0) errors = null;

    this.setState({ errors: errors });

    //console.log("errors:", errors);
    return errors;
  }

  verifySMS() {
    //TODO - Si hace F5 en la pantalla de sms pierde la fn confirmationResult

    if (window.confirmationResult) {
      this.setState({ loading: true });
      let $this = this;
      //this.state.confirmationResult
      window.confirmationResult
        .confirm(this.state.verificationCode)
        .then((result) => {
          this.state.lead.phoneNumberValidated = true;
          //this.setState({ lead: this.state.lead });

          this.innerUpdatePhoneNumberValidated(this.state.lead);
        })
        .catch(function (e) {
          // User couldn't sign in (bad verification code?)
          // ...
          $this.setState({ codeRejected: true, loading: false });

          console.log(e, e.message, e.code);
        });
    } else {
      this.state.lead.phoneNumberValidated = false;

      //this.props.history.push("/onboarding-step-2", {
      this.props.history.push('/onboarding', {
        lead: this.state.lead,
      });
    }
  }

  innerUpdatePhoneNumberValidated(lead) {
    this.setState({ loading: true });
    getUserToken()
      .then((token) => {
        updatePhoneNumberValidated(token, lead)
          .then(() => {
            setPersistedValue('lead', lead);

            this.props.history.push('/onboarding-step-2', {
              lead: lead,
            });
          })
          .catch((e) => {
            console.log(e, e.message, e.code);
            goToFatalErrorPage(this.props.history);
          });
      })
      .catch((e) => {
        console.log(e, e.message, e.code);
        goToFatalErrorPage(this.props.history);
      });
  }

  render() {
    return (
      <>
        {/* <MetaTags>
          <title>El plan para tu seguro de salud | Hola René</title>
          <meta
            id="meta-description"
            name="description"
            content="Toda la información para que elijas el plan de salud ideal para vos y tu familia."
          />
        </MetaTags> */}

        <WhatsappButton />
        <BasicNavbar />

        <main ref="main">
          <section className="section section-shaped bg-light-grey">
            <Container>
              <Row className="justify-content-center">
                <Col lg="3 px-sm-0">
                  <DigitalOnboardingStepper step={1} maxSteps={5} progress={25} />
                </Col>
                <Col lg="7">
                  <Loading loading={this.state.loading} />
                  <Card className="bg-transparent card-form border-0">
                    <CardHeader className="bg-transparents">
                      <div className="form-title">
                        <>Ingresá el código que te mandamos </>
                      </div>
                      <div className="form-subtitle">
                        <label>¿No te llegó todavía? </label>{' '}
                        {this.state.smsCountDown !== 0 ? (
                          <a
                            onClick={() => {
                              this.props.history.goBack();
                            }}
                            style={{ color: 'orange' }}
                          >
                            Esperá {this.state.smsCountDown.toString().replace('-', '')} segundos
                          </a>
                        ) : (
                          <a
                            onClick={() => {
                              //this.props.history.goBack();
                              //puenteo
                              this.props.history.push('/onboarding-step-2', {
                                lead: this.state.lead,
                              });
                            }}
                            style={{ color: 'blue' }}
                          >
                            Reenviar código
                          </a>
                        )}
                      </div>
                    </CardHeader>
                    <CardBody>
                      <CustomInput
                        labelText={'Código enviado al ' + this.state.lead.phoneNumber}
                        errorLabelText="Código incorrecto"
                        validationFn={validateHasValue}
                        error={this.state.codeRejected}
                        inputProps={{
                          pattern: '/d*',
                          type: 'number',
                          value: this.state.verificationCode,
                          onChange: (event) => {
                            this.state.verificationCode = event.target.value;

                            this.validateFormData(this.state.verificationCode);

                            this.setState({
                              verificationCode: this.state.verificationCode,
                              codeRejected: false,
                            });
                          },

                          placeholder: '',
                        }}
                      />

                      <label>
                        <span>
                          Si {this.state.lead.phoneNumber} no es tu teléfono, volvé a la pantalla
                          anterior para corregirlo
                        </span>
                      </label>

                      <div>
                        <Button
                          className="mt-4"
                          color="custom secondary noarrow"
                          type="button"
                          onClick={() => {
                            this.props.history.goBack();
                          }}
                        >
                          Atrás
                        </Button>

                        <Button
                          className="mt-4"
                          color="custom"
                          type="button"
                          onClick={() => {
                            this.verifySMS();
                          }}
                          disabled={
                            (this.state.errors && this.state.errors.length !== 0) ||
                            this.state.loading
                          }
                        >
                          Ver planes
                        </Button>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </section>
        </main>
      </>
    );
  }
}

export default DigitalOnboardingValidateSMS;
