import { getHttpBearerConfig } from 'services/httpServices';
import axios from 'axios';
import { getUserWithToken } from 'helpers/coreHelper';

const appConfig = require('config/appConfig');

export const listMedics = (limit, offset = 0, filters) => {
  return new Promise(function (resolve, reject) {
    let qs = '?offset=' + offset;

    if (limit) qs += '&limit=' + limit;

    getUserWithToken().then((uwt) => {
      let config = getHttpBearerConfig(uwt.token);

      return axios
        .get(`${appConfig.gcfUrl}/medics${qs}`, config)
        .then((response) => {
          console.log('medics response:', response);
          if (response && response.data) resolve(response.data);
          else resolve([]);
        })
        .catch((e) => {
          console.log('error:', e);

          reject(e);
        });
    });
  });
};

export const listMedicsSpecialities = () => {
  return new Promise(function (resolve, reject) {
    getUserWithToken().then((uwt) => {
      let config = getHttpBearerConfig(uwt.token);

      return axios
        .get(`${appConfig.gcfUrl}/medics/specialities`, config)
        .then((response) => {
          console.log('medics response:', response);
          if (response && response.data) resolve(response.data);
          else resolve([]);
        })
        .catch((e) => {
          console.log('error:', e);

          reject(e);
        });
    });
  });
};

export const bulkUpsertDrappMedics = (medics) => {
  return new Promise(function (resolve, reject) {
    getUserWithToken().then((uwt) => {
      let config = getHttpBearerConfig(uwt.token);

      let data = medics;

      return axios
        .post(`${appConfig.gcfUrl}/medics/bulk-upsert-drapp-medics`, data, config)
        .then((response) => {
          console.log('create medics response:', response);
          if (response && response.data) resolve(response.data);
          else resolve(null);
        })
        .catch((e) => {
          console.log('error:', e);

          reject(e);
        });
    });
  });
};

export const createMedic = (currentUserToken, medic) => {
  return new Promise(function (resolve, reject) {
    //exports.saveRole = function(currentUserToken, targetUser, role) {
    let config = getHttpBearerConfig(currentUserToken);

    return axios
      .post(
        `${appConfig.gcfUrl}/medics`,
        {
          name: medic.name,
          description: medic.description,
          phoneNumber: medic.phoneNumber,
          address: medic.address,
          photo: medic.photo,
          coordinate: medic.coordinate,
          specialities: medic.specialities,
        },
        config
      )
      .then((response) => {
        console.log('create medic response:', response);
        if (response && response.data) resolve(response.data);
        else resolve(null);
      })
      .catch((e) => {
        console.log('error:', e);

        reject(e);
      });
  });
};

export const updateMedic = (currentUserToken, medic) => {
  return new Promise(function (resolve, reject) {
    //exports.saveRole = function(currentUserToken, targetUser, role) {
    let config = getHttpBearerConfig(currentUserToken);

    return axios
      .patch(
        `${appConfig.gcfUrl}/medics/${medic.id}`,
        {
          name: medic.name,
          description: medic.description,
          phoneNumber: medic.phoneNumber,
          address: medic.address,
          photo: medic.photo,
          coordinate: medic.coordinate,
          specialities: medic.specialities,
        },
        config
      )
      .then((response) => {
        console.log('update medic response:', response);
        if (response && response.data) resolve(response.data);
        else resolve(null);
      })
      .catch((e) => {
        console.log('error:', e);

        reject(e);
      });
  });
};

export const deleteMedic = (currentUserToken, medic) => {
  return new Promise(function (resolve, reject) {
    let config = getHttpBearerConfig(currentUserToken);

    return axios
      .delete(`${appConfig.gcfUrl}/medics/${medic.id}`, config)
      .then((response) => {
        console.log('delete medic response:', response);
        if (response && response.data) resolve(response.data);
        else resolve(null);
      })
      .catch((e) => {
        console.log('error:', e);

        reject(e);
      });
  });
};

export const drAppSync = () => {
  return new Promise(function (resolve, reject) {
    getUserWithToken().then((uwt) => {
      let config = getHttpBearerConfig(uwt.token);

      console.log('adsdsa2222');
      return axios
        .get(`${appConfig.gcfUrl}/medics/dr-app-sync`, config)
        .then((response) => {
          if (!response || !response.data) throw new Error('sin respuesta del sync de dr app');

          resolve(response.data);
        })
        .catch((e) => {
          console.log('error:', e);

          reject(e);
        });
    });
  });
};

export const lunchDrappGoogleSheetsMedicsUpdate = () => {
  return new Promise(function (resolve, reject) {
    getUserWithToken().then((uwt) => {
      let config = getHttpBearerConfig(uwt.token);

      let data = null;

      return axios
        .post(`${appConfig.gcfUrl}/medics/sync-drapp-medics-from-googlesheets`, data, config)
        .then((response) => {
          console.log('create medics response:', response);
          if (response && response.data) resolve(response.data);
          else resolve(null);
        })
        .catch((e) => {
          console.log('error:', e);

          reject(e);
        });
    });
  });
};

export const lunchRedsomMedicsUpdate = () => {
  return new Promise(function (resolve, reject) {
    getUserWithToken().then((uwt) => {
      let config = getHttpBearerConfig(uwt.token);

      let data = null;

      return axios
        .post(`${appConfig.gcfUrl}/medics/sync-redsom-medics-from-api`, data, config)
        .then((response) => {
          console.log('create medics response:', response);
          if (response && response.data) resolve(response.data);
          else resolve(null);
        })
        .catch((e) => {
          console.log('error:', e);

          reject(e);
        });
    });
  });
};
