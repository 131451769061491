exports.getHttpBearerConfig = function (token) {
  let config = {};
  if (token)
    config = {
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        Accept: `application/vnd.iman.v1+json, application/json, text/plain, */*`,
        'App-Version': 1,
      },
    };
  else
    config = {
      headers: {
        'Content-Type': 'application/json',
        Accept: `application/vnd.iman.v1+json, application/json, text/plain, */*`,
        'App-Version': 1,
      },
    };
  return config;
};
