import { authRef } from 'config/firebase';
import React from 'react';

import { GoogleAuthProvider as googleProvider } from 'firebase/auth';

import { getAuth, signInWithEmailAndPassword, signInWithPopup } from 'firebase/auth';

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Row,
} from 'reactstrap';

// core components
import SimpleFooter from 'components/Footers/SimpleFooter.jsx';
import BasicNavbar from 'components/Navbars/BasicNavbar.jsx';

import { ROLE_ADMIN, ROLE_MEDIC, ROLE_OPERATOR } from 'types/rolesTypes';

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      termsChecked: true,
      registrationPassword: '',
      registrationEmail: '',
    };
  }

  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }

  async signIn(provider) {
    //  async const signIn = provider => {
    //const providerId = provider.providerId;
    //registerEventAnonymous(idetity, "I", null, "Pre Login");

    const auth = getAuth();

    signInWithPopup(auth, provider)
      .then((result) => {
        result.user.isLoggedIn = true;
        console.log('result', result);
        console.log('result.user', result.user);

        this.onSuccessLogIn(result.user);
      })
      .catch((err) => {
        console.log('signInWithPopup error:', err);
        alert(err.message);
      });
  }

  signInBasicCredentials(email, pass) {
    return new Promise(function (resolve, reject) {
      const auth = getAuth();

      signInWithEmailAndPassword(auth, email, pass)
        .then((result) => {
          resolve(result);
        })
        .catch((e) => {
          reject(e);
        });
    });
  }

  onSuccessLogIn(user) {
    console.log('user:', user);

    authRef.currentUser
      .getIdTokenResult()
      .then((idTokenResult) => {
        console.log('idTokenResult:', idTokenResult);

        if (idTokenResult.claims.role && idTokenResult.claims.role.includes(ROLE_ADMIN)) {
          this.props.history.push('/users');
        } else if (idTokenResult.claims.role && idTokenResult.claims.role.includes(ROLE_OPERATOR)) {
          this.props.history.push('/users');
        } else if (idTokenResult.claims.role && idTokenResult.claims.role.includes(ROLE_MEDIC)) {
          this.props.history.push('/users');
        } else this.props.history.push('/');
      })
      .catch((error) => {
        console.log(error);
      });
  }

  internalSignInBasicCredentials(event) {
    event.preventDefault();
    if (this.state.registrationPassword === '' || this.state.registrationEmail === '') {
      this.setState({
        noticeModal: true,
        signupModal: false,
        errorCode: 'user-password-required',
        functionalError: true,
        errorTitle: 'Campo requerido',
        errorMessage: 'Necesitamos que completes todos los datos para poder ingresar',
      });
      return;
    }

    this.setState({ loadingExternaLogin: true });
    this.signInBasicCredentials(this.state.registrationEmail, this.state.registrationPassword)
      .then((user) => {
        this.onSuccessLogIn(user);

        this.setState({ loadingExternaLogin: false });
      })
      .catch((e) => {
        console.log('Login error', e);

        this.loginError(e);
      });
  }

  loginError(error) {
    alert(error.code);

    // var errorCode = error.code;
    // var errorMessage = error.message;
    // if (errorCode == "auth/account-exists-with-different-credential") {
    //   this.setState({
    //     noticeModal: true,
    //     errorCode: errorCode,
    //     functionalError: true,
    //     errorTitle: "Autenticación duplicada",
    //     errorMessage:
    //       "Ingresaste previamente con otro mecanismo de autenticación. Por favor volvé a intentar.",
    //     loadingExternaLogin: false
    //   });
    // } else {
    //   this.setState({
    //     noticeModal: true,
    //     signupModal: false,
    //     errorCode,
    //     functionalError: false,
    //     errorMessage: errorMessage,
    //     loadingExternaLogin: false
    //   });
    // }
  }

  render() {
    return (
      <>
        <BasicNavbar />
        <main ref="main">
          <section className="section section-shaped section-lg">
            <div className="shape shape-style-1 bg-gradient-default">
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
            </div>
            <Container className="pt-lg-md">
              <Row className="justify-content-center">
                <Col lg="5">
                  <Card className="bg-secondary shadow border-0">
                    <CardHeader className="bg-white pb-5">
                      <div className="text-muted text-center mb-3">
                        <small>Sign in with</small>
                      </div>
                      <div className="btn-wrapper text-center">
                        <Button
                          className="btn-neutral btn-icon ml-1"
                          color="default"
                          onClick={(e) => this.signIn(googleProvider)}
                        >
                          <span className="btn-inner--icon mr-1">
                            <img alt="..." src={require('assets/img/icons/common/google.svg')} />
                          </span>
                          <span className="btn-inner--text">Google</span>
                        </Button>
                      </div>
                    </CardHeader>
                    <CardBody className="px-lg-5 py-lg-5">
                      <div className="text-center text-muted mb-4">
                        <small>Or sign in with credentials</small>
                      </div>
                      <Form role="form">
                        <FormGroup className="mb-3">
                          <InputGroup className="input-group-alternative">
                            <InputGroupText>
                              <i className="ni ni-email-83" />
                            </InputGroupText>

                            <Input
                              id="registrationEmail"
                              value={this.state.registrationEmail}
                              onChange={(e) => {
                                this.setState({
                                  registrationEmail: e.target.value,
                                });
                              }}
                              placeholder="Email"
                              type="email"
                            />
                          </InputGroup>
                        </FormGroup>
                        <FormGroup>
                          <InputGroup className="input-group-alternative">
                            <InputGroupText>
                              <i className="ni ni-lock-circle-open" />
                            </InputGroupText>

                            <Input
                              id="registrationPassword"
                              value={this.state.registrationPassword}
                              onChange={(e) => {
                                this.setState({
                                  registrationPassword: e.target.value,
                                });
                              }}
                              placeholder="Password"
                              type="password"
                              autoComplete="off"
                            />
                          </InputGroup>
                        </FormGroup>
                        {/* <div className="custom-control custom-control-alternative custom-checkbox">
                          <input
                            className="custom-control-input"
                            id=" customCheckLogin"
                            type="checkbox"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor=" customCheckLogin"
                          >
                            <span>Remember me</span>
                          </label>
                        </div> */}
                        <div className="text-center">
                          <Button
                            className="my-4"
                            color="primary"
                            type="button"
                            onClick={(ev) => {
                              ev.preventDefault();
                              this.internalSignInBasicCredentials(ev);
                            }}
                          >
                            Sign in
                          </Button>
                        </div>
                      </Form>
                    </CardBody>
                  </Card>
                  <Row className="mt-3">
                    <Col xs="6">
                      <a
                        className="text-light"
                        onClick={(e) => {
                          e.preventDefault();
                          this.props.history.push('/forgot-password');
                        }}
                      >
                        <small>Forgot password?</small>
                      </a>
                    </Col>
                    <Col className="text-right" xs="6">
                      <a
                        className="text-light"
                        onClick={(e) => {
                          e.preventDefault();
                          alert('Comming soon');
                        }}
                      >
                        <small>Create new account</small>
                      </a>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </section>
        </main>
        <SimpleFooter />
      </>
    );
  }
}

export default Login;
