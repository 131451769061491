exports.RELATIONSHIP_CHILDREN = {
  key: 'children',
  label: 'Hijo',
};
exports.RELATIONSHIP_PARTNER = {
  key: 'partner',
  label: 'Conyuge',
};

exports.RELATIONSHIP_OWNER = {
  key: 'owner',
  label: 'Titular',
};

exports.RELATIONSHIP_OTHER = {
  key: 'other',
  label: 'Integrante',
};
