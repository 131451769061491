import React from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";

// JavaScript plugin that hides or shows a component based on your scroll
import Headroom from "headroom.js";
// reactstrap components
import {
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
} from "reactstrap";

class ContactUsLandingNavbar extends React.Component {
  componentDidMount() {
    let headroom = new Headroom(document.getElementById("navbar-main"));
    // initialise
    headroom.init();
  }
  render() {
    return (
      <>
        <header className="header-global">
          <Navbar
            className="navbar-main navbar-transparent navbar-light headroom"
            expand="lg"
            id="navbar-main"
          >
            <Container>
              <NavbarBrand className="mr-lg-5" to="/" tag={Link}>
                <NavLink className="user-logo" href="/">
                  Hola <b>René</b>
                </NavLink>
              </NavbarBrand>
              <Nav className="align-items-lg-center ml-lg-auto" navbar>
                {!this.props.disablePlansButton && (
                  <NavItem>
                    <NavLink
                      href="/onboarding"
                      target="_self"
                      className="custom"
                    >
                      Ver planes
                    </NavLink>
                  </NavItem>
                )}
              </Nav>
            </Container>
          </Navbar>
        </header>
      </>
    );
  }
}

export default withRouter(ContactUsLandingNavbar);
