import React from 'react';

// reactstrap components

import WhatsappButton from 'components/Buttons/WhatsappButton';

class Policy extends React.Component {
  render() {
    return (
      <>
        <WhatsappButton />

        <div class="PolicyPage__Container-sc-2lu2df-2 bWrJEj">
          <div title="Proveedor externo" frameborder="0">
            <article class="document">
              <h1 class="title align-center">POLÍTICA DE PRIVACIDAD</h1>
              <ol class="list">
                <li class="item">
                  <p class="title">ANTECEDENTES PRELIMINARES</p>
                  <p class="text">
                    Al utilizar HolaRene, usted, como usuario, nos confía su información. Mediante
                    la presente política de privacidad (en adelante, la “Política”), informamos a
                    nuestros usuarios cómo tratamos su información personal.
                  </p>
                  <p class="text">
                    Si nuestra página web (<a href="https://holarene.com">www.holarene.com.ar</a>) o
                    nuestra Aplicación "HolaRene" para dispositivos móviles (en adelante, el
                    "Portal") contiene enlaces que conducen a la página web de otro proveedor,
                    nuestra Política no se aplica a la página web a la que se le remite.
                  </p>
                  <p class="text">
                    Esta Política aplica a todos los usuarios de nuestro Portal y es necesario
                    prestar su consentimiento expreso, libre e informado al momento de registrarse
                    como usuario de HolaRene. Por tanto, con su consentimiento a la siguiente
                    Política, usted autoriza a AR Solutions S.A.U. y da su conformidad a la
                    recopilación, procesamiento, uso y tratamiento de sus datos personales por
                    nuestra parte, de conformidad con la normativa vigente en Argentina y en
                    particular con la Ley 25.326 de Protección de Datos, así como cualquier norma
                    que la reglamente, modifique o reemplace (en adelante, la “Normativa”).
                  </p>
                  <p class="text">
                    Los usuarios garantizan y responden, en cualquier caso, sobre la veracidad,
                    exactitud, vigencia y autenticidad de la Información Personal facilitada, y se
                    comprometen a mantenerla debidamente actualizada.
                  </p>
                  <p class="text">
                    Por favor, tómese el tiempo suficiente para leer esta Política detenidamente.
                  </p>
                  <p class="text">
                    Si usted tiene cualquier pregunta sobre esta Política o el tratamiento que le
                    damos a sus datos personales, por favor escribanos un email a
                    <a href="mailto:miche@holarene.com">miche@holarene.com</a>.
                  </p>
                </li>
                <li class="item">
                  <p class="title">PRINCIPIOS QUE ORIENTAN LA POLÍTICA</p>
                  <p class="text">
                    HolaRene y sus sociedades vinculadas o relacionadas (en adelante las "Sociedades
                    Vinculadas") respeta y respetará sus derechos, aplicando y garantizando los
                    siguientes principios orientadores de la Política:
                  </p>
                  <ol class="sublist" type="a">
                    <li>
                      <p class="text">
                        Principio de Legalidad: En el tratamiento de la información, se dará
                        aplicación a las disposiciones vigentes y aplicables en la República
                        Argentina, que rigen el tratamiento de la misma y demás derechos
                        fundamentales conexos, incluyendo las disposiciones contractuales pactadas
                        con los usuarios.
                      </p>
                    </li>
                    <li>
                      <p class="text">
                        Principio de Libertad: El tratamiento de datos personales, sólo se llevará a
                        cabo con el consentimiento, previo, expreso e informado del usuario. Los
                        datos personales que no tengan el carácter de datos públicos, no podrán ser
                        obtenidos o divulgados sin previa autorización, o en ausencia de mandato
                        legal, estatutario, o judicial que releve el consentimiento.
                      </p>
                    </li>
                    <li>
                      <p class="text">
                        Principio de Finalidad: El tratamiento de la información que no tenga el
                        carácter de dato público, a los que tenga acceso y sean recopilados y
                        recogidos por HolaRene, estarán subordinados y atenderán una finalidad
                        legítima, la cual será informada al respectivo usuario de dicha información.
                      </p>
                    </li>
                    <li>
                      <p class="text">
                        Principio de Veracidad o Calidad: La información protegida sujeta a
                        tratamiento debe ser veraz, completa, exacta, actualizada, comprobable y
                        comprensible. HolaRene no será responsable frente al Titular cuando sean
                        objeto de tratamiento, datos o información parcial, incompleta o fraccionada
                        o que induzca a error, entregada o divulgada por el Usuario declarando o
                        garantizando, de cualquier forma, su veracidad o calidad. HolaRene podrá -en
                        caso que fuera posible- pero no está obligado, a verificar la veracidad o
                        calidad de la información aportada por el Usuario, no constituyendo esta
                        facultad una eximición al Usuario de su obligación de aportar información
                        veraz, completa, exacta y actualizada.
                      </p>
                    </li>
                    <li>
                      <p class="text">
                        Principio de Transparencia: En el tratamiento de información protegida, se
                        garantizará el derecho del Usuario a obtener de HolaRene, en cualquier
                        momento y sin restricciones, información acerca de la existencia de
                        cualquier tipo de información protegida que sea de su interés (legal,
                        judicial o contractualmente justificado) y de su titularidad.
                      </p>
                    </li>
                    <li>
                      <p class="text">
                        Principio de Acceso y Circulación Restringida: La información protegida no
                        estará disponible en Internet u otros medios de divulgación o comunicación
                        masiva, salvo que el acceso sea técnicamente controlable para brindar un
                        conocimiento restringido sólo a HolaRene, las Sociedades Vinculadas, los
                        usuarios o terceros debidamente autorizados. Para estos propósitos la
                        obligación de HolaRene, será de medio, según lo establece la normativa
                        vigente.
                      </p>
                    </li>
                    <li>
                      <p class="text">
                        Principio de Seguridad: La información protegida bajo la Política sujeta a
                        tratamiento por HolaRene, será objeto de protección en la medida en que los
                        recursos técnicos y estándares mínimos así lo permitan, a través de la
                        adopción de medidas tecnológicas de protección, protocolos, y medidas
                        administrativas que sean necesarias para otorgar seguridad a los registros y
                        repositorios electrónicos evitando su adulteración, modificación, pérdida,
                        consulta, y en general en contra de cualquier uso o acceso no autorizado. En
                        caso de que exista un riesgo particular de violación de la seguridad de la
                        red, se informará a los usuarios sobre dicho riesgo y, si las medidas para
                        atenuar o eliminar ese riesgo no están bajo su control, sobre las posibles
                        soluciones.
                      </p>
                    </li>
                    <li>
                      <p class="text">
                        Principio de Confidencialidad: Todas y cada una de las personas que en
                        HolaRene administran, manejan, actualizan o tienen acceso a información
                        protegida que no tenga el carácter de pública, y se encuentre en sistemas de
                        información o bases o bancos de datos de terceros debidamente autorizados,
                        se comprometen a conservar y mantener de manera estrictamente confidencial y
                        no revelar a terceros, todas o cualesquiera de las informaciones personales,
                        comerciales, contables, técnicas, comerciales o de cualquier otro tipo
                        suministradas en la ejecución y ejercicio de sus funciones. HolaRene y sus
                        Sociedades Vinculadas para el tratamiento de la información protegida podrán
                        utilizar los sistemas de información y bases de datos de propiedad de
                        HolaRene y/o de sus Sociedades Vinculadas.
                      </p>
                    </li>
                  </ol>
                </li>
                <li class="item">
                  <p class="title">RESPONSABLE DEL TRATAMIENTO</p>
                  <p class="text">
                    Informamos a nuestros usuarios que el responsable de la base de datos es AR
                    Solutions S.A.U., CUIT 30-71707500-1, con domicilio en Av. Libertador 5930, Piso
                    12 – C1428ARP, Ciudad Autónoma de Buenos Aires, Argentina, y que los datos de
                    carácter personal proporcionados a través del Portal, así como los que pudiera
                    facilitar en el futuro en el marco de su relación con nosotros, son almacenados
                    en nuestros servidores para ser conservados bajo seguridad de los mismos.
                  </p>
                  <p class="text">
                    A continuación, informamos a nuestros usuarios qué datos recolectamos, para qué
                    propósitos y bajo qué circunstancias los compartimos con otros.
                  </p>
                  <ol class="sublist" type="a">
                    <li>
                      <p class="text">
                        Datos personales que recolectamos cuando visita nuestro Portal
                      </p>
                      <p class="text">
                        Los datos personales son información con la que podemos relacionar a
                        nuestros usuarios directa o indirectamente, tales como su nombre y
                        apellidos, dirección, número de teléfono, fecha de nacimiento, datos de
                        localización o dirección de correo electrónico y todo otro dato que usted
                        nos provea.
                      </p>
                      <p class="text">A continuación, le mostramos qué datos recolectamos:</p>
                      <ul class="sublist disc">
                        <li>
                          <p class="text">
                            <strong>
                              Cuando usted visita nuestra plataforma para realizar su próxima
                              solicitud, pero no se registra ni realiza una solicitud, recopilamos
                              los siguientes datos de navegación que no se encuentran relacionados
                              con los datos personales de los visitantes:
                            </strong>
                          </p>
                          <table class="t-simple">
                            <tbody>
                              <tr>
                                <td colspan="1" rowspan="1">
                                  <p class="text">
                                    <strong>CATEGORÍA DE INFORMACIÓN</strong>
                                  </p>
                                </td>
                                <td colspan="1" rowspan="1">
                                  <p class="text">
                                    <strong>EXPLICACIÓN</strong>
                                  </p>
                                </td>
                              </tr>
                              <tr>
                                <td colspan="1" rowspan="1">
                                  <p class="text">
                                    <strong>
                                      Información del dispositivo desde el que visita nuestro sitio
                                      web:
                                    </strong>
                                  </p>
                                </td>
                                <td colspan="1" rowspan="1">
                                  <p class="text">
                                    Identificación del dispositivo, sistema operativo y/u otros
                                    identificadores del dispositivo, hardware.
                                  </p>
                                </td>
                              </tr>
                              <tr>
                                <td colspan="1" rowspan="1">
                                  <p class="text">
                                    <strong>Información de conexión:</strong>
                                  </p>
                                </td>
                                <td colspan="1" rowspan="1">
                                  <p class="text">
                                    Hora, fecha, duración de uso del sitio web y origen.
                                  </p>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </li>
                        <li>
                          <p class="text">
                            <strong>
                              Si no sólo visita el Portal, sino que desea acceder a historial de
                              HolaRene (si ya fuera usuario) o si desea visualizar los servicios de
                              HolaRene, se le exige la registración como usuario para iniciar
                              sesión. En tales supuestos se le solicita a aceptación expresa de esta
                              Política y de los Términos y Condiciones generales de uso de HolaRene,
                              recopilamos los siguientes datos personales:
                            </strong>
                          </p>
                          <table class="t-simple">
                            <tbody>
                              <tr>
                                <td colspan="1" rowspan="1">
                                  <p class="text">
                                    <strong>CATEGORÍA DE INFORMACIÓN</strong>
                                  </p>
                                </td>
                                <td colspan="1" rowspan="1">
                                  <p class="text">
                                    <strong>EXPLICACIÓN</strong>
                                  </p>
                                </td>
                              </tr>
                              <tr>
                                <td colspan="1" rowspan="1">
                                  <p class="text">
                                    <strong>Información de acceso:</strong>
                                  </p>
                                </td>
                                <td colspan="1" rowspan="1">
                                  <p class="text">Nombre de usuario, contraseña.</p>
                                </td>
                              </tr>
                              <tr>
                                <td colspan="1" rowspan="1">
                                  <p class="text">
                                    <strong>Información de comunicación:</strong>
                                  </p>
                                </td>
                                <td colspan="1" rowspan="1">
                                  <p class="text">
                                    Comentarios, puntuaciones y devoluciones, comunicaciones
                                    generales
                                  </p>
                                </td>
                              </tr>
                              <tr>
                                <td colspan="1" rowspan="1">
                                  <p class="text">
                                    <strong>Otros datos:</strong>
                                  </p>
                                </td>
                                <td colspan="1" rowspan="1">
                                  <p class="text">
                                    Datos o información que Ud. nos provea voluntariamente mientras
                                    utiliza el sitio web, aplicación u otras fuentes como redes
                                    sociales u otras bases de datos públicas.
                                  </p>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </li>
                        <li>
                          <p class="text">
                            <strong>
                              Datos personales que recogemos al visitar nuestra página web, al
                              registrarse y o utilizar los servicios de HolaRene:
                            </strong>
                          </p>
                          <p class="text">
                            Los campos necesarios para la gestión están marcados como obligatorios.
                            Sin esta información no podemos procesar sus solicitudes y brindar
                            satisfactoriamente los servicios de HolaRene. El resto de la información
                            que no se muestra como campos obligatorios es opcional. Si usted nos
                            proporciona voluntariamente esta información, nos ayudará a mejorar el
                            servicio que le prestamos. Sin embargo, la omisión de esta información
                            no le impedirá aprovechar todas prestaciones ofrecidas por HolaRene. El
                            alcance depende de la información que usted proporcione. Pero la
                            información básica es la siguiente:
                          </p>
                          <table class="t-simple">
                            <tbody>
                              <tr>
                                <td colspan="1" rowspan="1">
                                  <p class="text">
                                    <strong>CATEGORÍA DE INFORMACIÓN</strong>
                                  </p>
                                </td>
                                <td colspan="1" rowspan="1">
                                  <p class="text">
                                    <strong>EXPLICACIÓN</strong>
                                  </p>
                                </td>
                              </tr>
                              <tr>
                                <td colspan="1" rowspan="1">
                                  <p class="text">
                                    <strong>Información de cuenta:</strong>
                                  </p>
                                </td>
                                <td colspan="1" rowspan="1">
                                  <p class="text">
                                    Esta información puede incluir el nombre completo, domicilio,
                                    fecha de nacimiento, número telefónico, email, información de
                                    pago (incluida información de verificación de pago vinculada),
                                    documento de identidad o número de documentos de identificación
                                    ante organismos públicos, número de socio en programas de
                                    promociones asociados a HolaRene, foto de perfil.
                                  </p>
                                </td>
                              </tr>
                              <tr>
                                <td colspan="1" rowspan="1">
                                  <p class="text">
                                    <strong>Ubicación:</strong>
                                  </p>
                                </td>
                                <td colspan="1" rowspan="1">
                                  <p class="text">
                                    Si el usuario da permiso para procesar sus datos de ubicación
                                    recopilamos dicha información precisa o aproximada a través de
                                    datos como GPS, dirección IP y wifi. Esta información se
                                    recopila cuando HolaRene funciona en primer plano y/o cuando
                                    está en segundo plano si el usuario dio permiso a dichos efectos
                                    en su dispositivo móvil o en los ajustes de HolaRene.
                                  </p>
                                </td>
                              </tr>
                              <tr>
                                <td colspan="1" rowspan="1">
                                  <p class="text">
                                    <strong>Información de acceso:</strong>
                                  </p>
                                </td>
                                <td colspan="1" rowspan="1">
                                  <p class="text">Mail y contraseña.</p>
                                </td>
                              </tr>
                              <tr>
                                <td colspan="1" rowspan="1">
                                  <p class="text">
                                    <strong>
                                      Información del dispositivo desde el que visita nuestro sitio
                                      web:
                                    </strong>
                                  </p>
                                </td>
                                <td colspan="1" rowspan="1">
                                  <p class="text">
                                    Identificación del dispositivo, sistema operativo y/u otros
                                    identificadores del dispositivo, hardware.
                                  </p>
                                </td>
                              </tr>
                              <tr>
                                <td colspan="1" rowspan="1">
                                  <p class="text">
                                    <strong>Información de conexión:</strong>
                                  </p>
                                </td>
                                <td colspan="1" rowspan="1">
                                  <p class="text">
                                    Hora, fecha, duración de uso del sitio web y origen.
                                  </p>
                                </td>
                              </tr>
                              <tr>
                                <td colspan="1" rowspan="1">
                                  <p class="text">
                                    <strong>Información de comunicación:</strong>
                                  </p>
                                </td>
                                <td colspan="1" rowspan="1">
                                  <p class="text">
                                    Comentarios, puntuaciones y devoluciones Y comunicaciones
                                    generales.
                                  </p>
                                </td>
                              </tr>
                              <tr>
                                <td colspan="1" rowspan="1">
                                  <p class="text">
                                    <strong>Historial de solicitudes:</strong>
                                  </p>
                                </td>
                                <td colspan="1" rowspan="1">
                                  <p class="text">
                                    Información sobre el tipo de servicio que solicita, el tipo de
                                    solicitud que realiza, el detalle de la solicitud, la
                                    información de entrega, la fecha y hora en la que se realiza la
                                    solicitud, el precio y la forma de pago.
                                  </p>
                                </td>
                              </tr>
                              <tr>
                                <td colspan="1" rowspan="1">
                                  <p class="text">
                                    <strong>Otra información:</strong>
                                  </p>
                                </td>
                                <td colspan="1" rowspan="1">
                                  <p class="text">
                                    Información que el usuario nos provea voluntariamente mientras
                                    utiliza el sitio web o la aplicación e información de otras
                                    fuentes tales como redes sociales o aplicaciones que utilicen
                                    nuestra interfaz de conexión u otras bases de datos públicas.
                                  </p>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </li>
                      </ul>
                      <p class="text">
                        En cualquiera de los casos antes indicados, por favor tenga presente que
                        toda información que usted nos provea según lo antes descripto, HolaRene la
                        considera información personal suya y que Ud. declara que la información
                        brindada se corresponde con sus datos personales y que los mismos son
                        correctos y actualizados.
                      </p>
                    </li>
                    <li>
                      <p class="text">Uso de los datos personales de los usuarios</p>
                      <p class="text">
                        Solo recolectamos sus datos personales cuando es necesario y la finalidad es
                        lícita y previamente definida. HolaRene recopila y usa su información
                        personal para los propósitos especificados en esta Política o en las
                        secciones relevantes de nuestro Portal.
                      </p>
                      <p class="text">HolaRene utiliza su información para distintos fines:</p>
                      <table class="t-simple">
                        <tbody>
                          <tr>
                            <td colspan="1" rowspan="1">
                              <p class="text">
                                <strong>Prestación de servicios:</strong>
                              </p>
                            </td>
                            <td colspan="1" rowspan="1">
                              <p class="text">
                                Utilizamos su información para ofrecer, personalizar, mantener y
                                mejorar la prestación de los servicios que les ofrecemos a nuestros
                                usuarios, así como realizar las siguientes actividades:
                              </p>
                              <ul class="sublist disc">
                                <li>
                                  <p class="text">Administrar el sitio web.</p>
                                </li>
                                <li>
                                  <p class="text">Crear y actualizar su cuenta.</p>
                                </li>
                                <li>
                                  <p class="text">Verificar su perfil.</p>
                                </li>
                                <li>
                                  <p class="text">
                                    Mejorar su experiencia de navegación personalizando el Portal.
                                  </p>
                                </li>
                                <li>
                                  <p class="text">
                                    Habilitarlo a usted para el uso de los servicios disponibles en
                                    el Portal.
                                  </p>
                                </li>
                                <li>
                                  <p class="text">Enviarle comunicados comerciales generales.</p>
                                </li>
                                <li>
                                  <p class="text">
                                    Realizar comentarios y valoraciones de los comercios adheridos,
                                    su oferta y productos.
                                  </p>
                                </li>
                                <li>
                                  <p class="text">Procesar y realizar el pago de los serviciod.</p>
                                </li>
                                <li>
                                  <p class="text">
                                    Controlar la ruta de la solicitud para verificar su
                                    procesamiento.
                                  </p>
                                </li>
                                <li>
                                  <p class="text">Activar funciones para personalizar su cuenta.</p>
                                </li>
                              </ul>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="1" rowspan="1">
                              <p class="text">
                                <strong>Servicio de Atención al Cliente:</strong>
                              </p>
                            </td>
                            <td colspan="1" rowspan="1">
                              <p class="text">
                                Cuando el usuario se pone en contacto con nuestro Servicio de
                                Atención al Cliente para obtener información o para presentar un
                                reclamo, almacenamos la información que el usuario nos proporciona.
                                A modo de ejemplo: la razón para contactarnos o en qué entrega
                                faltaba algo. Dependiendo del motivo del contacto, los datos
                                personales pueden variar. Como queremos mejorar nuestro servicio
                                para usted, almacenamos la comunicación en su cuenta. De esta manera
                                podemos responder a sus preguntas con mayor rapidez.
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="1" rowspan="1">
                              <p class="text">
                                <strong>Protección y seguridad:</strong>
                              </p>
                            </td>
                            <td colspan="1" rowspan="1">
                              <p class="text">
                                Utilizamos sus datos personales para mantener la protección,
                                seguridad e integridad de nuestros servicios y usuarios. Asimismo,
                                utilizamos la información para prevenir fraude.
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="1" rowspan="1">
                              <p class="text">
                                <strong>Investigación y desarrollo:</strong>
                              </p>
                            </td>
                            <td colspan="1" rowspan="1">
                              <p class="text">
                                Podemos utilizar la información que recopilamos para testear,
                                investigar, analizar y desarrollar productos. Esto permite que
                                podamos mejorar en términos de seguridad y que podamos desarrollar
                                nuevas funciones o servicios.
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="1" rowspan="1">
                              <p class="text">
                                <strong>Publicidad:</strong>
                              </p>
                            </td>
                            <td colspan="1" rowspan="1">
                              <p class="text">
                                Podemos enviarte correos electrónicos, SMS, push notifications o
                                realizar llamadas a tu móvil, teléfono fijo o cualquier otro
                                dispositivo que sea susceptible de recibir llamadas para ofrecerte
                                vouchers, descuentos y promociones, realizar encuestas y/ o sondeos
                                de opinión para mejorar nuestro servicio y la calidad del mismo. El
                                usuario puede oponerse a recibir llamadas y al tratamiento posterior
                                de los datos con fines publicitarios con cada correo electrónico o
                                en los ajustes en el Portal.
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="1" rowspan="1">
                              <p class="text">
                                <strong>Marketing:</strong>
                              </p>
                            </td>
                            <td colspan="1" rowspan="1">
                              <p class="text">
                                Queremos evitar newsletters genéricas y medidas de marketing
                                incontroladas. Por lo tanto, seleccionamos las ofertas que mejor se
                                adapten a sus intereses y nos ponemos en contacto con usted si
                                creemos que la información puede ser de su interés. Puede oponerse
                                al tratamiento posterior de los datos con fines publicitarios con
                                cada correo electrónico o en los ajustes en el Portal.
                              </p>
                              <p class="text">
                                HolaRene podrá utilizar la información para promocionar y procesar
                                concursos y sorteos, entregas de premios y ofrecerle publicidad y
                                contenidos relacionados con nuestros servicios y los de nuestros
                                socios comerciales o comercios adheridos.
                              </p>
                              <p class="text">
                                Asimismo, HolaRene podrá enviarle muestras de productos.
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="1" rowspan="1">
                              <p class="text">
                                <strong>
                                  Procedimientos judiciales o requerimientos administrativos:
                                </strong>
                              </p>
                            </td>
                            <td colspan="1" rowspan="1">
                              <p class="text">
                                Podremos utilizar su información personal en caso que nos sea
                                requerido por orden judicial o resolución administrativa,
                                regulatoria, arbitral, y en general cuando sea requerido por
                                cualquier autoridad gubernamental.
                              </p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <p class="text">
                        El Usuario presta su pleno y total consentimiento para que HolaRene recopile
                        sus datos personales y los utilice, transfiera, almacene y ceda, de
                        conformidad con el uso de información previsto en esta Política de
                        Privacidad.
                      </p>
                    </li>
                  </ol>
                </li>
                <li class="item">
                  <p class="title">ACTUALIZACIÓN DE LA INFORMACIÓN</p>
                  <p class="text">
                    Si su información cambia o ha cambiado, es usted el responsable de modificarla
                    en el Portal. Esto permitirá el correcto funcionamiento del sitio.
                  </p>
                </li>
                <li class="item">
                  <p class="title">CON QUIÉN COMPARTIMOS SUS DATOS PERSONALES</p>
                  <p class="text">
                    En el evento que HolaRene, en el ejercicio de sus actividades propias, utilice,
                    transmita o transfiera internacionalmente datos personales garantizará el
                    cumplimiento de los principios aplicables establecidos en la normativa
                    aplicable.
                  </p>
                  <p class="text">
                    Concretamente HolaRene, en el giro ordinario de sus negocios podrá incorporar la
                    información que Ud. nos provea dentro de sus sistemas de información. HolaRene,
                    como responsable del tratamiento garantiza que los sistemas de información
                    cumplen en su integridad la Política y la Normativa vigente en materia de
                    protección de datos personales en Argentina.
                  </p>
                  <p class="text">
                    El usuario reconoce con la aceptación de la Política que, de presentarse una
                    venta, fusión, consolidación, cambio de control societario, transferencia
                    sustancial de activos, reorganización o liquidación de HolaRene, HolaRene podrá
                    transferir, enajenar o asignar la Información Protegida a una o más partes
                    relevantes, incluidas Sociedades Vinculadas.
                  </p>
                  <p class="text">
                    En la siguiente sección le indicamos a quén le informamos sus datos y bajo qué
                    condiciones, así como a qué países los transferimos.
                  </p>
                  <ol class="sublist" type="a">
                    <li>
                      <p class="text">Qué terceros tienen acceso a los datos personales</p>
                      <ul class="sublist disc">
                        <li>
                          <p class="text">
                            <strong>Empresas vinculadas a HolaRene</strong>
                          </p>
                          <p class="text">
                            Podremos compartir datos con nuestras empresas vinculadas para facilitar
                            y mejorar el procesamiento de nuestros datos sobre servicios. Por
                            ejemplo, en la Unión Europea y Uruguay, HolaRene procesa y almacena
                            información en nombre de sus filiales internacionales.
                          </p>
                        </li>
                        <li>
                          <p class="text">
                            <strong>Comercios adheridos a HolaRene</strong>
                          </p>
                          <p class="text">
                            HolaRene comparte los datos estrictamente necesarios con los comercios
                            adheridos a efectos de que los comercios adheridos puedan brindar el
                            servicio contratado por parte de los usuarios.
                          </p>
                        </li>
                        <li>
                          <p class="text">
                            <strong>
                              Proveedores de servicios y comercios adheridos a HolaRene
                            </strong>
                          </p>
                          <p class="text">
                            HolaRene puede facilitar información a sus proveedores, consultores,
                            socios de marketing, empresas de investigación y otros proveedores de
                            servicios o socios comerciales. A modo de ejemplo HolaRene podrá
                            facilitar información a:
                          </p>
                          <ul class="sublist none">
                            <li>
                              <p class="text">- Procesadores y facilitadores de pagos</p>
                            </li>
                            <li>
                              <p class="text">- Proveedores de almacenamiento en la nube</p>
                            </li>
                            <li>
                              <p class="text">
                                - Socios de marketing y proveedores de plataformas de marketing
                              </p>
                            </li>
                            <li>
                              <p class="text">- Proveedores de análisis de datos</p>
                            </li>
                            <li>
                              <p class="text">
                                - Proveedores de investigación, incluidos los que implementan
                                estudios o proyectos de investigación en colaboración con HolaRene o
                                en su nombre
                              </p>
                            </li>
                            <li>
                              <p class="text">
                                - Proveedores que ayudan a HolaRene a mejorar la seguridad de sus
                                apps
                              </p>
                            </li>
                            <li>
                              <p class="text">
                                - Consultores y otros proveedores de servicios profesionales
                              </p>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <p class="text">
                            <strong>Otros</strong>
                          </p>
                          <p class="text">
                            HolaRene puede compartir su información de otra manera que no sea la
                            descripta en esta Política si así se lo notificamos y lo acepta
                            expresamente.
                          </p>
                        </li>
                      </ul>
                    </li>
                  </ol>
                </li>
                <li class="item">
                  <p class="title">A QUÉ PAÍSES TRANSFERIMOS SUS DATOS</p>
                  <p class="text">
                    Procesamos sus datos principalmente dentro de Argentina, Uruguay y dentro de la
                    Unión Europea (UE). Sin embargo, algunos de nuestros proveedores de servicios
                    tienen su sede en Estados Unidos y cumplen con los principios establecidos por
                    la normativa aplicable para países con niveles no adecuados de legislación
                    conforme la Ley 25.326.
                  </p>
                </li>
                <li class="item">
                  <p class="title">SUS DERECHOS</p>
                  <p class="text">
                    HolaRene es la responsable de la base de datos y ante quien podrán ejercerse los
                    derechos previstos en la Ley 25.326 de Protección de Datos y aquellas normas que
                    la modifiquen o reemplacen, acreditando debidamente su identidad o
                    representación. De acuerdo con dicha normativa, usted podrá, previa acreditación
                    de su identidad, solicitar, gratuitamente, el acceso, la rectificación,
                    actualización inclusión o modificación, cancelación y cualquier otra acción
                    relativa a su información personal de conformidad con Ley 25.326 de Protección
                    de Datos.
                  </p>
                  <p class="text">
                    HolaRene retiene la información del usuario y el perfil por el tiempo en que
                    usted mantenga su cuenta en HolaRene.
                  </p>
                  <p class="text">
                    Ud. tiene el derecho de acceder, dentro de los 10 (diez) días corridos desde la
                    acreditación de su identidad, en forma gratuita a sus datos personales, en
                    intervalos no inferiores a seis meses, salvo que se acredite un interés
                    legítimo.
                  </p>
                  <p class="text">
                    Para la rectificación, actualización, eliminación, así como a la oposición del
                    tratamiento de los datos personales, el titular de los mismo podrá ejercer tales
                    derechos dentro de los 5 (cinco) días hábiles de acreditada su identidad.
                    HolaRene procederá con dicha solicitud o informará de las razones por las que
                    estime no corresponde en el plazo anteriormente indicado.
                  </p>
                  <p class="text">
                    Puedes gestionar y solicitar la eliminación de tus datos personales tú mismo,
                    desde las entradas “Mi Cuenta” y “Ayuda en línea” de la aplicación.
                  </p>
                  <p class="text">
                    La AGENCIA DE ACCESO A LA INFORMACIÓN PÚBLICA, en su carácter de Órgano de
                    Control de la Ley N° 25.326, tiene la atribución de atender las denuncias y
                    reclamos que interpongan quienes resulten afectados en sus derechos por
                    incumplimiento de las normas vigentes en materia de protección de datos
                    personales.
                  </p>
                  <p class="text">
                    Una vez finalizado el procedimiento de eliminación de sus datos personales,
                    HolaRene no tendrá registro alguno de sus datos personales en sus bases de
                    datos, quedando únicamente los registros de solicitudes realizados por usted a
                    los solos efectos de análisis de prevención de fraude y estadísticos de la
                    plataforma, sin ser asociados a ninguna cuenta.
                  </p>
                </li>
                <li class="item">
                  <p class="title">INFORMACIÓN SOBRE COOKIES</p>
                  <p class="text">
                    Una cookie consiste en información enviada por un servidor web a un navegador
                    web, y almacenada por el navegador. La información es luego enviada de nuevo al
                    servidor en cada momento que el navegador solicita una página del servidor. Esto
                    habilita al servidor a identificar y rastrear el navegador web. Nosotros
                    usaremos tanto cookies de sesión como cookies persistentes únicamente en nuestro
                    sitio web.
                  </p>
                  <p class="text">
                    Usaremos las cookies de sesión para estar al tanto de usted mientras utiliza el
                    Portal.
                  </p>
                  <p class="text">
                    Por su parte, usaremos cookies persistentes para habilitar a nuestro sitio web
                    que lo reconozca a usted cuando visita nuestro Portal. Las cookies de sesión
                    serán eliminadas de su computadora cuando usted cierra el navegador web. Las
                    cookies persistentes se mantendrán almacenadas en su computadora hasta que Ud
                    las elimine, o hasta que lleguen a una fecha de expiración especificada.
                  </p>
                  <p class="text">
                    Utilizamos Google Analytics para analizar el uso de este sitio web. Google
                    Analytics genera información estadística y de otro tipo sobre el uso de sitios
                    web mediante cookies, las cuales son almacenadas en la computadora del usuario.
                    La información generada en relación con nuestro sitio web es utilizada para
                    crear reportes sobre el uso de nuestro sitio. Google almacenará esta
                    información.
                  </p>
                  <p class="text">
                    Las políticas de privacidad de Google se encuentran disponibles en:
                    <a href="https://www.google.com/url?q=http://www.google.com/privacypolicy.html&amp;sa=D&amp;ust=1582828667922000">
                      http://www.google.com/privacypolicy.html
                    </a>
                  </p>
                  <p class="text">
                    La mayoría de los navegadores web lo autorizan a usted a rechazar la aceptación
                    de cookies. Esto se logra a través de la configuración de cada navegador en
                    particular. Esto sin embargo, tendrá un impacto negativo con respecto a la
                    usabilidad de varios sitios web incluyendo este.
                  </p>
                </li>
                <li class="item">
                  <p class="title">SEGURIDAD DE SU INFORMACIÓN PERSONAL Y SERVIDORES</p>
                  <p class="text">
                    Toda información recabada en el Portal será tratada en cumplimiento de la
                    Normativa y los datos personales serán utilizados únicamente para los fines aquí
                    consentidos o los que consienta oportunamente. HolaRene expresa su compromiso de
                    proteger la seguridad de la información personal de los usuarios. Con ese fin,
                    HolaRene usa una amplia variedad de tecnologías y procedimientos de seguridad
                    para proteger la información personal contra un acceso, uso, modificación o una
                    divulgación no autorizados. Sin perjuicio de esto, le recomendamos que no
                    incluya información confidencial, secreta, comprometedora, datos sensibles o
                    información personal delicada que usted no desea revelar en estas comunicaciones
                    (incluyendo correos electrónicos). Asimismo, usted es responsable de proteger la
                    confidencialidad de saus credenciales de acceso a la aplicación.
                  </p>
                  <p class="text">
                    Usted reconoce y acepta que, a pesar de todos nuestros esfuerzos, ninguna medida
                    de seguridad de datos puede garantizar por completo la protección en todo
                    momento.
                  </p>
                </li>
                <li class="item">
                  <p class="title">CAMBIOS EN ESTA DECLARACIÓN DE PRIVACIDAD</p>
                  <p class="text">
                    HolaRene se reserva el derecho de mantener actualizada esta Política de
                    conformidad con la normativa vigente. Si se realizan cambios materiales en esta
                    Política, se lo informaremos mediante una comunicación electrónica y de ser
                    necesario se le solicitará nuevamente su consentimiento con la nueva Política
                    que se implemente. Consulte con frecuencia esta declaración para estar
                    informado, en forma permanente y actualizada, de cómo HolaRene protege la
                    privacidad de su información.
                  </p>
                </li>
                <li class="item">
                  <p class="title">NOTA SOBRE EL USO DE ESTE SITIO POR PARTE DE NIÑOS</p>
                  <p class="text">
                    Este sitio no está dirigido a niños. No deseamos recopilar ninguna información
                    que identifique personalmente a menores de edad (menor de 18 años). Si un menor
                    de edad nos hubiera suministrado información que lo identifica personalmente,
                    sus padres y/o tutores deberán comunicarse con nosotros a través del contacto
                    indicado debajo. En caso de tomar conocimiento que los datos de un menor de edad
                    han sido proporcionados a HolaRene, eliminaremos dicha información a la mayor
                    brevedad posible.
                  </p>
                </li>
                <li class="item">
                  <p class="title">DISPOSICIONES FINALES</p>
                  <p class="text">
                    El Portal puede contener enlaces con referencias a otros sitios web más allá de
                    nuestro control. Por favor recuerde que no tenemos ningún control sobre estos
                    sitios web y que nuestra Política no es aplicable a estos sitios. Le
                    recomendamos que lea las políticas de privacidad y los términos y condiciones de
                    los sitios web conectados o referenciados en los que usted entra. Ninguna parte
                    de esta Política supone la creación o adición de algún derecho o reclamo (ya sea
                    legal, equitativo o de otro tipo) que cualquier individuo o persona pueda tener
                    según la ley, o de alguna otra forma, en contra de la empresa, terceros, o sus
                    directores respectivos, delegados, empleados, agentes o representantes; ni la
                    existencia de la presente Política o su aplicación impondrá o agregará
                    obligación alguna o responsabilidad a ninguno de los nombrados, que no tenga
                    actualmente según la ley.
                  </p>
                </li>
                <li class="item">
                  <p class="title">JURISDICCIÓN Y LEY APLICABLE</p>
                  <p class="text">
                    La presente Política se encuentra regida sin excepción y en todos sus términos
                    por las leyes de la República de Argentina y será interpretada de acuerdo a
                    ellas. Cualquier controversia derivada de la presente Política, su existencia,
                    validez, interpretación, alcance o cumplimiento será sometida a los tribunales
                    ordinarios de Justicia con jurisdicción en Argentina, renunciándose en forma
                    expresa a cualquier otro fuero o jurisdicción.
                  </p>
                </li>
                <li class="item">
                  <p class="title">CONSENTIMIENTO</p>
                  <p class="text">
                    Presto mi consentimiento para que mis datos personales, sean tratados en
                    Argentina, y cedidos en caso de ser necesario para su procesamiento en la Unión
                    Europea, Uruguay o Estados Unidos de conformidad con la legislación vigente en
                    Argentina. Asimismo, presto mi consentimiento para que me hagan llegar vía
                    correo electrónico u otro medio de comunicación, información sobre promociones y
                    novedades relacionadas con HolaRene, decisión que puedo revocar personalmente en
                    todo momento a través de la configuración personal de mi cuenta.
                  </p>
                </li>
              </ol>
            </article>
          </div>
        </div>
      </>
    );
  }
}

export default Policy;
