import React from "react";

// reactstrap components
import {
  Button,
  Badge,
  Card,
  CardHeader,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Table,
  Container,
  Row,
  Col,
  Modal,
} from "reactstrap";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.jsx";
import { authRef } from "config/firebase";

import { hasRole, saveRole } from "services/roleServices";
import { ROLE_ADMIN, ROLE_OPERATOR, ROLE_MEDIC } from "types/rolesTypes";
import { listUsers, lunchCRMUsersApiUpdate } from "services/usersServices";
class UsersDashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = { users: null };
  }

  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
    });
  };

  componentDidMount() {
    authRef.onAuthStateChanged((user) => {
      this.setState({ currentUser: user });

      user.getIdToken().then((token) => {
        listUsers(token)
          .then((users) => {
            this.setState({ users: users });
          })
          .catch((e) => {
            if (e.response && e.response.data && e.response.data.message)
              alert(e.response.data.message);
            else alert(e.message);
          });
      });
    });
  }

  componentDidUpdate(e) {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.mainContent.scrollTop = 0;
  }

  saveRoleEdition() {
    //var user = firebase.auth().currentUser;
    authRef.onAuthStateChanged((currentUser) => {
      if (currentUser) {
        currentUser.getIdToken().then((token) => {
          let updateRolBody = this.state.editRoleUser.role;

          //displayName, password, email, role;

          saveRole(token, this.state.editRoleUser, updateRolBody)
            .then((result) => {
              alert("Ok !");

              this.toggleModal("editRoleModal");
            })
            .catch((e) => {
              // eventsHelper.registerEvent(auth, "E", e, {
              //   promotionalCode: promotionalCode,
              //   message: e.message,
              //   code: e.code
              // });

              if (e.response && e.response.data && e.response.data.message)
                alert(e.response.data.message);
              else alert(e.message);
            });
        });
      }
    });
  }

  onRoleChange(role, event) {
    this.state.editRoleUser.role = this.state.editRoleUser.role || [];

    if (event.target.checked) {
      if (!hasRole(this.state.editRoleUser, role)) {
        this.state.editRoleUser.role.push(role);
      }
    } //off
    else {
      if (hasRole(this.state.editRoleUser, role)) {
        let newRoles = [];
        this.state.editRoleUser.role.forEach((existentRole) => {
          if (existentRole !== role) newRoles.push(existentRole);
        });
        this.state.editRoleUser.role = newRoles;
      }
    }
    this.setState({ editRoleUser: this.state.editRoleUser });
    //alert(role + ": " + value);
  }

  lunchCRMUsersApiUpdate() {
    this.setState({ loading: true });
    lunchCRMUsersApiUpdate()
      .then((data) => {
        alert("OK");
        window.location.reload();
      })
      .catch((e) => {
        console.log("Error", e);
        alert("Hubo un error al procesar " + e.message);
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  render() {
    return (
      <>
        {/* <Sidebar
          {...this.props}
          routes={routes}
          logo={{
            innerLink: "/admin/index",
            imgSrc: require("assets/img/brand/argon-react.png"),
            imgAlt: "..."
          }}
        /> */}
        <div className="main-content" ref="mainContent">
          <AdminNavbar {...this.props} currentUser={this.state.currentUser} />
          {/* <Switch>{this.getRoutes(routes)}</Switch> */}

          {this.state.editRoleUser && (
            <Modal
              className="modal-dialog-centered"
              isOpen={this.state.editRoleModal}
              toggle={() => this.toggleModal("editRoleModal")}
            >
              <div className="modal-header">
                <h6 className="modal-title" id="modal-title-default">
                  <>Edit role for {this.state.editRoleUser.displayName}</>
                </h6>
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => this.toggleModal("editRoleModal")}
                >
                  <span aria-hidden={true}>×</span>
                </button>
              </div>
              <div className="modal-body">
                <p>Select roles</p>

                <div className="custom-control custom-control-alternative custom-checkbox">
                  <input
                    className="custom-control-input"
                    id="checkbox_ROLE_ADMIN"
                    type="checkbox"
                    onChange={(e) => {
                      this.onRoleChange(ROLE_ADMIN, e);
                    }}
                    checked={hasRole(this.state.editRoleUser, ROLE_ADMIN)}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="checkbox_ROLE_ADMIN"
                  >
                    <span className="text-muted">Admin</span>
                  </label>
                </div>

                <div className="custom-control custom-control-alternative custom-checkbox">
                  <input
                    className="custom-control-input"
                    id="checkbox_ROLE_OPERATOR"
                    type="checkbox"
                    onChange={(e) => {
                      this.onRoleChange(ROLE_OPERATOR, e);
                    }}
                    checked={hasRole(this.state.editRoleUser, ROLE_OPERATOR)}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="checkbox_ROLE_OPERATOR"
                  >
                    <span className="text-muted">Operator</span>
                  </label>
                </div>

                <div className="custom-control custom-control-alternative custom-checkbox">
                  <input
                    className="custom-control-input"
                    id="checkbox_ROLE_MEDIC"
                    type="checkbox"
                    onChange={(e) => {
                      this.onRoleChange(ROLE_MEDIC, e);
                    }}
                    checked={hasRole(this.state.editRoleUser, ROLE_MEDIC)}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="checkbox_ROLE_MEDIC"
                  >
                    <span className="text-muted">Medic</span>
                  </label>
                </div>
              </div>
              <div className="modal-footer">
                <Button
                  color="primary"
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    this.saveRoleEdition();
                  }}
                >
                  Save changes
                </Button>
                <Button
                  className="ml-auto"
                  color="link"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => this.toggleModal("editRoleModal")}
                >
                  Close
                </Button>
              </div>
            </Modal>
          )}
          <Container fluid>
            <Row>
              <div className="col">
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <Row>
                      <Col lg="2">
                        <h3 className="mb-0">Users</h3>
                      </Col>
                      <Col lg="2"></Col>
                      <Col lg="8">
                        <UncontrolledDropdown>
                          <DropdownToggle
                            href="#"
                            role="button"
                            size="lg"
                            color=""
                            onClick={(e) => e.preventDefault()}
                          >
                            Más
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-arrow" right>
                            <DropdownItem
                              onClick={(e) => {
                                e.preventDefault();
                                this.lunchCRMUsersApiUpdate();
                              }}
                            >
                              Actualizar CRM Users desde API
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </Col>
                    </Row>
                  </CardHeader>
                  {!this.state.users && <div>Loading</div>}
                  {this.state.users && (
                    <Table
                      className="align-items-center table-flush"
                      responsive
                    >
                      <thead className="thead-light">
                        <tr>
                          <th scope="col">Name</th>
                          <th scope="col">Email</th>
                          <th scope="col">Roles</th>
                          <th scope="col">Last Sign In Time</th>

                          <th scope="col" />
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.users.map((user, index) => {
                          return (
                            <tr key={index}>
                              <th scope="row">
                                <Media className="align-items-center">
                                  <a
                                    className="avatar rounded-circle mr-3"
                                    href="#"
                                    onClick={(e) => e.preventDefault()}
                                  >
                                    <img alt="..." src={user.photoURL} />
                                  </a>
                                  <Media>
                                    <span className="mb-0 text-sm">
                                      {user.displayName}
                                    </span>
                                  </Media>
                                </Media>
                              </th>
                              <td>{user.email}</td>
                              <td>
                                {user.role &&
                                  user.role.map((role, roleIndex) => {
                                    return (
                                      <Badge
                                        color=""
                                        className="badge-dot mr-4"
                                        key={roleIndex}
                                      >
                                        <i className="bg-warning" />
                                        {role}
                                      </Badge>
                                    );
                                  })}
                              </td>
                              <td>{user.lastSignInTime}</td>

                              <td className="text-right">
                                <UncontrolledDropdown>
                                  <DropdownToggle
                                    className="btn-icon-only text-light"
                                    href="#pablo"
                                    role="button"
                                    size="sm"
                                    color=""
                                    onClick={(e) => e.preventDefault()}
                                  >
                                    <i className="fas fa-ellipsis-v" />
                                  </DropdownToggle>
                                  <DropdownMenu
                                    className="dropdown-menu-arrow"
                                    right
                                  >
                                    <DropdownItem
                                      onClick={(e) => {
                                        e.preventDefault();
                                        this.props.history.push(
                                          "/user-profile/" + user.uid
                                        );
                                      }}
                                    >
                                      Profile
                                    </DropdownItem>
                                    <DropdownItem
                                      onClick={(e) => {
                                        e.preventDefault();

                                        console.log(user);
                                        this.setState({ editRoleUser: user });

                                        this.toggleModal("editRoleModal");
                                      }}
                                    >
                                      Edit Role
                                    </DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  )}{" "}
                </Card>
              </div>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

export default UsersDashboard;
