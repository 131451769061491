import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Table,
  Container,
  Col,
  Row,
} from "reactstrap";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.jsx";
import ParametersEditionModal from "./ParametersEditionModal.jsx";

import { authRef } from "config/firebase";

import {
  listParameters,
  createParameter,
  updateParameter,
  deleteParameter,
} from "services/parametersServices";

import { listPlans } from "services/plansServices";

class ParametersOverview extends React.Component {
  constructor(props) {
    super(props);

    this.state = { parameters: null, addNewParameter: {} };
  }

  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
    });
  };

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    authRef.onAuthStateChanged((user) => {
      this.setState({ currentUser: user });

      user.getIdToken().then((token) => {
        listParameters(token)
          .then((parameters) => {
            console.log("parameters: ", parameters);
            this.setState({ parameters: parameters });
          })
          .catch((e) => {
            if (e.response && e.response.data && e.response.data.message)
              alert(e.response.data.message);
            else alert(e.message);
          });

        listPlans(token)
          .then((plans) => {
            console.log("plans: ", plans);
            this.setState({ plans: plans });
          })
          .catch((e) => {
            if (e.response && e.response.data && e.response.data.message)
              alert(e.response.data.message);
            else alert(e.message);
          });
      });
    });
  }

  componentDidUpdate(e) {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.mainContent.scrollTop = 0;
  }

  saveParameter(parameter) {
    console.log("parameter:", parameter);
    if (!parameter.code || !parameter.value) {
      console.log("Faltan datos requeridos: ", parameter);
      alert("Faltan datos requeridos");
      return;
    }

    authRef.onAuthStateChanged((user) => {
      user.getIdToken().then((token) => {
        if (parameter.id)
          updateParameter(token, parameter)
            .then((parameter) => {
              this.loadData();
              console.log("Parameter: ", parameter);
              alert("Parameter: " + JSON.stringify(parameter));
              this.toggleModal("parametersEditionModal");
            })
            .catch((e) => {
              if (e.response && e.response.data && e.response.data.message)
                alert(e.response.data.message);
              else alert(e.message);
            });
        else
          createParameter(token, parameter)
            .then((parameter) => {
              this.loadData();
              console.log("Parameter: ", parameter);
              alert("Parameter: " + JSON.stringify(parameter));
              this.toggleModal("parametersEditionModal");
            })
            .catch((e) => {
              if (e.response && e.response.data && e.response.data.message)
                alert(e.response.data.message);
              else alert(e.message);
            });
      });
    });

    //alert("parameter recibido: " + parameter.name);
  }

  innerDeleteParameter(parameter) {
    authRef.onAuthStateChanged((user) => {
      user.getIdToken().then((token) => {
        deleteParameter(token, parameter)
          .then((parameter) => {
            this.loadData();
            console.log("Parameter: ", parameter);
            alert("Parameter: " + JSON.stringify(parameter) + "removed");
          })
          .catch((e) => {
            if (e.response && e.response.data && e.response.data.message)
              alert(e.response.data.message);
            else alert(e.message);
          });
      });
    });
  }

  render() {
    return (
      <>
        <div className="main-content" ref="mainContent">
          <AdminNavbar {...this.props} currentUser={this.state.currentUser} />

          {this.state.parameters && this.state.parameterToEdit && (
            <ParametersEditionModal
              modalName="parametersEditionModal"
              plans={this.state.plans}
              parametersEditionModalIsOpen={this.state.parametersEditionModal}
              parameter={this.state.parameterToEdit}
              toggleModal={this.toggleModal.bind(this)}
              saveParameter={this.saveParameter.bind(this)}
            />
          )}
          <Container fluid>
            <Row>
              <div className="col">
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <Row>
                      <Col lg="10">
                        <h3 className="mb-0">Parameters</h3>
                      </Col>
                      <Col lg="2">
                        <div className="btn-wrapper">
                          <Button
                            color="success"
                            onClick={(e) => {
                              e.preventDefault();

                              this.setState({ parameterToEdit: {} });
                              this.toggleModal("parametersEditionModal");
                            }}
                          >
                            New
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </CardHeader>
                  {!this.state.parameters && <div>Loading</div>}
                  {this.state.parameters && (
                    <Table
                      className="align-items-center table-flush"
                      responsive
                    >
                      <thead className="thead-light">
                        <tr>
                          <th scope="col">Code</th>
                          <th scope="col">Value</th>

                          <th scope="col" />
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.parameters.map((parameter, index) => {
                          return (
                            <tr key={index}>
                              <th scope="row">
                                <Media>
                                  <span className="mb-0 text-sm">
                                    {parameter.code}
                                  </span>
                                </Media>
                              </th>
                              <td>
                                <Media>
                                  <span className="mb-0 text-sm">
                                    {parameter.value}
                                  </span>
                                </Media>
                              </td>

                              <td className="text-right">
                                <UncontrolledDropdown>
                                  <DropdownToggle
                                    className="btn-icon-only text-light"
                                    href="#"
                                    role="button"
                                    size="sm"
                                    color=""
                                    onClick={(e) => e.preventDefault()}
                                  >
                                    <i className="fas fa-ellipsis-v" />
                                  </DropdownToggle>
                                  <DropdownMenu
                                    className="dropdown-menu-arrow"
                                    right
                                  >
                                    <DropdownItem
                                      onClick={(e) => {
                                        e.preventDefault();

                                        this.setState({
                                          parameterToEdit: parameter,
                                        });
                                        this.toggleModal(
                                          "parametersEditionModal"
                                        );
                                      }}
                                    >
                                      Edit
                                    </DropdownItem>

                                    <DropdownItem
                                      onClick={(e) => {
                                        e.preventDefault();

                                        this.innerDeleteParameter(parameter);
                                      }}
                                    >
                                      Delete
                                    </DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  )}{" "}
                </Card>
              </div>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

export default ParametersOverview;
