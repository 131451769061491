import React from 'react';
// import MetaTags from "react-meta-tags";

// reactstrap components
import { Button, Card, CardHeader, CardBody, Container, Row, Col } from 'reactstrap';

// core components
import BasicNavbar from 'components/Navbars/BasicNavbar.jsx';
import WhatsappButton from 'components/Buttons/WhatsappButton';

import DigitalOnboardingStepper from './DigitalOnboardingStepper';
import {
  goToFatalErrorPage,
  getUserToken,
  setPersistedValue,
  getPersistedValue,
  validateHasValue,
  validateCard,
  formatMoney,
  PAYMENT_METHODS,
  onlyText,
} from 'helpers/coreHelper';
import CustomInput from 'components/Inputs/CustomInput.jsx';
import Loading from 'components/Loading/Loading.jsx';
import SearchLocationInput from 'components/Inputs/SearchLocationInput.jsx';

import { savePaymentData } from 'services/leadsServices';

class DigitalOnboardingStep4Checkout extends React.Component {
  constructor(props) {
    super(props);

    let lead = null;

    try {
      lead = getPersistedValue('lead');
    } catch (e) {}

    lead = {
      ...lead,
      paymentData: lead.paymentData || {
        paymentMethod: PAYMENT_METHODS[0].key,
        cardProvider: '',
        cardHolder: '',
        cardNumber: '',
        cardDueDate: '',
        CBU: '',
        cardCVC: '',
        identificationNumber: '',
      },
    };

    this.state = {
      lead: lead,
      paymentMethodOptions: PAYMENT_METHODS,

      isValidCardNumber: validateHasValue(lead.paymentData.cardNumber),

      cvc: '',
      expiry: '',
      focus: '',
      name: '',
      number: '',
      loading: false,
    };
  }

  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    // this.refs.main.scrollTop = 0;

    this.validateFormData();
  }

  getCardType = (number) => {
    try {
      // visa
      var re = new RegExp('^4');
      if (number.match(re) != null) return 'visa';

      // Mastercard
      // Updated for Mastercard 2017 BINs expansion
      if (
        /^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(
          number
        )
      )
        return 'master';

      // AMEX
      re = new RegExp('^3[47]');
      if (number.match(re) != null) return 'amex';

      // Discover
      re = new RegExp(
        '^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)'
      );
      if (number.match(re) != null) return 'discover';

      // Diners
      re = new RegExp('^36');
      if (number.match(re) != null) return 'diners';

      // Diners - Carte Blanche
      re = new RegExp('^30[0-5]');
      if (number.match(re) != null) return 'diners - Carte Blanche';

      // JCB
      re = new RegExp('^35(2[89]|[3-8][0-9])');
      if (number.match(re) != null) return 'jcb';

      // Visa Electron
      re = new RegExp('^(4026|417500|4508|4844|491(3|7))');
      if (number.match(re) != null) return 'visa Electron';

      return '';
    } catch (e) {
      return '';
    }
  };

  validateFormData(lead, isValidCardNumber) {
    let errors = [];

    if (!lead) lead = this.state.lead;
    if (typeof isValidCardNumber === 'undefined') isValidCardNumber = this.state.isValidCardNumber;

    if (!validateHasValue(lead.paymentData.paymentMethod)) errors.push('paymentMethod');

    if (!validateHasValue(lead.paymentData.cardHolder)) errors.push('cardHolder');

    if (lead.paymentData.paymentMethod === 'CBU') {
      if (!validateHasValue(lead.paymentData.CBU, 22) && lead.paymentData.CBU.length <= 22)
        errors.push('CBU');
    } else {
      if (!validateHasValue(lead.paymentData.cardNumber)) errors.push('cardNumber');

      if (!validateHasValue(lead.paymentData.cardDueDate)) errors.push('cardDueDate');

      if (!validateHasValue(lead.paymentData.cardCVC, 3)) errors.push('cvc');

      // if (!isValidCardNumber) errors.push("cardNumber");
    }

    if (
      !this.state.lead.address ||
      !this.state.lead.address.geolocation.lat ||
      !this.state.lead.address.geolocation.lng ||
      // !this.state.lead.address.city ||
      // !this.state.lead.address.county ||
      // !this.state.lead.address.postalCode ||
      // !this.state.lead.address.state ||
      !this.state.lead.address.formatted_address
    ) {
      errors.push('address');
    }

    if (errors.length === 0) errors = null;

    this.setState({ errors: errors });

    if (errors) console.log('errors:', errors, lead);
    return errors;
  }

  savePaymentData() {
    let errors = this.validateFormData();

    if (errors) return;

    this.setState({ loading: true });

    getUserToken()
      .then((token) => {
        savePaymentData(token, this.state.lead)
          .then(() => {
            setPersistedValue('lead', this.state.lead);

            this.props.history.push('/onboarding-step-5');
          })
          .catch((e) => {
            console.log(e, e.message, e.code);
            goToFatalErrorPage(this.props.history);
          });
      })
      .catch((e) => {
        console.log(e, e.message, e.code);
        goToFatalErrorPage(this.props.history);
      });
  }

  handleInputFocus = (e) => {
    this.setState({ focus: e.target.name });
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;

    this.setState({ [name]: value });
  };

  setaddressScreen = (value) => {
    this.setState({ address: value });
  };

  textOnly(input) {
    return onlyText(input);
  }

  render() {
    return (
      <>
        <WhatsappButton />
        <BasicNavbar />

        <main ref="main">
          <section className="section section-shaped bg-light-grey">
            <Container>
              <Row className="justify-content-center">
                <Col lg="3 px-sm-0" md="3 mr-3">
                  <DigitalOnboardingStepper step={3} maxSteps={4} progress={75} />
                </Col>
                <Col lg="7" md="7" className="pl-md-0">
                  <Loading loading={this.state.loading} />
                  <Button
                    className="mt-4 hidden-sm"
                    color="custom flat back"
                    type="button"
                    onClick={() => {
                      this.props.history.goBack();
                    }}
                  ></Button>

                  <Card className="bg-transparent card-form border-0">
                    <CardHeader className="pb-0 bg-transparent">
                      <div className="form-title mb-3">Seleccioná un medio de pago</div>
                      <div className="form-subtitle">
                        Se te debitarán ${' '}
                        {formatMoney(this.state.lead.selectedPlan.prices[0].amount)} por mes del
                        medio de pago que elijas
                      </div>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col lg="8" sm="8">
                          {[this.state.paymentMethodOptions[0]].map((paymentMethod, index) => {
                            return (
                              <div className="box-white-2" key={index}>
                                <div className="custom-control custom-radio">
                                  <input
                                    className="custom-control-input"
                                    onChange={(event) => {
                                      this.state.lead.paymentData.paymentMethod =
                                        event.target.value;

                                      this.validateFormData(this.state.lead);

                                      this.setState({
                                        lead: this.state.lead,
                                      });
                                    }}
                                    checked={
                                      paymentMethod.key ===
                                      this.state.lead.paymentData.paymentMethod
                                    }
                                    value={paymentMethod.key}
                                    id={'customRadio_' + index}
                                    name="custom-radio"
                                    type="radio"
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor={'customRadio_' + index}
                                  >
                                    <span>{paymentMethod.value}</span>
                                  </label>
                                </div>
                              </div>
                            );
                          })}
                        </Col>
                      </Row>

                      <div
                        style={{ marginTop: 20, marginBottom: 30 }}
                        className="title-form-purple"
                      >
                        Completá los datos de tu Tarjeta
                      </div>

                      <Row>
                        <Col lg="12" sm="12">
                          <CustomInput
                            behavior={{
                              className: 'input form-control css-card',
                              options: {
                                creditCard: true,
                              },
                            }}
                            labelText="Número de tarjeta"
                            errorLabelText="Campo inválido"
                            validationFn={validateCard}
                            onFocus={(event) => {
                              this.setState({ focus: 'number' });
                            }}
                            inputProps={{
                              autoComplete: 'cc-number',
                              name: 'cardnumber',
                              value: this.state.lead.paymentData.cardNumber,

                              onChange: (event) => {
                                this.state.lead.paymentData.cardNumber = event.target.rawValue;

                                this.state.lead.paymentData.cardProvider = this.getCardType(
                                  event.target.rawValue
                                );
                                this.validateFormData(this.state.lead);

                                this.setState({
                                  lead: this.state.lead,
                                });
                              },

                              placeholder: '',
                            }}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6" sm="6">
                          <CustomInput
                            behavior={{
                              className: 'input form-control css-card',
                              options: {
                                date: true,
                                datePattern: ['m', 'y'],
                              },
                            }}
                            labelText="Vencimiento"
                            errorLabelText="Campo inválido"
                            validationFn={validateHasValue}
                            onFocus={(event) => {
                              this.setState({ focus: 'expiry' });
                            }}
                            inputProps={{
                              autoComplete: 'cc-exp',
                              name: 'ccmonth',
                              value: this.state.lead.paymentData.cardDueDate,
                              onFocus: (event) => {
                                // this.handleInputFocus(event);
                                this.setState({ focus: 'expiry' });
                              },
                              onChange: (event) => {
                                this.state.lead.paymentData.cardDueDate = event.target.rawValue;

                                this.validateFormData(this.state.lead);

                                this.setState({
                                  lead: this.state.lead,
                                });
                              },

                              placeholder: 'MM/AA',
                            }}
                          />
                        </Col>
                        <Col lg="6" sm="6">
                          <CustomInput
                            labelText="Código de seguridad"
                            errorLabelText="Campo inválido"
                            validationFn={(value) => {
                              return validateHasValue(value, 3);
                            }}
                            inputProps={{
                              autoComplete: 'cc-cvc',
                              name: 'cvc',
                              value: this.state.lead.paymentData.cardCVC,
                              onFocus: (event) => {
                                // this.handleInputFocus(event);
                                this.setState({ focus: 'cvc' });
                              },
                              onChange: (event) => {
                                this.state.lead.paymentData.cardCVC = event.target.value;

                                this.validateFormData(this.state.lead);

                                this.setState({
                                  lead: this.state.lead,
                                });
                              },

                              placeholder: 'XXX',
                            }}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="12" sm="12">
                          <CustomInput
                            labelText="Nombre y apellido"
                            errorLabelText="Campo inválido"
                            validationFn={validateHasValue}
                            onFocus={(event) => {
                              this.setState({ focus: 'name' });
                            }}
                            inputProps={{
                              autoComplete: 'cc-name',
                              name: 'ccname',
                              value: this.state.lead.paymentData.cardHolder,
                              onChange: (event) => {
                                if (this.textOnly(event.target.value)) {
                                  this.state.lead.paymentData.cardHolder = event.target.value;

                                  this.validateFormData(this.state.lead);

                                  this.setState({
                                    lead: this.state.lead,
                                  });
                                }
                              },

                              placeholder: '',
                            }}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="12" sm="12">
                          <CustomInput
                            errorLabelText="Campo inválido"
                            validationFn={validateHasValue}
                            behavior={{
                              className: 'input form-control css-card',
                              options: {
                                numeral: true,
                                numeralDecimalMark: '',
                                delimiter: '',
                              },
                            }}
                            labelText="Documento del titular"
                            onFocus={(event) => {}}
                            inputProps={{
                              name: 'identificationNumber',
                              value: this.state.lead.paymentData.identificationNumber,

                              onChange: (event) => {
                                this.state.lead.paymentData.identificationNumber =
                                  event.target.rawValue;

                                this.validateFormData(this.state.lead);

                                this.setState({
                                  lead: this.state.lead,
                                });
                              },

                              placeholder: '',
                            }}
                          />
                        </Col>
                      </Row>

                      {/* Este form es necesario porque sino se activa el autocomplete */}
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                        }}
                      >
                        <Row style={{ marginBottom: 5 }}>
                          <Col lg="12" sm="12" style={{ marginBottom: 5 }}>
                            <SearchLocationInput
                              labelText="Dirección"
                              errorLabelText="Por favor, asegurate de seleccionar una dirección desde las opciones desplegadas"
                              validationFn={validateHasValue}
                              className="form-group"
                              error={this.state.addressError}
                              onPlaceSelected={({
                                lat,
                                lng,
                                city,
                                county,
                                postal_code,
                                state,
                                addressString,
                                addressObject,
                                streetAndNumber,
                                country,
                              }) => {
                                this.state.lead.address = {
                                  geolocation: { lat, lng },
                                  city,
                                  county,
                                  postalCode: postal_code,
                                  state,
                                  formatted_address: addressString,
                                  streetAndNumber,
                                  country,
                                };

                                console.log('SELECTED ADDRESS: ', this.state.lead.address);
                                this.validateFormData(this.state.lead);

                                this.setState({
                                  lead: this.state.lead,

                                  addressError: false,
                                });
                              }}
                              inputProps={{
                                value: this.state.lead.address
                                  ? this.state.lead.address.formatted_address
                                  : '',
                                onBlur: () => {
                                  if (
                                    !this.state.lead.address ||
                                    !this.state.lead.address.formatted_address
                                  ) {
                                    this.setState({ addressError: true });
                                  } else {
                                    this.setState({ addressError: false });
                                  }
                                },
                              }}
                            />
                          </Col>
                        </Row>
                      </form>
                      <Row>
                        <Col lg="6" sm="6">
                          <CustomInput
                            labelText="Piso"
                            errorLabelText="Campo inválido"
                            inputProps={{
                              name: 'floor',
                              value: this.state.lead.address ? this.state.lead.address.floor : '',

                              onChange: (event) => {
                                if (!this.state.lead.address) this.state.lead.address = {};

                                this.state.lead.address.floor = event.target.value;

                                this.validateFormData(this.state.lead);

                                this.setState({
                                  lead: this.state.lead,
                                });
                              },

                              placeholder: '',
                            }}
                          />
                        </Col>
                        <Col lg="6" sm="6">
                          <CustomInput
                            labelText="Departamento"
                            errorLabelText="Campo inválido"
                            inputProps={{
                              name: 'floor',
                              value: this.state.lead.address
                                ? this.state.lead.address.department
                                : '',

                              onChange: (event) => {
                                if (!this.state.lead.address) this.state.lead.address = {};

                                this.state.lead.address.department = event.target.value;

                                this.validateFormData(this.state.lead);

                                this.setState({
                                  lead: this.state.lead,
                                });
                              },

                              placeholder: '',
                            }}
                          />
                        </Col>
                      </Row>

                      {/* <Row>
                        <Col lg="12" sm="12">
                          <CustomInput
                            labelText="Cod descuento"
                            errorLabelText="Campo inválido"
                            inputProps={{
                              name: "discountCode",
                              value: this.state.lead.discountCode
                                ? this.state.lead.discountCode
                                : "",

                              onChange: (event) => {
                                this.state.lead.discountCode =
                                  event.target.value;

                                this.validateFormData(this.state.lead);

                                this.setState({
                                  lead: this.state.lead,
                                });
                              },

                              placeholder: "",
                            }}
                          />
                        </Col>
                      </Row> */}

                      <div>
                        <Button
                          className="mt-4"
                          color="custom"
                          type="button"
                          onClick={(event) => {
                            event.preventDefault();
                            this.savePaymentData();
                          }}
                          disabled={
                            (this.state.errors && this.state.errors.length !== 0) ||
                            this.state.loading
                          }
                        >
                          Revisar información
                        </Button>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </section>
        </main>
      </>
    );
  }
}

export default DigitalOnboardingStep4Checkout;
