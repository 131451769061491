import React from 'react';
// import MetaTags from "react-meta-tags";

// reactstrap components
import { Button, Card, CardHeader, CardBody, Container, Row, Col } from 'reactstrap';

// core components
import BasicNavbar from 'components/Navbars/BasicNavbar.jsx';

import WhatsappButton from 'components/Buttons/WhatsappButton';

import { addBeneficiaries } from 'services/leadsServices';

import DigitalOnboardingStepper from './DigitalOnboardingStepper';
import {
  getUserToken,
  setPersistedValue,
  getPersistedValue,
  validateHasValue,
  validateDate,
  goToFatalErrorPage,
} from 'helpers/coreHelper';
import CustomInput from 'components/Inputs/CustomInput.jsx';

import Loading from 'components/Loading/Loading.jsx';
import {
  PLAN_SWITCHER_OPTION_CHILDRENS,
  PLAN_SWITCHER_OPTION_ME_AND_PARTNER,
  PLAN_SWITCHER_OPTION_FAMILY,
} from 'types/planSwitcherTypes';

import { RELATIONSHIP_CHILDREN, RELATIONSHIP_PARTNER } from 'types/relationshipTypes';

class DigitalOnboardingStep3Beneficiaries extends React.Component {
  constructor(props) {
    super(props);

    let lead = null;

    try {
      lead = getPersistedValue('lead');
    } catch (e) {}

    if (!lead && this.props.location && this.props.location.state && this.props.location.state.lead)
      lead = this.props.location.state.lead;

    if (!lead.beneficiaries) {
      //lead.beneficiaries.push({ name: "", birthday: "", identificationNumber: "" });
    }

    this.state = {
      lead: lead,
      errors: null,
      loading: false,
    };
  }

  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;

    this.validateFormData();
  }

  validateFormData(lead) {
    let errors = [];

    if (!lead) lead = this.state.lead;

    lead.beneficiaries.forEach((beneficiary) => {
      if (!this.isValidadBeneficiaryData(beneficiary)) {
        errors.push(beneficiary);
      }
    });

    if (errors.length === 0) errors = null;

    this.setState({ errors: errors });

    return errors;
  }

  saveBeneficiaries() {
    let errors = this.validateFormData();

    if (errors) return;

    this.setState({ loading: true });
    getUserToken()
      .then((token) => {
        addBeneficiaries(token, this.state.lead)
          .then(() => {
            setPersistedValue('lead', this.state.lead);

            this.props.history.push('/onboarding-step-4');
          })
          .catch((e) => {
            console.log(e, e.message, e.code);

            goToFatalErrorPage(this.props.history);
          });
      })
      .catch((e) => {
        console.log(e, e.message, e.code);

        goToFatalErrorPage(this.props.history);
      });
  }

  isValidadBeneficiaryData(beneficiary) {
    if (!beneficiary) return false;
    return (
      validateHasValue(beneficiary.name) &&
      beneficiary.name.split(' ').length >= 2 &&
      validateDate(beneficiary.birthday) &&
      validateHasValue(beneficiary.identificationNumber)
    );
  }

  renderTitle() {
    const option = this.state.lead.selectedPlanSwitcherOption.key;
    //console.log("option:", option);
    if (option === PLAN_SWITCHER_OPTION_CHILDRENS.key)
      return <div className="form-title">Completá los datos de tus hijos</div>;
    else if (option === PLAN_SWITCHER_OPTION_ME_AND_PARTNER.key)
      return <div className="form-title">Completá los datos de tu pareja</div>;
    else if (option === PLAN_SWITCHER_OPTION_FAMILY.key)
      return <div className="form-title">Completá los datos de tu familia</div>;

    return <div className="form-title">Completá los datos de tu grupo familiar</div>;
  }

  renderBeneficiaryTitle(beneficiary, index) {
    if (beneficiary.relationship === RELATIONSHIP_CHILDREN.key) {
      return <>Hijo {beneficiary.index}</>;
    } else if (beneficiary.relationship === RELATIONSHIP_PARTNER.key) {
      return <>Pareja</>;
    }

    return <>Familiar {beneficiary.index}</>;
  }

  render() {
    return (
      <>
        {/* <MetaTags>
          <title>El plan para tu seguro de salud | Hola René</title>
          <meta
            id="meta-description"
            name="description"
            content="Toda la información para que elijas el plan de salud ideal para vos y tu familia."
          />
        </MetaTags> */}

        <WhatsappButton />
        <BasicNavbar />

        <main ref="main">
          <section className="section section-shaped bg-light-grey">
            <Container>
              <Row className="justify-content-center">
                <Col lg="3 px-sm-0" md="3 mr-3">
                  <DigitalOnboardingStepper step={2} maxSteps={4} progress={50} />
                </Col>
                <Col lg="7" md="7" className="pl-md-0">
                  <Loading loading={this.state.loading} />
                  <Button
                    className="mt-4 hidden-sm"
                    color="custom flat back"
                    type="button"
                    onClick={() => {
                      this.props.history.goBack();
                    }}
                  ></Button>
                  <Card className="bg-transparent card-form border-0">
                    <CardHeader className="bg-transparent">
                      {this.renderTitle()}

                      <div className="form-subtitle">
                        Completá los datos del resto de tu grupo. Podés omitir este paso por el
                        momento y completrlo los datos desde la app.
                      </div>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col lg="12" sm="12">
                          {this.state.lead.beneficiaries &&
                            this.state.lead.beneficiaries.map((beneficiary, index) => {
                              return (
                                <Card key={index} className=" bg-transparent card-form border-0">
                                  <CardBody className="p-0">
                                    <div className="d-flex">
                                      <div className="fw">
                                        <Row>
                                          <Col lg="6" sm="6" xs="6">
                                            <div className="title-form-purple">
                                              {this.renderBeneficiaryTitle(beneficiary, index)}
                                            </div>
                                          </Col>
                                        </Row>

                                        <Row className="pt-2">
                                          <Col lg="12" sm="12">
                                            <CustomInput
                                              labelText="Nombre completo"
                                              errorLabelText="Campo inválido"
                                              validationFn={validateHasValue}
                                              inputProps={{
                                                value: this.state.lead.beneficiaries[index].name,
                                                onChange: (event) => {
                                                  this.state.lead.beneficiaries[index].name =
                                                    event.target.value;

                                                  this.validateFormData(this.state.lead);

                                                  this.setState({
                                                    lead: this.state.lead,
                                                  });
                                                },

                                                placeholder: '',
                                              }}
                                            />
                                          </Col>
                                        </Row>

                                        <Row>
                                          <Col lg="12" sm="12">
                                            <CustomInput
                                              behavior={{
                                                className: 'input form-control css-date',
                                                options: {
                                                  date: true,
                                                  datePattern: ['d', 'm', 'Y'],
                                                },
                                              }}
                                              labelText="Fecha de nacimiento"
                                              errorLabelText="Campo inválido"
                                              validationFn={validateDate}
                                              inputProps={{
                                                value:
                                                  this.state.lead.beneficiaries &&
                                                  this.state.lead.beneficiaries[index].birthday
                                                    ? this.state.lead.beneficiaries[index].birthday
                                                    : '',
                                                onChange: (event) => {
                                                  this.state.lead.beneficiaries[index].birthday =
                                                    event.target.value;

                                                  this.validateFormData(this.state.lead);

                                                  this.setState({
                                                    lead: this.state.lead,
                                                  });
                                                },

                                                placeholder: 'DD/MM/AAAA',
                                              }}
                                            />
                                          </Col>
                                        </Row>

                                        <Row>
                                          <Col lg="12" sm="12">
                                            <CustomInput
                                              labelText="DNI"
                                              errorLabelText="Campo inválido"
                                              validationFn={validateHasValue}
                                              inputProps={{
                                                type: 'number',
                                                name: 'number',
                                                value:
                                                  this.state.lead.beneficiaries &&
                                                  this.state.lead.beneficiaries[index]
                                                    .identificationNumber
                                                    ? this.state.lead.beneficiaries[index]
                                                        .identificationNumber
                                                    : '',
                                                onChange: (event) => {
                                                  this.state.lead.beneficiaries[
                                                    index
                                                  ].identificationNumber = event.target.value;

                                                  this.validateFormData(this.state.lead);

                                                  this.setState({
                                                    lead: this.state.lead,
                                                  });
                                                },

                                                placeholder: '',
                                              }}
                                            />
                                          </Col>
                                        </Row>
                                      </div>
                                    </div>
                                  </CardBody>
                                </Card>
                              );
                            })}
                        </Col>
                      </Row>

                      {/* {this.state.lead.beneficiaries.length === 1 &&
                        !this.isValidadBeneficiaryData(
                          this.state.lead.beneficiaries[0]
                        ) && (
                          <label className="msg-form mt-5">
                            Necesitás agregar al menos un heredero
                          </label>
                        )} */}

                      <div>
                        <Button
                          className="mt-4"
                          color="custom"
                          type="button"
                          onClick={(event) => {
                            event.preventDefault();
                            this.saveBeneficiaries();
                          }}
                          disabled={
                            (this.state.errors && this.state.errors.length !== 0) ||
                            this.state.loading
                          }
                        >
                          Elegir medio de pago
                        </Button>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </section>
        </main>
      </>
    );
  }
}

export default DigitalOnboardingStep3Beneficiaries;
