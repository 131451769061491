import React from "react";
// import MetaTags from "react-meta-tags";

// Import Swiper styles
import "swiper/swiper.scss";

// reactstrap components
import {  Container, Row, Col } from "reactstrap";

// core components
import WhatsappButton from "components/Buttons/WhatsappButton";
import ContactUsLandingNavbar from "./ContactUsLandingNavbar.jsx";
import Footer from "./Footer.jsx";
import ModalError from "components/Modals/ModalError.jsx";
import ContactForm from "components/Forms/ContactForm.jsx";
import { setPersistedValue } from "helpers/coreHelper";

import "cleave.js/dist/addons/cleave-phone.ar";

class ContactUsLanding extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      // userFullName: "",
      // phoneNumber: "",
      // email: "",
      // phoneRawValue: "",
      // loading: false,
      // disabled: true,
      errorModalInfo: null,
    };

    // this.onPhoneChange = this.onPhoneChange.bind(this);

    this.myRef = React.createRef();
  }

  navigateToAboutUs() {
    this.props.history.push("/about-us");
  }

  navigateToOnboarding() {
    let targetUrl = "/onboarding";

    const lead = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      phoneNumber: this.state.phoneNumber,
      email: this.state.email,
    };

    setPersistedValue("lead", lead);

    if (this.props.location.search) targetUrl += this.props.location.search;
    // this.props.history.push(targetUrl, {
    //   lead: lead,
    // });

    this.props.history.push(targetUrl);
  }

  renderError() {
    if (this.state.errorModalInfo) {
      return (
        <ModalError
          title={this.state.errorModalInfo.title}
          description={this.state.errorModalInfo.description}
          onClose={() => {
            this.setState({ errorModalInfo: null });
          }}
        />
      );
    }
    return null;
  }

  componentDidMount() {
    //document.documentElement.scrollTop = 0;
    //document.scrollingElement.scrollTop = 0;
    // this.refs.main.scrollTop = 0;

    this.myRef.current.scrollIntoView();

    // this.validateFormData();
  }

  // onPhoneChange(event) {
  //   this.setState({ phoneNumber: "+549" + event.target.rawValue });
  //   this.setState({ phoneRawValue: event.target.rawValue });
  // }

  // formIsFull() {
  //   if (this.validateFormData() !== []) {
  //     this.setState({ disabled: false });
  //   } else {
  //     this.setState({ disabled: true });
  //   }
  // }

  // onSubmitFormData() {
  //   if (this.state.loading) {
  //     return;
  //   }

  //   let errors = this.validateFormData();

  //   if (errors) {
  //     this.setState({
  //       errorModalInfo: {
  //         title: "Error",
  //         description: "Alguno de los campos es inválido",
  //       },
  //     });
  //     return;
  //   }

  //   this.setState({ loading: true });

  //   //011 y lrpmqtrmp
  //   const phoneNumber = this.state.phoneNumber.replace(
  //     PHONE_NUMBER_PREFIX_AR + "011",
  //     PHONE_NUMBER_PREFIX_AR + "11"
  //   );

  //   let search = window.location.search;
  //   let params = new URLSearchParams(search);

  //   let utm_campaign = params.get("utm_campaign");
  //   let utm_source = params.get("utm_source");
  //   let utm_content = params.get("utm_content");
  //   let utm_medium = params.get("utm_medium");
  //   let utm_term = params.get("utm_term");

  //   let utmData = null;

  //   utmData = {
  //     utm_campaign: utm_campaign,
  //     utm_source: utm_source,
  //     utm_content: utm_content,
  //     utm_medium: utm_medium,
  //     utm_term: utm_term,
  //   };

  //   const fistName = this.state.userFullName;
  //   const lastName = "";
  //   const email = this.state.email;

  //   let lead = {
  //     fistName: fistName,
  //     lastName: lastName,
  //     phoneNumber: phoneNumber,
  //     email: email,
  //     utmData: utmData,
  //   };

  //   createCallRequestLead(lead)
  //     .then(() => {
  //       this.props.history.push("/contact-us-success");
  //     })
  //     .catch((e) => {
  //       this.setState({
  //         errorModalInfo: {
  //           title: "Error",
  //           description:
  //             "¡Hola! Hubo un error en el proceso (" + e.message + ")",
  //         },
  //       });
  //     })
  //     .finally(() => {
  //       this.setState({ loading: false });
  //     });
  // }

  // validateFormData(userFullName, phoneNumber, email) {
  //   let errors = [];

  //   if (!userFullName) userFullName = this.state.userFullName;

  //   if (!phoneNumber) phoneNumber = this.state.phoneNumber;

  //   if (!email) email = this.state.email;

  //   if (!validateHasValue(userFullName)) errors.push("userFullName");
  //   if (!validateHasValue(this.state.phoneRawValue, 10))
  //     errors.push("phoneNumber");
  //   if (!validateHasValue(email) || !validateEmail(email)) {
  //     errors.push("email");
  //   }
  //   if (errors.length === 0) errors = null;

  //   this.setState({ errors: errors });

  //   //console.log("errors:", errors);
  //   return errors;
  // }

  // renderError() {
  //   if (this.state.errorModalInfo) {
  //     return (
  //       <ModalError
  //         title={this.state.errorModalInfo.title}
  //         description={this.state.errorModalInfo.description}
  //         onClose={() => {
  //           this.setState({ errorModalInfo: null });
  //         }}
  //       />
  //     );
  //   }
  //   return null;
  // }

  render() {
    return (
      <>
        {/* <MetaTags>
          <title>Contactate con nosotros | Hola René</title>
          <meta
            id="meta-description"
            name="description"
            content="Contactate con nosotros para contratar nuestro seguro de salud o información que requieras al respecto."
          />
        </MetaTags> */}

        {this.renderError()}

        <WhatsappButton />

        <ContactUsLandingNavbar />
        <main ref="main">
          {/*hero*/}
          <section className="section section-lg section-contact-us">
            <Container className="d-flex">
              <div className="separator separator-top bg-white "></div>
              {/* SVG separator */}
              <div className="separator separator-top separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 200"
                  x="0"
                  y="0"
                >
                  <polygon
                    className="fill-white"
                    points="0 0 2560 0 2560 0 0 200"
                  />
                </svg>
              </div>
              <div className="col px-0">
                <Row>
                  <Col xs="12" lg="12" className="text-center">
                    <h1 className="contact-us-title text-center">
                      ¿Tenés dudas o consultas?
                      <br />
                      Estamos a tu disposición
                    </h1>
                    <img
                      alt="..."
                      style={{ width: "350px" }}
                      src={require("assets/img/brand/contactus.png")}
                    />
                    <p className="contact-us-sub-title">
                      Escribinos por WhatsApp
                    </p>
                    <p className="text-white">
                      Vas a recibir atención inmediata con uno de nuestros
                      asesores
                    </p>
                    <p className="whatapp">
                      <img
                        alt="..."
                        src={require("assets/img/brand/whatapp-contact.png")}
                      />
                      <span>11 3140-9784</span>
                    </p>
                  </Col>
                </Row>
              </div>
            </Container>
          </section>

          {/*Llamanos a nuestra Atención al Cliente */}
          <section className="section section-md  bg-light-grey pattern-right">
            <Container>
              <Row className="row-grid align-items-center">
                <Col md="12">
                  <div>
                    <h3 className="gradient-title text-center">
                      Llamanos a nuestra Atención al Cliente
                    </h3>
                    <p className="text-center">
                      {" "}
                      Podés hacerlo de lunes a viernes de 10 a 16 hs.{" "}
                    </p>
                    <p className="gradient-title text-center">0810-220-0229</p>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>

          {/*Form*/}
          <section
            className="section section-lg pt-150 pb-150"
            id="section-contacto"
            ref={this.myRef}
          >
            <Container>
              <Row className="row-grid align-items-center justify-content-center">
                <Col md="8">
                  <form className="contact-us-form">
                    <h4>Dejanos tu contacto</h4>
                    <p>
                      En breve te llamaremos para darte más información sobre
                      nuestros planes.
                    </p>

                    {/* <CustomInput
                      inputProps={{
                        value: this.state.userFullName,
                        name: "name",
                        onChange: (event) => {
                          this.state.userFullName = event.target.value;

                          this.setState(
                            {
                              userFullName: this.state.userFullName,
                            },

                            () => {
                              this.formIsFull();
                            }
                          );
                        },

                        placeholder: "Nombre completo",
                        className: "contact-input",
                      }}
                    />

                    <CustomInput
                      behavior={{
                        className: "css-phone input form-control contact-input",
                        options: {
                          phone: true,
                          phoneRegionCode: "AR",
                        },
                        // initialValue: this.state.phoneNumber
                        //   .replace(" ", "")
                        //   .replace(" ", "")
                        //   .replace(PHONE_NUMBER_PREFIX_AR, ""),
                      }}
                      validationFn={(info) => {
                        return validateHasValue(info, 10);
                      }}
                      error={this.state.errorPhoneNumber}
                      inputProps={{
                        type: "tel",
                        name: "phone",
                        autoComplete: "tel",
                        value: this.state.phoneNumber
                          .replace(" ", "")
                          .replace(" ", "")
                          .replace(PHONE_NUMBER_PREFIX_AR, ""),
                        onChange: (event) => {
                          this.state.phoneNumber =
                            PHONE_NUMBER_PREFIX_AR + event.target.rawValue;

                          this.setState(
                            {
                              phoneNumber: this.state.phoneNumber,
                              phoneNumberValidated: false, //si lo cambia tiene que validarlo nuevamente
                              phoneRawValue: event.target.rawValue,
                            },

                            () => {
                              this.formIsFull();
                            }
                          );
                        },
                        placeholder: "Tél. celular (código de área + núm)",
                      }}
                    />

                    <CustomInput
                      inputProps={{
                        value: this.state.email,
                        name: "email",
                        onChange: (event) => {
                          // this.state.email = event.target.value;

                          this.setState(
                            {
                              email: event.target.value,
                            },

                            () => {
                              this.formIsFull();
                            }
                          );
                        },

                        placeholder: "Correo electrónico",
                        className: "contact-input",
                      }}
                    />

                    <div className="btn-wrapper">
                      <Button
                        className="mt-4 full-w"
                        color="custom noarrow"
                        type="button"
                        onClick={(event) => {
                          this.onSubmitFormData();
                        }}
                        disabled={
                          this.state.loading ||
                          this.state.disabled ||
                          this.state.errorModalInfo
                        }
                      >
                        {!this.state.loading && "Enviar"}
                        {this.state.loading && "Cargando..."}
                      </Button>
                    </div> */}
                  </form>
                  <ContactForm
                    onError={(e) => {
                      this.setState({
                        errorModalInfo: {
                          title: "Error",
                          description:
                            "¡Hola! Hubo un error en el proceso (" +
                            e.message +
                            ")",
                        },
                      });
                    }}
                    onSuccess={() => {
                      this.props.history.push("/contact-us-success");
                    }}
                  />
                </Col>
              </Row>
            </Container>
          </section>
        </main>
        <Footer />
      </>
    );
  }
}

export default ContactUsLanding;
