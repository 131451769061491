import React from "react";

// reactstrap components
import { Button, Container, Col, Row, FormGroup, Input } from "reactstrap";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.jsx";

import { authRef } from "config/firebase";

import {
  getAssistance,
  changeAssistanceState,
  deleteAssistance,
} from "services/assistancesServices";

import { TYPE_TELEMEDICINE } from "types/productsTypes";

import AssistanceLadSimulatorEditionModal from "./AssistanceLadSimulatorEditionModal";
const {
  ASSISTANCE_STATE_REQUESTED,

  ASSISTANCE_STATE_CANCELED_BY_USER,
  ASSISTANCE_STATE_FINISHED,
} = require("types/assistancesStateTypes");

const ASSISTANCES_STATE_LIST = [
  { key: ASSISTANCE_STATE_REQUESTED, label: "REQUESTED", index: 1 },
  // { key: ASSISTANCE_STATE_PENDING, label: "Pendiente", index: 2 },
  // { key: ASSISTANCE_STATE_IN_PROGRESS, label: "En progreso", index: 3 },
  {
    key: ASSISTANCE_STATE_CANCELED_BY_USER,
    label: "CANCELED_BY_USER",
    index: 4,
  },
  { key: ASSISTANCE_STATE_FINISHED, label: "FINISHED", index: 5 },
];

const {
  ASSISTANCE_RESULT_STATUS_RESOLVED,
  ASSISTANCE_RESULT_STATUS_URGENT,
  ASSISTANCE_RESULT_STATUS_REFERRED,
  ASSISTANCE_RESULT_STATUS_CANCELED,
  ASSISTANCE_RESULT_STATUS_UNKNOWN,
} = require("types/assistancesResultStatusTypes");

const ASSISTANCE_RESULT_CODES_LIST = [
  { key: ASSISTANCE_RESULT_STATUS_RESOLVED, label: "RESOLVED", index: 1 },
  { key: ASSISTANCE_RESULT_STATUS_URGENT, label: "URGENT", index: 2 },
  { key: ASSISTANCE_RESULT_STATUS_REFERRED, label: "REFERRED", index: 3 },
  { key: ASSISTANCE_RESULT_STATUS_CANCELED, label: "CANCELED", index: 4 },
  { key: ASSISTANCE_RESULT_STATUS_UNKNOWN, label: "UNKNOWN", index: 5 },
];

class AssistanceDetail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      assistance: null,
      addNewAssistance: {},
      messages: [],
      selectedAssistanceState: null,
      selectedAssistanceResultCode: null,
      assistanceLadSimulatorEditionModal: false,
    };
  }

  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
    });
  };

  getSelectedInitialAssistanceStatus(assistance) {
    if (!assistance) return ASSISTANCES_STATE_LIST[0].key;

    if (assistance.assistanceState === ASSISTANCE_STATE_REQUESTED)
      return ASSISTANCE_STATE_FINISHED;
    if (assistance.assistanceState === ASSISTANCE_STATE_FINISHED)
      return ASSISTANCE_STATE_FINISHED;

    const res = ASSISTANCES_STATE_LIST.find((item) => {
      return item.key !== assistance.assistanceState;
    });

    if (res) return res.key;
    else return null;
  }

  getSelectedInitialAssistanceResultCode(assistance) {
    if (!assistance) return null;

    if (assistance.result && assistance.result.code) {
      const res = ASSISTANCE_RESULT_CODES_LIST.find((code) => {
        return code.key === assistance.result.code;
      });

      if (res) return res.key;
    }

    return null;
  }

  componentDidMount() {
    let assistanceId = this.props.match.params.id;

    authRef.onAuthStateChanged((user) => {
      this.setState({ currentUser: user });
      getAssistance(assistanceId)
        .then((assistance) => {
          console.log("assistance: ", assistance);

          const selectedAssistanceState =
            this.getSelectedInitialAssistanceStatus(assistance);

          const selectedAssistanceResultCode =
            this.getSelectedInitialAssistanceResultCode(assistance);

          this.setState({
            assistance: assistance,
            selectedAssistanceState: selectedAssistanceState,
            selectedAssistanceResultCode: selectedAssistanceResultCode,
          });
        })
        .catch((e) => {
          if (e.response && e.response.data && e.response.data.message)
            alert(e.response.data.message);
          else alert(e.message);
        });
    });
  }

  componentWillUnmount() {}

  componentDidUpdate(e) {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    // this.refs.mainContent.scrollTop = 0;
  }

  innerChangeAssistanceState() {
    if (
      this.state.selectedAssistanceState === ASSISTANCE_STATE_FINISHED &&
      !this.state.selectedAssistanceResultCode
    ) {
      alert("Se debe asignar el resultado");
      return;
    }

    this.state.assistance.assistanceState = this.state.selectedAssistanceState;

    if (!this.state.assistance.assistanceResult)
      this.state.assistance.assistanceResult = {};
    this.state.assistance.assistanceResult =
      this.state.selectedAssistanceResultCode;

    changeAssistanceState(this.state.assistance)
      .then((updatedAssistance) => {
        this.setState({ assistance: this.state.assistance });
        alert("Ok");
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.message)
          alert(e.response.data.message);
        else alert(e.message);
      });
  }

  innerDeleteAssistance(assistance) {
    authRef.onAuthStateChanged((user) => {
      user.getIdToken().then((token) => {
        deleteAssistance(token, assistance)
          .then((assistance) => {
            console.log("Assistance: ", assistance);
            alert("Assistance: " + JSON.stringify(assistance) + "removed");
          })
          .catch((e) => {
            if (e.response && e.response.data && e.response.data.message)
              alert(e.response.data.message);
            else alert(e.message);
          });
      });
    });
  }

  showLadSimulatorModal() {
    this.toggleModal("assistanceLadSimulatorEditionModal");
  }

  render() {
    if (!this.state.assistance) return <>loading</>;
    return (
      <>
        <div className="main-content" ref="mainContent">
          <AdminNavbar {...this.props} currentUser={this.state.currentUser} />

          {this.state.assistance && (
            <AssistanceLadSimulatorEditionModal
              modalName="assistanceLadSimulatorEditionModal"
              assistance={this.state.assistance}
              assistanceLadSimulatorEditionModalIsOpen={
                this.state.assistanceLadSimulatorEditionModal
              }
              toggleModal={this.toggleModal.bind(this)}
            />
          )}

          <Container fluid style={{ margin: 20 }}>
            <div className="modal-body">
              <Row>
                <Col lg="6" sm="6">
                  <div className="mb-3">
                    <small className="text-uppercase font-weight-bold">
                      Id
                    </small>
                  </div>
                  <FormGroup>
                    <Input
                      disabled
                      type="text"
                      value={
                        this.state.assistance.id ? this.state.assistance.id : ""
                      }
                    />
                  </FormGroup>
                </Col>
                <Col lg="6" sm="6">
                  <div className="mb-3">
                    <small className="text-uppercase font-weight-bold">
                      Updated at
                    </small>
                  </div>
                  <FormGroup>
                    <Input
                      disabled
                      type="text"
                      value={
                        this.state.assistance.updatedAt
                          ? this.state.assistance.updatedAt
                          : this.state.assistance.createdAt
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>

              <div className="mb-3">
                <small className="text-uppercase font-weight-bold">User</small>
              </div>
              <Row>
                <Col lg="4" sm="4">
                  <FormGroup>
                    <label>
                      <span className="text-muted">Full name</span>
                    </label>

                    <Input
                      disabled
                      type="text"
                      value={
                        this.state.assistance.user &&
                        this.state.assistance.user.firstName &&
                        this.state.assistance.user.lastName
                          ? this.state.assistance.user.firstName +
                            " " +
                            this.state.assistance.user.lastName
                          : ""
                      }
                    />
                  </FormGroup>
                </Col>

                <Col lg="4" sm="4">
                  <FormGroup>
                    <label>
                      <span className="text-muted">DNI</span>
                    </label>

                    <Input
                      disabled
                      type="text"
                      value={
                        this.state.assistance.user &&
                        this.state.assistance.user.identificationNumber
                          ? this.state.assistance.user.identificationNumber
                          : ""
                      }
                    />
                  </FormGroup>
                </Col>

                <Col lg="4" sm="4">
                  <FormGroup>
                    <label>
                      <span className="text-muted">ID</span>
                    </label>

                    <Input
                      disabled
                      type="text"
                      value={this.state.assistance.user_id}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <div className="mb-3">
                <small className="text-uppercase font-weight-bold">
                  Patient
                </small>
              </div>
              <Row>
                <Col lg="4" sm="4">
                  <FormGroup>
                    <label>
                      <span className="text-muted">Full name</span>
                    </label>

                    <Input
                      disabled
                      type="text"
                      value={
                        this.state.assistance.patient &&
                        this.state.assistance.patient.firstName &&
                        this.state.assistance.patient.lastName
                          ? this.state.assistance.patient.firstName +
                            " " +
                            this.state.assistance.patient.lastName
                          : ""
                      }
                    />
                  </FormGroup>
                </Col>

                <Col lg="4" sm="4">
                  <FormGroup>
                    <label>
                      <span className="text-muted">DNI</span>
                    </label>

                    <Input
                      disabled
                      type="text"
                      value={
                        this.state.assistance.patient &&
                        this.state.assistance.patient.identificationNumber
                          ? this.state.assistance.patient.identificationNumber
                          : ""
                      }
                    />
                  </FormGroup>
                </Col>

                <Col lg="4" sm="4">
                  <FormGroup>
                    <label>
                      <span className="text-muted">ID</span>
                    </label>

                    <Input
                      disabled
                      type="text"
                      value={
                        this.state.assistance.patient
                          ? this.state.assistance.patient.uid
                          : ""
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>

              <div className="mb-3">
                <small className="text-uppercase font-weight-bold">
                  Asistencia
                </small>
              </div>
              <Row>
                <Col lg="6" sm="6">
                  <FormGroup>
                    <label>
                      <span className="text-muted">Tipo</span>
                    </label>

                    <Input
                      disabled
                      type="text"
                      value={
                        this.state.assistance.type
                          ? this.state.assistance.type
                          : ""
                      }
                    />
                  </FormGroup>
                </Col>

                <Col lg="6" sm="6">
                  <FormGroup>
                    <label>
                      <span className="text-muted">Estado actual</span>
                    </label>

                    <Input
                      disabled
                      type="text"
                      value={
                        this.state.assistance.assistanceState
                          ? this.state.assistance.assistanceState
                          : ""
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col lg="4" sm="4">
                  <FormGroup>
                    <label>
                      <span className="text-muted">Fecha evento</span>
                    </label>

                    <Input
                      disabled
                      type="text"
                      value={
                        this.state.assistance.eventDate
                          ? this.state.assistance.eventDate
                          : ""
                      }
                    />
                  </FormGroup>
                </Col>

                <Col lg="4" sm="4">
                  <FormGroup>
                    <label>
                      <span className="text-muted">Precio</span>
                    </label>

                    <Input
                      disabled
                      type="text"
                      value={
                        this.state.assistance.assistancePrice
                          ? this.state.assistance.assistancePrice
                          : ""
                      }
                    />
                  </FormGroup>
                </Col>

                <Col lg="4" sm="4">
                  <FormGroup>
                    <label>
                      <span className="text-muted">Proveedor de servicio</span>
                    </label>

                    <Input
                      disabled
                      type="text"
                      value={
                        this.state.assistance.provider
                          ? this.state.assistance.provider
                          : ""
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>

              <div className="mb-3">
                <small className="text-uppercase font-weight-bold">
                  Profesional
                </small>
              </div>
              <Row>
                <Col lg="4" sm="4">
                  <FormGroup>
                    <label>
                      <span className="text-muted">ID/DNI</span>
                    </label>

                    <Input
                      disabled
                      type="text"
                      value={
                        this.state.assistance.professional
                          ? this.state.assistance.professional.externalId
                          : ""
                      }
                    />
                  </FormGroup>
                </Col>

                <Col lg="4" sm="4">
                  <FormGroup>
                    <label>
                      <span className="text-muted">Nombre</span>
                    </label>

                    <Input
                      disabled
                      type="text"
                      value={
                        this.state.assistance.professional
                          ? this.state.assistance.professional.name
                          : ""
                      }
                    />
                  </FormGroup>
                </Col>

                <Col lg="4" sm="4">
                  <FormGroup>
                    <label>
                      <span className="text-muted">Especialidad</span>
                    </label>

                    <Input
                      disabled
                      type="text"
                      value={
                        this.state.assistance.professional &&
                        this.state.assistance.professional.rawSpeciality
                          ? this.state.assistance.professional.rawSpeciality
                          : ""
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>

              <div className="mb-3">
                <small className="text-uppercase font-weight-bold">
                  Resultado
                </small>
              </div>
              <Row>
                <Col lg="4" sm="4">
                  <FormGroup>
                    <label>
                      <span className="text-muted">Estado</span>
                    </label>

                    <Input
                      disabled
                      type="text"
                      value={
                        this.state.assistance.assistanceResult
                          ? this.state.assistance.assistanceResult.status
                          : ""
                      }
                    />
                  </FormGroup>
                </Col>

                <Col lg="4" sm="4">
                  <FormGroup>
                    <label>
                      <span className="text-muted">Práctica</span>
                    </label>

                    <Input
                      disabled
                      type="text"
                      value={
                        this.state.assistance.assistanceResult
                          ? this.state.assistance.assistanceResult
                              .medicalPractice
                          : ""
                      }
                    />
                  </FormGroup>
                </Col>

                <Col lg="4" sm="4">
                  <FormGroup>
                    <label>
                      <span className="text-muted">Receta</span>
                    </label>

                    <Input
                      disabled
                      type="text"
                      value={
                        this.state.assistance.assistanceResult
                          ? this.state.assistance.assistanceResult.prescription
                          : ""
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col lg="12" sm="12">
                  <FormGroup>
                    <label>
                      <span className="text-muted">Descripción</span>
                    </label>

                    <Input
                      disabled
                      type="text"
                      value={
                        this.state.assistance.assistanceResult
                          ? this.state.assistance.assistanceResult.message
                          : ""
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>
              <div className="mb-3">
                <small className="text-uppercase font-weight-bold">
                  Nuevo estado
                </small>
              </div>
              <Row>
                <Col lg="6" sm="6">
                  <FormGroup>
                    <select
                      disabled={
                        this.state.assistance.assistanceState ===
                        ASSISTANCE_STATE_FINISHED
                      }
                      value={this.state.selectedAssistanceState}
                      onChange={(e) => {
                        const selectedAssistanceState =
                          ASSISTANCES_STATE_LIST.find((item) => {
                            return item.key === e.target.value;
                          });

                        this.setState({
                          selectedAssistanceState: selectedAssistanceState.key,
                        });
                      }}
                    >
                      {ASSISTANCES_STATE_LIST.filter((item) => {
                        return (
                          item.key !== this.state.assistance.assistanceState
                        );
                      }).map((item, index) => {
                        return (
                          <option key={index} value={item.key}>
                            {item.label}
                          </option>
                        );
                      })}
                    </select>
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col lg="6" sm="6">
                  <FormGroup>
                    <label>
                      <span className="text-muted">Resultado</span>
                    </label>

                    <br />
                    <select
                      disabled={
                        this.state.assistance.assistanceState ===
                        ASSISTANCE_STATE_FINISHED
                      }
                      value={this.state.selectedAssistanceResultCode}
                      onChange={(e) => {
                        const selectedAssistanceResultCode =
                          ASSISTANCE_RESULT_CODES_LIST.find((item) => {
                            return item.key === e.target.value;
                          });

                        this.setState({
                          selectedAssistanceResultCode:
                            selectedAssistanceResultCode.key,
                        });
                      }}
                    >
                      <option key={999} value={"na"}>
                        {"Sin asignar"}
                      </option>
                      {ASSISTANCE_RESULT_CODES_LIST.map((item, index) => {
                        return (
                          <option key={index} value={item.key}>
                            {item.label}
                          </option>
                        );
                      })}
                    </select>
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col lg="6" sm="6">
                  <FormGroup>
                    <Button
                      className="ml-auto"
                      color="primary"
                      type="button"
                      onClick={() => this.innerChangeAssistanceState()}
                    >
                      Cambiar estado
                    </Button>
                  </FormGroup>
                </Col>
                {this.state.assistance.type === TYPE_TELEMEDICINE && (
                  <Col lg="6" sm="6">
                    <FormGroup>
                      <Button
                        className="ml-auto"
                        color="primary"
                        type="button"
                        onClick={() => this.showLadSimulatorModal()}
                      >
                        Simular respuesta LAD
                      </Button>
                    </FormGroup>
                  </Col>
                )}
              </Row>

              <Row style={{ textAlign: "center" }}>
                <Col lg="12" sm="12">
                  DANGER ZONE
                </Col>
                <Col lg="12" sm="12">
                  <FormGroup>
                    <Button
                      className="ml-auto"
                      color="danger"
                      type="button"
                      onClick={() => this.innerDeleteAssistance()}
                    >
                      Eliminar
                    </Button>
                  </FormGroup>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </>
    );
  }
}

export default AssistanceDetail;
