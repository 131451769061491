import { getHttpBearerConfig } from 'services/httpServices';
import axios from 'axios';
import { db } from 'config/firebase';
import { getUserWithToken } from 'helpers/coreHelper';

import { collection, query, onSnapshot } from 'firebase/firestore';

const appConfig = require('config/appConfig');

export const subscribePromotionalPlans = (onUpdate) => {
  return new Promise(function (resolve, reject) {
    const path = 'promotionalPlans';
    let q = null;

    // if (firestoreQueryConditions) q = query(collection(db, path), ...firestoreQueryConditions);
    // else q = query(collection(db, path));
    q = query(collection(db, path));
    onSnapshot(
      q,
      (promoPlansSnapshot) => {
        const plans = [];
        promoPlansSnapshot.forEach((planDoc) => {
          const plan = planDoc.data();
          plan.id = planDoc.id;
          plans.push(plan);
        });

        onUpdate(plans);
        resolve(plans);
      },
      (e) => {
        reject(e);
      }
    );
  });
};

export const listPromotionalPlans = () => {
  return new Promise(function (resolve, reject) {
    getUserWithToken().then((uwt) => {
      let config = getHttpBearerConfig(uwt.token);

      return axios
        .get(`${appConfig.gcfUrl}/promotionalPlans`, config)
        .then((response) => {
          console.log('promotionalPlans response:', response);
          if (response && response.data) resolve(response.data);
          else resolve([]);
        })
        .catch((e) => {
          console.log('error:', e);

          reject(e);
        });
    });
  });
};

export const createPromotionalPlan = (promotionalPlan) => {
  return new Promise(function (resolve, reject) {
    getUserWithToken().then((uwt) => {
      let config = getHttpBearerConfig(uwt.token);

      return axios
        .post(`${appConfig.gcfUrl}/promotionalPlans`, promotionalPlan, config)
        .then((response) => {
          console.log('create promotionalPlan response:', response);
          if (response && response.data) resolve(response.data);
          else resolve(null);
        })
        .catch((e) => {
          console.log('error:', e);

          reject(e);
        });
    });
  });
};

export const updatePromotionalPlan = (promotionalPlan) => {
  return new Promise(function (resolve, reject) {
    getUserWithToken().then((uwt) => {
      let config = getHttpBearerConfig(uwt.token);
      return axios
        .patch(
          `${appConfig.gcfUrl}/promotionalPlans/${promotionalPlan.id}`,
          promotionalPlan,
          config
        )
        .then((response) => {
          console.log('update promotionalPlan response:', response);
          if (response && response.data) resolve(response.data);
          else resolve(null);
        })
        .catch((e) => {
          console.log('error:', e);

          reject(e);
        });
    });
  });
};

export const deletePromotionalPlan = (promotionalPlan) => {
  return new Promise(function (resolve, reject) {
    getUserWithToken().then((uwt) => {
      let config = getHttpBearerConfig(uwt.token);

      return axios
        .delete(`${appConfig.gcfUrl}/promotionalPlans/${promotionalPlan.id}`, config)
        .then((response) => {
          console.log('delete promotionalPlan response:', response);
          if (response && response.data) resolve(response.data);
          else resolve(null);
        })
        .catch((e) => {
          console.log('error:', e);

          reject(e);
        });
    });
  });
};

export const getPromotionalPlan = (promotionalPlanId) => {
  return new Promise(function (resolve, reject) {
    getUserWithToken().then((uwt) => {
      let config = getHttpBearerConfig(uwt.token);

      return axios
        .get(
          `${appConfig.gcfUrl}/promotionalPlans/single/${uwt.user.uid}/${promotionalPlanId}`,
          config
        )
        .then((response) => {
          console.log('promotionalPlans response:', response);
          if (response && response.data) resolve(response.data);
          else resolve([]);
        })
        .catch((e) => {
          console.log('error:', e);

          reject(e);
        });
    });
  });
};
