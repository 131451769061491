import React from "react";

import classnames from "classnames";
// reactstrap components
import {
  Button,
  Modal,
  FormGroup,
  Input,
  
  InputGroupText,
  InputGroup,
} from "reactstrap";
// core components

class NotificationEventsEditionModal extends React.Component {
  constructor(props) {
    super(props);

    if (!this.props.notificationEvent.startDate)
      this.props.notificationEvent.startDate = "2020-12-30T03:00:00.000Z";
    if (!this.props.notificationEvent.endDate)
      this.props.notificationEvent.endDate = "2020-12-31T03:00:00.000Z";

    this.state = { notificationEvent: this.props.notificationEvent };
  }

  componentWillReceiveProps(nextProps) {
    console.log("componentWillReceiveProps", nextProps);
    this.setState({ notificationEvent: nextProps.notificationEvent });
  }

  onPlanSelectionChanged(item, event) {
    this.state.notificationEvent.plan = this.state.notificationEvent.plan || {};

    if (event.target.checked) {
      this.state.notificationEvent.plan = item;
      //this.state.notificationEvent.plan.id = item.id;
      //this.state.notificationEvent.plan.name = item.name;
    }
    this.setState({ notificationEvent: this.state.notificationEvent });
  }

  render() {
    return (
      <>
        {
          <Modal
            className="modal-dialog-centered"
            isOpen={this.props.notificationEventsEditionModalIsOpen}
            toggle={() => this.props.toggleModal(this.props.modalName)}
          >
            <div className="modal-header">
              <h6 className="modal-title" id="modal-title-default">
                <>Notification event edition</>
              </h6>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => this.props.toggleModal(this.props.modalName)}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <div className="modal-body">
              {this.state.notificationEvent.id && (
                <FormGroup>
                  <Input
                    disabled
                    placeholder="Id"
                    type="text"
                    value={this.state.notificationEvent.id}
                  />
                </FormGroup>
              )}
              <FormGroup
                className={classnames({
                  focused: this.state.birthdayFocused,
                })}
              >
                <InputGroup className="mb-4">
                  <Input
                    value={
                      this.state.notificationEvent.title
                        ? this.state.notificationEvent.title
                        : ""
                    }
                    placeholder="Title"
                    type="text"
                    onChange={(e) => {
                      e.preventDefault();
                      this.state.notificationEvent.title = e.target.value;
                      this.setState({
                        notificationEvent: this.state.notificationEvent,
                      });
                    }}
                  />

                  
                    <InputGroupText>
                      <i className="ni ni-zoom-split-in" />
                    </InputGroupText>
                  
                </InputGroup>
                <InputGroup className="mb-4">
                  <Input
                    placeholder="Body"
                    value={
                      this.state.notificationEvent.body
                        ? this.state.notificationEvent.body
                        : ""
                    }
                    type="text"
                    onChange={(e) => {
                      e.preventDefault();
                      this.state.notificationEvent.body = e.target.value;
                      this.setState({
                        notificationEvent: this.state.notificationEvent,
                      });
                    }}
                  />

                  
                    <InputGroupText>
                      <i className="ni ni-zoom-split-in" />
                    </InputGroupText>
                  
                </InputGroup>

                <InputGroup className="mb-4">
                  <Input
                    placeholder="Start date"
                    value={
                      this.state.notificationEvent.startDate
                        ? this.state.notificationEvent.startDate
                        : ""
                    }
                    type="text"
                    onChange={(e) => {
                      e.preventDefault();
                      this.state.notificationEvent.startDate = e.target.value;
                      this.setState({
                        notificationEvent: this.state.notificationEvent,
                      });
                    }}
                  />

                  
                    <InputGroupText>
                      <i className="ni ni-zoom-split-in" />
                    </InputGroupText>
                  
                </InputGroup>

                <InputGroup className="mb-4">
                  <Input
                    placeholder="End date"
                    value={
                      this.state.notificationEvent.endDate
                        ? this.state.notificationEvent.endDate
                        : ""
                    }
                    type="text"
                    onChange={(e) => {
                      e.preventDefault();
                      this.state.notificationEvent.endDate = e.target.value;
                      this.setState({
                        notificationEvent: this.state.notificationEvent,
                      });
                    }}
                  />

                  
                    <InputGroupText>
                      <i className="ni ni-zoom-split-in" />
                    </InputGroupText>
                  
                </InputGroup>

                <InputGroup className="mb-4">
                  <Input
                    placeholder="Image"
                    value={
                      this.state.notificationEvent.image
                        ? this.state.notificationEvent.image
                        : ""
                    }
                    type="text"
                    onChange={(e) => {
                      e.preventDefault();
                      this.state.notificationEvent.image = e.target.value;
                      this.setState({
                        notificationEvent: this.state.notificationEvent,
                      });
                    }}
                  />

                  
                    <InputGroupText>
                      <i className="ni ni-zoom-split-in" />
                    </InputGroupText>
                  
                </InputGroup>

                <InputGroup className="mb-4">
                  <Input
                    placeholder="Navigation"
                    value={
                      this.state.notificationEvent.navigation
                        ? this.state.notificationEvent.navigation
                        : ""
                    }
                    type="text"
                    onChange={(e) => {
                      e.preventDefault();
                      this.state.notificationEvent.navigation = e.target.value;
                      this.setState({
                        notificationEvent: this.state.notificationEvent,
                      });
                    }}
                  />

                  
                    <InputGroupText>
                      <i className="ni ni-zoom-split-in" />
                    </InputGroupText>
                  
                </InputGroup>
              </FormGroup>
            </div>
            <div className="modal-footer">
              <Button
                color="primary"
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  this.props.saveNotificationEvent(
                    this.state.notificationEvent
                  );
                }}
              >
                Save
              </Button>
              <Button
                className="ml-auto"
                color="link"
                data-dismiss="modal"
                type="button"
                onClick={() => this.props.toggleModal(this.props.modalName)}
              >
                Close
              </Button>
            </div>
          </Modal>
        }
      </>
    );
  }
}

export default NotificationEventsEditionModal;
