import React from 'react';
// import MetaTags from "react-meta-tags";

// Import Swiper styles
import 'swiper/swiper.scss';

// reactstrap components
import { Alert, Badge, Container, Row, Col } from 'reactstrap';

// core components
import LifeInsuranceProductLandingNavbar from './LifeInsuranceProductLandingNavbar.jsx';
import Footer from './Footer.jsx';
import SliderAboutUs from '../../components/Sliders/SliderAboutUs';
import ModalError from 'components/Modals/ModalError.jsx';
import ContactForm from 'components/Forms/ContactForm.jsx';
import WhatsappButton from 'components/Buttons/WhatsappButton';

import { setPersistedValue } from 'helpers/coreHelper';

//NO BORRAR
import 'cleave.js/dist/addons/cleave-phone.ar';

class LifeInsuranceProductLanding extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      //   phoneNumber: "",
      //   firstName: "",
      //   lastName: "",
      //   email: "",
      //   phoneRawValue: "",
      //   loading: false,
      errorModalInfo: null,
      //   disabled: true,
    };

    // this.onPhoneChange = this.onPhoneChange.bind(this);
  }
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }

  navigateToAboutUs() {
    this.props.history.push('/about-us');
  }

  navigateToOnboarding() {
    let targetUrl = '/onboarding';

    const lead = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      phoneNumber: this.state.phoneNumber,
      email: this.state.email,
    };

    setPersistedValue('lead', lead);

    if (this.props.location.search) targetUrl += this.props.location.search;
    // this.props.history.push(targetUrl, {
    //   lead: lead,
    // });

    this.props.history.push(targetUrl);
  }

  // onPhoneChange(event) {
  //   this.setState({ phoneNumber: "+549" + event.target.rawValue });
  //   this.setState({ phoneRawValue: event.target.rawValue });
  // }

  // validateForm() {
  //   if (
  //     validateHasValue(this.state.firstName) &&
  //     validateHasValue(this.state.lastName) &&
  //     validateHasValue(this.state.email) &&
  //     validateHasValue(this.state.phoneRawValue, 10) &&
  //     validateEmail(this.state.email)
  //   )
  //     return true;

  //   return false;
  // }

  // formIsFull() {
  //   if (this.validateForm()) {
  //     this.setState({ disabled: false });
  //   } else {
  //     this.setState({ disabled: true });
  //   }
  // }

  // onSubmitFormData(event) {
  //   if (this.state.loading) return;

  //   if (!this.validateForm()) {
  //     this.setState({
  //       errorModalInfo: {
  //         title: "Error",
  //         description: "Alguno de los campos es inválido",
  //       },
  //     });
  //     return;
  //   }

  //   let search = window.location.search;
  //   let params = new URLSearchParams(search);

  //   let utm_campaign = params.get("utm_campaign");
  //   let utm_source = params.get("utm_source");
  //   let utm_content = params.get("utm_content");
  //   let utm_medium = params.get("utm_medium");
  //   let utm_term = params.get("utm_term");

  //   let utmData = {
  //     utm_campaign: utm_campaign,
  //     utm_source: utm_source,
  //     utm_content: utm_content,
  //     utm_medium: utm_medium,
  //     utm_term: utm_term,
  //   };

  //   let lead = {
  //     firstName: this.state.firstName,
  //     lastName: this.state.lastName,
  //     phoneNumber: this.state.phoneNumber,
  //     email: this.state.email,
  //     utmData: utmData,
  //   };

  //   this.setState({ loading: true });

  //   createCallRequestLead(lead)
  //     .then(() => {
  //       this.props.history.push("/asociarme-salud-success");
  //     })
  //     .catch((e) => {
  //       this.setState({
  //         errorModalInfo: {
  //           title: "Error",
  //           description:
  //             "¡Hola! Hubo un error en el proceso (" + e.message + ")",
  //         },
  //       });
  //     })
  //     .finally(() => {
  //       this.setState({ loading: false });
  //     });
  // }

  renderError() {
    if (this.state.errorModalInfo) {
      return (
        <ModalError
          title={this.state.errorModalInfo.title}
          description={this.state.errorModalInfo.description}
          onClose={() => {
            this.setState({ errorModalInfo: null });
          }}
        />
      );
    }
    return null;
  }

  // renderForm() {
  //   return (
  //     <Form className="pt-140">
  //       <FormGroup>
  //         <CustomInput
  //           inputProps={{
  //             value: this.state.firstName,
  //             name: "firstName",
  //             onChange: (event) => {
  //               this.setState(
  //                 {
  //                   firstName: event.target.value,
  //                 },
  //                 () => {
  //                   this.formIsFull();
  //                 }
  //               );
  //             },

  //             placeholder: "Nombre",
  //             className: "contact-input",
  //           }}
  //         />
  //       </FormGroup>

  //       <FormGroup>
  //         <CustomInput
  //           inputProps={{
  //             value: this.state.lastName,
  //             name: "lastName",
  //             onChange: (event) => {
  //               this.setState(
  //                 {
  //                   lastName: event.target.value,
  //                 },
  //                 () => {
  //                   this.formIsFull();
  //                 }
  //               );
  //             },

  //             placeholder: "Apellido",
  //             className: "contact-input",
  //           }}
  //         />
  //       </FormGroup>
  //       <FormGroup>
  //         <CustomInput
  //           behavior={{
  //             className: "css-phone input form-control contact-input",
  //             options: {
  //               phone: true,
  //               phoneRegionCode: "AR",
  //             },
  //             // initialValue: this.state.phoneNumber
  //             //   .replace(" ", "")
  //             //   .replace(" ", "")
  //             //   .replace(PHONE_NUMBER_PREFIX_AR, ""),
  //           }}
  //           validationFn={(info) => {
  //             return validateHasValue(info, 10);
  //           }}
  //           // error={this.state.errorPhoneNumber}
  //           inputProps={{
  //             type: "tel",
  //             name: "phone",
  //             autoComplete: "tel",
  //             value: this.state.phoneNumber
  //               .replace(" ", "")
  //               .replace(" ", "")
  //               .replace(PHONE_NUMBER_PREFIX_AR, ""),
  //             onChange: (event) => {
  //               this.state.phoneNumber =
  //                 PHONE_NUMBER_PREFIX_AR + event.target.rawValue;

  //               // this.validateFormData(
  //               //   this.state.userFullName,
  //               //   this.state.phoneNumber
  //               // );

  //               this.setState(
  //                 {
  //                   phoneNumber: this.state.phoneNumber,
  //                   // phoneNumberValidated: false, //si lo cambia tiene que validarlo nuevamente
  //                   phoneRawValue: event.target.rawValue,
  //                 },
  //                 () => {
  //                   this.formIsFull();
  //                 }
  //               );
  //             },
  //             placeholder: "Tél. celular (código de área + núm)",
  //           }}
  //         />
  //       </FormGroup>
  //       <FormGroup>
  //         <CustomInput
  //           inputProps={{
  //             value: this.state.email,
  //             name: "email",
  //             onChange: (event) => {
  //               this.setState(
  //                 {
  //                   email: event.target.value,
  //                 },
  //                 () => {
  //                   this.formIsFull();
  //                 }
  //               );
  //             },

  //             placeholder: "Email",
  //             className: "contact-input",
  //           }}
  //         />
  //       </FormGroup>
  //       <div className="btn-wrapper">
  //         <Button
  //           variant="primary"
  //           className="mt-4 full-w"
  //           color="custom noarrow"
  //           type="button"
  //           size="lg"
  //           block
  //           onClick={(event) => {
  //             this.onSubmitFormData();
  //           }}
  //           disabled={this.state.loading || this.state.disabled}
  //         >
  //           {!this.state.loading && "Enviar"}
  //           {this.state.loading && "Cargando..."}
  //         </Button>
  //       </div>
  //     </Form>
  //   );
  // }
  render() {
    return (
      <>
        {/* <MetaTags>
          <title>Seguro de Salud integral | Hola René</title>
          <meta
            id="meta-description"
            name="description"
            content="Con nuestro seguro de salud, vos y tu familia están cubiertos. Cuidá la salud de tu familia con Hola René."
          />
        </MetaTags> */}

        {this.renderError()}
        <WhatsappButton />

        <LifeInsuranceProductLandingNavbar disableCallRequestButton disablePlansButton />
        <main ref="main">
          {/*hero*/}
          <section className="section section-lg2 section-home pb-lg-0 pt-lg-0 pt-sm-5">
            <Container className="d-flex">
              {/* SVG separator */}
              <div className="separator separator-top separator-skew hidden-md hidden-sm">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 200"
                  x="0"
                  y="0"
                >
                  <polygon className="fill-white" points="0 0 2560 0 2560 0 0 200" />
                </svg>
              </div>
              <div className="col px-0 mb-2">
                <Row>
                  <Col xs="12" md="7" lg="6">
                    <h1 className="main-title text-white pt-150">
                      La salud de tu familia cubierta desde $930 al mes
                    </h1>
                    <p className="text-white">
                      Te atendemos en tu casa o en miles de consultorios con los mejores médicos de
                      la Argentina
                    </p>
                    <div className="btn-wrapper" style={{ paddingBottom: 100 }}>
                      {/* <Badge color="custom">
                        * IVA incluido{" "}
                        {
                          "                                                                                                "
                        }
                      </Badge> */}
                      {/* <Button
                        className="mt-4 full-w-sm"
                        color="custom secondary noarrow"
                        type="button"
                        // onClick={(event) => {
                        //   this.navigateToOnboarding();
                        // }}
                      >
                        <a href="#contact-form">Ver planes</a>
                      </Button> */}
                    </div>
                  </Col>
                  {/*  Form */}
                  <Col
                    md="5"
                    lg="6"
                    className="hidden-sm hidden-md bg-white z-index-1 text-center p-4"
                  >
                    <h3 className="home-title pt-100">Dejanos tu contacto</h3>
                    <p>En breve te llamaremos para darte más información sobre nuestros planes.</p>
                    {/* {this.renderForm()} */}
                    <ContactForm
                      onError={(e) => {
                        this.setState({
                          errorModalInfo: {
                            title: 'Error',
                            description: '¡Hola! Hubo un error en el proceso (' + e.message + ')',
                          },
                        });
                      }}
                      onSuccess={() => {
                        this.props.history.push('/asociarme-salud-success');
                      }}
                    />
                  </Col>
                </Row>
              </div>
              {/* SVG separator */}
              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 200"
                  x="0"
                  y="0"
                >
                  <polygon className="fill-light-grey" points="2560 0 2560 200 0 200" />
                </svg>
              </div>
            </Container>
          </section>

          {/*Slider*/}
          <SliderAboutUs />

          <section className="section section-lg2  bg-light-grey d-lg-none z-index-1">
            <Container>
              <div className="">
                <h3 className="home-title">Dejanos tu contacto</h3>
                <p>En breve te llamaremos para darte más información sobre nuestros planes.</p>
                {/* {this.renderForm()} */}
                <ContactForm
                  onError={(e) => {
                    this.setState({
                      errorModalInfo: {
                        title: 'Error',
                        description: '¡Hola! Hubo un error en el proceso (' + e.message + ')',
                      },
                    });
                  }}
                  onSuccess={() => {
                    this.props.history.push('/asociarme-salud-success');
                  }}
                />
              </div>
            </Container>
          </section>

          {/*Dejá de esperar horas a que te atiendan en el hospital*/}
          <section className="section section-lg2  bg-light-grey">
            <Container>
              <Row className="row-grid align-items-center">
                <Col className="order-md-2 px-sm-0" md="6">
                  <img
                    alt="..."
                    className="img-fluid hidden-sm"
                    src={require('assets/img/brand/medico.png')}
                  />
                  <img
                    alt="..."
                    className="img-full visible-sm pb-4 "
                    src={require('assets/img/brand/medico-sm.png')}
                  />
                </Col>
                <Col className="order-md-1" md="6">
                  <div className="pr-md-5">
                    <h3 className="home-title">
                      Dejá de esperar horas a que te atiendan en el hospital
                    </h3>
                    <p>
                      Con nuestro servicio de médico a domicilio, evitas salir de tu casa y serás
                      atendido por los mejores profesionales. Pedís el médico desde la app las 24hs
                      del día, los 7 días de la semana.
                    </p>
                    {/* <Button
                      color="custom arrow flat"
                      type="button"
                      onClick={(event) => {
                        this.navigateToOnboarding();
                      }}
                    >
                      Más info
                    </Button> */}
                  </div>
                </Col>
              </Row>
            </Container>
          </section>

          {/*Hablá con un médico online y sacate cualquier duda*/}
          <section className="section section-lg2 bg-light-grey">
            <Container>
              <Row className="row-grid align-items-center">
                <Col md="6">
                  <img
                    alt="..."
                    style={{ width: '400px' }}
                    className="img-fluid hidden-sm"
                    src={require('assets/img/brand/iPhone.png')}
                  />
                  <img
                    alt="..."
                    className="img-full visible-sm pb-4 "
                    src={require('assets/img/brand/iPhone-sm.png')}
                  />
                </Col>
                <Col md="6">
                  <div className="pr-md-5">
                    <h3 className="home-title">
                      Hablá con un médico online y sacate cualquier duda
                    </h3>
                    <p>
                      ¿Te sentís mal o necesitás una receta? Hablá con nuestros médicos a toda hora
                      y las veces que quieras, sin salir de tu casa. La receta te la mandamos por
                      whatsapp para que la puedas usar ni bien la necesitás.
                    </p>

                    <Alert color="gray">
                      <span className="alert-inner--text">
                        Contamos con asesores para darte soporte todos los días de la semana en
                        tiempo real desde la app
                      </span>
                    </Alert>
                    {/* <Button
                      color="custom arrow flat"
                      type="button"
                      onClick={(event) => {
                        this.navigateToOnboarding();
                      }}
                    >
                      Más info
                    </Button> */}
                  </div>
                </Col>
              </Row>
            </Container>
          </section>

          {/* Accedé a más de 5.000 médicos de todas las especialidades */}
          <section className="section section-lg2 bg-light-grey pb-md-0 pb-sm-4">
            <Container>
              <Row className="row-grid align-items-center">
                <Col className="order-md-2 px-sm-0" md="4">
                  <img
                    alt="..."
                    className="img-fluid hidden-sm"
                    src={require('assets/img/brand/iPhone-2.png')}
                  />
                  <img
                    alt="..."
                    className="img-full visible-sm"
                    src={require('assets/img/brand/iPhone-2-sm.png')}
                  />
                </Col>
                <Col className="order-md-1" md="8">
                  <div className="pr-md-5 mt-2">
                    <h3 className="home-title">
                      Accedé a más de 5.000 médicos de todas las especialidades.
                    </h3>
                    <ul className="list-unstyled mt-3">
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge className="badge-circle small gradient mr-3">
                              <i className="ni ni-check-bold text-white" />
                            </Badge>
                          </div>
                          <div>
                            <h6 className="mb-0">Odontólogos</h6>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge className="badge-circle small gradient mr-3">
                              <i className="ni ni-check-bold text-white" />
                            </Badge>
                          </div>
                          <div>
                            <h6 className="mb-0">Pediatras</h6>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge className="badge-circle small gradient mr-3">
                              <i className="ni ni-check-bold text-white" />
                            </Badge>
                          </div>
                          <div>
                            <h6 className="mb-0">Ginecólogos</h6>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge className="badge-circle small gradient mr-3">
                              <i className="ni ni-check-bold text-white" />
                            </Badge>
                          </div>
                          <div>
                            <h6 className="mb-8">Oftalmólogos</h6>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge className="badge-circle small gradient mr-3">
                              <i className="ni ni-check-bold text-white" />
                            </Badge>
                          </div>
                          <div>
                            <h6 className="mb-8">Traumatólogos</h6>
                          </div>
                        </div>
                      </li>
                      <h6>
                        Desde la app podes ver los días y horarios de atención, los turnos
                        disponibles y solicitar el turno online sin llamar al consultorio.
                      </h6>
                    </ul>
                    {/* <Button
                      color="custom arrow flat"
                      type="button"
                      onClick={(event) => {
                        this.navigateToOnboarding();
                      }}
                    >
                      Más info
                    </Button> */}
                  </div>
                </Col>
              </Row>
              {/* SVG separator */}
              <div className="separator separator-bottom separator-skew hidden-sm">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 200"
                  x="0"
                  y="0"
                  className="shadow-svg"
                >
                  <polygon className="fill-white" points="2560 0 2560 200 0 200" />
                </svg>
              </div>
            </Container>
          </section>

          {/*Si te pasa algo grave, nosotros te cubrimos*/}
          <section className="section section-lg2">
            <Container>
              <Row className="row-grid align-items-center">
                <Col md="6" className="px-sm-0">
                  <img
                    alt="..."
                    className="img-fluid hidden-sm"
                    src={require('assets/img/brand/medico-2.png')}
                  />
                  <img
                    alt="..."
                    className="img-full pb-4 visible-sm"
                    src={require('assets/img/brand/medico-2-sm.png')}
                  />
                </Col>
                <Col md="6">
                  <div className="pr-md-5">
                    <h3 className="home-title">Si te pasa algo grave, nosotros te cubrimos</h3>
                    <p>
                      Con el seguro de intervenciones quirúrgicas accedes a una suma de hasta
                      $50.000 en caso que te tengan que operar. Además te damos $1.000 de renta por
                      día mientras estés internado.
                    </p>
                    {/* <Button
                      color="custom arrow flat"
                      type="button"
                      onClick={(event) => {
                        this.navigateToOnboarding();
                      }}
                    >
                      Más info
                    </Button> */}
                  </div>
                </Col>
              </Row>
            </Container>
          </section>

          {/*Podes contratar un plan para vos o solo para tus hijos.*/}
          <section className="section section-lg2">
            <Container>
              <Row className="row-grid align-items-center">
                <Col className="order-md-2 px-sm-0" md="6">
                  <img
                    alt="..."
                    className="img-fluid hidden-sm"
                    src={require('assets/img/brand/abrazo.png')}
                  />
                  <img
                    alt="..."
                    className="img-full pb-4 visible-sm"
                    src={require('assets/img/brand/abrazo-sm.png')}
                  />
                </Col>
                <Col className="order-md-1" md="6">
                  <div className="pr-md-5">
                    <h3 className="home-title">
                      Podés contratarlo para vos, tu familia, o solo para tus hijos
                    </h3>
                    <p>
                      Vos elegís a quienes cubrís. Si solo querés cubrir a tus hijos lo podes hacer.
                      Te queremos dar toda la flexibilidad para que encuentres el plan que más te
                      sirva.
                    </p>
                    {/* <Button
                      color="custom arrow flat"
                      type="button"
                      onClick={(event) => {
                        this.navigateToOnboarding();
                      }}
                    >
                      Más info
                    </Button> */}
                  </div>
                </Col>
              </Row>
            </Container>
          </section>

          {/*Objetivo*/}
          <section className="section section-lg2 bg-gradient pt-200 pb-200">
            <Container>
              {/* SVG separator */}
              <div className="separator separator-top separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 200"
                  x="0"
                  y="0"
                >
                  <polygon className="fill-white" points="0 0 2560 0 2560 0 0 200" />
                </svg>
              </div>

              <Row className="row-grid align-items-center">
                <Col md="12">
                  <div className="pr-md-5 aling-flex-center">
                    <h3 className="text-white text-center home-title">
                      Te cuidamos con la misma calidad que una obra social, sin que tengas que
                      gastarte todo el sueldo para pagarla.{' '}
                    </h3>
                    {/* <Button
                      className="mt-4"
                      color="custom secondary noarrow"
                      type="button"
                      onClick={(event) => {
                        this.navigateToOnboarding();
                      }}
                    >
                      Más Info
                    </Button> */}
                  </div>
                </Col>
              </Row>
              {/* SVG separator */}
              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 200"
                  x="0"
                  y="0"
                >
                  <polygon className="fill-white" points="2560 0 2560 200 0 200" />
                </svg>
              </div>
            </Container>
          </section>

          {/*Quienes Somos*/}
          <section className="section section-lg2 pt-150 pb-150">
            <Container>
              <Row className="row-grid align-items-center">
                <Col md="12">
                  <div className="pr-md-5">
                    <h3 className="home-title text-center">Quiénes somos</h3>
                    <p className="text-center">
                      HolaRené es un producto de{' '}
                      <span
                        onClick={() => {
                          this.navigateToAboutUs();
                        }}
                        className="blue"
                      >
                        ARVida
                      </span>
                      , compañía de seguros de vida con más de 120 años de experiencia en el país y
                      más de 200.000 asegurados, convirtiéndola en una de las empresas con más
                      trayectoria en su rubro.
                    </p>
                    <p className="text-center bold">
                      Nos especializamos en proteger lo más querido de los argentinos, sus familias.
                    </p>
                  </div>
                </Col>
              </Row>
              {/* SVG separator */}
              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 200"
                  x="0"
                  y="0"
                >
                  <polygon fill="#FAFAFA" points="2560 0 2560 200 0 200" />
                </svg>
              </div>
            </Container>
          </section>
        </main>
        <Footer />
      </>
    );
  }
}

export default LifeInsuranceProductLanding;
