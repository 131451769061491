import { getHttpBearerConfig } from 'services/httpServices';
import axios from 'axios';

import { getUserWithToken, getCustomError } from 'helpers/coreHelper';

const appConfig = require('config/appConfig');

export const listLeads = (currentUserToken) => {
  return new Promise(function (resolve, reject) {
    //exports.saveRole = function(currentUserToken, targetUser, role) {
    let config = getHttpBearerConfig(currentUserToken);

    return axios
      .get(`${appConfig.gcfUrl}/leads`, config)
      .then((response) => {
        console.log('leads response:', response);
        if (response && response.data) resolve(response.data);
        else resolve([]);
      })
      .catch((e) => {
        console.log('error:', e);

        reject(e);
      });
  });
};

export const createLead = (lead) => {
  return new Promise(function (resolve, reject) {
    getUserWithToken()
      .then((uwt) => {
        return uwt;
      })
      .then((uwt) => {
        let config = null;
        if (uwt) config = getHttpBearerConfig(uwt.token);

        let data = lead;

        data.currentStep = 'onboarding-step-2';
        return axios.post(`${appConfig.gcfUrl}/leads`, data, config).then((response) => {
          if (response && response.data) resolve(response.data);
          else resolve(null);
        });
      })
      .catch((e) => {
        console.log('error:', e);

        reject(e);
      });
  });
};

export const updatePhoneNumberValidated = (currentUserToken, lead) => {
  return new Promise(function (resolve, reject) {
    let config = getHttpBearerConfig(currentUserToken);

    let data = {
      phoneNumberValidated: lead.phoneNumberValidated,
      currentStep: 'phoneValidation',
    };

    return axios
      .patch(`${appConfig.gcfUrl}/leads/${lead.id}/phoneNumberValidated`, data, config)
      .then((response) => {
        console.log('savePhoneNumberValidated lead response:', response);
        if (response && response.data) resolve(response.data);
        else resolve(null);
      })
      .catch((e) => {
        console.log('error:', e);

        reject(e);
      });
  });
};

export const addBeneficiaries = (currentUserToken, lead) => {
  return new Promise(function (resolve, reject) {
    let config = getHttpBearerConfig(currentUserToken);

    let data = {
      beneficiaries: lead.beneficiaries,
      currentStep: 'onboarding-step-3',
    };

    return axios
      .post(`${appConfig.gcfUrl}/leads/${lead.id}/beneficiaries`, data, config)
      .then((response) => {
        if (response && response.data) resolve(response.data);
        else resolve(null);
      })
      .catch((e) => {
        console.log('error:', e);

        reject(e);
      });
  });
};

//Deprecated
export const saveSelectedPlan = (currentUserToken, lead) => {
  return new Promise(function (resolve, reject) {
    let config = getHttpBearerConfig(currentUserToken);

    let data = { selectedPlan: lead.selectedPlan, currentStep: 2 };

    return axios
      .patch(`${appConfig.gcfUrl}/leads/${lead.id}/plan`, data, config)
      .then((response) => {
        console.log('saveSelectedPlan lead response:', response);
        if (response && response.data) resolve(response.data);
        else resolve(null);
      })
      .catch((e) => {
        console.log('error:', e);

        reject(e);
      });
  });
};

export const savePolicyConsent = (currentUserToken, lead) => {
  return new Promise(function (resolve, reject) {
    let config = getHttpBearerConfig(currentUserToken);

    let data = {
      policyConsent: lead.policyConsent,
      currentStep: 'onboarding-step-null',
    };

    return axios
      .patch(`${appConfig.gcfUrl}/leads/${lead.id}/policy-consent`, data, config)
      .then((response) => {
        console.log('policy-consent lead response:', response);
        if (response && response.data) resolve(response.data);
        else resolve(null);
      })
      .catch((e) => {
        console.log('error:', e);

        reject(e);
      });
  });
};

export const saveAdditionalData = (currentUserToken, lead) => {
  return new Promise(function (resolve, reject) {
    let config = getHttpBearerConfig(currentUserToken);

    let data = {
      address: lead.address,
      documentType: lead.documentType,
      identificationNumber: lead.identificationNumber,
      birthday: lead.birthday,
      gender: lead.gender,
      maritalStatus: lead.maritalStatus,
      currentStep: 'onboarding-step-null',
    };

    return axios
      .patch(`${appConfig.gcfUrl}/leads/${lead.id}/additional-data`, data, config)
      .then((response) => {
        console.log('additional-data lead response:', response);
        if (response && response.data) resolve(response.data);
        else resolve(null);
      })
      .catch((e) => {
        console.log('error:', e);

        reject(e);
      });
  });
};

export const savePaymentData = (currentUserToken, lead) => {
  return new Promise(function (resolve, reject) {
    let config = getHttpBearerConfig(currentUserToken);

    let data = {
      paymentData: lead.paymentData,
      address: lead.address,
      discountCode: lead.discountCode,
      currentStep: 'onboarding-step-4',
    };

    return axios
      .patch(`${appConfig.gcfUrl}/leads/${lead.id}/payment-data`, data, config)
      .then((response) => {
        console.log('payment-data lead response:', response);
        if (response && response.data) resolve(response.data);
        else resolve(null);
      })
      .catch((e) => {
        console.log('error:', e);

        reject(e);
      });
  });
};

export const confirmReview = (lead) => {
  return new Promise(function (resolve, reject) {
    getUserWithToken()
      .then((uwt) => {
        return uwt;
      })
      .then((uwt) => {
        let config = null;
        if (uwt) config = getHttpBearerConfig(uwt.token);

        let data = lead;

        return axios.post(`${appConfig.gcfUrl}/leads/${lead.id}/confirm-review`, data, config);
      })
      .then((response) => {
        console.log('confirm-review lead response:', response);
        if (response && response.data) resolve(response.data);
        else resolve(null);
      })
      .catch((e) => {
        console.log('error:', e);

        const customError = getCustomError(e);

        if (customError) reject(customError);
        else reject(e);
      });
  });
};

export const updateLead = (currentUserToken, lead) => {
  return new Promise(function (resolve, reject) {
    //exports.saveRole = function(currentUserToken, targetUser, role) {
    let config = getHttpBearerConfig(currentUserToken);

    let data = lead;
    return axios
      .patch(`${appConfig.gcfUrl}/leads/${lead.id}`, data, config)
      .then((response) => {
        console.log('update lead response:', response);
        if (response && response.data) resolve(response.data);
        else resolve(null);
      })
      .catch((e) => {
        console.log('error:', e);

        reject(e);
      });
  });
};

export const deleteLead = (currentUserToken, lead) => {
  return new Promise(function (resolve, reject) {
    let config = getHttpBearerConfig(currentUserToken);

    return axios
      .delete(`${appConfig.gcfUrl}/leads/${lead.id}`, config)
      .then((response) => {
        console.log('delete lead response:', response);
        if (response && response.data) resolve(response.data);
        else resolve(null);
      })
      .catch((e) => {
        console.log('error:', e);

        reject(e);
      });
  });
};

export const createCallRequestLead = (lead) => {
  return new Promise(function (resolve, reject) {
    getUserWithToken()
      .then((uwt) => {
        return uwt;
      })
      .then((uwt) => {
        let config = null;
        if (uwt) config = getHttpBearerConfig(uwt.token);

        let data = lead;

        return axios
          .post(`${appConfig.gcfUrl}/leads/call-request`, data, config)
          .then((response) => {
            if (response && response.data) resolve(response.data);
            else resolve(null);
          });
      })
      .catch((e) => {
        console.log('error:', e);

        reject(e);
      });
  });
};
