import { getHttpBearerConfig } from 'services/httpServices';
import axios from 'axios';
import { getUserWithToken } from 'helpers/coreHelper';
const appConfig = require('config/appConfig');

export const RENE_WEB_POS_CODE = 'holarene';

export const listPlans = (currentUserToken) => {
  return new Promise(function (resolve, reject) {
    //exports.saveRole = function(currentUserToken, targetUser, role) {
    let config = getHttpBearerConfig(currentUserToken);

    return axios
      .get(`${appConfig.gcfUrl}/plans`, config)
      .then((response) => {
        console.log('plans response:', response);
        if (response && response.data) resolve(response.data);
        else resolve([]);
      })
      .catch((e) => {
        console.log('error:', e);

        reject(e);
      });
  });
};

export const createPlan = (currentUserToken, plan) => {
  return new Promise(function (resolve, reject) {
    let config = getHttpBearerConfig(currentUserToken);

    let planCoverages = [];

    if (plan.planCoverages)
      plan.planCoverages.forEach((i) => {
        planCoverages.push({
          id: i.id,
          name: i.name,
          quotas: i.quotas,
        });
      });

    let data = plan;
    //le saco toda la info del coverage quedandome solo con el id y name de ese obj
    data.planCoverages = planCoverages;

    return axios
      .post(`${appConfig.gcfUrl}/plans`, data, config)
      .then((response) => {
        console.log('create plan response:', response);
        if (response && response.data) resolve(response.data);
        else resolve(null);
      })
      .catch((e) => {
        console.log('error:', e);

        reject(e);
      });
  });
};

export const updatePlan = (currentUserToken, plan) => {
  return new Promise(function (resolve, reject) {
    //exports.saveRole = function(currentUserToken, targetUser, role) {
    let config = getHttpBearerConfig(currentUserToken);

    let planCoverages = [];

    if (plan.planCoverages)
      plan.planCoverages.forEach((i) => {
        planCoverages.push({
          id: i.id,
          name: i.name,
          quotas: i.quotas,
        });
      });

    let data = plan;
    //le saco toda la info del coverage quedandome solo con el id y name de ese obj
    data.planCoverages = planCoverages;

    return axios
      .patch(`${appConfig.gcfUrl}/plans/${plan.id}`, data, config)
      .then((response) => {
        console.log('update plan response:', response);
        if (response && response.data) resolve(response.data);
        else resolve(null);
      })
      .catch((e) => {
        console.log('error:', e);

        reject(e);
      });
  });
};

export const deletePlan = (currentUserToken, plan) => {
  return new Promise(function (resolve, reject) {
    let config = getHttpBearerConfig(currentUserToken);

    return axios
      .delete(`${appConfig.gcfUrl}/plans/${plan.id}`, config)
      .then((response) => {
        console.log('delete plan response:', response);
        if (response && response.data) resolve(response.data);
        else resolve(null);
      })
      .catch((e) => {
        console.log('error:', e);

        reject(e);
      });
  });
};

export const lunchCRMPlansApiUpdate = () => {
  return new Promise(function (resolve, reject) {
    getUserWithToken().then((uwt) => {
      let config = getHttpBearerConfig(uwt.token);

      return axios
        .post(`${appConfig.gcfUrl}/plans/sync-crm-plans-from-api`, null, config)
        .then((response) => {
          console.log('sync crm plans response:', response);
          if (response && response.data) resolve(response.data);
          else resolve(null);
        })
        .catch((e) => {
          console.log('error:', e);

          reject(e);
        });
    });
  });
};
