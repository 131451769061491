import React from "react";

import classnames from "classnames";
// reactstrap components
import {
  Button,
  Modal,
  FormGroup,
  Input,
  
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";
// core components



import {
  MEDIC_SPECIALITY_TRAUMATOLOGIA,
  MEDIC_SPECIALITY_PEDIATRIA,
  MEDIC_SPECIALITY_OCULISTA,
  MEDIC_SPECIALITY_CARDIOLOGIA,
} from "types/medicSpecialitiesTypes";

class MedicEditionModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      medic: this.props.medic,
      specialities: [
        {
          id: "1",
          name: MEDIC_SPECIALITY_TRAUMATOLOGIA,
          description: "Traumatología",
        },
        { id: "2", name: MEDIC_SPECIALITY_PEDIATRIA, description: "Pediatría" },
        { id: "3", name: MEDIC_SPECIALITY_OCULISTA, description: "Oculista" },
        {
          id: "4",
          name: MEDIC_SPECIALITY_CARDIOLOGIA,
          description: "Cardiología",
        },
      ],
    };
  }

  componentDidMount() {}

  componentWillReceiveProps(nextProps) {
    //console.log("componentWillReceiveProps", nextProps);
    this.setState({ medic: nextProps.medic });
  }

  onUpload(url) {
    this.state.medic.photo = url;
    this.setState({ medic: this.state.medic });
  }

  hasItem = (items, item) => {
    if (!items) return false;

    if (!item) return false;

    if (!items.length) return false;

    let exists = false;
    items.forEach((i) => {
      if (i.id === item.id) exists = true;
    });

    return exists;
  };

  onSpecialitySelectionChanged(item, event) {
    this.state.medic.specialities = this.state.medic.specialities || [];

    if (event.target.checked) {
      if (!this.hasItem(this.state.medic.specialities, item)) {
        this.state.medic.specialities.push(item);
      }
    } //off
    else {
      if (this.hasItem(this.state.medic.specialities, item)) {
        let newList = [];
        this.state.medic.specialities.forEach((existent) => {
          if (existent.id !== item.id) newList.push(existent);
        });
        this.state.medic.specialities = newList;
      }
    }
    this.setState({ medic: this.state.medic });
  }

  render() {
    return (
      <>
        <Modal
          className="modal-dialog-centered"
          isOpen={this.props.medicEditionModalIsOpen}
          toggle={() => this.props.toggleModal(this.props.modalName)}
        >
          <div className="modal-header">
            <h6 className="modal-title" id="modal-title-default">
              <>Medic edition</>
            </h6>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.props.toggleModal(this.props.modalName)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            {this.state.medic.id && (
              <FormGroup>
                <Input
                  disabled
                  placeholder="Id"
                  type="text"
                  value={this.state.medic.id}
                />
              </FormGroup>
            )}
            <FormGroup
              className={classnames({
                focused: this.state.birthdayFocused,
              })}
            >
              <InputGroup className="mb-4">
                <Input
                  value={this.state.medic.name ? this.state.medic.name : ""}
                  placeholder="Name"
                  type="text"
                  onChange={(e) => {
                    e.preventDefault();
                    this.state.medic.name = e.target.value;
                    this.setState({ medic: this.state.medic });
                  }}
                />

                
                  <InputGroupText>
                    <i className="ni ni-zoom-split-in" />
                  </InputGroupText>
                
              </InputGroup>

              <InputGroup className="mb-4">
                <Input
                  placeholder="Description"
                  value={
                    this.state.medic.description
                      ? this.state.medic.description
                      : ""
                  }
                  type="text"
                  onChange={(e) => {
                    e.preventDefault();
                    this.state.medic.description = e.target.value;
                    this.setState({ medic: this.state.medic });
                  }}
                />

                
                  <InputGroupText>
                    <i className="ni ni-zoom-split-in" />
                  </InputGroupText>
                
              </InputGroup>

              <InputGroup className="mb-4">
                <Input
                  placeholder="Phone number"
                  value={
                    this.state.medic.phoneNumber
                      ? this.state.medic.phoneNumber
                      : ""
                  }
                  type="text"
                  onChange={(e) => {
                    e.preventDefault();
                    this.state.medic.phoneNumber = e.target.value;
                    this.setState({ medic: this.state.medic });
                  }}
                />

                
                  <InputGroupText>
                    <i className="ni ni-zoom-split-in" />
                  </InputGroupText>
                
              </InputGroup>

              <InputGroup className="mb-4">
                <Input
                  placeholder="Address"
                  value={
                    this.state.medic.address ? this.state.medic.address : ""
                  }
                  type="text"
                  onChange={(e) => {
                    e.preventDefault();
                    this.state.medic.address = e.target.value;
                    this.setState({ medic: this.state.medic });
                  }}
                />

                
                  <InputGroupText>
                    <i className="ni ni-zoom-split-in" />
                  </InputGroupText>
                
              </InputGroup>

              <div className="mb-3">
                <small className="text-uppercase font-weight-bold">
                  Coordinate
                </small>
              </div>
              <Row>
                <Col lg="4" sm="6">
                  <FormGroup>
                    <label>
                      <span className="text-muted">Long</span>
                    </label>

                    <Input
                      placeholder="-30.0000"
                      type="text"
                      value={
                        this.state.medic.coordinate &&
                        this.state.medic.coordinate._longitude
                          ? this.state.medic.coordinate._longitude
                          : ""
                      }
                      onChange={(e) => {
                        e.preventDefault();
                        this.state.medic.coordinate =
                          this.state.medic.coordinate || {};
                        this.state.medic.coordinate._longitude = e.target.value;

                        this.setState({ plan: this.state.plan });
                      }}
                    />
                  </FormGroup>
                </Col>

                <Col lg="4" sm="6">
                  <FormGroup>
                    <label>
                      <span className="text-muted">Lat</span>
                    </label>

                    <Input
                      placeholder="-30.0000"
                      type="text"
                      value={
                        this.state.medic.coordinate &&
                        this.state.medic.coordinate._latitude
                          ? this.state.medic.coordinate._latitude
                          : ""
                      }
                      onChange={(e) => {
                        e.preventDefault();
                        this.state.medic.coordinate =
                          this.state.medic.coordinate || {};
                        this.state.medic.coordinate._latitude = e.target.value;

                        this.setState({ plan: this.state.plan });
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <div className="mb-3">
                <small className="text-uppercase font-weight-bold">
                  Specialities
                </small>
              </div>

              {this.state.specialities.map((item, index) => {
                return (
                  <div key={index}>
                    <div
                      className="custom-control custom-control-alternative custom-checkbox"
                      key={index}
                    >
                      <input
                        className="custom-control-input"
                        type="checkbox"
                        id={"checkbox_SPECIALITY_" + index}
                        onChange={(e) => {
                          this.onSpecialitySelectionChanged(item, e);
                        }}
                        checked={this.hasItem(
                          this.state.medic.specialities,
                          item
                        )}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor={"checkbox_SPECIALITY_" + index}
                      >
                        <span className="text-muted">{item.name}</span>
                      </label>
                    </div>
                  </div>
                );
              })}

              
            </FormGroup>
          </div>
          <div className="modal-footer">
            <Button
              color="primary"
              type="button"
              onClick={(e) => {
                e.preventDefault();
                this.props.saveMedic(this.state.medic);
              }}
            >
              Save
            </Button>
            <Button
              className="ml-auto"
              color="link"
              data-dismiss="modal"
              type="button"
              onClick={() => this.props.toggleModal(this.props.modalName)}
            >
              Close
            </Button>
          </div>
        </Modal>
      </>
    );
  }
}

export default MedicEditionModal;
