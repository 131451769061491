import { getHttpBearerConfig } from 'services/httpServices';
import axios from 'axios';
const appConfig = require('config/appConfig');

export const hasRole = (user, role) => {
  //exports.hasRole = function(user, role) {
  if (!user.role) return false;

  if (!Array.isArray(user.role)) return false;

  if (user.role.includes(role)) {
    return true;
  }

  return false;
};

export const saveRole = (currentUserToken, targetUser, role) => {
  //exports.saveRole = function(currentUserToken, targetUser, role) {
  let config = getHttpBearerConfig(currentUserToken);

  return axios
    .patch(
      //`${appConfig.gcfUrl}/users/${targetUser.uid}`,
      `${appConfig.gcfUrl}/users/role/${targetUser.uid}`,
      { role: role },
      config
    )
    .catch((e) => {
      console.log('error:', e);

      throw e;
    });
};
