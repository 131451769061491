import React from 'react';

import classnames from 'classnames';
// reactstrap components
import { Button, Modal, FormGroup, Input, InputGroupText, InputGroup, Row, Col } from 'reactstrap';
// core components

const { PLAN_BULLETS } = require('types/planBulletTypes');

class PromotionalPlanEditionModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      promotionalPlan: this.props.promotionalPlan,
      plans: this.props.plans,
    };
  }

  componentWillReceiveProps(nextProps) {
    //console.log("componentWillReceiveProps", nextProps);
    this.setState({
      promotionalPlan: nextProps.promotionalPlan,
      plans: nextProps.plans,
    });
  }

  onLifeInsuranceSelectionChanged(item, event) {
    this.state.promotionalPlan.lifeInsurances = this.state.promotionalPlan.lifeInsurances || [];

    if (event.target.checked) {
      if (!this.hasItem(this.state.promotionalPlan.lifeInsurances, item)) {
        this.state.promotionalPlan.lifeInsurances.push(item);
      }
    } //off
    else {
      if (this.hasItem(this.state.promotionalPlan.lifeInsurances, item)) {
        let newList = [];
        this.state.promotionalPlan.lifeInsurances.forEach((existent) => {
          if (existent.id !== item.id) newList.push(existent);
        });
        this.state.promotionalPlan.lifeInsurances = newList;
      }
    }
    this.setState({ promotionalPlan: this.state.promotionalPlan });
  }

  hasPlan = (items, item) => {
    if (!items) return false;

    if (!item) return false;

    if (!items.length) return false;

    let exists = false;
    items.forEach((i) => {
      if (i.id === item.id) exists = true;
    });

    return exists;
  };

  hasItem = (items, item) => {
    if (!items) return false;

    if (!item) return false;

    if (!items.length) return false;

    let exists = false;
    items.forEach((i) => {
      if (i === item) exists = true;
    });

    return exists;
  };

  validateFormData(promotionalPlan) {
    let errors = [];

    if (!promotionalPlan) promotionalPlan = this.state.promotionalPlan;

    //promotionalPlan.includedBullets.forEach((item) => {
    // if (!this.isValidadBeneficiaryData(beneficiary)) {
    //   errors.push(beneficiary);
    // }
    //});

    if (errors.length === 0) errors = null;

    this.setState({ errors: errors });

    return errors;
  }

  onPriceInputInit(cleave, initialValue) {
    //this.state.phoneCleave.setRawValue(this.state.phoneRawValue);

    //cleave.setRawValue(this.state.phoneRawValue);
    cleave.setRawValue(initialValue ? initialValue : '');
  }

  removeIncludedBullet(index) {
    let newList = [];

    this.state.promotionalPlan.includedBullets.forEach((element, bulletIndex) => {
      if (index !== bulletIndex) {
        newList.push(element);
      }
    });

    this.state.promotionalPlan.includedBullets = newList;
    this.setState({ lead: this.state.lead }, this.validateFormData);
  }

  onPromotionalPlanSelectionChanged(item, event) {
    this.state.promotionalPlan.includedBullets = this.state.promotionalPlan.includedBullets || [];

    if (event.target.checked) {
      if (!this.hasItem(this.state.promotionalPlan.includedBullets, item)) {
        this.state.promotionalPlan.includedBullets.push(item);
      }
    } //off
    else {
      if (this.hasItem(this.state.promotionalPlan.includedBullets, item)) {
        let newList = [];
        this.state.promotionalPlan.includedBullets.forEach((existent) => {
          if (existent !== item) newList.push(existent);
        });
        this.state.promotionalPlan.includedBullets = newList;
      }
    }
    this.setState({ promotionalPlan: this.state.promotionalPlan });
  }

  onAssociatedPlansSelectionChanged(item, event) {
    this.state.promotionalPlan.associatedPlans = this.state.promotionalPlan.associatedPlans || [];

    if (event.target.checked) {
      if (!this.hasPlan(this.state.promotionalPlan.associatedPlans, item)) {
        this.state.promotionalPlan.associatedPlans.push(item);
      }
    } //off
    else {
      if (this.hasPlan(this.state.promotionalPlan.associatedPlans, item)) {
        let newList = [];
        this.state.promotionalPlan.associatedPlans.forEach((existent) => {
          if (existent.id !== item.id) newList.push(existent);
        });
        this.state.promotionalPlan.associatedPlans = newList;
      }
    }
    this.setState({ promotionalPlan: this.state.promotionalPlan });
  }

  render() {
    return (
      <>
        <Modal
          className="modal-dialog-centered"
          isOpen={this.props.promotionalPlanEditionModalIsOpen}
          toggle={() => this.props.toggleModal(this.props.modalName)}
        >
          <div className="modal-header">
            <h6 className="modal-title" id="modal-title-default">
              <>PromotionalPlan edition</>
            </h6>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.props.toggleModal(this.props.modalName)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            {this.state.promotionalPlan.id && (
              <FormGroup>
                <Input
                  disabled
                  placeholder="Id"
                  type="text"
                  value={this.state.promotionalPlan.id}
                />
              </FormGroup>
            )}

            <div className="mb-3">
              <small className="text-uppercase font-weight-bold">Plan asociado</small>
            </div>

            {this.state.plans &&
              this.state.plans.map((plan, index) => {
                return (
                  <div className="box-white-2">
                    <div
                      key={index}
                      className="custom-control custom-control-alternative custom-checkbox"
                    >
                      <input
                        className="custom-control-input"
                        onChange={(e) => {
                          this.onAssociatedPlansSelectionChanged(plan, e);
                        }}
                        checked={this.hasPlan(this.state.promotionalPlan.associatedPlans, plan)}
                        value={plan.id}
                        id={'checkbox_PLAN_' + index}
                        type="checkbox"
                      />
                      <label className="custom-control-label" htmlFor={'checkbox_PLAN_' + index}>
                        <span className="text-muted">{plan.name}</span>
                      </label>
                    </div>
                  </div>
                );
              })}

            <FormGroup
              className={classnames({
                focused: this.state.birthdayFocused,
              })}
            >
              <div className="mb-3">
                <small className="text-uppercase font-weight-bold">Título</small>
              </div>
              <InputGroup className="mb-4">
                <Input
                  value={this.state.promotionalPlan.name ? this.state.promotionalPlan.name : ''}
                  placeholder="Un gran plan"
                  type="text"
                  onChange={(e) => {
                    e.preventDefault();
                    this.state.promotionalPlan.name = e.target.value;
                    this.setState({
                      promotionalPlan: this.state.promotionalPlan,
                    });
                  }}
                />

                <InputGroupText>
                  <i className="ni ni-zoom-split-in" />
                </InputGroupText>
              </InputGroup>

              <div className="mb-3">
                <small className="text-uppercase font-weight-bold">Descripción</small>
              </div>
              <InputGroup className="mb-4">
                <Input
                  placeholder="Las razones por la que este está bueno son varias"
                  value={
                    this.state.promotionalPlan.description
                      ? this.state.promotionalPlan.description
                      : ''
                  }
                  type="text"
                  onChange={(e) => {
                    e.preventDefault();
                    this.state.promotionalPlan.description = e.target.value;
                    this.setState({
                      promotionalPlan: this.state.promotionalPlan,
                    });
                  }}
                />

                <InputGroupText>
                  <i className="ni ni-zoom-split-in" />
                </InputGroupText>
              </InputGroup>

              <label>
                <span className="text-muted">Order</span>
              </label>
              <InputGroup className="mb-4">
                <Input
                  placeholder="1 = primero"
                  value={this.state.promotionalPlan.order ? this.state.promotionalPlan.order : ''}
                  type="text"
                  onChange={(e) => {
                    e.preventDefault();
                    try {
                      this.state.promotionalPlan.order = parseInt(e.target.value);
                    } catch (e) {}
                    this.setState({
                      promotionalPlan: this.state.promotionalPlan,
                    });
                  }}
                />

                <InputGroupText>
                  <i className="ni ni-zoom-split-in" />
                </InputGroupText>
              </InputGroup>

              <label>
                <span className="text-muted">Recomended</span>
              </label>
              <InputGroup className="mb-4">
                <Input
                  placeholder="0 ó 1"
                  value={
                    this.state.promotionalPlan.recomended
                      ? this.state.promotionalPlan.recomended
                      : ''
                  }
                  type="text"
                  onChange={(e) => {
                    e.preventDefault();
                    try {
                      this.state.promotionalPlan.recomended = e.target.value;
                    } catch (e) {}
                    this.setState({
                      promotionalPlan: this.state.promotionalPlan,
                    });
                  }}
                />

                <InputGroupText>
                  <i className="ni ni-zoom-split-in" />
                </InputGroupText>
              </InputGroup>

              <div className="mb-3">
                <small className="text-uppercase font-weight-bold">Pricings</small>
              </div>
              {this.state.promotionalPlan &&
                this.state.promotionalPlan.associatedPlans &&
                this.state.promotionalPlan.associatedPlans.map((associatedPlan) => {
                  return (
                    <>
                      <Row>
                        <Col lg="4" sm="6">
                          <FormGroup>
                            <label>
                              <span className="text-muted">Price</span>
                            </label>

                            <p>
                              {associatedPlan.prices && associatedPlan.prices.lenght !== 0
                                ? '$ ' + associatedPlan.prices[0].amount
                                : ''}
                            </p>
                          </FormGroup>
                        </Col>
                        <Col lg="4" sm="6">
                          <FormGroup>
                            <label>
                              <span className="text-muted">Cantidad individuos</span>
                            </label>

                            <p>
                              {associatedPlan.maxFamilyMembers
                                ? associatedPlan.maxFamilyMembers
                                : ''}
                            </p>
                          </FormGroup>
                        </Col>
                      </Row>
                    </>
                  );
                })}

              <p>Add included bullets</p>

              {PLAN_BULLETS.map((item, index) => {
                return (
                  <div key={index}>
                    <div className="custom-control custom-control-alternative custom-checkbox">
                      <input
                        className="custom-control-input"
                        type="checkbox"
                        id={'checkbox_PROMOTIONAL_PLAN_' + index}
                        onChange={(e) => {
                          this.onPromotionalPlanSelectionChanged(item.productType, e);
                        }}
                        checked={this.hasItem(
                          this.state.promotionalPlan.includedBullets,
                          item.productType
                        )}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor={'checkbox_PROMOTIONAL_PLAN_' + index}
                      >
                        <span className="text-muted">{item.title}</span>
                      </label>
                    </div>
                  </div>
                );
              })}
              {/* {this.state.promotionalPlan.includedBullets &&
                this.state.promotionalPlan.includedBullets.map(
                  (item, index) => {
                    return (
                      <Card
                        key={index}
                        className=" bg-transparent card-form border-0"
                      >
                        <CardBody className="p-0">
                          <div className="d-flex">
                            <div className="fw">
                              <Row>
                                <Col lg="6" sm="6" xs="6">
                                  <div className="title-form-purple">
                                    Bullet {index + 1}
                                  </div>
                                </Col>
                                <Col
                                  lg="6"
                                  sm="6"
                                  xs="6"
                                  className="text-right"
                                >
                                  <div
                                    className="text-eliminar"
                                    onClick={() => {
                                      this.removeIncludedBullet(index);
                                    }}
                                  >
                                    Eliminar
                                  </div>
                                </Col>
                              </Row>

                              <Row className="pt-2">
                                <Col lg="12" sm="12">
                                  <CustomInput
                                    labelText="Texto"
                                    errorLabelText="Campo inválido"
                                    validationFn={validateHasValue}
                                    inputProps={{
                                      value: this.state.promotionalPlan
                                        .includedBullets[index],
                                      onChange: (event) => {
                                        this.state.promotionalPlan.includedBullets[
                                          index
                                        ] = event.target.value;

                                        this.validateFormData(
                                          this.state.promotionalPlan
                                        );

                                        this.setState({
                                          promotionalPlan: this.state
                                            .promotionalPlan,
                                        });
                                      },

                                      placeholder: "",
                                    }}
                                  />
                                </Col>
                              </Row>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    );
                  }
                )} */}
            </FormGroup>
          </div>
          <div className="modal-footer">
            <Button
              color="primary"
              type="button"
              onClick={(e) => {
                e.preventDefault();
                this.props.savePromotionalPlan(this.state.promotionalPlan);
              }}
            >
              Save
            </Button>
            <Button
              className="ml-auto"
              color="link"
              data-dismiss="modal"
              type="button"
              onClick={() => this.props.toggleModal(this.props.modalName)}
            >
              Close
            </Button>
          </div>
        </Modal>
      </>
    );
  }
}

export default PromotionalPlanEditionModal;
