import { getHttpBearerConfig } from 'services/httpServices';
import axios from 'axios';
const appConfig = require('config/appConfig');

export const listNotificationEvents = (currentUserToken) => {
  return new Promise(function (resolve, reject) {
    let config = getHttpBearerConfig(currentUserToken);

    return axios
      .get(`${appConfig.gcfUrl}/notifications?filters[]=includeEvents`, config)
      .then((response) => {
        console.log('notifications response:', response);
        if (response && response.data) resolve(response.data);
        else resolve([]);
      })
      .catch((e) => {
        console.log('error:', e);

        reject(e);
      });
  });
};

export const createNotificationEvent = (currentUserToken, notificationEvent) => {
  return new Promise(function (resolve, reject) {
    let config = getHttpBearerConfig(currentUserToken);

    console.log('notificationEvent en service:', notificationEvent);

    let data = notificationEvent;

    //uid = null para que sea para todos...
    data.uid = null;

    return axios
      .post(`${appConfig.gcfUrl}/notifications`, data, config)
      .then((response) => {
        console.log('create notificationEvent response:', response);
        if (response && response.data) resolve(response.data);
        else resolve(null);
      })
      .catch((e) => {
        console.log('error:', e);

        reject(e);
      });
  });
};

export const updateNotificationEvent = (currentUserToken, notificationEvent) => {
  return new Promise(function (resolve, reject) {
    //exports.saveRole = function(currentUserToken, targetUser, role) {
    let config = getHttpBearerConfig(currentUserToken);

    let data = notificationEvent;
    return axios
      .patch(`${appConfig.gcfUrl}/notifications/${notificationEvent.id}`, data, config)
      .then((response) => {
        console.log('update notificationEvent response:', response);
        if (response && response.data) resolve(response.data);
        else resolve(null);
      })
      .catch((e) => {
        console.log('error:', e);

        reject(e);
      });
  });
};

export const deleteNotificationEvent = (currentUserToken, notificationEvent) => {
  return new Promise(function (resolve, reject) {
    let config = getHttpBearerConfig(currentUserToken);

    return axios
      .delete(`${appConfig.gcfUrl}/notifications/${notificationEvent.id}`, config)
      .then((response) => {
        console.log('delete notificationEvent response:', response);
        if (response && response.data) resolve(response.data);
        else resolve(null);
      })
      .catch((e) => {
        console.log('error:', e);

        reject(e);
      });
  });
};
