import React from "react";

// reactstrap components
import { Card, CardHeader, CardBody, Container, Row, Col } from "reactstrap";

// core components
import WhatsappButton from "components/Buttons/WhatsappButton";
import BasicNavbar from "components/Navbars/BasicNavbar.jsx";

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);

    let search = window.location.search;
    let params = new URLSearchParams(search);

    let resetCode = params.get("oobCode");

    this.state = {
      termsChecked: true,
      registrationPassword: "",
      registrationEmail: "",
      resetCode: resetCode,
    };
  }

  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }

  render() {
    return (
      <>
        <WhatsappButton />
        <BasicNavbar />

        <main ref="main">
          <section className="section section-shaped bg-light-grey">
            <Container>
              <Row className="justify-content-center">
                <Col lg="5">
                  <Card className="bg-transparent card-form border-0">
                    <CardHeader className="pb-0 bg-transparent">
                      <img
                        alt="..."
                        className="img-center"
                        style={{}}
                        src={require("assets/img/brand/illustration-end.svg")}
                      />
                      <div className="big-title text-center">
                        Felicitaciones, ya generaste tu clave
                      </div>
                    </CardHeader>
                    <CardBody>
                      <p className="text-end">
                        Descargá la app e ingresá para <b>disfrutar</b> del
                        poder de la salud digital
                      </p>
                    </CardBody>

                    {/* <div className="text-center">
                      <Button
                        color="custom noarrow"
                        type="button"
                        onClick={(event) => {
                          event.preventDefault();

                          this.props.history.push("/");
                        }}
                      >
                        Volver a la pantalla principal
                      </Button>
                    </div> */}
                  </Card>
                </Col>
              </Row>
            </Container>
          </section>
        </main>
      </>
    );
  }
}

export default ResetPassword;
