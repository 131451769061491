import React from "react";
// nodejs library that concatenates classes

// Import Swiper styles
import "swiper/swiper.scss";

// reactstrap components
import { Badge, Container, Row, Col } from "reactstrap";

// core components
import WhatsappButton from "components/Buttons/WhatsappButton";
import LifeInsuranceProductLandingNavbar from "./LifeInsuranceProductLandingNavbar.jsx";
import Footer from "./Footer.jsx";
class RiskClasification extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }

  render() {
    return (
      <>
        <WhatsappButton />

        <LifeInsuranceProductLandingNavbar />
        <main ref="main">
          {/*hero*/}
          <section className="section section-lg section-risk pb-md-150 pt-sm-5">
            <Container className="d-flex">
              <div className="col px-0">
                <form>
                  <Row>
                    <Col xs="12" lg="12">
                      <h1 className="main-title text-black text-center pt-100 max-720">
                        Clasificación de riesgo
                      </h1>
                      <p className="text-black text-center max-720 pb-100">
                        Existe una institución que se encarga de evaluar la
                        posición de una compañía en la industria. Se trata de la
                        Evaluadora Latinoamericana S.A. Calificadora de Riesgos,
                        entidad que en marzo de 2014 evaluó a nuestra empresa y
                        la distinguió otorgándole la máxima calificación,{" "}
                        <b>simbolizada con la letra “A“ (*).</b>
                      </p>
                    </Col>
                  </Row>
                </form>{" "}
              </div>
              {/* SVG separator */}
              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 200"
                  x="0"
                  y="0"
                >
                  <polygon
                    className="fill-white"
                    points="2560 0 2560 200 0 200"
                  />
                </svg>
              </div>
            </Container>
          </section>
          {/*Box clasificacion*/}
          <section className="section section-lg mt--200 pb-100">
            <Container>
              <Row className="row-grid align-items-center">
                <Col md="12">
                  <div className="box-risk">
                    <img
                      alt="..."
                      //style={{ width: "350px" }}
                      src={require("assets/img/brand/clasif-a.png")}
                    />
                    <p>
                      Se trata de una calificación de validez internacional de
                      la que estamos orgullosos y que nos fue otorgada por el
                      voto unánime de todos los miembros que integran el
                      consejo.
                    </p>
                  </div>
                  <p className="small-text text-center max-720">
                    (*) El sistema de calificación emplea un sistema alfabético
                    que va desde la «A» hasta la «D»
                  </p>
                </Col>
              </Row>
            </Container>
          </section>

          {/*Dictamen de la calificadora*/}
          <section className="section section-lg pt-0 pb-150">
            <Container>
              <Row className="row-grid align-items-center">
                <Col md="12">
                  <h4 className="text-black text-center">
                    Dictamen de la calificadora
                  </h4>
                  <p className="text-black text-center max-720">
                    La categoría A corresponde a las obligaciones de seguros que
                    presentan una buena capacidad de pago en los términos y
                    plazos pactados. La calificación final surge de la suma de
                    puntajes obtenidos por:
                  </p>
                  <div className="max-420">
                    <ul className="list-unstyled mt-3">
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge className="badge-circle small gradient mr-3">
                              <i className="ni ni-check-bold text-white" />
                            </Badge>
                          </div>
                          <div>
                            <h6 className="mb-0">Composición de Activos</h6>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge className="badge-circle small gradient mr-3">
                              <i className="ni ni-check-bold text-white" />
                            </Badge>
                          </div>
                          <div>
                            <h6 className="mb-0">Análisis de Sensibilidad</h6>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge className="badge-circle small gradient mr-3">
                              <i className="ni ni-check-bold text-white" />
                            </Badge>
                          </div>
                          <div>
                            <h6 className="mb-0">
                              Evaluación de la Política de Reaseguro
                            </h6>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge className="badge-circle small gradient mr-3">
                              <i className="ni ni-check-bold text-white" />
                            </Badge>
                          </div>
                          <div>
                            <h6 className="mb-0">
                              Evaluación de la Política de Inversiones
                            </h6>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge className="badge-circle small gradient mr-3">
                              <i className="ni ni-check-bold text-white" />
                            </Badge>
                          </div>
                          <div>
                            <h6 className="mb-0">
                              Posición de la Compañía en la Industria
                            </h6>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge className="badge-circle small gradient mr-3">
                              <i className="ni ni-check-bold text-white" />
                            </Badge>
                          </div>
                          <div>
                            <h6 className="mb-0">
                              Características de la Administración y Propiedad
                            </h6>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
              {/* SVG separator */}
              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 200"
                  x="0"
                  y="0"
                >
                  <polygon fill="#FAFAFA" points="2560 0 2560 200 0 200" />
                </svg>
              </div>
            </Container>
          </section>
        </main>
        <Footer />
      </>
    );
  }
}

export default RiskClasification;
