import { getHttpBearerConfig } from 'services/httpServices';
import { getUserWithToken } from 'helpers/coreHelper';
import axios from 'axios';
import { authRef } from 'config/firebase';
import { TechnicalError } from 'helpers/errorClasses';

const appConfig = require('config/appConfig');

export const listAssistances = (limit, offset = 0, filters) => {
  return new Promise(function (resolve, reject) {
    let qs = '?offset=' + offset;

    if (limit) qs += '&limit=' + limit;

    getUserWithToken().then((uwt) => {
      let config = getHttpBearerConfig(uwt.token);
      return axios
        .get(`${appConfig.gcfUrl}/assistances${qs}`, config)
        .then((response) => {
          console.log('assistances response:', response);
          if (response && response.data) resolve(response.data);
          else resolve([]);
        })
        .catch((e) => {
          console.log('error:', e);

          reject(e);
        });
    });
  });
};

export const createAssistance = (currentUserToken, assistance) => {
  return new Promise(function (resolve, reject) {
    //exports.saveRole = function(currentUserToken, targetUser, role) {
    let config = getHttpBearerConfig(currentUserToken);

    return axios
      .post(
        `${appConfig.gcfUrl}/assistances`,
        {
          name: assistance.name,
          description: assistance.description,
          tel: assistance.tel,
          address: assistance.address,
          photo: assistance.photo,
          coordinate: assistance.coordinate,
          specialities: assistance.specialities,
        },
        config
      )
      .then((response) => {
        console.log('create assistance response:', response);
        if (response && response.data) resolve(response.data);
        else resolve(null);
      })
      .catch((e) => {
        console.log('error:', e);

        reject(e);
      });
  });
};

export const updateAssistance = (currentUserToken, assistance) => {
  return new Promise(function (resolve, reject) {
    //exports.saveRole = function(currentUserToken, targetUser, role) {
    let config = getHttpBearerConfig(currentUserToken);

    return axios
      .patch(
        `${appConfig.gcfUrl}/assistances/${assistance.id}`,
        {
          name: assistance.name,
          description: assistance.description,
          tel: assistance.tel,
          address: assistance.address,
          photo: assistance.photo,
          coordinate: assistance.coordinate,
          specialities: assistance.specialities,
        },
        config
      )
      .then((response) => {
        console.log('update assistance response:', response);
        if (response && response.data) resolve(response.data);
        else resolve(null);
      })
      .catch((e) => {
        console.log('error:', e);

        reject(e);
      });
  });
};

export const deleteAssistance = (currentUserToken, assistance) => {
  return new Promise(function (resolve, reject) {
    let config = getHttpBearerConfig(currentUserToken);

    return axios
      .delete(`${appConfig.gcfUrl}/assistances/${assistance.id}`, config)
      .then((response) => {
        console.log('delete assistance response:', response);
        if (response && response.data) resolve(response.data);
        else resolve(null);
      })
      .catch((e) => {
        console.log('error:', e);

        reject(e);
      });
  });
};

export const getAssistance = (assistanceId) => {
  return new Promise(function (resolve, reject) {
    authRef.onAuthStateChanged((user) => {
      if (!user) {
        reject({ code: 'AUTH_REQUIRED', message: 'Auth required' });

        return;
      }

      user.getIdToken().then((currentUserToken) => {
        let config = getHttpBearerConfig(currentUserToken);

        return axios
          .get(`${appConfig.gcfUrl}/assistances/single/${user.uid}/${assistanceId}`, config)
          .then((response) => {
            console.log('assistances response:', response);
            if (response && response.data) resolve(response.data);
            else resolve([]);
          })
          .catch((e) => {
            console.log('error:', e);

            reject(e);
          });
      });
    });
  });
};

export const changeAssistanceState = (assistance) => {
  return new Promise(function (resolve, reject) {
    getUserWithToken().then((uwt) => {
      let config = getHttpBearerConfig(uwt.token);

      return axios
        .post(
          `${appConfig.gcfUrl}/assistances/change-assistance-state/${assistance.id}`,
          {
            ...assistance,
          },
          config
        )
        .then((response) => {
          console.log('changeAssistanceState response:', response);
          if (response && response.data) resolve(response.data);
          else resolve([]);
        })
        .catch((e) => {
          console.log('error:', e);

          reject(e);
        });
    });
  });
};

export const bulkUpsertDrappMedicBookingAssistances = (assistances) => {
  return new Promise(function (resolve, reject) {
    getUserWithToken().then((uwt) => {
      let config = getHttpBearerConfig(uwt.token);

      let data = assistances;

      return axios
        .post(
          `${appConfig.gcfUrl}/assistances/bulk-upsert-drapp-medic-booking-assistances`,
          data,
          config
        )
        .then((response) => {
          console.log('create medics response:', response);
          if (response && response.data) resolve(response.data);
          else resolve(null);
        })
        .catch((e) => {
          console.log('error:', e);

          reject(e);
        });
    });
  });
};

export const lunchDrappGoogleSheetsMedicBookingAssistancesUpdate = () => {
  return new Promise(function (resolve, reject) {
    getUserWithToken().then((uwt) => {
      let config = getHttpBearerConfig(uwt.token);

      let data = null;

      return axios
        .post(
          `${appConfig.gcfUrl}/assistances/sync-drapp-medic-booking-assistances-from-googlesheets`,
          data,
          config
        )
        .then((response) => {
          console.log('sync drapp medic booking assistances response:', response);
          if (response && response.data) resolve(response.data);
          else resolve(null);
        })
        .catch((e) => {
          if (e.isAxiosError && e.response && e.response.data && e.response.data.isCustomError) {
            reject(new TechnicalError(e.response.data.code, null, e.response.data.message, e));
          } else reject(e);
        });
    });
  });
};

export const lunchGAMWebServiceHomeAssistancesUpdate = () => {
  return new Promise(function (resolve, reject) {
    getUserWithToken().then((uwt) => {
      let config = getHttpBearerConfig(uwt.token);

      let data = null;

      return axios
        .post(`${appConfig.gcfUrl}/assistances/sync-gam-home-assistances-from-ws`, data, config)
        .then((response) => {
          console.log('sync gam home assistances response:', response);
          if (response && response.data) resolve(response.data);
          else resolve(null);
        })
        .catch((e) => {
          console.log('error:', e);

          reject(e);
        });
    });
  });
};
