exports.TYPE_HOME_ASSISTANCE = 'homeAssistance';

exports.TYPE_HOME_ASSISTANCE_EMERGENCY = 'home-assistance-emergency';

exports.TYPE_TELEMEDICINE = 'telemedicine';

exports.TYPE_MEDIC_BOOKING = 'medicBooking';

exports.TYPE_ASSISTANCE = 'assistance';

exports.TYPE_ODONTOLOGY = 'odontology';

exports.TYPE_ODONTOLOGY_URGENCY = 'ODO_URG';
exports.TYPE_ODONTOLOGY_PREVENTIVE = 'ODO_PREV';
exports.TYPE_ODONTOLOGY_BASIC = 'ODO_BASIC';
exports.TYPE_ODONTOLOGY_MAYOR = 'ODO_MAYOR';

exports.TYPE_LIFE_INSURANCE = 'life-insurance';

exports.TYPE_GUARD_SERVICE = 'guard-service';

exports.TYPE_SEG_RDI = 'seg-rdi';
exports.TYPE_SEG_IQX = 'seg-iqx';
exports.TYPE_SEG_PYO = 'seg-pyo';
exports.TYPE_SEG_EG = 'seg-eg';
exports.TYPE_FAR_VADE = 'far-vade';
exports.TYPE_FAR_DESC = 'far-desc';
