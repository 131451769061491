import React from 'react';

import { getAuth, confirmPasswordReset } from 'firebase/auth';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Container,
  Row,
  Col,
} from 'reactstrap';

import CustomInput from 'components/Inputs/CustomInput.jsx';

import ModalError from 'components/Modals/ModalError.jsx';

import Footer from './../Landings/Footer';

// core components
import WhatsappButton from 'components/Buttons/WhatsappButton';
import BasicNavbar from 'components/Navbars/BasicNavbar.jsx';

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);

    let search = window.location.search;
    let params = new URLSearchParams(search);

    let resetCode = params.get('oobCode');

    this.state = {
      termsChecked: true,
      password: null,
      resetCode: resetCode,
      loading: false,
      errorModalInfo: null,
    };
  }
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }

  resetPassword() {
    if (this.state.loading) {
      return;
    }

    if (!this.state.password || !this.state.password.length) {
      // this.toggleModal("notificationModal")

      this.setState({
        errorModalInfo: {
          title: 'Error',
          description: 'La contraseña no puede estar vacía',
        },
      });
      return;
    }

    this.setState({ loading: true });

    const auth = getAuth();

    confirmPasswordReset(auth, this.state.resetCode, this.state.password)
      .then((result) => {
        this.props.history.push('/reset-password-success');
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.error('Error on password reset:', err);

        if (!err.code) {
          // alert("Error reseteando su contraseña: " + err.message);
          this.setState({
            errorModalInfo: {
              title: 'Error',
              description: ' Error reseteando su contraseña: ' + err.message,
            },
          });
          return;
        } else if (err.code === 'auth/invalid-action-code') {
          this.setState({
            errorModalInfo: {
              title: 'Error',
              description: 'Código inválido (' + err.code + ' - ' + err.message + ')',
            },
          });
          return;
        } else if (err.code === 'auth/weak-password') {
          this.setState({
            errorModalInfo: {
              title: 'Error : Complejidad de su contraseña muy baja, utilice mayúsculas y números',
              description: '  (' + err.code + ' - ' + err.message + ')',
            },
          });
          return;
        } else {
          this.setState({
            errorModalInfo: {
              title: 'Error ',
              description: '  (' + err.code + ' - ' + err.message + ')',
            },
          });
          return;
        }
      });
  }
  renderError() {
    if (this.state.errorModalInfo) {
      return (
        <ModalError
          title={this.state.errorModalInfo.title}
          description={this.state.errorModalInfo.description}
          onClose={() => {
            this.setState({ errorModalInfo: null });
          }}
        />
      );
    }
    return null;
  }

  render() {
    return (
      <>
        {this.renderError()}
        <WhatsappButton />

        <BasicNavbar />
        <main ref="main">
          <section className="section section-lg section-reset pb-lg-0 pt-lg-0 pt-sm-5">
            <Container className="d-flex">
              {/* SVG separator */}
              <div className="separator separator-top separator-skew hidden-md hidden-sm">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 200"
                  x="0"
                  y="0"
                >
                  <polygon className="fill-white" points="0 0 2560 0 2560 0 0 200" />
                </svg>
              </div>
              <div className="col px-0 mb-2">
                <Row className="justify-content-center">
                  <Col lg="5">
                    <Card className="shadow border-0">
                      <CardHeader className="bg-white">
                        <h4 className="text-center text-black">Confirmá tu nueva contraseña</h4>
                        <div className="btn-wrapper text-center"></div>
                      </CardHeader>
                      <CardBody className="px-lg-5 py-lg-5">
                        <Form
                          role="form"
                          onSubmit={(e) => {
                            e.preventDefault();
                          }}
                        >
                          <FormGroup>
                            <CustomInput
                              inputProps={{
                                id: 'registrationPassword',
                                placeholder: 'Password',
                                type: 'password',
                                autoComplete: 'off',
                                value: this.state.password,
                                onChange: (e) => {
                                  this.setState({
                                    password: e.target.value,
                                  });
                                },
                              }}
                            />

                            {/*<InputGroup className="input-group-alternative">

                              <InputGroupText>
                                <i className="ni ni-lock-circle-open" />
                              </InputGroupText>

                            <Input
                              id="registrationPassword"
                              value={this.state.password}
                              onChange={(e) => {
                                this.setState({
                                  password: e.target.value,
                                });
                              }}
                              placeholder="Password"
                              type="password"
                              autoComplete="off"
                            />
                          </InputGroup>
                          */}
                          </FormGroup>
                          {/* <div className="custom-control custom-control-alternative custom-checkbox">
                          <input
                            className="custom-control-input"
                            id=" customCheckLogin"
                            type="checkbox"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor=" customCheckLogin"
                          >
                            <span>Remember me</span>
                          </label>
                        </div> */}
                          <div className="text-center">
                            <Button
                              className="mt-4 full-w"
                              color="custom noarrow"
                              type="button"
                              disabled={this.state.loading}
                              onClick={(ev) => {
                                ev.preventDefault();
                                this.resetPassword();
                              }}
                            >
                              Continuar
                            </Button>
                          </div>
                        </Form>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            </Container>
          </section>
        </main>
        <Footer />
      </>
    );
  }
}

export default ResetPassword;
