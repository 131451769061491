import React from "react";
// nodejs library that concatenates classes

// Import Swiper styles
import "swiper/swiper.scss";

// reactstrap components
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
} from "reactstrap";

// core components
import WhatsappButton from "components/Buttons/WhatsappButton";
import ContactUsLandingNavbar from "./ContactUsLandingNavbar.jsx";
import Footer from "./Footer.jsx";
class ContactUsSuccess extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }

  render() {
    return (
      <>
        <WhatsappButton />

        <ContactUsLandingNavbar disablePlansButton />
        <main ref="main">
          {/*hero*/}
          <section className="section section-lg section-about-us pb-md-0 pt-md-0 pt-sm-5">
            <Container className="d-flex">
              <div className="col px-0">
                <form>
                  <Row>
                    <Col xs="12" lg="6">
                      <h1
                        style={{ fontWeight: "800" }}
                        className=" text-white pt-100"
                      >
                        Pronto nos estaremos comunicando con vos!
                      </h1>
                    </Col>
                    <Col lg="6" className="z-index-1 pt-5">
                      <img
                        alt="..."
                        style={{ width: "350px" }}
                        className="mb-sm--150"
                        src={require("assets/img/brand/hola.png")}
                      />
                    </Col>
                  </Row>
                </form>{" "}
              </div>
              {/* SVG separator */}
              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 200"
                  x="0"
                  y="0"
                >
                  <polygon
                    className="fill-white"
                    points="2560 0 2560 200 0 200"
                  />
                </svg>
              </div>
            </Container>
          </section>

          <section className="section section-lg">
            <Container>
              <Row className="row-grid aling-flex-center">
                <Card className="custom-about">
                  <CardBody>
                    <div className="image">
                      <img
                        alt="..."
                        style={{ width: "120px" }}
                        src={require("assets/img/brand/reward.png")}
                      />
                    </div>
                    <CardTitle>+200.000</CardTitle>
                    <CardSubtitle>de asegurados</CardSubtitle>
                  </CardBody>
                </Card>
                <Card className="custom-about">
                  <CardBody>
                    <div className="image">
                      <img
                        alt="..."
                        style={{ width: "120px" }}
                        src={require("assets/img/brand/time.png")}
                      />
                    </div>
                    <CardTitle>120</CardTitle>
                    <CardSubtitle>años de experiencia</CardSubtitle>
                  </CardBody>
                </Card>
                <Card className="custom-about">
                  <CardBody>
                    <div className="image">
                      <img
                        alt="..."
                        style={{ width: "120px" }}
                        src={require("assets/img/brand/app.png")}
                      />
                    </div>
                    <CardTitle>+5.000</CardTitle>
                    <CardSubtitle>profesionales en una app</CardSubtitle>
                  </CardBody>
                </Card>
              </Row>
            </Container>
          </section>
        </main>
        <Footer />
      </>
    );
  }
}

export default ContactUsSuccess;
