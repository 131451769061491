import React from "react";

import classnames from "classnames";
// reactstrap components
import {
  Button,
  Modal,
  FormGroup,
  Input,
  
  InputGroupText,
  InputGroup,
} from "reactstrap";
// core components

class ParametersEditionModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = { parameter: this.props.parameter };
  }

  componentWillReceiveProps(nextProps) {
    console.log("componentWillReceiveProps", nextProps);
    this.setState({ parameter: nextProps.parameter });
  }

  onPlanSelectionChanged(item, event) {
    this.state.parameter.plan = this.state.parameter.plan || {};

    if (event.target.checked) {
      this.state.parameter.plan = item;
      //this.state.parameter.plan.id = item.id;
      //this.state.parameter.plan.name = item.name;
    }
    this.setState({ parameter: this.state.parameter });
  }

  render() {
    return (
      <>
        {this.props.plans && (
          <Modal
            className="modal-dialog-centered"
            isOpen={this.props.parametersEditionModalIsOpen}
            toggle={() => this.props.toggleModal(this.props.modalName)}
          >
            <div className="modal-header">
              <h6 className="modal-title" id="modal-title-default">
                <>Parameter edition</>
              </h6>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => this.props.toggleModal(this.props.modalName)}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <div className="modal-body">
              {this.state.parameter.id && (
                <FormGroup>
                  <Input
                    disabled
                    placeholder="Id"
                    type="text"
                    value={this.state.parameter.id}
                  />
                </FormGroup>
              )}
              <FormGroup
                className={classnames({
                  focused: this.state.birthdayFocused,
                })}
              >
                <InputGroup className="mb-4">
                  <Input
                    value={
                      this.state.parameter.code ? this.state.parameter.code : ""
                    }
                    placeholder="Code"
                    type="text"
                    onChange={(e) => {
                      e.preventDefault();
                      this.state.parameter.code = e.target.value;
                      this.setState({ parameter: this.state.parameter });
                    }}
                  />

                  
                    <InputGroupText>
                      <i className="ni ni-zoom-split-in" />
                    </InputGroupText>
                  
                </InputGroup>
                <InputGroup className="mb-4">
                  <Input
                    placeholder="Value"
                    value={
                      this.state.parameter.value
                        ? this.state.parameter.value
                        : ""
                    }
                    type="text"
                    onChange={(e) => {
                      e.preventDefault();
                      this.state.parameter.value = e.target.value;
                      this.setState({ parameter: this.state.parameter });
                    }}
                  />

                  
                    <InputGroupText>
                      <i className="ni ni-zoom-split-in" />
                    </InputGroupText>
                  
                </InputGroup>
              </FormGroup>
            </div>
            <div className="modal-footer">
              <Button
                color="primary"
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  this.props.saveParameter(this.state.parameter);
                }}
              >
                Save
              </Button>
              <Button
                className="ml-auto"
                color="link"
                data-dismiss="modal"
                type="button"
                onClick={() => this.props.toggleModal(this.props.modalName)}
              >
                Close
              </Button>
            </div>
          </Modal>
        )}
      </>
    );
  }
}

export default ParametersEditionModal;
