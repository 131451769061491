import { getHttpBearerConfig } from 'services/httpServices';
import axios from 'axios';
import { getUserWithToken } from 'helpers/coreHelper';

const appConfig = require('config/appConfig');

export const listUserActivity = (userId) => {
  return new Promise(function (resolve, reject) {
    //exports.saveRole = function(currentUserToken, targetUser, role) {
    getUserWithToken().then((uwt) => {
      let config = getHttpBearerConfig(uwt.token);

      return axios
        .get(`${appConfig.gcfUrl}/activity/${userId}`, config)
        .then((response) => {
          console.log('logs response:', response);
          if (response && response.data) resolve(response.data);
          else resolve([]);
        })
        .catch((e) => {
          console.log('error:', e);

          reject(e);
        });
    });
  });
};
