import React from "react";

// reactstrap components
import { Button, Modal, Row, Col } from "reactstrap";

class LogDetailEditionModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = { log: this.props.log };
  }

  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
    });
  };

  componentWillReceiveProps(nextProps) {
    this.setState({
      log: nextProps.log,
    });
  }

  componentDidMount() {}

  componentWillUnmount() {}

  componentDidUpdate(e) {}

  render() {
    const log = this.state.log;

    const datetime = log.metadata ? log.metadata.timestamp : null;
    const uid = log.data ? log.data.uid : null;
    const spanId = log.data ? log.data.spanId : null;
    const severity = log.metadata ? log.metadata.severity : null;
    const eventType = log.data ? log.data.eventType : null;
    const env = log.data ? log.data.env : null;
    const msg = log.data ? log.data.msg : null;
    const functionName =
      log.metadata &&
      log.metadata.resource &&
      log.metadata.resource.labels &&
      log.metadata.resource.labels.function_name
        ? log.metadata.resource.labels.function_name
        : null;

    const data = log.data ? log.data.data : null;
    const error = log.data ? log.data.error : null;

    const errorCode = log.data ? log.data.errorCode : null;
    const service = log.data ? log.data.service : null;
    const elapsedTime = log.data ? log.data.elapsedTime : null;

    const request = log.data ? log.data.request : null;
    const response = log.data ? log.data.response : null;

    return (
      <>
        {
          <Modal
            className="modal-dialog-centered modal-lg"
            isOpen={this.props.LogDetailEditionModalIsOpen}
            toggle={() => this.props.toggleModal(this.props.modalName)}
          >
            <div className="modal-header">
              <h6 className="modal-title" id="modal-title-default">
                <>Detalle Log</>
              </h6>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => this.props.toggleModal(this.props.modalName)}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="mb-3">
                <small className="text-uppercase font-weight-bold">User</small>
              </div>

              <Row>
                <Col lg="12" sm="12">
                  <label>
                    <span className="text-muted">ID Usuario</span>
                  </label>
                  <p>{uid}</p>
                </Col>
              </Row>

              <div className="mb-3">
                <small className="text-uppercase font-weight-bold">Other</small>
              </div>

              <Row>
                <Col lg="6" sm="6">
                  <label>
                    <span className="text-muted">Fecha</span>
                  </label>
                  <p>{datetime}</p>
                </Col>

                <Col lg="6" sm="6">
                  <label>
                    <span className="text-muted">Severidad</span>
                  </label>
                  <p>{severity}</p>
                </Col>
              </Row>

              <Row>
                <Col lg="6" sm="6">
                  <label>
                    <span className="text-muted">Event Type</span>
                  </label>
                  <p> {eventType}</p>
                </Col>

                <Col lg="6" sm="6">
                  <label>
                    <span className="text-muted">Env</span>
                  </label>
                  <p>{env}</p>
                </Col>
              </Row>

              <Row>
                <Col lg="12" sm="12">
                  <label>
                    <span className="text-muted">Span Id</span>
                  </label>
                  <p>{spanId}</p>
                </Col>
              </Row>

              <Row>
                <Col lg="12" sm="12">
                  <label>
                    <span className="text-muted">Message</span>
                  </label>
                  <p>{msg}</p>
                </Col>
              </Row>

              <Row>
                <Col lg="12" sm="12">
                  <label>
                    <span className="text-muted">Data</span>
                  </label>
                  <p>{JSON.stringify(data)}</p>
                </Col>
              </Row>

              <div className="mb-3">
                <small className="text-uppercase font-weight-bold">Error</small>
              </div>

              <Row>
                <Col lg="12" sm="12">
                  <label>
                    <span className="text-muted">Error data</span>
                  </label>
                  <p>{JSON.stringify(error)}</p>
                </Col>
              </Row>

              <Row>
                <Col lg="12" sm="12">
                  <label>
                    <span className="text-muted">Error code</span>
                  </label>
                  <p>{errorCode}</p>
                </Col>
              </Row>

              <div className="mb-3">
                <small className="text-uppercase font-weight-bold">
                  Service
                </small>
              </div>

              <Row>
                <Col lg="12" sm="12">
                  <label>
                    <span className="text-muted">Elapsed time</span>
                  </label>
                  <p>{JSON.stringify(elapsedTime)}</p>
                </Col>
              </Row>

              <Row>
                <Col lg="12" sm="12">
                  <label>
                    <span className="text-muted">Function</span>
                  </label>
                  <p>{functionName}</p>
                </Col>
              </Row>

              <Row>
                <Col lg="12" sm="12">
                  <label>
                    <span className="text-muted">Service</span>
                  </label>
                  <p>{JSON.stringify(service)}</p>
                </Col>
              </Row>

              <Row>
                <Col lg="12" sm="12">
                  <label>
                    <span className="text-muted">IO Request</span>
                  </label>
                  <p>{JSON.stringify(request)}</p>
                </Col>
              </Row>

              <Row>
                <Col lg="12" sm="12">
                  <label>
                    <span className="text-muted">IO Response</span>
                  </label>
                  <p>{JSON.stringify(response)}</p>
                </Col>
              </Row>
            </div>

            <div className="modal-footer">
              <Button
                className="ml-auto"
                color="link"
                data-dismiss="modal"
                type="button"
                onClick={() => this.props.toggleModal(this.props.modalName)}
              >
                Salir
              </Button>
            </div>
          </Modal>
        }
      </>
    );
  }
}

export default LogDetailEditionModal;
