import React from 'react';

// reactstrap components

import WhatsappButton from 'components/Buttons/WhatsappButton';

class Policy extends React.Component {
  render() {
    return (
      <>
        <WhatsappButton />

        <div class="PolicyPage__Container-sc-2lu2df-2 bWrJEj">
          <div title="Proveedor externo" frameborder="0">
            <article class="document">
              <h1 class="title align-center">SOLICITUD DE BAJA Y ELIMINACIÓN DE DATOS HOLA RENE</h1>
              <ol class="list">
                <li class="item">
                  <p class="title">Procedimiento</p>
                  <p class="text">
                    Para solicitar la baja debe enviar un email desde la cuenta registrada en
                    nuestra plataforma a la dirección{' '}
                    <a href="mailto:miche@holarene.com">miche@holarene.com</a>.
                  </p>
                  <p class="text">
                    Esta acción iniciará el proceso de baja tanto de la cuenta como de los datos
                    asociados
                  </p>
                  <p class="text">Este proceso puede durar un máximo de 4 horas</p>
                </li>
                <li class="item">
                  <p class="title">PRINCIPIOS QUE ORIENTAN LA POLÍTICA</p>
                  <p class="text">
                    HolaRene y sus sociedades vinculadas o relacionadas (en adelante las "Sociedades
                    Vinculadas") respeta y respetará sus derechos, aplicando y garantizando los
                    siguientes principios orientadores de la Política:
                  </p>
                  <ol class="sublist" type="a">
                    <li>
                      <p class="text">
                        Principio de Legalidad: En el tratamiento de la información, se dará
                        aplicación a las disposiciones vigentes y aplicables en la República
                        Argentina, que rigen el tratamiento de la misma y demás derechos
                        fundamentales conexos, incluyendo las disposiciones contractuales pactadas
                        con los usuarios.
                      </p>
                    </li>
                    <li>
                      <p class="text">
                        Principio de Libertad: El tratamiento de datos personales, sólo se llevará a
                        cabo con el consentimiento, previo, expreso e informado del usuario. Los
                        datos personales que no tengan el carácter de datos públicos, no podrán ser
                        obtenidos o divulgados sin previa autorización, o en ausencia de mandato
                        legal, estatutario, o judicial que releve el consentimiento.
                      </p>
                    </li>
                    <li>
                      <p class="text">
                        Principio de Finalidad: El tratamiento de la información que no tenga el
                        carácter de dato público, a los que tenga acceso y sean recopilados y
                        recogidos por HolaRene, estarán subordinados y atenderán una finalidad
                        legítima, la cual será informada al respectivo usuario de dicha información.
                      </p>
                    </li>
                    <li>
                      <p class="text">
                        Principio de Veracidad o Calidad: La información protegida sujeta a
                        tratamiento debe ser veraz, completa, exacta, actualizada, comprobable y
                        comprensible. HolaRene no será responsable frente al Titular cuando sean
                        objeto de tratamiento, datos o información parcial, incompleta o fraccionada
                        o que induzca a error, entregada o divulgada por el Usuario declarando o
                        garantizando, de cualquier forma, su veracidad o calidad. HolaRene podrá -en
                        caso que fuera posible- pero no está obligado, a verificar la veracidad o
                        calidad de la información aportada por el Usuario, no constituyendo esta
                        facultad una eximición al Usuario de su obligación de aportar información
                        veraz, completa, exacta y actualizada.
                      </p>
                    </li>
                    <li>
                      <p class="text">
                        Principio de Transparencia: En el tratamiento de información protegida, se
                        garantizará el derecho del Usuario a obtener de HolaRene, en cualquier
                        momento y sin restricciones, información acerca de la existencia de
                        cualquier tipo de información protegida que sea de su interés (legal,
                        judicial o contractualmente justificado) y de su titularidad.
                      </p>
                    </li>
                    <li>
                      <p class="text">
                        Principio de Acceso y Circulación Restringida: La información protegida no
                        estará disponible en Internet u otros medios de divulgación o comunicación
                        masiva, salvo que el acceso sea técnicamente controlable para brindar un
                        conocimiento restringido sólo a HolaRene, las Sociedades Vinculadas, los
                        usuarios o terceros debidamente autorizados. Para estos propósitos la
                        obligación de HolaRene, será de medio, según lo establece la normativa
                        vigente.
                      </p>
                    </li>
                    <li>
                      <p class="text">
                        Principio de Seguridad: La información protegida bajo la Política sujeta a
                        tratamiento por HolaRene, será objeto de protección en la medida en que los
                        recursos técnicos y estándares mínimos así lo permitan, a través de la
                        adopción de medidas tecnológicas de protección, protocolos, y medidas
                        administrativas que sean necesarias para otorgar seguridad a los registros y
                        repositorios electrónicos evitando su adulteración, modificación, pérdida,
                        consulta, y en general en contra de cualquier uso o acceso no autorizado. En
                        caso de que exista un riesgo particular de violación de la seguridad de la
                        red, se informará a los usuarios sobre dicho riesgo y, si las medidas para
                        atenuar o eliminar ese riesgo no están bajo su control, sobre las posibles
                        soluciones.
                      </p>
                    </li>
                    <li>
                      <p class="text">
                        Principio de Confidencialidad: Todas y cada una de las personas que en
                        HolaRene administran, manejan, actualizan o tienen acceso a información
                        protegida que no tenga el carácter de pública, y se encuentre en sistemas de
                        información o bases o bancos de datos de terceros debidamente autorizados,
                        se comprometen a conservar y mantener de manera estrictamente confidencial y
                        no revelar a terceros, todas o cualesquiera de las informaciones personales,
                        comerciales, contables, técnicas, comerciales o de cualquier otro tipo
                        suministradas en la ejecución y ejercicio de sus funciones. HolaRene y sus
                        Sociedades Vinculadas para el tratamiento de la información protegida podrán
                        utilizar los sistemas de información y bases de datos de propiedad de
                        HolaRene y/o de sus Sociedades Vinculadas.
                      </p>
                    </li>
                  </ol>
                </li>
                <li class="item">
                  <p class="title">RESPONSABLE DEL TRATAMIENTO</p>
                  <p class="text">
                    Informamos a nuestros usuarios que el responsable de la base de datos es AR
                    Solutions S.A.U., CUIT 30-71707500-1, con domicilio en Av. Libertador 5930, Piso
                    12 – C1428ARP, Ciudad Autónoma de Buenos Aires, Argentina, y que los datos de
                    carácter personal proporcionados a través del Portal, así como los que pudiera
                    facilitar en el futuro en el marco de su relación con nosotros, son almacenados
                    en nuestros servidores para ser conservados bajo seguridad de los mismos.
                  </p>
                  <p class="text">
                    A continuación, informamos a nuestros usuarios qué datos recolectamos, para qué
                    propósitos y bajo qué circunstancias los compartimos con otros.
                  </p>
                  <ol class="sublist" type="a">
                    <li>
                      <p class="text">
                        Datos personales que recolectamos cuando visita nuestro Portal
                      </p>
                      <p class="text">
                        Los datos personales son información con la que podemos relacionar a
                        nuestros usuarios directa o indirectamente, tales como su nombre y
                        apellidos, dirección, número de teléfono, fecha de nacimiento, datos de
                        localización o dirección de correo electrónico y todo otro dato que usted
                        nos provea.
                      </p>
                      <p class="text">A continuación, le mostramos qué datos recolectamos:</p>
                      <ul class="sublist disc">
                        <li>
                          <p class="text">
                            <strong>
                              Cuando usted visita nuestra plataforma para realizar su próxima
                              solicitud, pero no se registra ni realiza una solicitud, recopilamos
                              los siguientes datos de navegación que no se encuentran relacionados
                              con los datos personales de los visitantes:
                            </strong>
                          </p>
                          <table class="t-simple">
                            <tbody>
                              <tr>
                                <td colspan="1" rowspan="1">
                                  <p class="text">
                                    <strong>CATEGORÍA DE INFORMACIÓN</strong>
                                  </p>
                                </td>
                                <td colspan="1" rowspan="1">
                                  <p class="text">
                                    <strong>EXPLICACIÓN</strong>
                                  </p>
                                </td>
                              </tr>
                              <tr>
                                <td colspan="1" rowspan="1">
                                  <p class="text">
                                    <strong>
                                      Información del dispositivo desde el que visita nuestro sitio
                                      web:
                                    </strong>
                                  </p>
                                </td>
                                <td colspan="1" rowspan="1">
                                  <p class="text">
                                    Identificación del dispositivo, sistema operativo y/u otros
                                    identificadores del dispositivo, hardware.
                                  </p>
                                </td>
                              </tr>
                              <tr>
                                <td colspan="1" rowspan="1">
                                  <p class="text">
                                    <strong>Información de conexión:</strong>
                                  </p>
                                </td>
                                <td colspan="1" rowspan="1">
                                  <p class="text">
                                    Hora, fecha, duración de uso del sitio web y origen.
                                  </p>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </li>
                        <li>
                          <p class="text">
                            <strong>
                              Si no sólo visita el Portal, sino que desea acceder a historial de
                              HolaRene (si ya fuera usuario) o si desea visualizar los servicios de
                              HolaRene, se le exige la registración como usuario para iniciar
                              sesión. En tales supuestos se le solicita a aceptación expresa de esta
                              Política y de los Términos y Condiciones generales de uso de HolaRene,
                              recopilamos los siguientes datos personales:
                            </strong>
                          </p>
                          <table class="t-simple">
                            <tbody>
                              <tr>
                                <td colspan="1" rowspan="1">
                                  <p class="text">
                                    <strong>CATEGORÍA DE INFORMACIÓN</strong>
                                  </p>
                                </td>
                                <td colspan="1" rowspan="1">
                                  <p class="text">
                                    <strong>EXPLICACIÓN</strong>
                                  </p>
                                </td>
                              </tr>
                              <tr>
                                <td colspan="1" rowspan="1">
                                  <p class="text">
                                    <strong>Información de acceso:</strong>
                                  </p>
                                </td>
                                <td colspan="1" rowspan="1">
                                  <p class="text">Nombre de usuario, contraseña.</p>
                                </td>
                              </tr>
                              <tr>
                                <td colspan="1" rowspan="1">
                                  <p class="text">
                                    <strong>Información de comunicación:</strong>
                                  </p>
                                </td>
                                <td colspan="1" rowspan="1">
                                  <p class="text">
                                    Comentarios, puntuaciones y devoluciones, comunicaciones
                                    generales
                                  </p>
                                </td>
                              </tr>
                              <tr>
                                <td colspan="1" rowspan="1">
                                  <p class="text">
                                    <strong>Otros datos:</strong>
                                  </p>
                                </td>
                                <td colspan="1" rowspan="1">
                                  <p class="text">
                                    Datos o información que Ud. nos provea voluntariamente mientras
                                    utiliza el sitio web, aplicación u otras fuentes como redes
                                    sociales u otras bases de datos públicas.
                                  </p>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </li>
                        <li>
                          <p class="text">
                            <strong>
                              Datos personales que recogemos al visitar nuestra página web, al
                              registrarse y o utilizar los servicios de HolaRene:
                            </strong>
                          </p>
                          <p class="text">
                            Los campos necesarios para la gestión están marcados como obligatorios.
                            Sin esta información no podemos procesar sus solicitudes y brindar
                            satisfactoriamente los servicios de HolaRene. El resto de la información
                            que no se muestra como campos obligatorios es opcional. Si usted nos
                            proporciona voluntariamente esta información, nos ayudará a mejorar el
                            servicio que le prestamos. Sin embargo, la omisión de esta información
                            no le impedirá aprovechar todas prestaciones ofrecidas por HolaRene. El
                            alcance depende de la información que usted proporcione. Pero la
                            información básica es la siguiente:
                          </p>
                          <table class="t-simple">
                            <tbody>
                              <tr>
                                <td colspan="1" rowspan="1">
                                  <p class="text">
                                    <strong>CATEGORÍA DE INFORMACIÓN</strong>
                                  </p>
                                </td>
                                <td colspan="1" rowspan="1">
                                  <p class="text">
                                    <strong>EXPLICACIÓN</strong>
                                  </p>
                                </td>
                              </tr>
                              <tr>
                                <td colspan="1" rowspan="1">
                                  <p class="text">
                                    <strong>Información de cuenta:</strong>
                                  </p>
                                </td>
                                <td colspan="1" rowspan="1">
                                  <p class="text">
                                    Esta información puede incluir el nombre completo, domicilio,
                                    fecha de nacimiento, número telefónico, email, información de
                                    pago (incluida información de verificación de pago vinculada),
                                    documento de identidad o número de documentos de identificación
                                    ante organismos públicos, número de socio en programas de
                                    promociones asociados a HolaRene, foto de perfil.
                                  </p>
                                </td>
                              </tr>
                              <tr>
                                <td colspan="1" rowspan="1">
                                  <p class="text">
                                    <strong>Ubicación:</strong>
                                  </p>
                                </td>
                                <td colspan="1" rowspan="1">
                                  <p class="text">
                                    Si el usuario da permiso para procesar sus datos de ubicación
                                    recopilamos dicha información precisa o aproximada a través de
                                    datos como GPS, dirección IP y wifi. Esta información se
                                    recopila cuando HolaRene funciona en primer plano y/o cuando
                                    está en segundo plano si el usuario dio permiso a dichos efectos
                                    en su dispositivo móvil o en los ajustes de HolaRene.
                                  </p>
                                </td>
                              </tr>
                              <tr>
                                <td colspan="1" rowspan="1">
                                  <p class="text">
                                    <strong>Información de acceso:</strong>
                                  </p>
                                </td>
                                <td colspan="1" rowspan="1">
                                  <p class="text">Mail y contraseña.</p>
                                </td>
                              </tr>
                              <tr>
                                <td colspan="1" rowspan="1">
                                  <p class="text">
                                    <strong>
                                      Información del dispositivo desde el que visita nuestro sitio
                                      web:
                                    </strong>
                                  </p>
                                </td>
                                <td colspan="1" rowspan="1">
                                  <p class="text">
                                    Identificación del dispositivo, sistema operativo y/u otros
                                    identificadores del dispositivo, hardware.
                                  </p>
                                </td>
                              </tr>
                              <tr>
                                <td colspan="1" rowspan="1">
                                  <p class="text">
                                    <strong>Información de conexión:</strong>
                                  </p>
                                </td>
                                <td colspan="1" rowspan="1">
                                  <p class="text">
                                    Hora, fecha, duración de uso del sitio web y origen.
                                  </p>
                                </td>
                              </tr>
                              <tr>
                                <td colspan="1" rowspan="1">
                                  <p class="text">
                                    <strong>Información de comunicación:</strong>
                                  </p>
                                </td>
                                <td colspan="1" rowspan="1">
                                  <p class="text">
                                    Comentarios, puntuaciones y devoluciones Y comunicaciones
                                    generales.
                                  </p>
                                </td>
                              </tr>
                              <tr>
                                <td colspan="1" rowspan="1">
                                  <p class="text">
                                    <strong>Historial de solicitudes:</strong>
                                  </p>
                                </td>
                                <td colspan="1" rowspan="1">
                                  <p class="text">
                                    Información sobre el tipo de servicio que solicita, el tipo de
                                    solicitud que realiza, el detalle de la solicitud, la
                                    información de entrega, la fecha y hora en la que se realiza la
                                    solicitud, el precio y la forma de pago.
                                  </p>
                                </td>
                              </tr>
                              <tr>
                                <td colspan="1" rowspan="1">
                                  <p class="text">
                                    <strong>Otra información:</strong>
                                  </p>
                                </td>
                                <td colspan="1" rowspan="1">
                                  <p class="text">
                                    Información que el usuario nos provea voluntariamente mientras
                                    utiliza el sitio web o la aplicación e información de otras
                                    fuentes tales como redes sociales o aplicaciones que utilicen
                                    nuestra interfaz de conexión u otras bases de datos públicas.
                                  </p>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </li>
                      </ul>
                      <p class="text">
                        En cualquiera de los casos antes indicados, por favor tenga presente que
                        toda información que usted nos provea según lo antes descripto, HolaRene la
                        considera información personal suya y que Ud. declara que la información
                        brindada se corresponde con sus datos personales y que los mismos son
                        correctos y actualizados.
                      </p>
                    </li>
                    <li>
                      <p class="text">Uso de los datos personales de los usuarios</p>
                      <p class="text">
                        Solo recolectamos sus datos personales cuando es necesario y la finalidad es
                        lícita y previamente definida. HolaRene recopila y usa su información
                        personal para los propósitos especificados en esta Política o en las
                        secciones relevantes de nuestro Portal.
                      </p>
                      <p class="text">HolaRene utiliza su información para distintos fines:</p>
                      <table class="t-simple">
                        <tbody>
                          <tr>
                            <td colspan="1" rowspan="1">
                              <p class="text">
                                <strong>Prestación de servicios:</strong>
                              </p>
                            </td>
                            <td colspan="1" rowspan="1">
                              <p class="text">
                                Utilizamos su información para ofrecer, personalizar, mantener y
                                mejorar la prestación de los servicios que les ofrecemos a nuestros
                                usuarios, así como realizar las siguientes actividades:
                              </p>
                              <ul class="sublist disc">
                                <li>
                                  <p class="text">Administrar el sitio web.</p>
                                </li>
                                <li>
                                  <p class="text">Crear y actualizar su cuenta.</p>
                                </li>
                                <li>
                                  <p class="text">Verificar su perfil.</p>
                                </li>
                                <li>
                                  <p class="text">
                                    Mejorar su experiencia de navegación personalizando el Portal.
                                  </p>
                                </li>
                                <li>
                                  <p class="text">
                                    Habilitarlo a usted para el uso de los servicios disponibles en
                                    el Portal.
                                  </p>
                                </li>
                                <li>
                                  <p class="text">Enviarle comunicados comerciales generales.</p>
                                </li>
                                <li>
                                  <p class="text">
                                    Realizar comentarios y valoraciones de los comercios adheridos,
                                    su oferta y productos.
                                  </p>
                                </li>
                                <li>
                                  <p class="text">Procesar y realizar el pago de los serviciod.</p>
                                </li>
                                <li>
                                  <p class="text">
                                    Controlar la ruta de la solicitud para verificar su
                                    procesamiento.
                                  </p>
                                </li>
                                <li>
                                  <p class="text">Activar funciones para personalizar su cuenta.</p>
                                </li>
                              </ul>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="1" rowspan="1">
                              <p class="text">
                                <strong>Servicio de Atención al Cliente:</strong>
                              </p>
                            </td>
                            <td colspan="1" rowspan="1">
                              <p class="text">
                                Cuando el usuario se pone en contacto con nuestro Servicio de
                                Atención al Cliente para obtener información o para presentar un
                                reclamo, almacenamos la información que el usuario nos proporciona.
                                A modo de ejemplo: la razón para contactarnos o en qué entrega
                                faltaba algo. Dependiendo del motivo del contacto, los datos
                                personales pueden variar. Como queremos mejorar nuestro servicio
                                para usted, almacenamos la comunicación en su cuenta. De esta manera
                                podemos responder a sus preguntas con mayor rapidez.
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="1" rowspan="1">
                              <p class="text">
                                <strong>Protección y seguridad:</strong>
                              </p>
                            </td>
                            <td colspan="1" rowspan="1">
                              <p class="text">
                                Utilizamos sus datos personales para mantener la protección,
                                seguridad e integridad de nuestros servicios y usuarios. Asimismo,
                                utilizamos la información para prevenir fraude.
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="1" rowspan="1">
                              <p class="text">
                                <strong>Investigación y desarrollo:</strong>
                              </p>
                            </td>
                            <td colspan="1" rowspan="1">
                              <p class="text">
                                Podemos utilizar la información que recopilamos para testear,
                                investigar, analizar y desarrollar productos. Esto permite que
                                podamos mejorar en términos de seguridad y que podamos desarrollar
                                nuevas funciones o servicios.
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="1" rowspan="1">
                              <p class="text">
                                <strong>Publicidad:</strong>
                              </p>
                            </td>
                            <td colspan="1" rowspan="1">
                              <p class="text">
                                Podemos enviarte correos electrónicos, SMS, push notifications o
                                realizar llamadas a tu móvil, teléfono fijo o cualquier otro
                                dispositivo que sea susceptible de recibir llamadas para ofrecerte
                                vouchers, descuentos y promociones, realizar encuestas y/ o sondeos
                                de opinión para mejorar nuestro servicio y la calidad del mismo. El
                                usuario puede oponerse a recibir llamadas y al tratamiento posterior
                                de los datos con fines publicitarios con cada correo electrónico o
                                en los ajustes en el Portal.
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="1" rowspan="1">
                              <p class="text">
                                <strong>Marketing:</strong>
                              </p>
                            </td>
                            <td colspan="1" rowspan="1">
                              <p class="text">
                                Queremos evitar newsletters genéricas y medidas de marketing
                                incontroladas. Por lo tanto, seleccionamos las ofertas que mejor se
                                adapten a sus intereses y nos ponemos en contacto con usted si
                                creemos que la información puede ser de su interés. Puede oponerse
                                al tratamiento posterior de los datos con fines publicitarios con
                                cada correo electrónico o en los ajustes en el Portal.
                              </p>
                              <p class="text">
                                HolaRene podrá utilizar la información para promocionar y procesar
                                concursos y sorteos, entregas de premios y ofrecerle publicidad y
                                contenidos relacionados con nuestros servicios y los de nuestros
                                socios comerciales o comercios adheridos.
                              </p>
                              <p class="text">
                                Asimismo, HolaRene podrá enviarle muestras de productos.
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="1" rowspan="1">
                              <p class="text">
                                <strong>
                                  Procedimientos judiciales o requerimientos administrativos:
                                </strong>
                              </p>
                            </td>
                            <td colspan="1" rowspan="1">
                              <p class="text">
                                Podremos utilizar su información personal en caso que nos sea
                                requerido por orden judicial o resolución administrativa,
                                regulatoria, arbitral, y en general cuando sea requerido por
                                cualquier autoridad gubernamental.
                              </p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <p class="text">
                        El Usuario presta su pleno y total consentimiento para que HolaRene recopile
                        sus datos personales y los utilice, transfiera, almacene y ceda, de
                        conformidad con el uso de información previsto en esta Política de
                        Privacidad.
                      </p>
                    </li>
                  </ol>
                </li>
              </ol>
            </article>
          </div>
        </div>
      </>
    );
  }
}

export default Policy;
