import React from "react";

import classnames from "classnames";
// reactstrap components
import {
  Button,
  Modal,
  FormGroup,
  Input,
  
  InputGroupText,
  InputGroup
} from "reactstrap";
// core components

class TelemedicineEditionModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { telemedicine: this.props.telemedicine };
  }

  componentWillReceiveProps(nextProps) {
    //console.log("componentWillReceiveProps", nextProps);
    this.setState({ telemedicine: nextProps.telemedicine });
  }

  render() {
    return (
      <>
        <Modal
          className="modal-dialog-centered"
          isOpen={this.props.telemedicineEditionModalIsOpen}
          toggle={() => this.props.toggleModal(this.props.modalName)}
        >
          <div className="modal-header">
            <h6 className="modal-title" id="modal-title-default">
              <>Telemedicine edition</>
            </h6>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.props.toggleModal(this.props.modalName)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            {this.state.telemedicine.id && (
              <FormGroup>
                <Input
                  disabled
                  placeholder="Id"
                  type="text"
                  value={this.state.telemedicine.id}
                />
              </FormGroup>
            )}
            <FormGroup
              className={classnames({
                focused: this.state.birthdayFocused
              })}
            >
              <InputGroup className="mb-4">
                <Input
                  value={
                    this.state.telemedicine.name
                      ? this.state.telemedicine.name
                      : ""
                  }
                  placeholder="Name"
                  type="text"
                  onChange={e => {
                    e.preventDefault();
                    this.state.telemedicine.name = e.target.value;
                    this.setState({ telemedicine: this.state.telemedicine });
                  }}
                />

                
                  <InputGroupText>
                    <i className="ni ni-zoom-split-in" />
                  </InputGroupText>
                
              </InputGroup>

              <InputGroup className="mb-4">
                <Input
                  placeholder="Description"
                  value={
                    this.state.telemedicine.description
                      ? this.state.telemedicine.description
                      : ""
                  }
                  type="text"
                  onChange={e => {
                    e.preventDefault();
                    this.state.telemedicine.description = e.target.value;
                    this.setState({ telemedicine: this.state.telemedicine });
                  }}
                />

                
                  <InputGroupText>
                    <i className="ni ni-zoom-split-in" />
                  </InputGroupText>
                
              </InputGroup>

              <InputGroup className="mb-4">
                <Input
                  placeholder="Tel."
                  value={
                    this.state.telemedicine.tel
                      ? this.state.telemedicine.tel
                      : ""
                  }
                  type="text"
                  onChange={e => {
                    e.preventDefault();
                    this.state.telemedicine.tel = e.target.value;
                    this.setState({ telemedicine: this.state.telemedicine });
                  }}
                />

                
                  <InputGroupText>
                    <i className="ni ni-zoom-split-in" />
                  </InputGroupText>
                
              </InputGroup>

              <InputGroup className="mb-4">
                <Input
                  placeholder="Address"
                  value={
                    this.state.telemedicine.address
                      ? this.state.telemedicine.address
                      : ""
                  }
                  type="text"
                  onChange={e => {
                    e.preventDefault();
                    this.state.telemedicine.address = e.target.value;
                    this.setState({ telemedicine: this.state.telemedicine });
                  }}
                />

                
                  <InputGroupText>
                    <i className="ni ni-zoom-split-in" />
                  </InputGroupText>
                
              </InputGroup>
            </FormGroup>
          </div>
          <div className="modal-footer">
            <Button
              color="primary"
              type="button"
              onClick={e => {
                e.preventDefault();
                this.props.saveTelemedicine(this.state.telemedicine);
              }}
            >
              Save
            </Button>
            <Button
              className="ml-auto"
              color="link"
              data-dismiss="modal"
              type="button"
              onClick={() => this.props.toggleModal(this.props.modalName)}
            >
              Close
            </Button>
          </div>
        </Modal>
      </>
    );
  }
}

export default TelemedicineEditionModal;
