import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Table,
  Container,
  Col,
  Row,
} from "reactstrap";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.jsx";
import MedicEditionModal from "./MedicEditionModal.jsx";
import ImportExcelModal from "./ImportExcelModal.jsx";

import { authRef } from "config/firebase";

import {
  listMedics,
  createMedic,
  updateMedic,
  deleteMedic,
  drAppSync,
  listMedicsSpecialities,
  bulkUpsertDrappMedics,
  lunchDrappGoogleSheetsMedicsUpdate,
  lunchRedsomMedicsUpdate,
} from "services/medicsServices";

import { DRAPP_COVERAGE_ID } from "config/appConfig";

import ExcelJS from "exceljs";

class MedicsOverview extends React.Component {
  constructor(props) {
    super(props);

    this.state = { leads: null, coverages: null, addNewLead: {} };
  }

  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
    });
  };

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    authRef.onAuthStateChanged((user) => {
      this.setState({ currentUser: user });

      listMedics(999999, 0, null)
        .then((medics) => {
          this.setState({ medics: medics.items });
        })
        .catch((e) => {
          if (e.response && e.response.data && e.response.data.message)
            alert(e.response.data.message);
          else alert(e.message);
        });

      listMedicsSpecialities()
        .then((specialities) => {
          this.setState({ specialities: specialities });
        })
        .catch((e) => {
          if (e.response && e.response.data && e.response.data.message)
            alert(e.response.data.message);
          else alert(e.message);
        });
    });
  }

  componentDidUpdate(e) {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }

  saveMedic(medic) {
    if (
      !medic.name ||
      !medic.phoneNumber ||
      !medic.address ||
      !medic.description
    ) {
      alert("Faltan datos requeridos");
      return;
    }

    authRef.onAuthStateChanged((user) => {
      user.getIdToken().then((token) => {
        if (medic.id)
          updateMedic(token, medic)
            .then((medic) => {
              this.loadData();
              console.log("Medic: ", medic);
              //alert("Medic: " + JSON.stringify(medic));
              this.toggleModal("medicEditionModal");
            })
            .catch((e) => {
              if (e.response && e.response.data && e.response.data.message)
                alert(e.response.data.message);
              else alert(e.message);
            });
        else
          createMedic(token, medic)
            .then((medic) => {
              this.loadData();
              console.log("Medic: ", medic);
              //alert("Medic: " + JSON.stringify(medic));
              this.toggleModal("medicEditionModal");
            })
            .catch((e) => {
              if (e.response && e.response.data && e.response.data.message)
                alert(e.response.data.message);
              else alert(e.message);
            });
      });
    });
  }

  innerDeleteMedic(medic) {
    authRef.onAuthStateChanged((user) => {
      user.getIdToken().then((token) => {
        deleteMedic(token, medic)
          .then((medic) => {
            this.loadData();
            console.log("Medic: ", medic);
            alert("Medic: " + JSON.stringify(medic) + "removed");
          })
          .catch((e) => {
            if (e.response && e.response.data && e.response.data.message)
              alert(e.response.data.message);
            else alert(e.message);
          });
      });
    });
  }

  innerDrAppSync() {
    drAppSync()
      .then((results) => {
        alert(JSON.stringify(results));
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.message)
          alert(e.response.data.message);
        else alert(e.message);
      });
  }

  worksheetHasHeader(worksheet, headerName) {
    const headerValues = worksheet.getRow(1).values;

    let exist = false;
    for (let index = 1; index <= headerValues.length; index++) {
      if (headerValues[index] === headerName) exist = true;
    }
    return exist;
  }

  onExcelUpload(excelFile) {
    if (!this.state.specialities) return;

    const workbook = new ExcelJS.Workbook();

    const medics = [];
    let parseErrors = [];

    const arryBuffer = new Response(excelFile).arrayBuffer();

    const $this = this;
    arryBuffer.then((data) => {
      workbook.xlsx.load(data).then(async function () {
        // play with workbook and worksheet now

        //const worksheet = workbook.getWorksheet(1);
        const worksheet = workbook.worksheets[0];

        let database_index = 1;
        let practitionerID_index = 2;
        let firstName_index = 3;
        let lastName_index = 4;
        let speciality_index = 5;
        let address_index = 6;
        let coords_index = 7;
        let price_index = 8;
        let url_index = 9;

        const rowHeader_database = "database";
        const rowHeader_practitionerID = "practitionerID";
        const rowHeader_Nombre = "Nombre";
        const rowHeader_Apellido = "Apellido";
        const rowHeader_Especialidad = "Especialidad";
        const rowHeader_Direccion = "Dirección";
        const rowHeader_Coordenadas = "Coordenadas";
        const rowHeader_Arancel = "Arancel (consulta)";
        const rowHeader_URL = "URL";

        try {
          if (!$this.worksheetHasHeader(worksheet, rowHeader_database))
            throw new Error("No existe la columna " + rowHeader_database);

          if (!$this.worksheetHasHeader(worksheet, rowHeader_practitionerID))
            throw new Error("No existe la columna " + rowHeader_practitionerID);

          if (!$this.worksheetHasHeader(worksheet, rowHeader_Nombre))
            throw new Error("No existe la columna " + rowHeader_Nombre);

          if (!$this.worksheetHasHeader(worksheet, rowHeader_Apellido))
            throw new Error("No existe la columna " + rowHeader_Apellido);

          if (!$this.worksheetHasHeader(worksheet, rowHeader_Especialidad))
            throw new Error("No existe la columna " + rowHeader_Especialidad);

          if (!$this.worksheetHasHeader(worksheet, rowHeader_Direccion))
            throw new Error("No existe la columna " + rowHeader_Direccion);
          if (!$this.worksheetHasHeader(worksheet, rowHeader_Coordenadas))
            throw new Error("No existe la columna " + rowHeader_Coordenadas);
          if (!$this.worksheetHasHeader(worksheet, rowHeader_Arancel))
            throw new Error("No existe la columna " + rowHeader_Arancel);
          if (!$this.worksheetHasHeader(worksheet, rowHeader_URL))
            throw new Error("No existe la columna " + rowHeader_URL);
        } catch (e) {
          $this.setState({ error: e });
          console.log("Values de columnas:", worksheet.getRow(1).values);
          $this.setState({ loading: false });
          return;
        }
        worksheet.eachRow(function (row, rowNumber) {
          //primera fila = header
          if (rowNumber === 1) {
            const rowValues = row.values;
            for (let index = 1; index <= rowValues.length; index++) {
              const element = rowValues[index];
              if (element === rowHeader_database) database_index = index;
              if (element === rowHeader_practitionerID)
                practitionerID_index = index;
              if (element === rowHeader_Nombre) firstName_index = index;
              if (element === rowHeader_Apellido) lastName_index = index;

              if (element === rowHeader_Especialidad) speciality_index = index;
              if (element === rowHeader_Direccion) address_index = index;
              if (element === rowHeader_Coordenadas) coords_index = index;

              if (element === rowHeader_Arancel) price_index = index;
              if (element === rowHeader_URL) url_index = index;
            }

            return;
          }

          try {
            const practitionerID = row.getCell(practitionerID_index).text;

            //salteo los vacíos
            if (practitionerID === "") return;

            const drappSpeciality = row.getCell(speciality_index).text;
            const mappedSpeciality =
              $this.getSpecialityByDrappEquivalence(drappSpeciality);
            const database = row.getCell(database_index).text;
            const firstName = row.getCell(firstName_index).text;
            const lastName = row.getCell(lastName_index).text;

            const address = row.getCell(address_index).text;
            const coords = row.getCell(coords_index).text;
            const price = row.getCell(price_index).text;
            const url = row.getCell(url_index).text;

            const medic = {
              externalId: practitionerID,
              practitionerID: practitionerID,
              database: database,
              name: firstName + " " + lastName,
              firstName: firstName,
              lastName: lastName,
              speciality: mappedSpeciality,
              address: address,
              coordinate:
                coords && coords.split(",").length > 1
                  ? {
                      _latitude: parseFloat(coords.split(",")[0].trim()),
                      _longitude: parseFloat(coords.split(",")[1].trim()),
                    }
                  : null,
              price: price,
              url: url,

              phoneNumber: null,

              photo: null,
              description: null,

              drappSpecialities: null,
              drappSpeciality: drappSpeciality,
              specialities: mappedSpeciality ? [mappedSpeciality] : [],

              coverages: [DRAPP_COVERAGE_ID],
              label: "drapp",
            };

            medics.push(medic);
          } catch (err) {
            debugger;
            parseErrors.push({ rowNumber: rowNumber, message: err.message });
          }
        });

        if (parseErrors && parseErrors.length !== 0) {
          let errorString = "";
          parseErrors.forEach((element) => {
            errorString +=
              "Fila: " +
              element.rowNumber +
              " - Error: " +
              element.message +
              "\n";
          });
          $this.setState({ error: { message: errorString } });

          return;
        }

        if (!medics || medics.length === 0) {
          alert("No hay medics a importar");
          return;
        }

        const groupByExternalId = $this.groupBy("externalId");
        const groupedMedicsAux = groupByExternalId(medics);

        const groupedMedics = [];
        for (var prop in groupedMedicsAux) {
          groupedMedics.push(groupedMedicsAux[prop]);
        }

        const unifiedMedics = [];
        groupedMedics.forEach((medicGroup) => {
          if (medicGroup.length > 1) {
            const specialities = [];
            medicGroup.forEach((medic) => {
              if (medic.speciality) specialities.push(medic.speciality);
            });

            //me quedo con el registro que tiene
            let medic = medicGroup.find((mg) => {
              return mg.speciality;
            });

            if (!medic) medic = medicGroup[0];
            unifiedMedics.push({
              ...medic,
              specialities: specialities,
            });
          } else {
            unifiedMedics.push({ ...medicGroup[0] });
          }
        });

        console.log("unifiedMedics:", unifiedMedics);
        $this.setState({ loading: true });

        try {
          let requestData = {
            medics: unifiedMedics,
            //parseErrors: parseErrors,
          };

          await bulkUpsertDrappMedics(requestData);
        } catch (e) {
          $this.setState({ error: e });
        }
        $this.setState({ loading: false });
        window.location.reload();
      });
    });
  }

  groupBy(key) {
    return function group(array) {
      return array.reduce((acc, obj) => {
        const property = obj[key];
        acc[property] = acc[property] || [];
        acc[property].push(obj);
        return acc;
      }, {});
    };
  }

  getSpecialityByDrappEquivalence(drappSpeciality) {
    return this.state.specialities.find((spec) => {
      return (
        spec &&
        spec.equivalences &&
        spec.equivalences.drappExcel === drappSpeciality
      );
    });
  }

  lunchDrappGoogleSheetsMedicsUpdate() {
    this.setState({ loading: true });
    lunchDrappGoogleSheetsMedicsUpdate()
      .then((data) => {
        alert("OK");
        window.location.reload();
      })
      .catch((e) => {
        console.log("Error", e);
        alert("Hubo un error al procesar " + e.message);
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  lunchRedsomMedicsUpdate() {
    this.setState({ loading: true });
    lunchRedsomMedicsUpdate()
      .then((data) => {
        alert("OK");
        window.location.reload();
      })
      .catch((e) => {
        console.log("Error", e);
        alert("Hubo un error al procesar " + e.message);
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  render() {
    if (this.state.loading) return <>loading</>;
    if (this.state.error) return <>{this.state.error.message}</>;

    return (
      <>
        <div className="main-content" ref="mainContent">
          <AdminNavbar {...this.props} currentUser={this.state.currentUser} />

          {this.state.medicToEdit && (
            <MedicEditionModal
              modalName="medicEditionModal"
              medics={this.state.medics}
              medicEditionModalIsOpen={this.state.medicEditionModal}
              medic={this.state.medicToEdit}
              toggleModal={this.toggleModal.bind(this)}
              saveMedic={this.saveMedic.bind(this)}
            />
          )}

          <ImportExcelModal
            modalName="ImportExcelModal"
            ImportExcelModalIsOpen={this.state.ImportDrappMedicsExcel}
            toggleModal={this.toggleModal.bind(this)}
            onExcelUpload={this.onExcelUpload.bind(this)}
          />

          <Container fluid>
            <Row>
              <div className="col">
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <Row>
                      <Col lg="7">
                        <h3 className="mb-0">Medics</h3>
                      </Col>
                      <Col lg="2">
                        <div className="btn-wrapper">
                          <Button
                            color="success"
                            onClick={(e) => {
                              e.preventDefault();

                              this.setState({ medicToEdit: {} });
                              this.toggleModal("medicEditionModal");
                            }}
                          >
                            New
                          </Button>
                        </div>
                      </Col>

                      <Col lg="3">
                        <UncontrolledDropdown>
                          <DropdownToggle
                            href="#"
                            role="button"
                            size="lg"
                            color=""
                            onClick={(e) => e.preventDefault()}
                          >
                            Más
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-arrow" right>
                            {/* <DropdownItem
                              onClick={(e) => {
                                e.preventDefault();
                                this.innerDrAppSync();
                              }}
                            >
                              DrApp Sync (NO USAR)
                            </DropdownItem> */}

                            <DropdownItem
                              onClick={(e) => {
                                e.preventDefault();
                                this.toggleModal("ImportDrappMedicsExcel");
                              }}
                            >
                              Importar Drapp Médicos desde Excel
                            </DropdownItem>
                            <DropdownItem
                              onClick={(e) => {
                                e.preventDefault();
                                this.lunchDrappGoogleSheetsMedicsUpdate();
                              }}
                            >
                              Actualizar Drapp Médicos desde GoogleSheets
                            </DropdownItem>
                            <DropdownItem
                              onClick={(e) => {
                                e.preventDefault();
                                this.lunchRedsomMedicsUpdate();
                              }}
                            >
                              Actualizar Redsom Médicos desde Api
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </Col>
                    </Row>
                  </CardHeader>
                  {!this.state.medics && <div>Loading</div>}
                  {this.state.medics && (
                    <Table
                      className="align-items-center table-flush"
                      responsive
                    >
                      <thead className="thead-light">
                        <tr>
                          <th scope="col"></th>
                          <th scope="col">Id</th>
                          <th scope="col">Name</th>
                          <th scope="col">Tel</th>
                          <th scope="col">Address</th>

                          <th scope="col" />
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.medics.map((medic, index) => {
                          return (
                            <tr key={index}>
                              <th scope="row">
                                <Media className="align-items-center">
                                  <a
                                    className="avatar rounded-circle mr-3"
                                    onClick={(e) => e.preventDefault()}
                                  >
                                    <img
                                      alt="..."
                                      src={medic.photo ? medic.photo : ""}
                                    />
                                  </a>
                                </Media>
                              </th>
                              {/* 01f89628-5448-4ebc-9aa2-fbdf94af0694 */}
                              <td>
                                <Media>
                                  <span className="mb-0 text-sm">
                                    {medic.id}
                                  </span>
                                </Media>
                              </td>
                              <td>
                                <Media>
                                  <span className="mb-0 text-sm">
                                    {medic.name}
                                  </span>
                                </Media>
                              </td>
                              <td>{medic.phoneNumber}</td>

                              <td>{medic.address}</td>

                              <td className="text-right">
                                <UncontrolledDropdown>
                                  <DropdownToggle
                                    className="btn-icon-only text-light"
                                    href="#"
                                    role="button"
                                    size="sm"
                                    color=""
                                    onClick={(e) => e.preventDefault()}
                                  >
                                    <i className="fas fa-ellipsis-v" />
                                  </DropdownToggle>
                                  <DropdownMenu
                                    className="dropdown-menu-arrow"
                                    right
                                  >
                                    <DropdownItem
                                      onClick={(e) => {
                                        e.preventDefault();

                                        this.setState({
                                          medicToEdit: medic,
                                        });
                                        this.toggleModal("medicEditionModal");
                                      }}
                                    >
                                      Edit
                                    </DropdownItem>

                                    <DropdownItem
                                      onClick={(e) => {
                                        e.preventDefault();
                                        this.props.history.push(
                                          "/medic-detail"
                                        );
                                      }}
                                    >
                                      Detail
                                    </DropdownItem>
                                    <DropdownItem
                                      onClick={(e) => {
                                        e.preventDefault();

                                        this.innerDeleteMedic(medic);
                                      }}
                                    >
                                      Delete
                                    </DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  )}{" "}
                </Card>
              </div>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

export default MedicsOverview;
