import React from 'react';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Table,
  Container,
  Col,
  Row,
} from 'reactstrap';
// core components
import AdminNavbar from 'components/Navbars/AdminNavbar.jsx';
import PromotionalPlanEditionModal from './PromotionalPlanEditionModal.jsx';

import { authRef } from 'config/firebase';

import {
  listPromotionalPlans,
  createPromotionalPlan,
  updatePromotionalPlan,
  deletePromotionalPlan,
} from 'services/promotionalPlansServices';

import { listPlans } from 'services/plansServices';

class PromotionalPlansOverview extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      promotionalPlans: null,
      medics: null,
      emergencies: null,
      homeCares: null,
      telemedicines: null,
      lifeInsurances: null,
      homeAssistances: null,
    };
  }

  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
    });
  };

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    authRef.onAuthStateChanged((user) => {
      this.setState({ currentUser: user });

      listPromotionalPlans()
        .then((promotionalPlans) => {
          console.log('promotionalPlans: ', promotionalPlans);

          this.setState({ promotionalPlans: promotionalPlans });
        })
        .catch((e) => {
          if (e.response && e.response.data && e.response.data.message)
            alert(e.response.data.message);
          else alert(e.message);
        });

      listPlans()
        .then((plans) => {
          console.log('plans: ', plans);

          this.setState({ plans: plans });
        })
        .catch((e) => {
          if (e.response && e.response.data && e.response.data.message)
            alert(e.response.data.message);
          else alert(e.message);
        });
    });
  }

  componentDidUpdate(e) {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.mainContent.scrollTop = 0;
  }

  hasItem = (items, item) => {
    if (!items) return false;

    if (!item) return false;

    if (!items.length) return false;

    let exists = false;
    items.forEach((i) => {
      if (i.id === item.id) exists = true;
    });

    return exists;
  };

  savePromotionalPlan(promotionalPlan) {
    if (
      !promotionalPlan.name ||
      !promotionalPlan.associatedPlans ||
      !promotionalPlan.description ||
      !promotionalPlan.includedBullets
    ) {
      alert('Faltan datos requeridos');
      return;
    }

    promotionalPlan.associatedPlans = promotionalPlan.associatedPlans.map((associatedPlan) => {
      return {
        id: associatedPlan.id,
        prices: associatedPlan.prices,
        maxFamilyMembers: associatedPlan.maxFamilyMembers,
        name: associatedPlan.name,
        products: associatedPlan.products,
        planType: associatedPlan.planType,
        planSwitcherType: associatedPlan.planSwitcherType,
      };
    });

    if (promotionalPlan.id)
      updatePromotionalPlan(promotionalPlan)
        .then((promotionalPlan) => {
          this.loadData();
          console.log('PromotionalPlan: ', promotionalPlan);

          this.toggleModal('promotionalPlanEditionModal');
        })
        .catch((e) => {
          if (e.response && e.response.data && e.response.data.message)
            alert(e.response.data.message);
          else alert(e.message);
        });
    else
      createPromotionalPlan(promotionalPlan)
        .then((promotionalPlan) => {
          this.loadData();
          console.log('PromotionalPlan: ', promotionalPlan);

          this.toggleModal('promotionalPlanEditionModal');
        })
        .catch((e) => {
          if (e.response && e.response.data && e.response.data.message)
            alert(e.response.data.message);
          else alert(e.message);
        });
  }

  innerDeletePromotionalPlan(promotionalPlan) {
    deletePromotionalPlan(promotionalPlan)
      .then((promotionalPlan) => {
        this.loadData();
        console.log('PromotionalPlan: ', promotionalPlan);
        alert('PromotionalPlan: ' + JSON.stringify(promotionalPlan) + 'removed');
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.message)
          alert(e.response.data.message);
        else alert(e.message);
      });
  }

  render() {
    return (
      <>
        <div className="main-content" ref="mainContent">
          <AdminNavbar {...this.props} currentUser={this.state.currentUser} />

          {this.state.plans && this.state.promotionalPlans && this.state.promotionalPlanToEdit && (
            <PromotionalPlanEditionModal
              modalName="promotionalPlanEditionModal"
              medics={this.state.medics}
              emergencies={this.state.emergencies}
              homeCares={this.state.homeCares}
              telemedicines={this.state.telemedicines}
              lifeInsurances={this.state.lifeInsurances}
              promotionalPlanEditionModalIsOpen={this.state.promotionalPlanEditionModal}
              promotionalPlan={this.state.promotionalPlanToEdit}
              plans={this.state.plans}
              toggleModal={this.toggleModal.bind(this)}
              savePromotionalPlan={this.savePromotionalPlan.bind(this)}
            />
          )}

          <Container fluid>
            {/* COVERAGES */}
            <Row>
              <div className="col">
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <Row>
                      <Col lg="10">
                        <h3 className="mb-0">PromotionalPlans</h3>
                      </Col>
                      <Col lg="2">
                        <div className="btn-wrapper">
                          <Button
                            color="success"
                            onClick={(e) => {
                              e.preventDefault();

                              this.setState({ promotionalPlanToEdit: {} });
                              this.toggleModal('promotionalPlanEditionModal');
                            }}
                          >
                            New
                          </Button>
                          {/* <Button
                            className="btn-white"
                            color="default"
                            to="/register-page"
                            tag={Link}
                          >
                            Register Page
                          </Button> */}
                        </div>
                      </Col>
                    </Row>
                  </CardHeader>
                  {!this.state.promotionalPlans && <div>Loading</div>}
                  {this.state.promotionalPlans && (
                    <Table className="align-items-center table-flush" responsive>
                      <thead className="thead-light">
                        <tr>
                          <th scope="col">Id</th>
                          <th scope="col">Name</th>
                          <th scope="col">Description</th>

                          <th scope="col">IncludedBullets</th>

                          <th scope="col" />
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.promotionalPlans.map((promotionalPlan, index) => {
                          return (
                            <tr key={index}>
                              <th scope="row">
                                <Media className="align-items-center">
                                  <a
                                    className="avatar rounded-circle mr-3"
                                    onClick={(e) => e.preventDefault()}
                                  >
                                    {promotionalPlan.id}
                                  </a>
                                </Media>
                              </th>
                              <td>
                                <Media>
                                  <span className="mb-0 text-sm">{promotionalPlan.name}</span>
                                </Media>
                              </td>
                              <td>{promotionalPlan.description}</td>

                              <td>
                                {promotionalPlan.includedBullets && (
                                  <ul>
                                    {promotionalPlan.includedBullets.map((item, index) => {
                                      return <li key={index}>{item}</li>;
                                    })}
                                  </ul>
                                )}
                              </td>

                              <td className="text-right">
                                <UncontrolledDropdown>
                                  <DropdownToggle
                                    className="btn-icon-only text-light"
                                    href="#"
                                    role="button"
                                    size="sm"
                                    color=""
                                    onClick={(e) => e.preventDefault()}
                                  >
                                    <i className="fas fa-ellipsis-v" />
                                  </DropdownToggle>
                                  <DropdownMenu className="dropdown-menu-arrow" right>
                                    <DropdownItem
                                      onClick={(e) => {
                                        e.preventDefault();

                                        this.setState({
                                          promotionalPlanToEdit: promotionalPlan,
                                        });
                                        this.toggleModal('promotionalPlanEditionModal');
                                      }}
                                    >
                                      Edit
                                    </DropdownItem>

                                    <DropdownItem
                                      onClick={(e) => {
                                        e.preventDefault();
                                        this.props.history.push('/promotionalPlan-detail');
                                      }}
                                    >
                                      Detail
                                    </DropdownItem>
                                    <DropdownItem
                                      onClick={(e) => {
                                        e.preventDefault();

                                        this.innerDeletePromotionalPlan(promotionalPlan);
                                      }}
                                    >
                                      Delete
                                    </DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  )}{' '}
                </Card>
              </div>
            </Row>
            <br />
            <hr></hr>
          </Container>
        </div>
      </>
    );
  }
}

export default PromotionalPlansOverview;
