import React from "react";
// reactstrap components
import { Swiper, SwiperSlide } from "swiper/react";

import { Card, CardBody, Container, CardTitle, CardSubtitle } from "reactstrap";

const characterImg = require("assets/img/brand/Character.png")
class SliderAboutUs extends React.Component {
  
  render() {
    return (
      <>
        {/*Slider*/}
        <section className="section section-lg2 section-home-2 pt-150 pb-0  bg-light-grey">
          <Container className="d-flex">
            <div className="col px-4">
              <Swiper slidesPerView={"auto"} className="slider">
                <SwiperSlide>
                  <Card className="custom-home">
                    <CardBody>
                      <div className="image">
                        <img
                          alt="..."
                          style={{ width: "100px" }}
                          src={characterImg.default}
                        />
                      </div>
                      <CardTitle>
                        Médicos online y a domicilio al instante
                      </CardTitle>
                      <CardSubtitle>
                        Médicos clínicos y pediatras las 24hs, los 7 días de la
                        semana a tu disposición.
                      </CardSubtitle>
                    </CardBody>
                  </Card>
                </SwiperSlide>
                <SwiperSlide>
                  <Card className="custom-home">
                    <CardBody>
                      <div className="image">
                        <img
                          alt="..."
                          style={{}}
                          src={require("assets/img/brand/Review.png").default}
                        />
                      </div>
                      <CardTitle>La mejor cartilla médica</CardTitle>
                      <CardSubtitle>
                        Todas las especialidades, desde traumatólogos hasta
                        odontólogos.
                      </CardSubtitle>
                    </CardBody>
                  </Card>
                </SwiperSlide>
                <SwiperSlide>
                  <Card className="custom-home">
                    <CardBody>
                      <div className="image">
                        <img
                          alt="..."
                          style={{}}
                          src={require("assets/img/brand/Character2.png").default}
                        />
                      </div>
                      <CardTitle>40% descuento en farmacias</CardTitle>
                      <CardSubtitle>
                        Aprovecha este descuento en todas las farmacias del
                        país.
                      </CardSubtitle>
                    </CardBody>
                  </Card>
                </SwiperSlide>
                <SwiperSlide>
                  <Card className="custom-home">
                    <CardBody>
                      <div className="image">
                        <img
                          alt="..."
                          style={{ width: "180px" }}
                          src={require("assets/img/brand/Chat.png").default}
                        />
                      </div>
                      <CardTitle>
                        Para vos, tu familia, o solo para tus hijos
                      </CardTitle>
                      <CardSubtitle>
                        Los planes se ajustan a vos y a tus necesidades.
                      </CardSubtitle>
                    </CardBody>
                  </Card>
                </SwiperSlide>
                <SwiperSlide>
                  <Card className="custom-home">
                    <CardBody>
                      <div className="image">
                        <img
                          alt="..."
                          style={{ width: "150px" }}
                          src={require("assets/img/brand/Relax.png").default}
                        />
                      </div>
                      <CardTitle>Seguro de internación</CardTitle>
                      <CardSubtitle>
                        Cubrimos los gastos en caso que vos o tu familia se
                        tengan que internar.
                      </CardSubtitle>
                    </CardBody>
                  </Card>
                </SwiperSlide>
              </Swiper>
            </div>
          </Container>
        </section>
      </>
    );
  }
}

export default SliderAboutUs;
