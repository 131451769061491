import React from "react";
// nodejs library that concatenates classes

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  Container,
  Row,
  Col,
  CardHeader,
} from "reactstrap";

// core components
import WhatsappButton from "components/Buttons/WhatsappButton";
import BasicNavbar from "components/Navbars/BasicNavbar.jsx";

class FatalError extends React.Component {
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }

  render() {
    return (
      <>
        <WhatsappButton />

        <BasicNavbar />
        <main ref="main">
          <section className="section section-shaped bg-light-grey">
            <Container>
              <Row className="justify-content-center">
                <Col lg="8">
                  <Card className="bg-transparent card-form border-0">
                    <CardHeader className="pb-0 bg-transparent">
                      <img
                        alt="..."
                        className="img-center"
                        style={{}}
                        src={require("assets/img/brand/error.png")}
                      />
                      <div className="big-title text-center">
                        ¡Hola! Hubo un error en el proceso
                      </div>
                    </CardHeader>
                    <CardBody>
                      {/* <p className="text-end">
                        Algo habrá pasado que vamos a explicarte acá abajo, no
                        sabemos cómo aún, pero bueno, es clave que tengamos una
                        respuesta por cada posible error
                      </p> */}
                      {this.props.location.state.error && (
                        <>
                          <p className="text-end">
                            {this.props.location.state.error.code}
                          </p>
                          <p className="text-end">
                            {this.props.location.state.error.message}
                          </p>
                        </>
                      )}
                    </CardBody>

                    <div className="text-center">
                      <Button
                        color="custom noarrow"
                        type="button"
                        onClick={() => {
                          this.props.history.goBack();
                        }}
                      >
                        Reintentar
                      </Button>
                    </div>
                  </Card>
                </Col>
              </Row>
            </Container>
          </section>
        </main>
      </>
    );
  }
}

export default FatalError;
