import { getHttpBearerConfig } from 'services/httpServices';
import axios from 'axios';

const appConfig = require('config/appConfig');

export const listHomeCares = (currentUserToken) => {
  return new Promise(function (resolve, reject) {
    //exports.saveRole = function(currentUserToken, targetUser, role) {
    let config = getHttpBearerConfig(currentUserToken);

    return axios
      .get(`${appConfig.gcfUrl}/homeCares`, config)
      .then((response) => {
        console.log('homeCares response:', response);
        if (response && response.data) resolve(response.data);
        else resolve([]);
      })
      .catch((e) => {
        console.log('error:', e);

        reject(e);
      });
  });
};

export const createHomeCare = (currentUserToken, homeCare) => {
  return new Promise(function (resolve, reject) {
    //exports.saveRole = function(currentUserToken, targetUser, role) {
    let config = getHttpBearerConfig(currentUserToken);

    return axios
      .post(
        `${appConfig.gcfUrl}/homeCares`,
        {
          name: homeCare.name,
          description: homeCare.description,
          tel: homeCare.tel,
          address: homeCare.address,
        },
        config
      )
      .then((response) => {
        console.log('create homeCare response:', response);
        if (response && response.data) resolve(response.data);
        else resolve(null);
      })
      .catch((e) => {
        console.log('error:', e);

        reject(e);
      });
  });
};

export const updateHomeCare = (currentUserToken, homeCare) => {
  return new Promise(function (resolve, reject) {
    //exports.saveRole = function(currentUserToken, targetUser, role) {
    let config = getHttpBearerConfig(currentUserToken);

    return axios
      .patch(
        `${appConfig.gcfUrl}/homeCares/${homeCare.id}`,
        {
          name: homeCare.name,
          description: homeCare.description,
          tel: homeCare.tel,
          address: homeCare.address,
        },
        config
      )
      .then((response) => {
        console.log('update homeCare response:', response);
        if (response && response.data) resolve(response.data);
        else resolve(null);
      })
      .catch((e) => {
        console.log('error:', e);

        reject(e);
      });
  });
};

export const deleteHomeCare = (currentUserToken, homeCare) => {
  return new Promise(function (resolve, reject) {
    let config = getHttpBearerConfig(currentUserToken);

    return axios
      .delete(`${appConfig.gcfUrl}/homeCares/${homeCare.id}`, config)
      .then((response) => {
        console.log('delete homeCare response:', response);
        if (response && response.data) resolve(response.data);
        else resolve(null);
      })
      .catch((e) => {
        console.log('error:', e);

        reject(e);
      });
  });
};
