import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

import 'assets/vendor/nucleo/css/nucleo.css';
import 'assets/vendor/font-awesome/css/font-awesome.min.css';
import 'assets/scss/argon-design-system-react.scss';

// import LifeInsuranceProductLanding from 'views/Landings/LifeInsuranceProductLanding';
import LifeInsuranceProductLandingForm from 'views/Landings/LifeInsuranceProductLandingForm';

import ContactUsLanding from 'views/Landings/ContactUsLanding';
import GetAppLanding from 'views/Landings/GetAppLanding';

import ContactUsSuccess from 'views/Landings/ContactUsSuccess';
import AboutUs from 'views/Landings/AboutUs';
import RiskClasification from 'views/Landings/RiskClasification';

import Login from 'views/Auth/Login';
import ResetPassword from 'views/Auth/ResetPassword';
import ResetPasswordSuccess from 'views/Auth/ResetPasswordSuccess';
import ForgotPassword from 'views/Auth/ForgotPassword';

import FatalError from 'views/Error/FatalError';

import DigitalOnboardingValidateSMS from 'views/Onboarding/DigitalOnboardingValidateSMS';

import DigitalOnboardingStep1PlanSelection from 'views/Onboarding/DigitalOnboardingStep1PlanSelection';
import DigitalOnboardingStep2BasicData from 'views/Onboarding/DigitalOnboardingStep2BasicData';

import DigitalOnboardingStep3Beneficiaries from 'views/Onboarding/DigitalOnboardingStep3Beneficiaries';
//import DigitalOnboardingStep4PolicyConsent from "views/Onboarding/DigitalOnboardingStep4PolicyConsent";
//import DigitalOnboardingStep5AdditionalInfo from "views/Onboarding/DigitalOnboardingStep5AdditionalInfo";
import DigitalOnboardingStep4Checkout from 'views/Onboarding/DigitalOnboardingStep4Checkout';
import DigitalOnboardingStep5Review from 'views/Onboarding/DigitalOnboardingStep5Review';
import DigitalOnboardingStep8Success from 'views/Onboarding/DigitalOnboardingStep8Success';

import UserProfile from 'views/Users/UserProfile';
import LogsDashboard from 'views/Logs/LogsDashboard';

import PromotionalPlansOverview from 'views/PromotionalPlans/PromotionalPlansOverview';
import CoveragesOverview from 'views/Coverages/CoveragesOverview';
import AssistancesOverview from 'views/Assistances/AssistancesOverview';
import AssistanceDetail from 'views/Assistances/AssistanceDetail';

import LeadsOverview from 'views/Leads/LeadsOverview';
import MedicsOverview from 'views/Medics/MedicsOverview';

import UsersDashboard from 'views/Users/UsersDashboard';
import Policy from 'views/Legals/Policy';
import BajaCuenta from 'views/Legals/BajaCuenta';

import PromotionsOverview from 'views/Manage/PromotionsOverview';
import NotificationEventsOverview from 'views/Manage/NotificationEventsOverview';

import ParametersOverview from 'views/Manage/ParametersOverview';

//import FirebaseAuthProvider from "contexts/authContext";

import { AuthProvider } from 'contexts/auth/auth.provider';

import { LeadProvider } from 'contexts/onboarding/use-lead';

// avoid local machine inspectors
if (process.env.REACT_APP_ENVIRONMENT === 'local') {
  setInterval(() => {
    const iframes = document.querySelectorAll('iframe');
    for (let i = 0; i < iframes.length; i++) {
      const iframe = iframes[i];
      if (iframe && (!iframe.id || iframe.id === '')) iframes[i].parentNode.removeChild(iframes[i]);
    }
  }, 3000);
}

ReactDOM.render(
  <AuthProvider>
    <LeadProvider>
      <BrowserRouter>
        <Switch>
          {/* <Route
            path="/"
            exact
            redi
            render={(props) => <LifeInsuranceProductLanding {...props} />}
          />
          <Route
            path="/"
            exact
            redi
            render={(props) => <LifeInsuranceProductLanding {...props} />}
          /> */}

          <Route
            path="/"
            exact
            redi
            render={(props) => (window.location = 'https://holarene.com')}
          />

          <Route
            path="/asociarme-salud"
            exact
            render={(props) => <LifeInsuranceProductLandingForm {...props} />}
          />
          <Route path="/health-tyc" exact render={(props) => <GetAppLanding {...props} />} />

          <Route path="/get-app" exact render={(props) => <GetAppLanding {...props} />} />

          <Route path="/contact-us" exact render={(props) => <ContactUsLanding {...props} />} />

          <Route
            path="/risk-clasification"
            exact
            render={(props) => <RiskClasification {...props} />}
          />

          <Route
            path="/contact-us-success"
            exact
            render={(props) => <ContactUsSuccess {...props} />}
          />
          <Route
            path="/asociarme-salud-success"
            exact
            render={(props) => <ContactUsSuccess {...props} />}
          />

          <Route path="/about-us" exact render={(props) => <AboutUs {...props} />} />

          <Route path="/error" exact render={(props) => <FatalError {...props} />} />

          <Route path="/login" exact render={(props) => <Login {...props} />} />

          <Route
            path="/onboarding"
            exact
            render={(props) => <DigitalOnboardingStep1PlanSelection {...props} />}
          />

          <Route
            path="/phone-validation"
            exact
            render={(props) => <DigitalOnboardingValidateSMS {...props} />}
          />

          <Route
            path="/onboarding-step-2"
            exact
            render={(props) => <DigitalOnboardingStep2BasicData {...props} />}
          />

          <Route
            path="/onboarding-step-3"
            exact
            render={(props) => <DigitalOnboardingStep3Beneficiaries {...props} />}
          />

          <Route
            path="/onboarding-step-4"
            exact
            render={(props) => <DigitalOnboardingStep4Checkout {...props} />}
          />

          <Route
            path="/onboarding-step-5"
            exact
            render={(props) => <DigitalOnboardingStep5Review {...props} />}
          />

          <Route
            path="/success"
            exact
            render={(props) => <DigitalOnboardingStep8Success {...props} />}
          />

          <Route path="/users" exact render={(props) => <UsersDashboard {...props} />} />

          <Route path="/coverages" exact render={(props) => <CoveragesOverview {...props} />} />

          <Route
            path="/promotional-plans"
            exact
            render={(props) => <PromotionalPlansOverview {...props} />}
          />

          <Route path="/user-profile/:id" render={(props) => <UserProfile {...props} />} />

          <Route path="/logs" render={(props) => <LogsDashboard {...props} />} />

          <Route path="/logs/:id" render={(props) => <LogsDashboard {...props} />} />

          <Route path="/assistances" exact render={(props) => <AssistancesOverview {...props} />} />

          <Route path="/leads" exact render={(props) => <LeadsOverview {...props} />} />

          <Route path="/medics" exact render={(props) => <MedicsOverview {...props} />} />

          <Route
            path="/assistance-detail/:id"
            exact
            render={(props) => <AssistanceDetail {...props} />}
          />

          <Route path="/policy" exact render={(props) => <Policy {...props} />} />
          <Route path="/baja" exact render={(props) => <BajaCuenta {...props} />} />

          <Route path="/promotions" exact render={(props) => <PromotionsOverview {...props} />} />

          <Route path="/parameters" exact render={(props) => <ParametersOverview {...props} />} />

          <Route
            path="/notification-events"
            exact
            render={(props) => <NotificationEventsOverview {...props} />}
          />

          <Route path="/reset-password" exact render={(props) => <ResetPassword {...props} />} />

          <Route
            path="/reset-password-success"
            exact
            render={(props) => <ResetPasswordSuccess {...props} />}
          />

          <Route path="/forgot-password" exact render={(props) => <ForgotPassword {...props} />} />

          <Redirect to="/" />
        </Switch>
      </BrowserRouter>
    </LeadProvider>
  </AuthProvider>,
  document.getElementById('root')
);
